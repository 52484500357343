import {UyapPaginationResponse, UyapRequest} from "./UyapRequest";
import UyapHtmlParser from "./parser/UyapHtmlParser";

export interface OdemeIslemiSorgulamaTalep {
    baslangicTarihi: string,
    bitisTarihi: string,
}

export interface OdemeIslemiSorgulamaCevap extends UyapPaginationResponse<OdemeIslemiSorgulamaRow> {
}

export interface OdemeIslemiSorgulamaRow {
    uyapId: number,
    uyapIslemTarihi: string,
    birimAdi: string,
    dosyaNo: string,
    durum: string,
    bankaAdi: string,
    tutar: number,
    borcluHesapNo: string,
    birimId: number,
    dosyaId: number,
    dosyaYil: number,
    dosyaSira: number,
    dosyaTurKod: string,
    uyapIslemTur: number,
    formatliUyapIslemTarihi: string,
    dosyaTaraflari: {
        isim: string
        soyad?: string,
        sifat: string
    }[],
}

/*
 dosyaTaraflari:
    {
        liste:
        {
            DosyaTaraflariDVOForPortal: [
            {
                isim: ZURICH ELEKTRİK PERAKENDE SATIŞ ANONİM ŞİRKETİ,
                sifat: ALACAKLI
            },
            {
                isim: AHMET,
                soyad: MEHMET,
                sifat: BORÇLU VE MÜFLİS
            }]
        },
        actualCount: 0,
        currentPage: 0,
        pageSize: 0,
        cacheID: 0,
        recordCount: 0
    },
 */

export default class OdemeIslemiSorgulama extends UyapRequest<OdemeIslemiSorgulamaTalep, OdemeIslemiSorgulamaCevap> {
    delayTime = 0;
    mockResponse: string = `<root><DVOList>
  <liste>
    <OdemeIslemleriRaporDVO>
      <uyapId>229679607</uyapId>
      <uyapIslemTarihi>2021-09-14 00:50:16.0</uyapIslemTarihi>
      <birimAdi>İstanbul Anadolu Abonelik Sözleşmeleri İcra Dairesi</birimAdi>
      <dosyaNo>2021/170984</dosyaNo>
      <durum>Başarılı</durum>
      <bankaAdi>Barokart</bankaAdi>
      <tutar>2.72</tutar>
      <borcluHesapNo>0041-00158007312418947</borcluHesapNo>
      <birimId>1059117</birimId>
      <dosyaId>643857739</dosyaId>
      <dosyaYil>2023</dosyaYil>
      <dosyaSira>170984</dosyaSira>
      <dosyaTurKod>İcra Dosyası</dosyaTurKod>
      <uyapIslemTur>44</uyapIslemTur>
      <dosyaTaraflari>
        <liste>
          <DosyaTaraflariDVOForPortal>
            <isim>ZURICH ELEKTRİK PERAKENDE SATIŞ ANONİM ŞİRKETİ</isim>
            <sifat>ALACAKLI</sifat>
          </DosyaTaraflariDVOForPortal>
          <DosyaTaraflariDVOForPortal>
            <isim>AHMET</isim>
            <soyad>MEHMET</soyad>
            <sifat>BORÇLU VE MÜFLİS</sifat>
          </DosyaTaraflariDVOForPortal>
        </liste>
        <actualCount>0</actualCount>
        <currentPage>0</currentPage>
        <pageSize>0</pageSize>
        <cacheID>0</cacheID>
        <recordCount>0</recordCount>
      </dosyaTaraflari>
    </OdemeIslemleriRaporDVO>
    <OdemeIslemleriRaporDVO>
      <uyapId>230199695</uyapId>
      <uyapIslemTarihi>2021-09-15 15:07:00.0</uyapIslemTarihi>
      <birimAdi>İstanbul Anadolu Abonelik Sözleşmeleri İcra Dairesi</birimAdi>
      <dosyaNo>2023/11475</dosyaNo>
      <durum>Başarılı</durum>
      <bankaAdi>Barokart</bankaAdi>
      <tutar>2.69</tutar>
      <borcluHesapNo>0041-00158007312418947</borcluHesapNo>
      <birimId>1059117</birimId>
      <dosyaId>644286369</dosyaId>
      <dosyaYil>2023</dosyaYil>
      <dosyaSira>171475</dosyaSira>
      <dosyaTurKod>İcra Dosyası</dosyaTurKod>
      <uyapIslemTur>44</uyapIslemTur>
      <dosyaTaraflari>
        <liste>
          <DosyaTaraflariDVOForPortal>
            <isim>BERLİN ELEKTRİK PERAKENDE SATIŞ ANONİM ŞİRKETİ</isim>
            <sifat>ALACAKLI</sifat>
          </DosyaTaraflariDVOForPortal>
          <DosyaTaraflariDVOForPortal>
            <isim>HANS</isim>
            <soyad>BOUER</soyad>
            <sifat>BORÇLU VE MÜFLİS</sifat>
          </DosyaTaraflariDVOForPortal>
        </liste>
        <actualCount>0</actualCount>
        <currentPage>0</currentPage>
        <pageSize>0</pageSize>
        <cacheID>0</cacheID>
        <recordCount>0</recordCount>
      </dosyaTaraflari>
    </OdemeIslemleriRaporDVO></liste>
  <actualCount>0</actualCount>
  <currentPage>0</currentPage>
  <pageSize>0</pageSize>
  <cacheID>0</cacheID>
  <recordCount>0</recordCount>
</DVOList></root>`;

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    async parse(data: string): Promise<OdemeIslemiSorgulamaCevap> {
        let jsonObj: any = await this.xml2json(data)
        return {
            currentPage: jsonObj.root.DVOList.currentPage[0],
            pageSize: jsonObj.root.DVOList.pageSize[0],
            items: jsonObj.root.DVOList.liste.OdemeIslemleriRaporDVO.map(
                (row: any): OdemeIslemiSorgulamaRow => {
                    let normalizedRow: OdemeIslemiSorgulamaRow = UyapHtmlParser.normalizeXmlSourcedJson(row);
                    if (normalizedRow && normalizedRow.uyapIslemTarihi) {
                        let d = new Date(normalizedRow.uyapIslemTarihi);
                        normalizedRow.formatliUyapIslemTarihi = this.getTrDate(d);
                    }
                    // @ts-ignore
                    normalizedRow.dosyaTaraflari = normalizedRow.dosyaTaraflari.liste.DosyaTaraflariDVOForPortal.map((taraf: any) => UyapHtmlParser.normalizeXmlSourcedJson(taraf));

                    // console.log(normalizedRow.dosyaTaraflari);
                    // normalizedRow.dosyaTaraflari = normalizedRow.dosyaTaraflari.liste[0].DosyaTaraflariDVOForPortal.map( (taraf) => {return {
                    //     console.log(taraf);
                    // }});
                    return normalizedRow;
                }
            )
        };
    }

    getTrDate(str: any, delimier: string = "") {
        if (typeof delimier == "undefined")
            delimier = "/";
        return str.toISOString().substring(0, 10).split("-").reverse().join(delimier);
    }

    getMockResponse(): any {
        return this.mockResponse;
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/uyap_odeme_islemi_sorgula.ajx";
    }

    // parse(data: string): Promise<AvukatSorgulamaCevap> {
    //     throw new DOMException("Not Implemented");
    // }
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

}


