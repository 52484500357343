import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    // {
    //   path: '/config',
    //   name: 'config',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/ConfigView.vue')
    // },
    {
        path: '/islemler',
        name: 'islemler',
        component: () => import('@/views/IslemlerView.vue')
    },
    {
        path: '/takip-hazirla-detay/:id',
        name: 'takipHazirlaDetay',
        component: () => import('@/views/islem-detay/TakipHazirlaIslemDetayView.vue')
    },
    {
        path: '/takip-ac-detay/:id',
        name: 'takipAcDetay',
        component: () => import('@/views/islem-detay/TakipAcIslemDetayView.vue')
    },
    {
        path: '/mts-takip-hazirla-detay/:id',
        name: 'mtsTakipHazirlaDetay',
        component: () => import('@/views/islem-detay/MtsTakipHazirlaIslemDetayView.vue')
    },
    {
        path: '/mts-takip-ac-detay/:id',
        name: 'mtsTakipAcDetay',
        component: () => import('@/views/islem-detay/MtsTakipAcIslemDetayView.vue')
    },
    {
        path: '/takip-sorgula-detay/:id',
        name: 'takipSorgulaDetay',
        component: () => import('@/views/islem-detay/TakipSorgulaIslemDetayView.vue')
    },
    {
        path: '/evrak-gonder-detay/:id',
        name: 'evrakGonderDetay',
        component: () => import('@/views/islem-detay/EvrakGonderIslemDetayView.vue')
    },
    {
        path: '/hazir-evrak-gonder-detay/:id',
        name: 'haziEvrakGonderDetay',
        component: () => import('@/views/islem-detay/HazirEvrakGonderIslemDetayView.vue')
    },
    {
        path: '/makbuz-indir-detay/:id',
        name: 'makbuzIndirDetay',
        component: () => import('@/views/islem-detay/MakbuzIndirIslemDetayView.vue')
    },
    {
        path: '/genel-talep-gonder-detay/:id',
        name: 'genelTalepGonderDetay',
        component: () => import('@/views/islem-detay/TopluGenelTalepGonderIslemDetayView.vue')
    },
    {
        path: '/tebligat-gonder-detay/:id',
        name: 'tebligatGonderDetay',
        component: () => import('@/views/islem-detay/TopluTebligatGonderIslemDetayView.vue')
    },
    {
        path: '/haciz-gonder-detay/:id',
        name: 'hacizGonderDetay',
        component: () => import('@/views/islem-detay/TopluHacizGonderIslemDetayView.vue')
    },
    {
        path: '/harc-masraf-ode-detay/:id',
        name: 'harcMasrafOdeDetay',
        component: () => import('@/views/islem-detay/HarcMasrafOdeIslemDetayView.vue')
    },
    {
        path: '/mts-ode-detay/:id',
        name: 'mtsOdemeDetay',
        component: () => import('@/views/islem-detay/MtsOdemeIslemDetayView.vue')
    },
    {
        path: '/mts-tebligat-gonder-detay/:id',
        name: 'mtsTebligatGonderDetay',
        component: () => import('@/views/islem-detay/MtsTebligatGonderIslemDetayView.vue')
    },
    {
        path: '/evrak-indir-detay/:id',
        name: 'evrakIndirDetay',
        component: () => import('@/views/islem-detay/EvrakIndirIslemDetayView.vue')
    },
    {
        path: '/deneme-tahtasi',
        name: 'denemeTahtasi',
        component: () => import('@/views/DenemeTahtasi.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
