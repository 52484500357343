import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface DosyaKapatmaNedenleriTalep {
    dosyaTurKod: number, //35
}

export interface DosyaKapatmaNedenleriCevap {
    items: Array<DosyaKapatmaNedenleriCevapRow>
}

export interface DosyaKapatmaNedenleriCevapRow {
    name: string,
    value: string
}

export default class DosyaKapatmaNedenleri extends UyapRequest<DosyaKapatmaNedenleriTalep, DosyaKapatmaNedenleriCevap> {
    getMethod(): "POST" | "GET" {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "DosyaKapatmaNedenleri_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/getDosyaKapatmaNedenleri_brd.ajx";
    }

    async parse(data: any): Promise<DosyaKapatmaNedenleriCevap> {
        return {items: data};
    }

    getMockResponse(): any {
        return [
            {"name": "İnfaz", "value": 0},
            {"name": "Takipsizlik", "value": 1},
            {"name": "Vazgeçme/Feragat", "value": 2},
            {"name": "Haricen Tahsil", "value": 3},
            {"name": "Aciz Vesikası", "value": 4},
            {"name": "Yetkisizlik", "value": 5},
            {"name": "Takipte İflas Yolunun Seçilmesi", "value": 6},
            {"name": "Takibin İptali", "value": 7},
            {"name": "Rehin Açığı Belgesi", "value": 8},
            {"name": "Birleştirme", "value": 9},
            {"name": "İİK.150/e Maddesi Gereğince Takibin Düşmesi", "value": 10},
            {"name": "İİK.193. Madde Gereği", "value": 11},
            {"name": "Zaman Aşımı (İİK 39)", "value": 12},
            {"name": "KHK Gereği Düşme", "value": 13}
        ]
    }
}