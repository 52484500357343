import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, ResponseType} from "axios";
import UyapHtmlParser from './parser/UyapHtmlParser';
import CACHE_STORAGE from 'sls-cache'
import {UyapRequestResponseHandler} from "./UyapRequestResponseHandler";
import app from "@/main";
import {StatusEnum} from "@/plugins/uyap-plugin/enum/StatusEnum";
import {clearInterval} from "timers";

CACHE_STORAGE.config({cacheName: 'uyapRequestCache', defaultTTL: 60 * 24});

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export interface UyapPaginationResponse<T> {
    items: T[],
    currentPage: number,
    pageSize: number
}

export function isArray(data: any): boolean {
    return Array.isArray(data);
}

export function isUndefined(item: any): boolean {
    return typeof item == "undefined";
}


export abstract class UyapRequest<T, C> extends UyapRequestResponseHandler {
    axios = axios;
    ImzaClientAddr = "https://imza.eicrapro.com:6081/";
    htmlParser = UyapHtmlParser;
    message!: string;
    talepData!: T;
    delayTime: number = 3000;
    uyapSessionFlag = false;
    uyapSessionMaxVal = 20;
    uyapSessionCounter = 0;
    uyapSessionInterval :any;
    async prepareRequestData(requestData: any): Promise<{ data: any, type: "urlencoded" | "formData" | "xml" | "json" }> {
        let data: any = requestData;
        let dataType: "urlencoded" | "formData" | "xml" | "json" = "urlencoded";
        if (typeof requestData.formData != "undefined") {
            console.log("FORMDATA INIT")
            data = requestData.data;
            dataType = "formData";
        } else if (typeof requestData.xml != "undefined") {
            data = requestData.data;
            dataType = "xml";
        } else if (typeof requestData.api != "undefined") {
            data = requestData.api;
            dataType = "json";
        }
        return {
            data: data,
            type: dataType
        }
    }

    getHeaders(data: any) {
        let jsessionId = window.sessionStorage.getItem("JSESSIONID");
        let headers = {
            "Token": jsessionId,//"JSESSIONID=0000Say0hve3UknBteFAizqT4Xt:1e3ae09ie",
            "Content-Type": "application/x-www-form-urlencoded"
        };
        if (typeof data != "undefined" && typeof data.formData != "undefined")
            headers["Content-Type"] = "multipart/form-data";
        if (typeof data != "undefined" && typeof data.xml != "undefined")
            headers["Content-Type"] = "application/xml;charset=UTF-8";
        if (typeof data != "undefined" && typeof data.api != "undefined")
            headers["Content-Type"] = "application/json;charset=UTF-8";
        return headers;
    }

    async execute(data: T): Promise<AxiosResponse> {
        let requestPayload = await this.prepareRequestData(data);
        let response;
        let axiosConfig = <AxiosRequestConfig>{
            url: this.getUrl(),
            method: this.getMethod(),
            headers: this.getHeaders(data),
            responseType: this.getResponseType(data),
            withCredentials: true
        }

        if (app.$store.getters.isEicraproDevMode) {
            await delay(this.delayTime);
            response = <AxiosResponse>{
                statusText: "OK",
                status: 200,
                data: this.getMockResponse(),
            };
        } else {
            //let params =  new URLSearchParams(data)
            switch (this.getMethod()) {
                case "GET":
                    axiosConfig.params = requestPayload.data;
                    break;
                case "POST":
                    if (requestPayload.type == "formData") {
                        axiosConfig.data = requestPayload.data;
                    } else {
                        axiosConfig.data = this.serialize(requestPayload.data);
                    }
                    break;
            }
            response = await this.axios(axiosConfig);
            /*let delayTime = defaultDelayTime;
            if(typeof this.getDelayTime() != "undefined"){
                delayTime = this.getDelayTime();
            }
             */
            await delay(this.delayTime);
        }
        return response
    }

    serialize(obj: any) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    public run(data: T): Promise<C> {
        return new Promise<C>(async (resolve, reject) => {
            this.talepData = data;
            let cacheArgs = this.cache();
            if (cacheArgs) {
                let cachedData = CACHE_STORAGE.get<C>(cacheArgs.key);
                if (cachedData) {
                    //console.log("RETURNED FROM CACHE STORAGE " + cacheArgs.key, cachedData);
                    let parsedResult = await this.parse(cachedData);
                    return resolve(parsedResult);
                }
            }
            this.talepData = data;
            this.execute(data).then((response: AxiosResponse) => {
                this.prepareResponseData(data,response).then((preparedResponse: AxiosResponse) => {
                    //console.log("PREPARED DATA",preparedResponse);
                    this.parse(preparedResponse.data).then((result: C) => {
                        if (cacheArgs && cacheArgs.active === true)
                            CACHE_STORAGE.set(cacheArgs.key, preparedResponse.data, cacheArgs.ttl);
                        return resolve(result);
                    }).catch((err: Error) => {
                        return reject(err);
                    })
                }).catch(preparedError => {
                    return reject({message: preparedError.message});
                });
            }).catch((err: AxiosError) => {
                return reject({message: err.message});
            });

        })
    }

    async prepareResponseData(requestData:any , data: AxiosResponse): Promise<AxiosResponse> {
        try {
            if (app.$store.state.eicraproDevMode == false) {
                data = await this.tryForBlob(data);
            }
            //this.sessionControl(data);
            data = await this.tryForXml(data);
            data = await this.tryForJson(data);
            return data;
            // @ts-ignore
        } catch (e) {
            console.log("error > prepareResponseData ", e);
            // @ts-ignore
            if (e.message == "nosessionobject" /*|| e.message == "Yapılmak istenen işlem sırasında hata oluştu. "*/) {
                //console.log("emit etcek ");
                if (!this.uyapSessionFlag){
                    return await this.uyapSessionCheck(requestData);
                }
            }
            // @ts-ignore
            throw new Error(e.message);
        }
    }

    uyapSessionCheck(requestData: any): Promise<AxiosResponse> {
        return  new Promise(((resolve, reject) => {
            this.uyapSessionFlag = true;
            app.$socket.client.emit('connectToUyap');
            this.uyapSessionInterval = setInterval(async () => {
                this.uyapSessionCounter ++;
                try {
                    let responseData = await this.execute(requestData);
                    let preparedResponseData = await this.prepareResponseData(requestData,responseData);
                    this.uyapSessionFlag = false;
                    console.log("cleared interval",preparedResponseData);
                    window.clearInterval(this.uyapSessionInterval);
                    return resolve(preparedResponseData);
                }catch (e) {
                    console.log("CATCH uyapSessionCheck",e);
                }finally {
                    if(this.uyapSessionCounter >= this.uyapSessionMaxVal){
                        this.uyapSessionCounter = 0;
                        window.clearInterval(this.uyapSessionInterval);
                    }
                }
            }, 7000);
        }))

    }


    protected getResponseType(data: any): ResponseType {
        if (typeof data != 'undefined' && typeof data.download != 'undefined') {
            return 'blob';
        }
        return 'text';
    }

    protected abstract getUrl(): string;

    protected abstract getMockResponse(): string | any;

    protected abstract getMethod(): 'POST' | 'GET';

    //protected abstract getDelayTime():number;
    protected abstract cache(): { active: boolean, ttl: number, key: string }

    protected abstract parse(data: string | Blob | any): Promise<C>;


}



