import {UyapRequest} from "./UyapRequest";
import {AdresRow} from "./CommonInterfaces";

export interface MtsIcraAcilisAlacakliTaraflarTalep {
    dosyaId: number | string
}

export interface MtsIcraAcilisAlacakliTaraflarCevap {
    taraflarList: Array<MtsIcraAcilisAlacakliTaraflarCevapRow>,
}


export interface MtsIcraAcilisAlacakliTaraflarCevapRow {
    ilgiliDosyaKisiId: string,
    asilTarafDosyaKisiId: string,
    ilgiliKisiTipi: string,
    ilgili: string,
    dosya: dosyaRow,
    ilgiliKisiIlkKisiKurumId: string,
    asilTarafIlkKisiKurumId: string,
    ilgiliKisiRolu: string,
    ilgiliKisiRolId: string,
    ilgilininHarcDurumu: string,
    sanikStatusu: string,
    addresList: Array<AdresRow>,
    secili: boolean,
    ilgiliKamuOzel: string,
    kisi: boolean,
    tcVatandasi: boolean,
    vergiNo: string,
    gulcelKisiKurumId: string,
    mersisNo: string
}

export interface clientDosyaDVORow {
    dosyaID: string,
    dosyaBilgileriTurKodu: number
}

export interface dosyaRow {
    clientDosyaDVO: clientDosyaDVORow,
    dosyaTur: number
}


export class MtsIcraAcilisAlacakliTaraflar extends UyapRequest<MtsIcraAcilisAlacakliTaraflarTalep, MtsIcraAcilisAlacakliTaraflarCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [
            {
                "ilgiliDosyaKisiId": "1000202043",
                "asilTarafDosyaKisiId": "1000202043",
                "ilgiliKisiTipi": "T",
                "ilgili": "ENERJİSA İSTANBUL ANADOLU YAKASI ELEKTRİK PERAKENDE SATIŞ ANONİM ŞİRKETİ",
                "dosya": {
                    "clientDosyaDVO": {
                        "dosyaID": "585123801",
                        "dosyaNotSayisi": 0,
                        "dosyaBilgileriTurKodu": 0
                    },
                    "dosyaTur": -1
                },
                "ilgiliKisiIlkKisiKurumId": "164725232",
                "asilTarafIlkKisiKurumId": "164725232",
                "ilgiliKisiRolu": "ALACAKLI",
                "ilgiliKisiRolId": "21",
                "ilgilininHarcDurumu": "1",
                "sanikStatusu": "H",
                "addresList": [
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "ATAŞEHİR",
                        "ilceKodu": 35,
                        "adres": "Barbaros Mah. Begonya Sok. Nida Kule Ataşehir Batı Sitesi No:1/1",
                        "kisiKurumID": 164725232,
                        "adresID": 298815729,
                        "adresTuru": "ADRTR00002",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 0,
                        "ilceKodu": 0,
                        "adres": " ",
                        "kisiKurumID": 164725232,
                        "adresID": 296205980,
                        "adresTuru": "ADRTR00013",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "MALTEPE",
                        "ilceKodu": 23,
                        "adres": "Bağlarbaşı Mah.RefahevlerSok. A Blok 2/1",
                        "kisiKurumID": 164725232,
                        "adresID": 299411433,
                        "adresTuru": "ADRTR00002",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "KADIKÖY",
                        "ilceKodu": 19,
                        "adres": "BARBAROS MAHALLESİ, BEGONYA SOKAK, NİDA KULE ATAŞEHİR BATI SİT. NO:1/1",
                        "kisiKurumID": 164725232,
                        "adresID": 297823564,
                        "adresTuru": "ADRTR00002",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "ATAŞEHİR",
                        "ilceKodu": 35,
                        "adres": "Barbaros Mahallesi, Begonya Sokak, Nida Kule Ataşehir Batı Sit. No:1/1",
                        "kisiKurumID": 164725232,
                        "adresID": 298558269,
                        "adresTuru": "ADRTR00002",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "MERKEZ",
                        "ilceKodu": 1,
                        "adres": "Barbaros Mahallesi No: No: 1 / 1",
                        "kisiKurumID": 164725232,
                        "adresID": 294522003,
                        "adresTuru": "ADRTR00002",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 0,
                        "ilceKodu": 0,
                        "adres": "BARBAROS MAH. BEGONYA SK. NİDA KULE ATAŞEHİR BATI Kapı No: 1 Daire No: 1 ATAŞEHİR/İSTANBUL",
                        "kisiKurumID": 164725232,
                        "adresID": 295835845,
                        "adresTuru": "ADRTR00021",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "ATAŞEHİR",
                        "ilceKodu": 35,
                        "adres": "Barbaros Mahallesi Begonya Sokak Nida Kule Ataşehir Batı Sit No:1/1 Ataşehir/İSTANBUL",
                        "kisiKurumID": 164725232,
                        "adresID": 297492346,
                        "adresTuru": "ADRTR00001",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "BEŞİKTAŞ",
                        "ilceKodu": 8,
                        "adres": "BALMUMCU MAH. ITRİ SOK. NO:36",
                        "kisiKurumID": 164725232,
                        "adresID": 298452852,
                        "adresTuru": "ADRTR00002",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "ATAŞEHİR",
                        "ilceKodu": 35,
                        "adres": "BARBAROS MAH. BEGONYA SOK. NİDA KULE ATAŞEHİR BATI SİT. NO:1/1",
                        "kisiKurumID": 164725232,
                        "adresID": 293578433,
                        "adresTuru": "ADRTR00002",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "ATAŞEHİR",
                        "ilceKodu": 35,
                        "adres": "BARBAROS MAHALLESİ BEGONYA SK. NİDA KULE ATAŞEHİR BATI Sit. NO: 1/1",
                        "kisiKurumID": 164725232,
                        "adresID": 297221511,
                        "adresTuru": "ADRTR00002",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "ATAŞEHİR",
                        "ilceKodu": 35,
                        "adres": "BARBAROS MAHALLESİ BEGONYA SK. NİDA KULE ATAŞEHİR BATI Sit.  NO: 1/1  ATAŞEHİR/İSTANBUL",
                        "kisiKurumID": 164725232,
                        "adresID": 282079632,
                        "adresTuru": "ADRTR00011",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    },
                    {
                        "ilKodu": 34,
                        "ilAdi": "İSTANBUL",
                        "ilceAdi": "ATAŞEHİR",
                        "ilceKodu": 35,
                        "adres": "BARBAROS MAH. BEGONYA SK. NİDA KULE ATAŞEHİR BATI SİT. NO:1/1",
                        "kisiKurumID": 164725232,
                        "adresID": 295473654,
                        "adresTuru": "ADRTR00002",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false
                    }
                ],
                "secili": false,
                "ilgiliKamuOzel": "O",
                "kisi": false,
                "tcVatandasi": false,
                "vergiNo": "4810577635",
                "gulcelKisiKurumId": "164725232",
                "mersisNo": "0481057763500017"
            }
        ]
    }

    // TODO: url ve talep yanlış olabilir bkz: eicrapro api.js[4066]
    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/mts_icra_acilis_alacakli_taraflar_brd.ajx';
    }

    async parse(data: Array<any>): Promise<MtsIcraAcilisAlacakliTaraflarCevap> {
        return {taraflarList: data}
    }

}

export default MtsIcraAcilisAlacakliTaraflar;
