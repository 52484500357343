import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface GetDosyaTarafListesiWithTarafRolTalep {
    dosyaId: number,
    islemTuru: number
    // 26: 3. Sahis Taraf Listesi
    // 21: Alacaklı Taraf Listesi
    // 71: Araci Kisi Kurum Taraf Listesi
}

export interface GetDosyaTarafListesiWithTarafRolCevap {
    items: Array<GetDosyaTarafListesiWithTarafRolCevapRow>
}

export interface GetDosyaTarafListesiWithTarafRolCevapRow {
    aboneVarmi: boolean,
    borcluBelediye: boolean,
    davaliDavaciGrup: string,
    dogacakHakDahilmi: boolean,
    dosyaKisiDVO: DosyaKisiDVO,
    guncelKisiKurumId: number,
    ilkKisiKurumId: number,
    kisiKurumBilgileriDVO: KisiKurumBilgileriDVO,
    kisiMiKurumMu: number,
    maasHesabiHaric: boolean,
    secili: boolean,
    tarafAdi: string,
    tarafRolu: number
}

export interface DosyaKisiDVO {
    aboneVarmi: boolean,
    cbsGroup: number,
    davaliDavaciGrup: string,
    dosyaBilg: DosyaBilg,
    dosyaDurumuAktif: string,
    dosyaId: number,
    dosyaKisiId: number,
    dosyasiIncelenebilir: boolean,
    guncelKisiKurumId: number,
    ilkKisiKurumId: number,
    kayitId: number,
    kimlikBilgisiAktif: string,
    kisiBilgDVO: KisiBilgDVO,
    kisiMiKurumMu: number,
    kurumBilgDVO: KurumBilgDVO,
    sanikStatusunde: string,
    tarafAdi: string,
    tarafDurumu: number,
    tarafRolu: number,
    tarafTC: string
}

export interface DosyaBilg {
    birimId: string,
    dosyaAcilisTarihi: string,
    dosyaDurumu: number,
    dosyaNo: string,
    dosyaNoSira: number,
    dosyaNoYil: number,
    dosyaTurKod: number
}

export interface KisiBilgDVO {
    harcDurumu: number,
    isEngelliKisiKurum: boolean,
    ozurOrani: number
}

export interface KurumBilgDVO {
    harcDurumu: number
    isEngelliKisiKurum: boolean,
    kamuOzel: string,
    kurumAdi: string,
    kurumVergiNo: string
}

export interface KisiKurumBilgileriDVO {
    adresList: Array<any>,
    kisiKurumId: number,
    kurumDVO: KurumDVO,
    ortakAd: string,
    turu: number,
}

export interface KurumDVO {
    faaliyetDurumu: number,
    harcDurumu: number,
    kamuOzel: string,
    kisiKurumID: number,
    kurumAdi: string,
    mersisIlKodu: number,
    mersisIlceKodu: number,
    tacirMi: string,
    vergiDairesi: string,
    vergiNo: string,
    veriGelisYeri: number,
}

export default class GetDosyaTarafListesiWithTarafRol extends UyapRequest<GetDosyaTarafListesiWithTarafRolTalep, GetDosyaTarafListesiWithTarafRolCevap> {
    getMethod(): "POST" | "GET" {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "GetDosyaTarafListesiWithTarafRol_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/getDosyaTarafListesiWithTarafRol.ajx";
    }

    async parse(data: any): Promise<GetDosyaTarafListesiWithTarafRolCevap> {
        return {items: data};
    }

    getMockResponse(): any {
        return [{
            "kisiKurumBilgileriDVO": {
                "kisiKurumId": 952188454,
                "kurumDVO": {
                    "kisiKurumID": 952188454,
                    "kurumAdi": "ZİRVE İHA TİC. VE SAN. LTD.ŞTİ",
                    "vergiNo": "1112211195",
                    "vergiDairesi": "ULUS",
                    "harcDurumu": 1,
                    "kamuOzel": "O",
                    "veriGelisYeri": 0,
                    "faaliyetDurumu": 3,
                    "tacirMi": "H",
                    "mersisIlKodu": 0,
                    "mersisIlceKodu": 0
                },
                "turu": 1,
                "ortakAd": "ZİRVE İHA TİC. VE SAN. LTD.ŞTİ",
                "adresList": []
            },
            "dosyaKisiDVO": {
                "kisiBilgDVO": {"harcDurumu": 1, "ozurOrani": 0, "isEngelliKisiKurum": false},
                "kurumBilgDVO": {
                    "kurumAdi": "ZİRVE İHA TİC. VE SAN. LTD.ŞTİ",
                    "kurumVergiNo": "1112211195",
                    "harcDurumu": 1,
                    "kamuOzel": "O",
                    "isEngelliKisiKurum": false
                },
                "dosyaBilg": {
                    "dosyaDurumu": 0,
                    "dosyaAcilisTarihi": "Aug 27, 2013 10:39:27 AM",
                    "dosyaNoSira": 13966,
                    "dosyaNoYil": 2013,
                    "dosyaNo": "2013/13966",
                    "birimId": "1000950",
                    "dosyaTurKod": 35
                },
                "dosyasiIncelenebilir": true,
                "kisiMiKurumMu": 1,
                "guncelKisiKurumId": 952188454,
                "ilkKisiKurumId": 952188454,
                "dosyaId": 254338002,
                "tarafRolu": 21,
                "dosyaKisiId": 400000047,
                "dosyaDurumuAktif": "E",
                "kimlikBilgisiAktif": "E",
                "kayitId": 2411163229,
                "sanikStatusunde": "H",
                "davaliDavaciGrup": "N",
                "tarafDurumu": 0,
                "aboneVarmi": true,
                "tarafAdi": "ZİRVE İHA TİC. VE SAN. LTD.ŞTİ",
                "tarafTC": "1112211195",
                "cbsGroup": 3
            },
            "secili": false,
            "dogacakHakDahilmi": false,
            "borcluBelediye": false,
            "maasHesabiHaric": false,
            "kisiMiKurumMu": 1,
            "guncelKisiKurumId": 952188454,
            "ilkKisiKurumId": 952188454,
            "tarafRolu": 21,
            "davaliDavaciGrup": "N",
            "aboneVarmi": true,
            "tarafAdi": "ZİRVE İHA TİC. VE SAN. LTD.ŞTİ"
        }];
    }
}