import {UyapRequest} from "./UyapRequest";
import {adresDVORow} from "./CommonInterfaces";

export interface MtsTarafTebligatListTalep {
    dosyaId: number
}

export type MtsTarafTebligatListCevap = MtsTarafTebligatCevapRow[];

export interface MtsTarafTebligatCevapRow {
    tarafTebligatDVO: {
        pttDurum: number,
        hasTebligatEvrak: boolean,
        evrakKisiDVO: EvrakKisiDvo,
        adresDVO: adresDVORow
    }
}

export interface EvrakKisiDvo {
    ilgiliDosyaKisiId: string,
    asilTarafDosyaKisiId: string,
    ilgiliKisiTipi: string,
    ilgili: string,
    dosya: dosyaRow,
    ilgiliKisiIlkKisiKurumId: string,
    asilTarafIlkKisiKurumId: string,
    ilgiliKisiRolu: string,
    ilgiliKisiRolId: string,
    ilgilininHarcDurumu: string,
    sanikStatusu: string,
    secili: boolean,
    tcKimlikNo: string,
    babaAdi: string,
    kisi: boolean,
    tcVatandasi: boolean,
    guncelKisiKurumId: string
}

export interface dosyaRow {
    clientDosyaDVO: Array<clientDosyaDVORow>,
    dosyaTur: number
}

export interface clientDosyaDVORow {
    dosyaID: string,
    dosyaBilgileriTurKodu: number,
}


export default class MtsTarafTebligatList extends UyapRequest<MtsTarafTebligatListTalep, MtsTarafTebligatListCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [
            {
                "tarafTebligatDVO": {
                    "pttDurum": 0,
                    "hasTebligatEvrak": false,
                    "evrakKisiDVO": {
                        "ilgiliDosyaKisiId": "948472222",
                        "asilTarafDosyaKisiId": "948472222",
                        "ilgiliKisiTipi": "T",
                        "ilgili": "MERTGÜN KAYA",
                        "dosya": {
                            "clientDosyaDVO": {"dosyaID": "553117361", "dosyaBilgileriTurKodu": 0},
                            "dosyaTur": -1
                        },
                        "ilgiliKisiIlkKisiKurumId": "23517400",
                        "asilTarafIlkKisiKurumId": "23517400",
                        "ilgiliKisiRolu": "BORÇLU VE MÜFLİS",
                        "ilgiliKisiRolId": "22",
                        "ilgilininHarcDurumu": "1",
                        "sanikStatusu": "E",
                        "secili": false,
                        "tcKimlikNo": "35483250628",
                        "babaAdi": "NİYAZİ",
                        "kisi": true,
                        "tcVatandasi": true,
                        "gulcelKisiKurumId": "23517400"
                    },
                    "adresDVO": {
                        "id": 938297086,
                        "adresId": 103026987,
                        "dosyaId": 553117361,
                        "ilkKisiKurumId": 23517400,
                        "guncelKisiKurumId": 23517400,
                        "gizliYazismaAdresi": false,
                        "kisikurumtur": "G",
                        "adresDVO": {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "ETİMESGUT",
                            "ilceKodu": 11,
                            "adres": "ŞEKER MAH. 1407 CAD. NO:16C İÇ KAPI NO:72 ",
                            "kisiKurumID": 23517400,
                            "adresID": 103026987,
                            "adresTuru": "ADRTR00008",
                            "adresTuruAciklama": "Mernis Adresi",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": true,
                            "guncellendiMi": false
                        }
                    }
                }
            }
        ];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/mts_taraf_tebligat_list_brd.ajx';
    }

    async parse(data: any): Promise<MtsTarafTebligatListCevap> {
        return data;
    }

}

