import {UyapRequest} from "./UyapRequest";

export interface YargiBirimleriTalep {
    yargiTuru: number
}

export type YargiBirimleriCevap = Array<YargiBirimleriCevapRow>

export interface YargiBirimleriCevapRow {
    altSistKodu: string,
    kod: string,
    tablo: string
}

export class YargiBirimleri extends UyapRequest<YargiBirimleriTalep, YargiBirimleriCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "YargiBirimleri_" + this.talepData.yargiTuru
        };
    }

    getMockResponse(): string {
        return `<root><DVOList>
                <liste>
                    <AltSistemBirimTuruDVO>
                        <altSistKodu>-1</altSistKodu>
            <tablo>1101</tablo>
            <kod>İCRA DAİRESİ</kod>
        </AltSistemBirimTuruDVO>
        </liste>
        <actualCount>0</actualCount>
        <currentPage>0</currentPage>
        <pageSize>0</pageSize>
        <cacheID>0</cacheID>
        <recordCount>0</recordCount>
        </DVOList></root>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/yargi_birimleri_sorgula_brd.ajx';
    }

    async parse(data: string): Promise<YargiBirimleriCevap> {
        let json: any = await this.xml2json(data);
        json = json.root.DVOList.liste.AltSistemBirimTuruDVO;
        if (!Array.isArray(json)) {
            json = [json];
        }
        return json;
    }

}

export default YargiBirimleri;
