import app from "@/main";
import TakipDosya from "../../../uyap/classes/TakipDosya";
import {GetDosyaTarafBilgileriCevapRow} from "../../../uyap/GetDosyaTarafBilgileri";
import {IcraPesinHarcGetirCevap} from "../../../uyap/IcraPesinHarcGetir";
import {OdemeTipiEnum} from "../../../takip_ac/OdemeTipleri";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {TaskDurum} from "../../../enum/TaskDurum";

export class HarcMasrafOdeCevap {
    dosyaNo: string = "";
    taraf: string = "";
    pesinHarcTutari: number = 0;
    pesinHarcDusulduMu: "Evet" | "Hayır" = "Hayır";
    tutar: any = "";
    toplamTutar: any = "";
    message: string = "";
}

interface PostaMasraflari {
    id: any,
    tur: string,
    adi: string,
    adet: any,
    tutar: any
}

export interface HarcMasrafOdeTaskInterface {
    id: number,
    tur: string,
    adi: string,
    adet: number,
    tutar: number,
    toplamTutar: number,
    tarafRolId: number,
    tarafRolAdi: string | undefined,
    pesinHarcDusulsunMu: boolean,
    pesinHarcTutari: number,
    tarafBilgileri: any,
    tahsilatAciklama: string,
    tahsilatHarcOrani: number | null,
    otomatikIliskilendirme: boolean,
    tahsilatKayitId: number | null,
}

export class HarcMasrafOdeTaskHandler extends AbstractTaskHandler {

    //TODO daha önce ödenmişmi Dosya filtreleme yapilmali dosyalar taska gonderilirken dosya esasnoya gore filtrelensin ** Emre
    //@ts-ignore
    dosya: TakipDosya = {};
    data: Array<HarcMasrafOdeTaskInterface> = [];
    //@ts-ignore
    firstData: HarcMasrafOdeTaskInterface = {};
    harcMasraflar: Array<any> = [];
    response: HarcMasrafOdeCevap = new HarcMasrafOdeCevap();

    async handle(task: ITask): Promise<ITask> {
        try {
            //Dosyayi bos getiriyor dolu icerik gerekiyor birimTuru3 gibi
            let dosya = await app.$uyap.DosyaBul().run(task, true);
            this.dosya = TakipDosya.fromJson(dosya);
            this.data = task.data;
            this.firstData = this.data[0];
            //Daha önce ödenmişmi kontrolu
            this.response.dosyaNo = task.dosya_esas_no;
            await this.dosya.dosyaDetayliTarafBilgileriQuery();
            let tarafRolId = this.firstData.tarafRolId;
            let ayniTurTaraflar = this.dosya.detayliTarafBilgileri.filter((taraf: GetDosyaTarafBilgileriCevapRow) => taraf.dosyaKisiDVO.tarafRolu == tarafRolId);
            if (ayniTurTaraflar.length > 1) {
                throw new Error('Dosyada aynı taraf türünden birden fazla bulunduğu için ödeme iptal edildi.Lütfen bu dosya için ödeme işlemlerinizi UYAP üzerinden yapınız.');
            }
            await this.harcMasrafHazirla();
            if (this.harcMasraflar.length == 0)
                throw new Error("Dosyada Taraf Bulunamadı");

            console.log("harcmasraflar", this.harcMasraflar);
            //this.postaMasraflari = this.harcMasraflar.filter(x=>x.tur == HarcMasrafTypeEnum.PUL);
            let odemeBilgileri = await this.odemeBilgileriOlustur();
            let requestObject = {
                dosyaId: this.dosya.dosyaId,
                tarafDVO: null,
                harcMasrafTipi: this.firstData.tur,
                harcMasrafList: odemeBilgileri.harcMasrafList,
                postaMasraflariList: JSON.stringify(odemeBilgileri.postaMasraflariList),
                odemeTipi: OdemeTipiEnum.Barokart,
                uyapId: "",
                vakifbankHesapBilgileri: "",
            }
            console.log(requestObject);
            //@ts-ignore
            let dosyaOdemeResult = await app.$uyap.DosyaOdemeIslemleri().run(requestObject);
            //let dosyaOdemeResult = {message:"sadsadsa"};
            this.response.message = dosyaOdemeResult.message;
            task.response = this.response;
            task.status = TaskDurum.BITTI_BASARILI
            return task;
        } catch (error: any) {
            console.log("CATCH", error);
            throw new Error(error.message);
        }
    }

    async harcMasrafHazirla() {
        let pesinHarc: IcraPesinHarcGetirCevap | null = null;
        try {
            pesinHarc = await app.$uyap.IcraPesinHarcGetir().run({dosyaId: this.dosya.dosyaId});
        } catch (e) {
            console.error(e)
        }
        if (pesinHarc)
            this.response.pesinHarcTutari = pesinHarc.pesinHarcUcreti;

        let pesinHarcDusuldu = false;
        let toplamTutar = 0;
        let normalTutar = 0;
        this.data.forEach((harcMasraf: any) => {
            let taraf = this.dosya.detayliTarafBilgileri.find((taraf: GetDosyaTarafBilgileriCevapRow) => taraf.dosyaKisiDVO.tarafRolu == harcMasraf.tarafRolId);
            if (harcMasraf.tahsilatAciklama == null || harcMasraf.tahsilatAciklama == "")
                harcMasraf.tahsilatAciklama = "-";
            if (taraf) {
                let harcMasrafData: any = Object.assign({}, harcMasraf);
                harcMasrafData.tarafBilgileri = {};
                if (taraf.dosyaKisiDVO.kisiMiKurumMu == '0')
                    harcMasrafData.tarafBilgileri.adSoyad = taraf.dosyaKisiDVO.kisiBilgDVO.adi + " " + taraf.dosyaKisiDVO.kisiBilgDVO.soyadi;
                else
                    harcMasrafData.tarafBilgileri.adSoyad = taraf.dosyaKisiDVO.kurumBilgDVO.kurumAdi;

                this.response.taraf = harcMasrafData.tarafBilgileri.adSoyad;
                harcMasrafData.tarafBilgileri.tarafDosyaKisiId = taraf.dosyaKisiDVO.dosyaKisiId;
                harcMasrafData.tarafBilgileri.kisiKurumId = taraf.dosyaKisiDVO.ilkKisiKurumId;
                harcMasrafData.tarafBilgileri.rolId = taraf.dosyaKisiDVO.tarafRolu;
                //@ts-ignore
                normalTutar = parseFloat(normalTutar + parseFloat(harcMasrafData.tutar * harcMasrafData.adet));
                if (harcMasrafData.pesinHarcDusulsunMu === true && pesinHarcDusuldu === false) {
                    if (this.response.pesinHarcTutari > 0) {
                        harcMasrafData.pesinHarcTutari = this.response.pesinHarcTutari;
                        //@ts-ignore
                        harcMasrafData.tutar = (parseFloat(harcMasrafData.tutar) - parseFloat(harcMasrafData.pesinHarcTutari)).toFixed(2);
                        pesinHarcDusuldu = true;
                        this.response.pesinHarcDusulduMu = "Evet";
                    }
                }
                //@ts-ignore
                toplamTutar = parseFloat(toplamTutar + parseFloat(harcMasrafData.tutar * harcMasrafData.adet)); // harc dusulmus tutar
                this.harcMasraflar.push(harcMasrafData);
            } else {
                throw new Error("Dosyada Taraf Bulunamadı");
            }
        });
        this.response.tutar = normalTutar.toString();
        this.response.toplamTutar = toplamTutar.toString();
    }

    odemeBilgileriOlustur(): Promise<{ harcMasrafList: string, postaMasraflariList: Array<PostaMasraflari> }> {
        try {
            let harcMasrafList: string = ""
            let postaMasraflariList: Array<PostaMasraflari> = [];
            let odenecekToplamTutar: any = 0;
            let pesinHarcDusuldu = false;
            let count = 0;
            console.log("harcMasraflar", this.harcMasraflar);
            this.harcMasraflar.forEach((value, i) => {
                console.log("value ", value);
                //console.log(value);
                count++;
                if (value.tur == "D") {
                    if (postaMasraflariList.length > 0) {
                        //showToastMessage('Uyarı','Bu ödeme türünden birden fazla eklenemez...','error');
                        //return false;
                    } else {
                        postaMasraflariList.push(
                            {
                                id: value.id,
                                tur: "D",
                                adi: value.adi,
                                adet: value.adet,
                                tutar: value.tutar
                            }
                        );
                    }
                    return;
                }
                let yargiGiderTuruAciklama = "";
                if (value["tur"] == "H") {
                    //harcMasrafListJson.harcList.filter(i => i.id)
                    yargiGiderTuruAciklama = "Harç";
                } else if (value["tur"] == "M") {
                    yargiGiderTuruAciklama = "Masraf";
                } else if (value["tur"] == "T") {
                    yargiGiderTuruAciklama = "Tahsilat";
                } else if (value["tur"] == "D") {
                    yargiGiderTuruAciklama = "Diğer";
                }
                //var dosya_bilgileri = {}; //dosya sorgulanip buraya arguman atilacak..
                odenecekToplamTutar = parseFloat(odenecekToplamTutar) + parseFloat(value["adet"]) * parseFloat(value["tutar"]);
                if (this.dosya.birimTuru3 == "1199") {
                    if (value["pesinHarcDusulsunMu"] == true) {
                        pesinHarcDusuldu = true;
                    }
                    if (i == 0) {
                        harcMasrafList = value["tur"] + "**" + value["id"] + "**" + value["adet"] + "**" + (parseFloat(value["adet"]) * parseFloat(value["tutar"])) + "**" + value["adi"] + "**" + value["tahsilatAciklama"] + "**" + value["pesinHarcDusulsunMu"] + "**" + value["pesinHarcTutari"] + "**" + value["tarafBilgileri"]["adSoyad"] + "**" + value["tarafBilgileri"]["kisiKurumId"] + "**" + value["tarafBilgileri"]["rolId"] + "**" + value["tahsilatHarcOrani"] + "**" + value["otomatikIliskilendirme"] + "**" + value["tahsilatKayitId"];
                    } else {
                        harcMasrafList = harcMasrafList + "|" + value["tur"] + "**" + value["id"] + "**" + value["adet"] + "**" + (parseFloat(value["adet"]) * parseFloat(value["tutar"])) + "**" + value["adi"] + "**" + value["tahsilatAciklama"] + "**" + value["pesinHarcDusulsunMu"] + "**" + value["pesinHarcTutari"] + "**" + value["tarafBilgileri"]["adSoyad"] + "**" + value["tarafBilgileri"]["kisiKurumId"] + "**" + value["tarafBilgileri"]["rolId"] + "**" + value["tahsilatHarcOrani"] + "**" + value["otomatikIliskilendirme"] + "**" + value["tahsilatKayitId"];
                    }
                } else {
                    if (i == 0) {
                        harcMasrafList = value["tur"] + "**" + value["id"] + "**" + value["adet"] + "**" + (parseFloat(value["adet"]) * parseFloat(value["tutar"])) + "**" + value["tur"];
                    } else {
                        harcMasrafList = harcMasrafList + "|" + value["tur"] + "**" + value["id"] + "**" + value["adet"] + "**" + (parseFloat(value["adet"]) * parseFloat(value["tutar"])) + "**" + value["tur"];
                    }
                }

            })
            return Promise.resolve({
                harcMasrafList: harcMasrafList,
                postaMasraflariList: postaMasraflariList
            });
        } catch (err) {
            //console.log("createRequestObject");
            console.log(err);
            return Promise.reject(err);
        }
    }
}