import {UyapRequest} from "./UyapRequest";
import HesapBilgisi from "../takip_ac/parser/HesapBilgisi";

export interface IbanListesiTalep {
    kisiKurumId: number,
    tarafTur: number
}

export default class GetIbanListesi extends UyapRequest<IbanListesiTalep, HesapBilgisi[]> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/get_iban_listesiJSON.ajx";
    }

    async parse(data: any): Promise<HesapBilgisi[]> {
        //{"type":"error","message":"Banka hesap bilgisi bulunamadı.","log":""}
        return data.value;
    }

    getMockResponse(): any {
        return {
            "type": "success",
            "message": "",
            "value": [{
                "kayitID": 5437178,
                "bankaAdi": "ING BANK A.S.",
                "ibanNumarasi": "TR920009900955523400100001",
                "hesapGenel": true,
                "hesapAktifmi": "E"
            }],
            "log": ""
        }
    }
}