import DosyaBilgileri from "./parser/DosyaBilgileri";
import Vekil from "./parser/Vekil";
import Taraf from "./parser/Taraf";
import Args from "./parser/Args";
import Ilam from "./parser/Ilam";
import Cek from "./parser/Ilamsiz/Cek";
import Senet from "./parser/Ilamsiz/Senet";
import Police from "./parser/Ilamsiz/Police";
import Kontrat from "./parser/Ilamsiz/Kontrat";
import Diger from "./parser/Ilamsiz/Diger";
import AlacakKalem from "./parser/AlacakKalem";
import TarafKisi from "./parser/TarafKisi";
import TarafKurum from "./parser/TarafKurum";
import TakipInjectable from "./parser/TakipInjectable";
import {DosyaFormDataInterface} from "./DosyaFormDataInterface";
import app from "@/main";
import {Mixins} from "@/plugins/uyap-plugin/takip_ac/Mixins";

export default class TakipXmlParser extends TakipInjectable{
    toplamTakibeEsasMiktar :number = 0;
    formData !: DosyaFormDataInterface;
    dosyaNode : HTMLUnknownElement | null  = null;
    ilamliList:Ilam[] = [];
    cekList:Cek[] = [];
    senetList:Senet[] = [];
    policeList:Police[] = [];
    kontratList:Kontrat[] = [];
    digerList:Diger[] = [];
    //Base64 String Array
    icraDosyaBilgileri !: DosyaBilgileri;
    vekilList : Vekil[] = [];
    tarafList : Array<TarafKisi | TarafKurum> = [];
    tahsilatList : any[] = [];
    args:Args = new Args();
    hash:string = "";
    hashObj: any;

    static fromJson(jsonData:any):TakipXmlParser{
        let parser = new TakipXmlParser();
        parser.dosyaNode = jsonData.dosyaNode;
        parser.formData = jsonData.formData;
        parser.toplamTakibeEsasMiktar = jsonData.toplamTakibeEsasMiktar;

        parser.ilamliList = jsonData.ilamliList.map((ilam:Ilam)=>{
            return Ilam.fromJson(ilam);
        });
        parser.cekList = jsonData.cekList.map((cek:Cek)=>{
            return Cek.fromJson(cek);
        });
        parser.senetList = jsonData.senetList.map((senet:Senet)=> {
            return Senet.fromJson(senet);
        });
        parser.policeList = jsonData.policeList.map((police:Police)=> {
            return Police.fromJson(police);
        });
        parser.kontratList = jsonData.kontratList.map((kontrat:Kontrat)=> {
            return Kontrat.fromJson(kontrat);
        });
        parser.digerList = jsonData.digerList.map((diger:Diger)=> {
            return Diger.fromJson(diger);
        });
        parser.vekilList = jsonData.vekilList.map((vekil:Vekil)=>{
            return Vekil.fromJson(vekil);
        });
        parser.tarafList = jsonData.tarafList.map( (taraf : any)=>{
            return Taraf.fromJson(taraf);
        })

        parser.icraDosyaBilgileri = DosyaBilgileri.fromJson(jsonData.icraDosyaBilgileri);

        parser.tahsilatList     = jsonData.tahsilatList;
        parser.dayanaklar       = jsonData.dayanaklar;
        parser.dayanaklarObject = jsonData.dayanaklarObject;
        parser.vekaletObject    = jsonData.vekaletObject;
        parser.args             = Args.fromJson(jsonData.args);
        parser.hash             = jsonData.hash;
        parser.hashObj          = jsonData.hashObj;

        return  parser;
    }
    static async  parse(dosyaNode:any,formData : any): Promise<TakipXmlParser> {
        try{
            let parser = new TakipXmlParser();
            parser.dosyaNode = dosyaNode;
            parser.formData = formData;
            if(parser.dosyaNode){
                parser.setEvraklar(parser.dosyaNode);
                await parser.setDayanakFromAlacakBelgePath();
                parser.icraDosyaBilgileri = await DosyaBilgileri.parse(parser.dosyaNode,parser.formData);
                parser.args.setIcraproXmliMi(parser.icraDosyaBilgileri.isIcraProXml());
                parser.dosyaNode.querySelectorAll('vekilkisi').forEach((node) => {
                    let vekil = Vekil.parse(node);
                    if(vekil)
                        parser.vekilList.push(vekil);
                });
                parser.dosyaNode.querySelectorAll('taraf').forEach((node) => {
                    // @ts-ignore
                    parser.tarafList.push(
                        Taraf.parse(node,parser.vekilList,parser.formData)
                    )
                });

                parser.setBelgeler();
                parser.takibeEsasMiktarTopla();
                parser.setDosyaAdi();

                //Hata kontrolleri
                if(!parser.alacakliVarmi() || !parser.borcluVarmi())
                    return Promise.reject({message:"Dosya taraflarında eksiklik var lütfen kontrol ediniz."});
                if(parser.tahsilatVarmi())
                    return Promise.reject({message:"XML'de Tahsilat Bulundu!"})
                parser.generateXmlHash();
                console.log("TO UYAP JSON",parser.toUyapJson());
                return Promise.resolve(parser);
            }else{
                return  Promise.reject({message:"Lütfen dosya yükleyiniz."})
            }
        }catch (e) {
            console.log("TakipXML PARSER ERROR",e);
            return  Promise.reject(e);
        }

    }

    isIlamli():boolean{
        return this.ilamliList.length > 0;
    }
    getIlamBilgileri():Ilam{
        return this.ilamliList[0];
    }
    setDosyaAdi(){
        if(this.icraDosyaBilgileri.dosyaBelirleyici.name == ""){
            this.tarafList.forEach((taraf)=>{
                if(taraf.isKurum())
                    this.icraDosyaBilgileri.dosyaBelirleyici.name += "("+taraf.tarafSifati.rolAdi + " : " + taraf.temelBilgileri.kurumAdi + " )"
                else
                    this.icraDosyaBilgileri.dosyaBelirleyici.name += "("+taraf.tarafSifati.rolAdi + " : " + taraf.temelBilgileri.adi + " " + taraf.temelBilgileri.soyadi + " )"
            })
        }
    }

    tahsilatVarmi():boolean{
        let result : boolean = false;
        this.getIlamsizList().forEach((ilamsiz)=>{
            if( ilamsiz.alacakKalemleri.filter( (alacakKalemi:any) => app.$uyapHelper.turkishToLower(alacakKalemi.temelBilgileri.aciklama).indexOf('tahsilat') > -1  ).length >0 ) {
                result = true;
            }
        })
        return  result;
    }
    alacakliVarmi():boolean{
        return this.tarafList.find(x=>x.isAlacakli()) != undefined;
    }
    borcluVarmi():boolean{
        return this.tarafList.find(x=>x.isBorclu()) != undefined;
    }
    getIlamsizList():any[]{
        //@ts-ignore
        let ilamsizList = [].concat(this.cekList).concat(this.senetList).concat(this.policeList).concat(this.kontratList).concat(this.digerList);

        if(this.formData.alacakKalemIslemisFaizlerToplansin){
            return this.alacakKalemIslemisFaizleriTopla(ilamsizList);
        }
        return ilamsizList;
    }
    alacakKalemIslemisFaizleriTopla(ilamsizList:any[]):any[]{
        ilamsizList.forEach(ilamsiz=>{
            let alacakKalemleri: any[] = [];
            let toplananAlacakKalemi:any = null;
            ilamsiz.alacakKalemleri.forEach((alacakKalemi:AlacakKalem)=>{
                //İşlemiş faiz 6
                if(alacakKalemi.temelBilgileri.selectedAlacakKalemKodu.alacakKalemKod == "6"){
                    if(!toplananAlacakKalemi)
                        toplananAlacakKalemi = alacakKalemi;
                    else
                        toplananAlacakKalemi.temelBilgileri.alacakTutari = (parseFloat(toplananAlacakKalemi.temelBilgileri.alacakTutari) + parseFloat(alacakKalemi.temelBilgileri.alacakTutari)).toFixed(2);
                }else{
                    alacakKalemleri.push(alacakKalemi);
                }
            })
            if(toplananAlacakKalemi){
                toplananAlacakKalemi.temelBilgileri.aciklama = "İşlemiş Faiz";
                toplananAlacakKalemi.temelBilgileri.detayliAciklama = "İşlemiş Faiz";
                alacakKalemleri.push(toplananAlacakKalemi);
            }
            ilamsiz.alacakKalemleri = alacakKalemleri;
        })
        return ilamsizList;
    }



    takibeEsasMiktarTopla(){
        //TODO selectedParaBirimi Dolar takibi sıkıntı olabilir ona bakılcak
        this.toplamTakibeEsasMiktar = 0;
        this.alacakKalemiTopla(this.digerList);
        this.alacakKalemiTopla(this.cekList);
        this.alacakKalemiTopla(this.policeList);
        this.alacakKalemiTopla(this.kontratList);
        this.alacakKalemiTopla(this.senetList);
        this.alacakKalemiTopla(this.ilamliList);
    }

    alacakKalemiTopla(belgeList : any[]){
        belgeList.forEach(belge=>{
            let deleteKeyList : any[] = [];
            belge.alacakKalemleri.forEach((alacakKalemi : AlacakKalem,key:number) =>{
                if(alacakKalemi === null){
                    //tutar 0  esit veya kucukse null geliyor
                    deleteKeyList.push(key);
                }else{
                    this.toplamTakibeEsasMiktar += parseFloat(alacakKalemi.temelBilgileri.alacakTutari)
                }
            });
            deleteKeyList.forEach(index=>{
                console.log("DELETED AKALEMI INDEX",index);
                belge.alacakKalemleri.splice(index,1);
            })

        })
    }

    async setDayanakFromAlacakBelgePath(): Promise<void> {
        try {
            let alacakBelgePath = Mixins.nullUndefCleaner((this.dosyaNode as HTMLUnknownElement).getAttribute("alacakbelge"));
            // alacakBelge="C:\Faturalar\MTS_CASE_OPENING_202201181077234757..pdf"
            console.log("alacakBelgePath", alacakBelgePath);
            console.log("dosyaNode", this.dosyaNode);
            if (alacakBelgePath != "") {
                let belge = await app.$imza.getFileFromLocalPath(alacakBelgePath);
                console.log("BELGEEEE ------", belge);
                let splited = alacakBelgePath.replace(/\\/g, "/").split("/");
                let fileName = splited[splited.length - 1];
                let evrak = {
                    data: belge.file,
                    mimeType: Mixins.findFileMimeType(fileName),
                    name: fileName
                };
                console.log("alacakBelge To Dayanak", evrak);
                this.dayanaklar.push(evrak);
            }
        } catch (e) {
            console.log("AlacakBelge Eklenemedi ", e);
        }
    }

    setBelgeler(){
        this.setIlamlar();
        this.setCekler();
        this.setSenetler();
        this.setPoliceler();
        this.setKontratlar();
        this.setDigerler();
    }


    setDigerler(){
        //UPDATE OK
        if(this.dosyaNode){
            let nodeList = this.sekizinciMaddeyeOzelNodeBirlestir(this.dosyaNode.querySelectorAll('digerAlacak'));
            nodeList.forEach((node:any) => {
                this.digerList.push(
                    Diger.parse(node,this.args,this.tarafList)
                )
            });
            this.ilamsizSortArray(this.digerList);
        }
    }
    setKontratlar(){
        //UPDATE OK
        if(this.dosyaNode){
            let nodeList = this.sekizinciMaddeyeOzelNodeBirlestirKontrat(this.dosyaNode.querySelectorAll('kontratkefil'));
            nodeList.forEach((node:any) => {
                this.kontratList.push(
                    Kontrat.parse(node,this.args,this.tarafList)
                )
            });
        }
    }
    setPoliceler(){
        //UPDATE OK
        if(this.dosyaNode){
            let nodeList = this.sekizinciMaddeyeOzelNodeBirlestir(this.dosyaNode.querySelectorAll('police'));
            nodeList.forEach((node:any) => {
                this.policeList.push(
                    Police.parse(node,this.args,this.tarafList)
                )
            });
        }

    }
    setSenetler(){
        //UPDATE OK
        if(this.dosyaNode) {
            let nodeList = this.sekizinciMaddeyeOzelNodeBirlestir(this.dosyaNode.querySelectorAll('senet'));
            nodeList.forEach((node: any) => {
                this.senetList.push(
                    Senet.parse(node, this.args, this.tarafList)
                )
            });
            this.ilamsizSortArray(this.senetList);
        }
    }
    setCekler(){
        //UPDATE OK
        if(this.dosyaNode) {
            let nodeList = this.sekizinciMaddeyeOzelNodeBirlestir(this.dosyaNode.querySelectorAll('cek'));
            nodeList.forEach((node: any) => {
                this.cekList.push(
                    Cek.parse(node, this.args, this.tarafList)
                )
            });
            this.ilamsizSortArray(this.cekList);
        }
    }

    setIlamlar(){
        //UPDATE OK
        if(this.dosyaNode){
            this.dosyaNode.querySelectorAll('ilam').forEach((node:any) => {
                this.ilamliList.push(
                    Ilam.parse(node,this.args,this.tarafList)
                )
            });
        }
    }


    ilamsizSortArray(ilamsizArray:any[]){
        ilamsizArray.sort((a, b) => {
            if (typeof a.alacakTarihi == 'undefined' || typeof b.alacakTarihi == 'undefined' || a.alacakTarihi == null || b.alacakTarihi == null) {
                return 0;
            }
            let aDateArr = a.alacakTarihi.split('/');
            let bDateArr = b.alacakTarihi.split('/');

            if (aDateArr.length != 3 || bDateArr.length != 3) {
                return 0;
            }

            let aDate = new Date(aDateArr[2], aDateArr[1], aDateArr[0]);
            let bDate = new Date(bDateArr[2], bDateArr[1], bDateArr[0]);
            if (aDate > bDate) {
                return 1;
            }
            if (aDate < bDate) {
                return -1;
            }
            return 0;
        })
    }

    sekizinciMaddeyeOzelNodeBirlestirKontrat(nodeList:NodeListOf<Element>){
        if(nodeList.length === 0) {
            return nodeList;
        }
        let firstNode = nodeList[0];
        nodeList.forEach((node, key) => {
            if (key > 0) {
                node.querySelectorAll("alacakKalemi").forEach(alacakKalemNode => {
                    if(firstNode){
                        //@ts-ignore
                        firstNode.querySelector("kontrat").appendChild(alacakKalemNode);
                    }
                })
            }
        });
        return [firstNode];
    }
    sekizinciMaddeyeOzelNodeBirlestir(nodeList:NodeListOf<Element>){
        //EUP-481 --
        if(nodeList.length > 0){
            let firstNode = nodeList[0];
            console.log("firstNode",firstNode);
            let sekizinciMadde = firstNode.getAttribute("sekizincimadde");
            if(sekizinciMadde){
                if(sekizinciMadde.trim() == "")
                    return nodeList;
                nodeList.forEach((node,key)=>{
                    if(key > 0){
                        if(node.getAttribute("sekizincimadde") == sekizinciMadde){

                            if(
                                firstNode.nodeName == "SENET" ||
                                firstNode.nodeName == "KONTRAT" ||
                                firstNode.nodeName == "POLICE"

                            ){
                                //@ts-ignore
                                let tutar = parseFloat(firstNode.getAttribute("belgeninTutari")) + parseFloat(node.getAttribute("belgeninTutari"));
                                firstNode.setAttribute("belgeninTutari",tutar.toString());
                            }else{
                                //@ts-ignore
                                let tutar = parseFloat(firstNode.getAttribute("tutar")) + parseFloat(node.getAttribute("tutar"));
                                firstNode.setAttribute("tutar",tutar.toString());
                            }

                            node.querySelectorAll("alacakKalemi").forEach(alacakKalemNode=>{
                                firstNode.appendChild(alacakKalemNode);
                            })
                        }
                    }
                })
              //  let resultNode = document.createElement(firstNode.nodeName)
                return [firstNode]
            }
        }
        return nodeList;
    }


    generateXmlHash(){
        let hashObj : any = {
            taraflar:[],
            takibeEsasMiktar:this.toplamTakibeEsasMiktar,
        };
        this.tarafList.forEach(taraf=>{
            if(taraf.isKisi()){
                hashObj.taraflar.push({
                    ad:taraf.temelBilgileri.adi,
                    soyad:taraf.temelBilgileri.soyadi,
                    tcKimlikNo:taraf.temelBilgileri.tcKimlikNo,
                    rolId:taraf.tarafSifati.rolID
                })
            }
            if(taraf.isKurum()){
                hashObj.taraflar.push({
                    kurumadi:taraf.temelBilgileri.kurumAdi,
                    vergino:taraf.temelBilgileri.vergiNo,
                    mersisno:taraf.temelBilgileri.mersisNo,
                    rolId:taraf.tarafSifati.rolID
                })
            }
        })
        this.hashObj = hashObj;
        this.hash = app.$uyapHelper.hash(hashObj)
    }
    toUyapTestJson(){
        let ilamsizList = this.getIlamsizList();
        let result = {
            IcraDosyaBilgileri: this.icraDosyaBilgileri.toUyapJson(),
            IlamliList: this.ilamliList.map((i) => i.toUyapJson()),
            IlamsizList: ilamsizList.map(x=>x.toUyapJson()),
            TarafList: this.tarafList.map((i) => i.toUyapJson()),
            TahsilatList: this.tahsilatList.map((i) => i.toUyapJson()),
            IslemTuru: 'topluTakip'
        };
        console.log("result toUyapJson",result);
        return result;
    }
    toUyapJson() {
        let ilamsizList = this.getIlamsizList();
        let result = {
            IcraDosyaBilgileri: JSON.stringify(this.icraDosyaBilgileri.toUyapJson()),
            IlamliList: JSON.stringify(this.ilamliList.map((i) => i.toUyapJson())),
            IlamsizList: JSON.stringify(ilamsizList.map(x=>x.toUyapJson())),
            TarafList: JSON.stringify(this.tarafList.map((i) => i.toUyapJson())),
            TahsilatList: JSON.stringify(this.tahsilatList.map((i) => i.toUyapJson())),
            // IslemTuru: 'topluTakip'
        };
        console.log("result toUyapJson",result);
        return result;
    }
}

