import {Mixins} from "../Mixins";
import {DosyaFormDataInterface} from "../DosyaFormDataInterface";
import app from "@/main";
import {IcraTakipMahiyetleriCevapRow} from "../../uyap/IcraTakipMahiyetleri";
import Args from "@/plugins/uyap-plugin/takip_ac/parser/Args";

export default class DosyaBilgileri {
    kotaKullanimSekli: string = ""; //kota degisecek //emre
    adliyeBirimId: string = ""; //"1000937",
    takipTuru: string = ""; //"1",
    takipYolu: string = "";//"3",
    takipSekli: string = ""; //"0",
    dosyaTevziTipiBanka: boolean = false;
    dosyaTevziTipiGayrimenkul: boolean = false;
    dosyaAciklama_48_4: string = "";// = //"Alacağın tahsili tarihine kadar &#37;...20 faizi masraf ve vekalet ücreti ile tahsili,kısmi ödemelerde BK.100 e göre yapılmasını talep ederim.",
    dosyaAciklama_48_9: string = ""; ////"Haciz Yolu",
    il: string = "";//= il;
    ipotekRehinAciklama: string = "\n";
    dosyaKriterList: string = "";//"bk"
    mahiyetId ?: number | null = null;
    dosyaBelirleyici: any = {};
    abonelikMi: boolean = false;
    takipMahiyetleri: Array<IcraTakipMahiyetleriCevapRow> = [];
    mahiyetTuru: "49_mahiyetleri" | "53_mahiyetleri" | "" = "";

    static fromJson(jsonData: any): DosyaBilgileri {
        return Args.staticFromJson(jsonData,new DosyaBilgileri());
    }

    static async parse(dosyaDomNode: HTMLUnknownElement, formData: DosyaFormDataInterface): Promise<DosyaBilgileri> {
        let dosyaBilgileri = new DosyaBilgileri();
        let merhununNeOldugu = dosyaDomNode.getAttribute("merhununneoldugu");
        let ucuncuSahisVeAdres = dosyaDomNode.getAttribute("ucuncusahisveadres");
        if (
            typeof merhununNeOldugu != "undefined" && merhununNeOldugu != "" && merhununNeOldugu != null &&
            typeof ucuncuSahisVeAdres != "undefined" && ucuncuSahisVeAdres != "" && ucuncuSahisVeAdres != null

        ) {
            dosyaBilgileri.ipotekRehinAciklama = merhununNeOldugu + "\n" + ucuncuSahisVeAdres;
            dosyaDomNode.setAttribute("bk84maddeuygulansin", 'E');
        }

        dosyaBilgileri.kotaKullanimSekli = formData.kotaKullanimSekli;
        dosyaBilgileri.il = formData.il;
        dosyaBilgileri.adliyeBirimId = formData.adliye;
        dosyaBilgileri.takipTuru = Mixins.nullUndefCleaner(dosyaDomNode.getAttribute("takipturu"));
        dosyaBilgileri.takipYolu = Mixins.nullUndefCleaner(dosyaDomNode.getAttribute("takipyolu"));
        dosyaBilgileri.takipSekli = Mixins.nullUndefCleaner(dosyaDomNode.getAttribute("takipsekli"));
        dosyaBilgileri.dosyaTevziTipiBanka = formData.bankaDosyasi ? formData.bankaDosyasi : false;
        dosyaBilgileri.dosyaTevziTipiGayrimenkul = formData.gayrimenkulDosyasi ? formData.gayrimenkulDosyasi : false;
        dosyaBilgileri.dosyaAciklama_48_4 = Mixins.replaceHtmlCode(dosyaDomNode.getAttribute("alacaklinintalepettigihak"));
        dosyaBilgileri.dosyaAciklama_48_9 = Mixins.nullUndefCleaner(dosyaDomNode.getAttribute("aciklama48e9"));

        dosyaBilgileri.setDosyaBelirleyici(dosyaDomNode);
        console.log("dosyaBilgileri.dosyaBelirleyici =------", dosyaBilgileri.dosyaBelirleyici);
        dosyaBilgileri.abonelikMi = dosyaBilgileri.dosyaBelirleyici.abonelikMi;
        dosyaBilgileri.setMahiyetId(dosyaDomNode.getAttribute("mahiyetkodu"));
        // if(dosyaBilgileri.takipTuru == "49" && dosyaBilgileri.mahiyetId == ""){
        //     return  Promise.reject({message:"Takip türü 49 olan takiplerde takip mahiyeti zorunludur lütfen xml'inizi kontrol ediniz."})
        // }
        dosyaBilgileri.setDosyaKriterList(dosyaDomNode);
        console.log("DOSYA BILGILERI", dosyaBilgileri)
        let isMahiyet = dosyaBilgileri.isMahiyet();
        console.log("ISMAHIYET", isMahiyet);
        console.log("dosyaBilgileri.mahiyetId", dosyaBilgileri.mahiyetId);
        if (dosyaBilgileri.isMahiyet()) {
            console.log("SET MAHIYETLER")
            await dosyaBilgileri.setMahiyetler();
            //dosyaBilgileri.mahiyetId = "1307";
        }
        return dosyaBilgileri;
    }

    async setMahiyetler() {
        let mahiyetler = await app.$uyap.IcraTakipMahiyetleri().run({
            takipSekli: parseInt(this.takipSekli),
            takipTuru: parseInt(this.takipTuru),
            takipYolu: parseInt(this.takipYolu)
        })
        if (this.takipTuru == "1" && this.takipYolu == "0" && this.takipSekli == "0") this.mahiyetTuru = "49_mahiyetleri";
        else if (this.takipTuru == "0" && this.takipYolu == "1" && this.takipSekli == "0") this.mahiyetTuru = "53_mahiyetleri";
        this.takipMahiyetleri = mahiyetler.items;
    }

    isMahiyet() {
        return (( // 49
                this.takipTuru == "1" &&
                this.takipYolu == "0" &&
                this.takipSekli == "0"
            )
            ||
            ( // 53
                this.takipTuru == "0" &&
                this.takipYolu == "1" &&
                this.takipSekli == "0"
            ))
    }

    isIcraProXml(): boolean {
        console.log("dosyabelirleyici isIcraproXml=", (typeof this.dosyaBelirleyici.id != "undefined" && this.dosyaBelirleyici.id != "") === true);
        return (typeof this.dosyaBelirleyici.id != "undefined" && this.dosyaBelirleyici.id != "") === true;
    }

    setMahiyetId(val: any) {
        if (this.isMahiyet()) {
            this.mahiyetId = parseInt(val);
        } else {
            delete this.mahiyetId;
        }
    }

    setDosyaBelirleyici(rootDomNode: HTMLUnknownElement) {
        let text = rootDomNode.getAttribute("dosyabelirleyicisi");
        let dosyaBelirleyici = {};
        let abonelikMi = false;
        if (typeof text != "undefined" && text != "" && text != null) {
            let splited = text.split('_:');//dosya belirleyici _: id _: aboneliksozlesmesi seklinde veri geliyor
            //@ts-ignore
            if (splited[splited.length - 1] == 1)
                abonelikMi = true;
            dosyaBelirleyici = {name: splited[0], id: splited[1], abonelikSozlesmesi: abonelikMi};
        }
        this.dosyaBelirleyici = dosyaBelirleyici;
        this.abonelikMi = abonelikMi;
    }

    setDosyaKriterList(rootDomNode: HTMLUnknownElement) {
        let bk, vkdv, bsmv, kkdf, kdv, oiv;
        bk = rootDomNode.getAttribute("bk84maddeuygulansin");
        vkdv = rootDomNode.getAttribute("vkdvuygulansin");
        bsmv = rootDomNode.getAttribute("bsmvuygulansin");
        kkdf = rootDomNode.getAttribute("kkdfuygulansin");
        kdv = rootDomNode.getAttribute("kdvuygulansin");
        oiv = rootDomNode.getAttribute("oivuygulansin");
        console.log("BISEYLER-=----")
        console.log(bk, vkdv, bsmv, kkdf, kdv, oiv);
        console.log("rootDomNode", rootDomNode);
        let result = "";
        if (bk == "E")
            result += ",bk";
        if (vkdv == "E")
            result += ",vkdv";
        if (kkdf == "E")
            result += ",kkdf";
        if (bsmv == "E")
            result += ",bsmv";
        if (kdv == "E")
            result += ",kdv";
        if (oiv == "E")
            result += ",oiv";
        if (result != "")
            result = result.substr(1);
        console.log("setDosyaKriterList", result);
        if (result != "")
            this.dosyaKriterList = result;
    }

    toUyapJson() {
        const {
            kotaKullanimSekli,
            adliyeBirimId,
            takipTuru,
            takipYolu,
            takipSekli,
            dosyaTevziTipiBanka,
            dosyaTevziTipiGayrimenkul,
            dosyaAciklama_48_4,
            dosyaAciklama_48_9,
            mahiyetId,
            dosyaKriterList,
            il,
            ipotekRehinAciklama,
            abonelikMi
        } = this;

        let returnData: any = {
            kotaKullanimSekli,
            adliyeBirimId,
            takipTuru,
            takipYolu,
            takipSekli,
            dosyaTevziTipiBanka,
            dosyaTevziTipiGayrimenkul,
            dosyaAciklama_48_4,
            dosyaAciklama_48_9,
            dosyaKriterList,
            il,
            ipotekRehinAciklama,
            abonelikMi
        }
        //@ts-ignore
        if (this.isMahiyet() && mahiyetId != "") {
            console.log("IS MAHIYET TRUE");
            returnData["mahiyetId"] = mahiyetId
        }
        return returnData;
    }


}

