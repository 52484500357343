import {ITask} from "../GorevYoneticisiTypes";
import {TaskDurum} from "../../../enum/TaskDurum";
import {TaskTuru} from "../../../enum/TaskTuru";
import app from "@/main";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {EvrakGonderTaskHandler} from "./EvrakGonderTaskHandler";
import {MtsOdemeTaskHandler} from "./MtsOdemeTaskHandler";
import {HarcMasrafOdeTaskHandler} from "./HarcMasrafOdeTaskHandler";
import {TopluHacizHazirlaTaskHandler} from "./TopluHacizHazirlaTaskHandler";
import {HazirEvrakGonderTaskHandler} from "./HazirEvrakGonderTaskHandler";
import {TakipSorgulaTaskHandler} from "./TakipSorgulaTaskHandler";
import {UyapTakipTevziAlTaskHandler} from "./UyapTakipTevziAlTaskHandler";
import {UyapTakipOdemeYapTaskHandler} from "./UyapTakipOdemeYapTaskHandler";
import {MakbuzIndirTaskHandler} from "./MakbuzIndirTaskHandler";
import {MtsTakipTevziAlTaskHandler} from "./MtsTakipTevziAlTaskHandler";
import {TopluDigerTalepGonderTaskHandler} from "./TopluDigerTalepGonderTaskHandler";
import {TopluTebligatGonderTaskHandler} from "./TopluTebligatGonderTaskHandler";
import {TopluHacizGonderTaskHandler} from "./TopluHacizGonderTaskHandler";
import {EvrakIndirTaskHandler} from "./EvrakIndirTaskHandler";
import {MtsTebligatGonderTaskHandler} from "./MtsTebligatGonderTaskHandler";

export class TaskHandler {
    async handle(task: ITask) {
        if (task.status == TaskDurum.SIRADA) {
            task.status = TaskDurum.ISLENIYOR;
            task.started_at = new Date();
            await app.$store.dispatch('putTask', {
                id: task.id,
                status: task.status
            });
            //@ts-ignore
            let parent = app.$store.getters.getSiradakiGorevler.find((gorev: ITask) => (gorev.id == task.parent_id));
            if (parent.status != TaskDurum.ISLENIYOR) {
                parent.started_at = new Date();
                parent.status = TaskDurum.ISLENIYOR;
                await app.$store.dispatch('putTask', {
                    id: parent.id,
                    status: parent.status
                });
            }
        }

        let handler: AbstractTaskHandler;

        switch (task.task_type_id) {
            case TaskTuru.takip_hazirla:
                handler = new UyapTakipTevziAlTaskHandler();
                break;
            case TaskTuru.takip_ac:
                handler = new UyapTakipOdemeYapTaskHandler();
                break;
            case TaskTuru.mts_takip_hazirla:
                handler = new MtsTakipTevziAlTaskHandler();
                break;
            case TaskTuru.mts_takip_ac:
                handler = new UyapTakipOdemeYapTaskHandler();
                break;
            case TaskTuru.evrak_gonder:
                handler = new EvrakGonderTaskHandler();
                break;
            case TaskTuru.mts_odeme:
                handler = new MtsOdemeTaskHandler();
                break;
            case TaskTuru.harc_masraf_ode:
                handler = new HarcMasrafOdeTaskHandler();
                break;
            case TaskTuru.toplu_haciz_hazirla:
                handler = new TopluHacizHazirlaTaskHandler();
                break;
            case TaskTuru.toplu_haciz_gonder:
                handler = new TopluHacizGonderTaskHandler();
                break;
            case TaskTuru.toplu_tebligat_hazirla:
                handler = new TopluTebligatGonderTaskHandler();
                break;
            case TaskTuru.toplu_genel_talep_hazirla:
                handler = new TopluDigerTalepGonderTaskHandler();
                break;
            case TaskTuru.hazir_evrak_gonder:
                handler = new HazirEvrakGonderTaskHandler();
                break;
            case TaskTuru.makbuz_indir:
                handler = new MakbuzIndirTaskHandler();
                break;
            case TaskTuru.takip_sorgula:
                handler = new TakipSorgulaTaskHandler();
                break;
            case TaskTuru.evrak_indir:
                handler = new EvrakIndirTaskHandler();
                break;
            case TaskTuru.mts_tebligat_gonder:
                handler = new MtsTebligatGonderTaskHandler();
                break;
        }
        try {
            //@ts-ignore
            let taskResult: ITask = await handler.handle(task);
            let status = taskResult.status;
            console.log('TaskResult',taskResult);
            if (!(taskResult.status == TaskDurum.BITTI_EKSIK || taskResult.status == TaskDurum.BITTI_HATALI))
                status = TaskDurum.BITTI_BASARILI
            await app.$store.dispatch('putTask', {
                id: taskResult.id,
                status: status,
                finished_at: new Date(),
                response: taskResult.response,
                result: taskResult.result,
                description: taskResult.description
            });
        } catch (error: any) {
            console.error('Bitti Hatalı', error);
            await app.$store.dispatch('putTask', {
                id: task.id,
                status: TaskDurum.BITTI_HATALI,
                finished_at: new Date(),
                response: error,
                result: task.result,
                description: error.message
            });
        }
    }
}