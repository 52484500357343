import {UyapRequest} from "./UyapRequest";
import {adresDVORow, AdresRow} from "./CommonInterfaces";

export interface MtsTebligatGonderTalep {
    MTSTebligat: number,
    dosyaId: number,
    tebligatKisiKurumIdList: number[] | string;
}

export type MtsTebligatGonderCevap = Array<MtsTebligatGonderRow>;

export interface MtsTebligatGonderRow {
    evrakId: number,
    tarafTebligatDVO: tarafTebligatDVORow,
}

export interface tarafTebligatDVORow {
    pttDurum: number,
    hasTebligatEvrak: boolean,
    tebligatGGEvrakId: number,
    evrakKisiDVO: evrakKisiDVORow,
    adresDVO: adresDVORow,
    tebligatInputDVO: tebligatInputDVO
}

export interface tebligatInputDVO {
    inputDVO: inputDVORow,
    dosyaKisiAdresDVO: adresDVORow,
    ggEvrakId: number
}

export interface inputDVORow {
    tebligatNo: string,
    barkodNo: string,
    avukatId: number,
    avukatTckn: number,
    avukatAd: string,
    avukatSoyad: string,
    avukatIl: string,
    avukatIlce: string,
    avukatAdres: string,
    avukatEPosta: string,
    aliciAdSoyad: string,
    aliciIl: string,
    aliciIlce: string,
    aliciAyrintiliAdres: string,
    basimSayfaAdedi: number,
    basimUcret: number,
    birlesikEvrak: Array<any>,
    tebligatTuru: string,
    dosyaNumarasi: string,
    icraDairesi: string,

}

export interface evrakKisiDVORow {
    evrakKisiId: string,
    ggEvrakId: string,
    ilgiliDosyaKisiId: string,
    asilTarafDosyaKisiId: string,
    ilgiliKisiTipi: string,
    ilgili: string,
    dosya: Array<dosyaRow>,
    ilgiliKisiIlkKisiKurumId: string,
    asilTarafIlkKisiKurumId: string,
    ilgiliKisiRolu: string,
    ilgiliKisiRolId: string,
    ilgilininHarcDurumu: string,
    sanikStatusu: string,
    secili: boolean,
    tcKimlikNo: string,
    babaAdi: string,
    kisi: boolean,
    tcVatandasi: boolean,
    gulcelKisiKurumId: string,
    addresList: Array<AdresRow>
}

export interface dosyaRow {
    clientDosyaDVO: Array<clientDosyaDVORow>,
    dosyaTur: number
}

export interface clientDosyaDVORow {
    dosyaID: string,
    dosyaBilgileriTurKodu: number,
}


export default class MtsTebligatGonder extends UyapRequest<MtsTebligatGonderTalep, MtsTebligatGonderCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }


    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/mts_tebligat_gonder_brd.ajx';
    }

    async parse(data: any): Promise<MtsTebligatGonderCevap> {
        return data;
    }

    getMockResponse(): any {
        return [
            {
                "evrakId": 5057828307,
                "tarafTebligatDVO": {
                    "pttDurum": 1,
                    "tebligatGGEvrakId": 6594336620,
                    "hasTebligatEvrak": true,
                    "evrakKisiDVO": {
                        "evrakKisiId": "1963294309",
                        "ggEvrakId": "6594336609",
                        "ilgiliDosyaKisiId": "948472222",
                        "asilTarafDosyaKisiId": "948472222",
                        "ilgiliKisiTipi": "T",
                        "ilgili": "MERTGÜN KAYA",
                        "dosya": {
                            "clientDosyaDVO": {"dosyaID": "553117361", "dosyaBilgileriTurKodu": 0},
                            "dosyaTur": -1
                        },
                        "ilgiliKisiIlkKisiKurumId": "23517400",
                        "asilTarafIlkKisiKurumId": "23517400",
                        "ilgiliKisiRolu": "BORÇLU VE MÜFLİS",
                        "ilgiliKisiRolId": "22",
                        "ilgilininHarcDurumu": "1",
                        "sanikStatusu": "E",
                        "addresList": [{
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "ÇANKAYA",
                            "ilceKodu": 8,
                            "adres": "Filistin Cad. No: 17/9 G.O.P.",
                            "kisiKurumID": 23517400,
                            "adresID": 223528684,
                            "adresTuru": "ADRTR00002",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": false,
                            "guncellendiMi": false
                        }, {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "ÇANKAYA",
                            "ilceKodu": 8,
                            "adres": "Kırkkonaklar Mh. 15. Sk No:28 D:8",
                            "kisiKurumID": 23517400,
                            "adresID": 172419129,
                            "adresTuru": "ADRTR00001",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": false,
                            "guncellendiMi": false
                        }, {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "ÇANKAYA",
                            "ilceKodu": 8,
                            "adres": "ESATOĞLU MAH.BARDACIK SOK.BİNA NO:59 KAT NO:1 DAİRE NO:1",
                            "kisiKurumID": 23517400,
                            "adresID": 102922164,
                            "adresTuru": "ADRTR00001",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": false,
                            "guncellendiMi": false
                        }, {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "ETİMESGUT",
                            "ilceKodu": 11,
                            "adres": "ŞEKER MAH. 1407 CAD.  AKDAL REZİDANCE NO:16C, İÇ KAPI NO:72 ",
                            "kisiKurumID": 23517400,
                            "adresID": 259103063,
                            "adresTuru": "ADRTR00001",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": false,
                            "guncellendiMi": false
                        }, {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "ETİMESGUT",
                            "ilceKodu": 11,
                            "adres": "İSTASYON MAH. SINIR SK. NO:8/AMERKEZ ETİMESGUT ANKARA",
                            "kisiKurumID": 23517400,
                            "adresID": 263044997,
                            "adresTuru": "ADRTR00001",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": true,
                            "guncellendiMi": false
                        }, {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "MERKEZ",
                            "ilceKodu": 1,
                            "adres": ".",
                            "kisiKurumID": 23517400,
                            "adresID": 233394986,
                            "adresTuru": "ADRTR00001",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": false,
                            "guncellendiMi": false
                        }, {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "ÇANKAYA",
                            "ilceKodu": 8,
                            "adres": "1. CAD. 115 SK. NO:3/3 İLKER DİKMEN",
                            "kisiKurumID": 23517400,
                            "adresID": 197434371,
                            "adresTuru": "ADRTR00001",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": false,
                            "guncellendiMi": false
                        }, {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "ETİMESGUT",
                            "ilceKodu": 11,
                            "adres": "ŞEKER MAH. 1407 CAD. NO:16C İÇ KAPI NO:72 ",
                            "kisiKurumID": 23517400,
                            "adresID": 103026987,
                            "adresTuru": "ADRTR00008",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": true,
                            "guncellendiMi": false
                        }, {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "YENİMAHALLE",
                            "ilceKodu": 25,
                            "adres": "YAŞAMKENT MAH. 3297 SK. NO:2D İÇ KAPI NO:5 ",
                            "kisiKurumID": 23517400,
                            "adresID": 177498395,
                            "adresTuru": "ADRTR00010",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": false,
                            "guncellendiMi": false
                        }, {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "ÇANKAYA",
                            "ilceKodu": 8,
                            "adres": "FİLİSTİN CADDESİ NO: 17/9 G.O.P.",
                            "kisiKurumID": 23517400,
                            "adresID": 225952554,
                            "adresTuru": "ADRTR00001",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": false,
                            "guncellendiMi": false
                        }],
                        "secili": false,
                        "tcKimlikNo": "35483250628",
                        "babaAdi": "NİYAZİ",
                        "kisi": true,
                        "tcVatandasi": true,
                        "gulcelKisiKurumId": "23517400"
                    },
                    "adresDVO": {
                        "id": 938297086,
                        "adresId": 103026987,
                        "dosyaId": 553117361,
                        "ilkKisiKurumId": 23517400,
                        "guncelKisiKurumId": 23517400,
                        "gizliYazismaAdresi": false,
                        "kisikurumtur": "G",
                        "adresDVO": {
                            "ilKodu": 6,
                            "ilAdi": "ANKARA",
                            "ilceAdi": "ETİMESGUT",
                            "ilceKodu": 11,
                            "adres": "ŞEKER MAH. 1407 CAD. NO:16C İÇ KAPI NO:72 ",
                            "kisiKurumID": 23517400,
                            "adresID": 103026987,
                            "adresTuru": "ADRTR00008",
                            "adresTuruAciklama": "Mernis Adresi",
                            "eklenebilirAdresMi": false,
                            "kayitliAdresMi": false,
                            "aktifAdresmi": true,
                            "guncellendiMi": false
                        }
                    },
                    "tebligatInputDVO": {
                        "inputDVO": {
                            "tebligatNo": "2019/1034166",
                            "barkodNo": "4100016024390",
                            "avukatId": 1191184,
                            "avukatTckn": 13660200390,
                            "avukatAd": "YASEMİN",
                            "avukatSoyad": "TEKELİ AYRANCI",
                            "avukatIl": "Ankara",
                            "avukatIlce": "Altındağ",
                            "avukatAdres": "16339-33686-9970316339-33686-99703 Altındağ / ANKARA",
                            "avukatEPosta": "yasemin_tekeli@hotmail.com",
                            "aliciAdSoyad": "BORÇLU VE MÜFLİS MERTGÜN KAYA",
                            "aliciIl": "ANKARA",
                            "aliciIlce": "ETİMESGUT",
                            "aliciAyrintiliAdres": "Şeker Mah. 1407 Cad. No:16C İç Kapı No:72  Etimesgut / ANKARA",
                            "basimSayfaAdedi": 2,
                            "basimUcret": 20,
                            "birlesikEvrak": [],
                            "tebligatTuru": "İdari Tebligat",
                            "dosyaNumarasi": "2019/1034166",
                            "icraDairesi": "Ankara Batı İcra Dairesi"
                        },
                        "dosyaKisiAdresDVO": {
                            "id": 938297086,
                            "adresId": 103026987,
                            "dosyaId": 553117361,
                            "ilkKisiKurumId": 23517400,
                            "guncelKisiKurumId": 23517400,
                            "gizliYazismaAdresi": false,
                            "kisikurumtur": "G",
                            "adresDVO": {
                                "ilKodu": 6,
                                "ilAdi": "ANKARA",
                                "ilceAdi": "ETİMESGUT",
                                "ilceKodu": 11,
                                "adres": "ŞEKER MAH. 1407 CAD. NO:16C İÇ KAPI NO:72 ",
                                "kisiKurumID": 23517400,
                                "adresID": 103026987,
                                "adresTuru": "ADRTR00008",
                                "adresTuruAciklama": "Mernis Adresi",
                                "eklenebilirAdresMi": false,
                                "kayitliAdresMi": false,
                                "aktifAdresmi": true,
                                "guncellendiMi": false
                            }
                        },
                        "ggEvrakId": 6594336620
                    }
                }
            }
        ];
    }

}

