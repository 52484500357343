import app from "@/main";
import MakbuzController from "../../../makbuz/MakbuzController";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {TaskDurum} from "../../../enum/TaskDurum";

export class MakbuzIndirTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        try {
            let makbuzBlob: Blob = await MakbuzController.getMakbuzBlob(task.data.uyapId, task.data.uyapIslemTur);
            //@ts-ignore
            makbuzBlob.name = task.birim_adi + ' ' + task.dosya_esas_no.replaceAll('/', '_') + " makbuz (" + task.data.uyapId + ")" + makbuzBlob.name.substr(makbuzBlob.name.lastIndexOf('.'));
            //TODO task fıle upload sınıfı yazılacak ç.
            let formData = new FormData();
            //@ts-ignore
            formData.append('name', makbuzBlob.name);
            formData.append('file', makbuzBlob);
            formData.append('task_id', (task.id ?? "").toString());
            await app.$http.post('/api/v1/task/file', formData);
            //@ts-ignore
            task.response = [task.dosya_esas_no, task.birim_adi, makbuzBlob.name];
            task.status = TaskDurum.BITTI_BASARILI;
            return task;
        } catch (e: any) {
            throw new Error(e.message)
        }
    }
}
