import {UyapRequest} from "./UyapRequest";

export interface AvukatKayitliIbanTalep {
}

export interface AvukatKayitliIban {
    kayitID: number,
    bankaAdi: string,
    ibanNumarasi: string,
    hesapGenel: boolean,
    hesapAktifmi: string
}

export default class AvukatKayitliIbanList extends UyapRequest<AvukatKayitliIbanTalep, AvukatKayitliIban[]> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "_AvukatKayitliIbanList"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/kayitli_iban_listesi.ajx";
    }

    async parse(data: string): Promise<AvukatKayitliIban[]> {
        let jsonObj: any = await this.xml2json(data)
        let liste = jsonObj['root']['ResultEvent']['value']['liste']['BankaHesapBilgileriDVO'];
        if (!Array.isArray(liste)) {
            return [liste];
        }
        return liste;
    }

    getMockResponse(): any {
        return `<root><ResultEvent>
          <type>success</type>
          <message/>
          <value>
            <liste>
              <BankaHesapBilgileriDVO>
                <kayitID>356199</kayitID>
                <bankaAdi>T.VAKIFLAR BANKASI T.A.O.</bankaAdi>
                <ibanNumarasi>TR550001500158007300608019</ibanNumarasi>
                <hesapGenel>true</hesapGenel>
                <hesapAktifmi>E</hesapAktifmi>
              </BankaHesapBilgileriDVO>
              
            </liste>
            <actualCount>0</actualCount>
            <currentPage>0</currentPage>
            <pageSize>0</pageSize>
            <cacheID>0</cacheID>
            <recordCount>0</recordCount>
          </value>
          <log/>
        </ResultEvent></root>`
    }
}