import {UyapRequest} from "./UyapRequest";
import UyapHtmlParser from "./parser/UyapHtmlParser";

export interface BorcluDibTalep {
    dosyaId: number | string,
    kisiKurumId: number | string,
    sorguBakiye: number | string
}

export class BorcluDibCevap {
    message: string = "";
    found: boolean = false;
    data: BorcluDibCevapRow[] = [];
    html: string = ""
}

export interface BorcluDibCevapRow {
    adresTuru: string,
    sehir: string,
    adres: string,
    postaKodu: string
}

export default class BorcluDib extends UyapRequest<BorcluDibTalep, BorcluDibCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "borcludib_" + this.talepData.kisiKurumId
        };
    }

    getMockResponse(): string {
        return `<div id="servis_sonuc_15">
    <fieldset id="content">
        <table class='table table-bordered table-striped'>
            <tr>
                <td style=" border: none; text-align: center; font-weight: bold; width: 100%;">BORÇLUYA AİT DIŞ İŞLERİ
                    BİLGİLERİ
                </td>
            </tr>
        </table>
        <fieldset style="margin-bottom: 30px;">
            <legend>Sorgulamaya Ait Genel Bilgiler</legend>
            <table class='table table-bordered table-striped'>
                <tr>
                    <td style=" border: none; text-align: left; font-weight: bold;width: 250px;">İşlemi Gerçekleştirenin
                        Adı Soyadı:
                    </td>
                    <td style="border: none; text-align: left;">Av. N___ R____</td>
                </tr>
                <tr>
                    <td style=" border: none; text-align: left; font-weight: bold;width: 250px;">Birim Adı/Dosya No:
                    </td>
                    <td style="border: none; text-align: left;">Ankara 29. İcra Dairesi 2011/16341</td>
                </tr>
                <tr>
                    <td style=" border: none; text-align: left; font-weight: bold;width: 250px;">Sorgulanan Kişi
                        Bilgileri:
                    </td>
                    <td style="border: none; text-align: left;">Borçlu YEŞİM YASEMİN</td>
                </tr>
            </table>
        </fieldset>
        <div><p style='border:1px solid #ddd;padding:10px;'><strong> Kişiye ait Dış İşleri Bakanlığı kayıtlarına göre yurt dışı adres kaydı var.</strong></p>
            <table class='table table-bordered table-striped'>
                <thead>
                <tr>
                    <th>Adres Türü</th>
                    <th>Şehir</th>
                    <th>Adres</th>
                    <th>Posta Kodu</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Ev</td>
                    <td>Gazimağusa</td>
                    <td>Doğu Akdeniz Üniv.</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Turkiye</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            <table style="border: none;margin-top:30px;">
                <tr>
                    <td style="width: 50%; border: none;"></td>
                    <td style="width: 50%; border: none;">
                        <table style="border: none;">
                            <tr>
                                <td style=" border: none; text-align: center; width: 100%;">27/12/2018 15:10</td>
                            </tr>
                            <tr>
                                <td style=" border: none; text-align: center; font-weight: bold; width: 100%;">
                                    Düzenleyen
                                </td>
                            </tr>
                            <tr>
                                <td style=" border: none; text-align: center; font-weight: bold; width: 100%;">AVUKAT
                                    PORTAL BİLGİ SİSTEMİ
                                </td>
                            </tr>
                            <tr>
                                <td style=" border: none; text-align: center; width: 100%;">Avukatların, Avukat
                                    Portal'dan almış oldukları hizmete ilişkin belgedir.
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
    </fieldset>
</div>
<div style="width: 100%; border: none; text-align: center;"><a id="114231062074117515320047263513160742707675_print"
                                                               class="print_btn"
                                                               style="margin-right: 10px; color: blue;"
                                                               href="javascript: void(0);">Yazdır</a><a
        id="114231062074117515320047263513160742707675_pdf" class="pdf_btn" style="margin-right: 10px; color: blue;"
        href="javascript: void(0);">PDF</a></div>
<script type="text/javascript">$('#114231062074117515320047263513160742707675_print').click(function () {
    $(this).hide();
    var w = window.open();
    w.document.write('<html><head><title>UYAP Bilgi Sistemi</title></head><body>');
    w.document.write($('div#servis_sonuc_15 fieldset[id=content]').html());
    w.document.write('</body></html>');
    w.print();
    w.close();
    $(this).show();
});</script>
<script type="text/javascript">$('#114231062074117515320047263513160742707675_pdf').click(function () {
    $(this).hide();
    postToURL_target('convert_to_pdf.uyap', {
        data: $('div#servis_sonuc_15 fieldset[id=content]').html(),
        filename: 'Borçlu Bilgileri DIŞ İŞLERİ'
    }, 'width=400,height=10,resizable=yes');
    $(this).show();
});</script>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/borclu_bilgileri_goruntule_dis_isleri_brd.ajx';
    }

    async parse(data: string): Promise<BorcluDibCevap> {
        let strIndex = data.indexOf("<div><p");
        let cuttedHtml = data.substr(strIndex);
        strIndex = cuttedHtml.indexOf("</div>");
        cuttedHtml = cuttedHtml.substr(0, strIndex + 6);
        let div: HTMLElement = document.createElement('div');
        div.innerHTML = cuttedHtml;
        let tbody = div.querySelectorAll('.table tbody tr');
        let message: string = (div.querySelector('strong') as HTMLElement).innerText;
        let found = message.indexOf('var.') > 1;
        let adresler: Array<BorcluDibCevapRow> = [];
        if (found) {
            //@ts-ignore
            adresler = UyapHtmlParser.nodeListMap<BorcluDibCevap>(tbody, (tr: HTMLElement): BorcluDibCevapRow => {
                return {
                    adresTuru: (tr.querySelectorAll('td')[0] as HTMLTableCellElement)?.innerText,
                    sehir: (tr.querySelectorAll('td')[1] as HTMLTableCellElement)?.innerText,
                    adres: (tr.querySelectorAll('td')[2] as HTMLTableCellElement)?.innerText,
                    postaKodu: (tr.querySelectorAll('td')[3] as HTMLTableCellElement)?.innerText
                }
            });
        }
        return {
            data: adresler,
            message: message,
            found: found,
            html: div.innerHTML
        }
    }
}

