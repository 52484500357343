import _Vue from "vue";
import axios from "axios";
import app from "@/main";
import {Mixins} from "@/plugins/uyap-plugin/takip_ac/Mixins";
import * as xml2js from "xml2js";
import {PlayerDurum} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {store} from "@/store";

const imzaciUrl = 'https://imza.eicrapro.com:6090/'
const proxyBaseUrl = 'https://imza.eicrapro.com:';

export enum UYAP_PLATFORM_SOCKET {
    ARABULUCU_PORTAL = 6080,
    ARABULUCU_PORTAL_URL = "arabulucu.uyap.gov.tr",
    AVUKAT_PORTAL = 6081,
    AVUKAT_PORTAL_URL = "avukat.uyap.gov.tr",
}

class ImzaClient {
    simulator = false;
    imzaLoginWaiting = false;
    waitingFailMessage = "Bekleyen bir giriş isteği mevcut.";

    setSimulator() {
        this.simulator = true;
    }

    heartbeat() {
        const xml = '<root><auth><token></token><hash></hash></auth><data></data></root>';
        return new Promise(async (s, f) => {
            if (this.simulator) {
                return s("");
            }
            try {
                let data = await axios.post(imzaciUrl + 'xml/heartbeat', {xml: xml}, {
                    withCredentials: true
                });
                app.$socket.client.connect();
                let tenant = await store.dispatch('tenantData');
                const cookieSetUrl = encodeURI(proxyBaseUrl + UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL + '/setConfig?token=' + 'Bearer ' + store.getters.accessToken + '&tenant=' + JSON.stringify(tenant));
                let cookieResponse = await app.$uyap.SetCookie().run({url: cookieSetUrl});
                _Vue.prototype.$socket.client.emit("updateToken");
                return s(data);
            } catch (e) {
                //console.log('fail');
                return f(e);
            }
        });
    }

    async setCookieToImza() {
        if (window.sessionStorage.hasOwnProperty("INJECT_JSESSIONID")) {
            let jsessionId = window.sessionStorage.getItem("INJECT_JSESSIONID");
            const cookieSetUrl = proxyBaseUrl + UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL.toString() + '/cookie?JSESSIONID=' + jsessionId + ';SameSite=None;Secure';
            await app.$uyap.SetCookie().run({url: cookieSetUrl});
        }
    }

    async changePlayerStatus(status: PlayerDurum) {
        const cookieSetUrl = proxyBaseUrl + UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL.toString() + '/putTaskPlayerStatus?' + status;
        await app.$uyap.ChangePlayerStatus().run({url: cookieSetUrl});
    }

    async getPlayerStatus() {
        const cookieSetUrl = proxyBaseUrl + UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL.toString() + '/getTaskPlayerStatus';
        return await app.$uyap.ChangePlayerStatus().run({url: cookieSetUrl});
    }

    async login(uyapPlatform: UYAP_PLATFORM_SOCKET) {
        try {
            if (this.imzaLoginWaiting == true)
                throw new Error(this.waitingFailMessage);
            this.imzaLoginWaiting = true;
            let authorizationCodeResponse = await axios.post(imzaciUrl + 'xml/session');
            window.sessionStorage.setItem("uyapAuthorizationCode", authorizationCodeResponse.data);
            let platform = UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL_URL;
            if (uyapPlatform == UYAP_PLATFORM_SOCKET.ARABULUCU_PORTAL)
                platform = UYAP_PLATFORM_SOCKET.ARABULUCU_PORTAL_URL;
            let uyapLoginResponse = await axios.get(imzaciUrl + 'uyapLogin?code=' + authorizationCodeResponse.data + '&platform=' + platform);
            let cookies = uyapLoginResponse.data;
            //@ts-ignore
            let jsessionId = cookies.find(c => c.indexOf("JSESSIONID") >= 0);
            const cookieSetUrl = proxyBaseUrl + uyapPlatform.toString() + '/cookie?' + cookies.join("; ") + ';SameSite=None;Secure';
            window.sessionStorage.setItem("JSESSIONID", jsessionId);
            app.$uyapHelper.setCookie("JSESSIONID", jsessionId.replace("JSESSIONID=", ""), 1);
            await app.$uyap.SetCookie().run({url: cookieSetUrl});
            app.$socket.client.emit("uyapcookies", {
                cookies: cookies,
            });
            let counter = 0;
            const checkSession = () => {
                app.$uyap.AvukatKisiselBilgileriSorgulama().run({}).then(avukat => {
                    this.imzaLoginWaiting = false;
                    app.$store.commit('setUyapAvukatBilgileri',avukat);
                    return avukat;
                }).catch((avukatErr) => {
                    console.log("CATCH AVUKAT", avukatErr);
                    if (counter > 5) {
                        this.imzaLoginWaiting = false;
                        throw new Error('UYAP oturumuna erişilemedi.');
                    } else {
                        counter++;
                        window.setTimeout(() => {
                            checkSession();
                        }, 3000)
                    }
                })
            }
            checkSession();
        } catch (e: any) {
            console.log("imza login CATCH", e);
            this.imzaLoginWaiting = false;
            throw new Error(e.message);
        }
    }

    fastLogin(uyapPlatform: number) {
        return new Promise((s, f) => {
            if (this.imzaLoginWaiting == true)
                return f({message: this.waitingFailMessage});
            this.imzaLoginWaiting = true;
            axios.post(imzaciUrl + 'xml/sessionFast').then((response) => {
                const cookieSetUrl = proxyBaseUrl + uyapPlatform.toString() + '/cookie?' + window.atob(response.data) + ';SameSite=None;Secure';
                axios.post(cookieSetUrl).then((cookieResponse) => {
                    this.imzaLoginWaiting = false;
                    return s(response.data);
                });
            }).catch((e) => {
                if (e.message != this.waitingFailMessage) {
                    this.imzaLoginWaiting = false;
                }
                f(e);
            });
        });
    }

    mesajImzala() {
        const myXml = '<root>' +
            '<auth><token></token></auth>' +
            '<data><message>Emre deneme imzala</message></data>' +
            '</root>';
        //readResult = reader.result.split(',')[1];
        axios.post(imzaciUrl + "xml/mesajImzala", myXml, {
            headers: {
                "Content-Type": "application/xml;charset=UTF-8"
            }
        })
    }

    dokumanImzala(data: Blob) {
        return new Promise((resolve, reject) => {
            if (this.simulator) {
                resolve("");
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(data); //udf base64 ceviriliyor..
            reader.onloadend = function () {
                //console.log("reader Result",reader.result);
                let b64Udf;
                if (typeof reader.result == "string") {
                    b64Udf = reader.result.split(',')[1];
                } else {
                    return reject('Dosya okunurken hata oluştu');
                }
                //console.log("b64UDF", b64Udf);

                //127.0.0.1:4723/xml/dokumanImzala
                //* blob data reader ile base 64 e ceviriliyor imzaciya gonderiliyor...

                const myXml = '<root>' +
                    '<auth><token></token></auth>' +
                    '<data><code>' + b64Udf + '</code></data>' +
                    '</root>';
                //readResult = reader.result.split(',')[1];
                axios.post(imzaciUrl + "xml/b64imzala", myXml, {
                    headers: {
                        "Content-Type": "application/xml;charset=UTF-8"
                    }
                }).then((data) => {
                    let parseString = xml2js.parseString;
                    parseString(data.data, {explicitArray: false}, (error: Error | null, result: any) => {
                        if (error) {
                            return reject(error);
                        }
                        //console.log("RESULT IMZALANMA", result);
                        return resolve(result.root.file);
                    });
                }).catch(function (err) {
                    if (err.response) {
                        let parseString = xml2js.parseString;
                        parseString(err.response.data, {explicitArray: false}, (error: Error | null, result: any) => {
                            app.$toast.error("Hata Kodu " + err.response.status + ": " + result.root.error_title);
                        });
                    } else if (err.request) {
                        app.$toast.error("İmzacı bağlantı hatası.");
                    } else {
                        app.$toast.error("Bilinmeyen hata (" + err.message + ")");
                    }
                    return reject(err);
                });

            };
        });
    }

    getFileFromLocalPath(path: string) {
        return new Promise((resolve, reject) => {
            let myXml = "<root>\n" +
                "<auth>\n" +
                "<token></token>\n" +
                "<hash></hash>\n" +
                "</auth>\n" +
                "<data>\n" +
                "<b64Xml>" + Mixins.utf8_to_b64("<path>" + path + "</path>") + "</b64Xml>\n" +
                "</data>\n" +
                "</root>"
            axios.post(imzaciUrl + "xml/getXmlContent", myXml, {
                headers: {
                    "Content-Type": "application/xml;charset=UTF-8"
                }
            }).then((data) => {
                let replacedData = String(data.data).replace(/\n/g, "");
                let parseString = xml2js.parseString;
                parseString(replacedData, {explicitArray: false}, (error: Error | null, jsonData: any) => {
                    if (error) {
                        return reject(error);
                    }
                    if (typeof jsonData.root.file != "undefined") {
                        return resolve(jsonData.root);
                    } else {
                        return reject({message: "Dosya bulunamadı !"});
                    }
                });
            }).catch(err => {
                return reject(err);
            })
        });
    }

    serialize(obj: any) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

}

export default function ImzaClientPlugin(Vue: typeof _Vue): void {
    // do stuff with options
    Vue.prototype.$imza = new ImzaClient();
}
