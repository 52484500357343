import {AppState} from "@/types";
import {TaskTarget} from "../../enum/TaskTarget";
import {TaskTuru} from "../../enum/TaskTuru";
import {TaskResult} from "../../enum/TaskResult";
import {TaskDurum} from "../../enum/TaskDurum";
import {MtsTakipAcTaskDataInterface, TakipAcTaskDataInterface} from "./TaskDataInterface/TakipAcTaskDataInterface";
import {
    MtsTakipHazirlaTaskDataInterface,
    TakipHazirlaTaskDataInterface
} from "./TaskDataInterface/TakipHazirlaTaskDataInterface";
import {MtsTebligatGonderTaskDataInterface} from "./TaskDataInterface/MtsTebligatGonderTaskDataInterface";
import {TakipSorgulaTaskDataInterface} from "./TaskDataInterface/TakipSorgulaTaskDataInterface";
import {TopluHacizHazirlaTaskCevap} from "./TaskHandler/TopluHacizHazirlaTaskHandler";
import {OdemeIslemiSorgulamaRow} from "../../uyap/OdemeIslemiSorgulama";
import {EvrakGonderTaskDataInterface} from "./TaskDataInterface/EvrakGonderTaskDataInterface";
import {HazirEvrakGonderTaskDataInterface} from "./TaskDataInterface/HazirEvrakGonderTaskDataInterface";
import {EvrakIndirTaskDataInterface} from "./TaskDataInterface/EvrakIndirTaskDataInterface";
import {MtsOdemeTaskDataInterface} from "./TaskDataInterface/MtsOdemeTaskDataInterface";
import {HarcMasrafOdeTaskInterface} from "./TaskHandler/HarcMasrafOdeTaskHandler";

export type S = TaskManagerState
export type R = AppState            // RootState Interface

export enum IslemDurum {
    BOSTA,
    ISLENIYOR
}

//TODO: enum değerleri uppercase yapılmalı
export enum PlayerDurum {
    oto,
    dur,
    durduruluyor
}

export interface TaskManagerState {
    tasks: ITasks,
    islemDurum: IslemDurum,
    playerDurum: PlayerDurum,
    enSonIslenenGorev: ITask | null
}

export interface ITasks {
    completed_tasks: Array<ITask>,
    incomplete_tasks: Array<ITask>,
    processed_task_childs: Array<IProcessedTaskChilds>
}

export interface ITask {
    id: number | null,
    data: any   | TakipAcTaskDataInterface | MtsTakipAcTaskDataInterface
                | TakipHazirlaTaskDataInterface | MtsTakipHazirlaTaskDataInterface
                | TakipSorgulaTaskDataInterface | MtsTebligatGonderTaskDataInterface
                | TopluHacizHazirlaTaskCevap | OdemeIslemiSorgulamaRow
                | EvrakGonderTaskDataInterface | HazirEvrakGonderTaskDataInterface
                | EvrakIndirTaskDataInterface | MtsOdemeTaskDataInterface
                | Array<HarcMasrafOdeTaskInterface>,// Form Data
    response: any,
    started_at: Date | null,
    takip_id: number,
    finished_at: Date | null,
    borclu_id: number,
    avukat_id: number | null,
    users: any,
    birim_adi: string,
    scheduled_at: Date | null,
    alacakli_id: number,
    description: string,                        // Açıklama
    dosya_id: number | string,
    dosya_esas_no: string,
    parent_id: number | null,
    uyap_kisi_kurum_id: number,
    task_target: TaskTarget,
    task_type_id: TaskTuru | undefined,         // Tür
    oncelik_sirasi: number,
    result: TaskResult | null,                  // Sonuç
    status: TaskDurum,                          // Durum
    aciklama: string | undefined,
    updated_at: Date | string,
    created_at: Date | string,
    childTasks: Array<ITask> | undefined
}

export class Task implements ITask {
    aciklama: string = "";
    alacakli_id: number = 0;
    avukat_id: number | null = null;
    birim_adi: string = "";
    borclu_id: number = 0;
    data: any = {};
    description: string = "";
    dosya_esas_no: string = "";
    dosya_id: number | string = 0;
    finished_at: Date | null = null;
    id: number | null = null;
    oncelik_sirasi: number = 0;
    parent_id: number | null = null;
    response: any = {};
    result: TaskResult | null = null;
    scheduled_at: Date | null = null;
    started_at: Date | null = null;
    status: TaskDurum = TaskDurum.SIRADA;
    takip_id: number = 0;
    task_target: TaskTarget = TaskTarget.dosya;
    task_type_id: TaskTuru | undefined = undefined;
    users: any = null;
    uyap_kisi_kurum_id: number = 0;
    updated_at = "";
    created_at = "";
    childTasks: Array<ITask> | undefined = undefined;
}

// İşlenen görevin child tasklarının durumunu gösterir
export interface IProcessedTaskChilds {
    id: number,
    status: TaskDurum
}

export interface IExcelDosya {
    tempId: string,     // birimAdi + dosyaNo
    birimAdi: string,
    dosyaNo: string,
    dosyaId: string
}

export interface IDosya {
    birimId: string,
    dosyaId: string,
    dosyaNo: string,
    birimAdi: string,
    birimTuru1: string,
    birimTuru2: string,
    birimTuru3: string,
    dosyaDurumu: string,
    dosyaTurKod: string,
    dosyaKisiSize: string,
    dosyaTurAciklama: string,
    dosyaAcilisTarihi: string,
    dosyaDurumAciklama: string
}