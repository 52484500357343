import {UyapRequest} from "@/plugins/uyap-plugin/uyap/UyapRequest";
import app from "@/main";

export interface AdliyedekiTumMahkemelerTalep {
    adliye: number | string,
    bltr2: number | string          //yargı birimi
}

export interface AdliyedekiTumMahkemelerCevap {
    birimAdi: string,
    birimId: string,
    evraginBirimDVODaGonderilecegiBirimBilgisi: string,
    yeniBirimEkle: string,
    orgKoduDegisti: string,
    isTumunuKopyala: string,
    eskiAd: string,
    eskiOrgKod: string,
    taranmamisKaydetme: string,
    testMi: string
}

export default class AdliyedekiTumMahkemeler extends UyapRequest<AdliyedekiTumMahkemelerTalep, AdliyedekiTumMahkemelerCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "adliyedekiTumMahkemeler_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse() {
        return `<root><object-array>
  <list>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 1. Ceza Dairesi</birimAdi>
      <birimId>1033632</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 2. Ceza Dairesi</birimAdi>
      <birimId>1033633</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 3. Ceza Dairesi</birimAdi>
      <birimId>1033634</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 4. Ceza Dairesi</birimAdi>
      <birimId>1033635</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 5. Ceza Dairesi</birimAdi>
      <birimId>1033636</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 6. Ceza Dairesi</birimAdi>
      <birimId>1033637</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 7. Ceza Dairesi</birimAdi>
      <birimId>1033638</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 8. Ceza Dairesi</birimAdi>
      <birimId>1033639</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 9. Ceza Dairesi</birimAdi>
      <birimId>1033640</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 10. Ceza Dairesi</birimAdi>
      <birimId>1033641</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 11. Ceza Dairesi</birimAdi>
      <birimId>1033642</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 12. Ceza Dairesi</birimAdi>
      <birimId>1033643</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 13. Ceza Dairesi</birimAdi>
      <birimId>1033644</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 14. Ceza Dairesi</birimAdi>
      <birimId>1033645</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 15. Ceza Dairesi</birimAdi>
      <birimId>1033646</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 16. Ceza Dairesi</birimAdi>
      <birimId>1033647</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 17. Ceza Dairesi</birimAdi>
      <birimId>1046905</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 18. Ceza Dairesi</birimAdi>
      <birimId>1046906</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 19. Ceza Dairesi</birimAdi>
      <birimId>1046907</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 20. Ceza Dairesi</birimAdi>
      <birimId>1046908</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 21. Ceza Dairesi</birimAdi>
      <birimId>1046909</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 22. Ceza Dairesi</birimAdi>
      <birimId>1046910</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 23. Ceza Dairesi</birimAdi>
      <birimId>1054885</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 24. Ceza Dairesi</birimAdi>
      <birimId>1046912</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 25. Ceza Dairesi</birimAdi>
      <birimId>1046913</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 26. Ceza Dairesi</birimAdi>
      <birimId>1046917</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 27. Ceza Dairesi</birimAdi>
      <birimId>1046919</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 28. Ceza Dairesi</birimAdi>
      <birimId>1046920</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 29. Ceza Dairesi</birimAdi>
      <birimId>1046922</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 30. Ceza Dairesi</birimAdi>
      <birimId>1046924</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
    <BirimDVO>
      <birimAdi>Ankara Bölge Adliye Mahkemesi 31. Ceza Dairesi</birimAdi>
      <birimId>1046926</birimId>
      <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
      <yeniBirimEkle>true</yeniBirimEkle>
      <orgKoduDegisti>false</orgKoduDegisti>
      <isTumunuKopyala>false</isTumunuKopyala>
      <eskiAd/>
      <eskiOrgKod/>
      <taranmamisKaydetme/>
      <testMi>0</testMi>
    </BirimDVO>
  </list>
</object-array></root>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/adliyedeki_tum_mahkemeler.ajx";
    }

    async parse(data: any): Promise<AdliyedekiTumMahkemelerCevap> {
        let json: any = await this.xml2json(data);
        let liste = json.root['object-array'].list.BirimDVO
        if (!Array.isArray(liste)) {
            liste = [liste];
        }
        return liste;
    }
}