import {UyapRequest} from "./UyapRequest";

export interface MtsTebligatUcretiTalep {
    dosyaId: number,
    tebligatKisiKurumIdList: Array<any>
}

export type MtsTebligatUcretiCevap = number;

export class MtsTebligatUcreti extends UyapRequest<MtsTebligatUcretiTalep, MtsTebligatUcretiCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): string {
        return `27.5`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/mts_tebligat_ucreti_brd.ajx';
    }

    async parse(data: string): Promise<MtsTebligatUcretiCevap> {
        return Number.parseFloat(data);
    }

}

export default MtsTebligatUcreti;
