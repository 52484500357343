import {UyapRequest} from "./UyapRequest";

export interface MtsTakipTalebiOlusturTalep {
    MTSDosyaBilgileri: any,
    MTS: number,
    tarafList: any,
    ilamsizList: any,
    MTSHarcBilgileri: any
}


export interface MtsTakipTalebiOlusturCevap {
    dosyaId: number;
    takibinYolu: number;
    aciklama: string;
    takibeEsasTutar: number;
    yenileme: string;
    terekeMi: string;
    dosyaDurumu: number;
    takipAnaTur: number;
    bk84Uygulansinmi: string;
    bsmvUygulansinmi: string;
    eskiDosyami: string;
    tefrikDosyasimi: string;
    dosyaTurKodu: number;
    aciklama48E9: string;
    dosyaAcilisTarihi: string;
    kkdfHesaplansinmi: string;
    vekaletUcretineKDVUygulansinmi: string;
    feragatIslmDrm: number;
    gelisYeri: number;
    kdv: string;
    vergi: string;
    mahiyetId: number;
    mtsAdliyeBirimID: string;
    gnlDosyaDurumu: number;
    birimID: string;
    birimAdi: string;

}


export default class MtsTakipTalebiOlustur extends UyapRequest<MtsTakipTalebiOlusturTalep, MtsTakipTalebiOlusturCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {
            "dosyaId": 522641040,
            "takibinYolu": 0,
            "aciklama": "Alacağın tahsili tarihine kadar 16,80faiz masraf ve vekalet ücreti ile tahsili. Kısmi ödemelerde TBK 100 e göre hesap yapılmasını talep ederim.",
            "terekeMi": "H",
            "dosyaDurumu": 0,
            "takipAnaTur": 1,
            "takipTur": 0,
            "bk84Uygulansinmi": "E",
            "bsmvUygulansinmi": "E",
            "dosyaTurKodu": 294,
            "dosyaNo": "2019/1147",
            "dosyaYil": 2019,
            "aciklama48E9": "Haciz Yolu",
            "dosyaAcilisTarihi": "15/06/2019",
            "kkdfHesaplansinmi": "E",
            "birimAdi": "Merkezi Takip Sistemi",
            "vekaletUcretineKDVUygulansinmi": "H",
            "feragatIslmDrm": 2,
            "harcKayitID": 6731647360,
            "harcKayitEvrakID": 5871661686,
            "uyapID": 111514275,
            "kdv": "H",
            "vergi": "H",
            "mahiyetId": 2007,
            "mtsAdliyeBirimID": "1006927",
            "gnlDosyaDurumu": 0,
            "birimID": "1054286",
            "dosyaSira": 1147
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/mts_takip_talebi_olustur_brd.ajx';
    }

    async parse(data: any): Promise<MtsTakipTalebiOlusturCevap> {
        return data;
    }

}

