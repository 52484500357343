import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import DigerTalepController from "../../../haciz/DigerTalepController";
import {DigerTalepEvrakTypeEnum} from "../../../uyap/enum/DigerTalepEvrakTypeEnum";
import {TopluHacizHazirlaTaskCevap} from "./TopluHacizHazirlaTaskHandler";
import {TopluHacizGonderTaskResponse} from "./TopluHacizGonderTaskHandler";
import {StatusEnum} from "../../../enum/StatusEnum";
import {TaskDurum} from "../../../enum/TaskDurum";
import JSZip from "jszip";
import app from "@/main";

export class TopluDigerTalepGonderTaskHandler extends AbstractTaskHandler {
    params: any[] = [];
    async handle(task: ITask): Promise<ITask> {
        let data: TopluHacizHazirlaTaskCevap = task.data;
        this.params = data.params.digerTalepParams;
        let response: TopluHacizGonderTaskResponse = {
            dosyaEsasNo: data.dosya.dosyaNo,
            icraDairesi: data.dosya.birimAdi,
            message: "",
            evraklar: []
        };
        if (data.borclular.length > 0) {
            for (const borclu of data.borclular) {
                try {
                    let digerTalepController = new DigerTalepController(borclu, data.dosya);
                    for (const param of this.params) {
                        switch (param.className) {
                            case DigerTalepEvrakTypeEnum.DosyaYenileme: {
                                digerTalepController.dosyaYenilemeEkle();
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.TakibinKesinlesmesi: {
                                digerTalepController.takibinKesinlesmesiEkle();
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.IbanGuncelleme: {
                                digerTalepController.ibanGuncellemesiEkle(borclu.digerTalep.ibanGuncellemeParams)
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.YuzuncuMaddeyeYararBilgiIstenilmesi: {
                                digerTalepController.yuzuncuMaddeyeYararBilgiIstenilmesiEkle(borclu.digerTalep.yuzuncuMaddeyeYararBilgiIstenilmesiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.AcizBelgesiHazirlanmasi: {
                                digerTalepController.acizBelgesiHazirlanmasiEkle(borclu.digerTalep.acizBelgesiHazirlanmasiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.AracUzerindekiHacizSerhininKaldirilmasi: {
                                digerTalepController.aracUzerindekiHacizSerhininKaldirilmasiEkle(borclu.digerTalep.aracUzerindekiHacizSerhininKaldirilmasiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.AracUzerindekiYakalamaSerhininKaldirilmasi: {
                                digerTalepController.aracUzerindekiYakalamaSerhininKaldirilmasiEkle(borclu.digerTalep.aracUzerindekiYakalamaSerhininKaldirilmasiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.BankadanHacizliParaninIstenilmesi: {
                                digerTalepController.bankadanHacizliParaninIstenilmesiEkle(borclu.digerTalep.bankadanHacizliParaninIstenilmesiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.BorclutarafindanYapilanOdemelerinHesabaAktarilmasi: {
                                digerTalepController.borcluTarafindanYapilanOdemelerinHesabaAktarilmasiEkle(borclu.digerTalep.borclutarafindanYapilanOdemelerinHesabaAktarilmasiParams);
                            }
                                break;
                            case  DigerTalepEvrakTypeEnum.DosyaHesabininYapilmasiVeDosyayaKaydedilmesi: {
                                digerTalepController.dosyaHesabininYapilmasiVeDosyayaKaydedilmesiEkle();
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.DosyadaHacizliMalinSatisi: {
                                digerTalepController.dosyadaHacizliMalinSatisiEkle(borclu.digerTalep.dosyadaHacizliMalinSatisiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.DosyadakiAvansinIadesi: {
                                digerTalepController.dosyadakiAvansinIadesiEkle(borclu.digerTalep.dosyadakiAvansinIadesiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.DosyadakiHacizlerinKaldirilmasi: {
                                digerTalepController.dosyadakiHacizlerinKaldirilmasiEkle(borclu.digerTalep.dosyadakiHacizlerinKaldirilmasiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.DosyaninIslemdenKaldirilmasi: {
                                digerTalepController.dosyaninIslemdenKaldirilmasiEkle(borclu.digerTalep.dosyaninIslemdenKaldirilmasiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.DosyaninYetkiliIcraDairesineGonderilmesi: {
                                digerTalepController.dosyaninYetkiliIcraDairesineGonderilmesiEkle(borclu.digerTalep.dosyaninYetkiliIcraDairesineGonderilmesiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.HacizliMalinKiymetTakdiriIcinTalimatYazilmasi: {
                                digerTalepController.hacizliMalinKiymetTakdiriIcinTalimatYazilmasiEkle(borclu.digerTalep.hacizliMalinKiymetTakdiriIcinTalimatYazilmasiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.HacizliMalinSatisiIcinTalimatYazilmasi: {
                                digerTalepController.hacizliMalinSatisiIcinTalimatYazilmasiEkle(borclu.digerTalep.hacizliMalinSatisiIcinTalimatYazilmasiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.HacizliTasinirMallarinMuhafazaAltinaAlinmasi: {
                                digerTalepController.hacizliTasinirMallarinMuhafazaAltinaAlinmasiEkle(borclu.digerTalep.hacizliTasinirMallarinMuhafazaAltinaAlinmasiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.HaricenTahsilatBildirimi: {
                                digerTalepController.haricenTahsilatBildirimiEkle(borclu.digerTalep.haricenTahsilatBildirimiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.IIk121MaddeyeGoreYetkiVerilmesi: {
                                digerTalepController.iik121MaddeyeGoreYetkiVerilmesiEkle(borclu.digerTalep.iik121MaddeyeGoreYetkiVerilmesiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.IIk150cSerhiEklenmesi: {
                                digerTalepController.iik150cSerhiEklenmesiEkle(borclu.digerTalep.iik150cSerhiEklenmesiParams);
                            }
                                break;
                            case  DigerTalepEvrakTypeEnum.KiymetTakdirininYapilmasi: {
                                digerTalepController.kiymetTakdirininYapilmasiEkle(borclu.digerTalep.kiymetTakdirininYapilmasiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.MaasUzerindekiHacizlerinKaldirilmesi: {
                                digerTalepController.maasUzerindekiHacizlerinKaldirilmesiEkle(borclu.digerTalep.maasUzerindekiHacizlerinKaldirilmesiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligi: {
                                digerTalepController.muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiEkle(borclu.digerTalep.muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.RehinAcigiBelgesiHazirlanmasi: {
                                digerTalepController.rehinAcigiBelgesiHazirlanmasiEkle(borclu.digerTalep.rehinAcigiBelgesiHazirlanmasiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.RehininParayaCevrilmesiSerhi: {
                                digerTalepController.rehininParayaCevrilmesiSerhiEkle(borclu.digerTalep.rehininParayaCevrilmesiSerhiParams);
                            }
                                break;
                            case DigerTalepEvrakTypeEnum.TasinmazHaczininKaldirilmasi: {
                                digerTalepController.tasinmazHaczininKaldirilmasiEkle(borclu.digerTalep.tasinmazHaczininKaldirilmasiParams);
                            }
                                break;
                        }
                    }
                    await digerTalepController.hacizEvrakHazirla();
                    await digerTalepController.hacizEvrakImzala();
                    let evrakResult = await digerTalepController.gonder();
                    //let evrakResult = {message:"deneme"};
                    response.evraklar.push({
                        message         : evrakResult.message,
                        status          : StatusEnum.success,
                        imzaliBlob      : digerTalepController.getHacizEvrakImzali(),
                        imzasizBlob     : digerTalepController.getHacizEvrakImzasiz(),
                        adSoyad         : borclu.adi + " " + borclu.soyadi,
                        tcKimlikNo      : borclu.tcKimlikNo,
                        evrakTutari     : digerTalepController.evrakTutar.toFixed(2),
                        params          : this.params
                    })
                } catch (e: any) {
                    response.evraklar.push({
                        message         : e.message,
                        status          : StatusEnum.error,
                        imzaliBlob      : null,
                        imzasizBlob     : null,
                        adSoyad         : borclu.adi + " " + borclu.soyadi,
                        tcKimlikNo      : borclu.tcKimlikNo,
                        evrakTutari     : 0,
                        params          : this.params
                    });
                }
            }
            let zip = new JSZip();
            for (let evrak of response.evraklar) {
                if (evrak.imzaliBlob) {
                    // @ts-ignore
                    zip.file((evrak.adSoyad + "/" + evrak.imzaliBlob.name), evrak.imzaliBlob);
                }
            }
            let zipBlob = await zip.generateAsync({type: "blob"});
            let formData = new FormData();
            let name = task.birim_adi + ' ' + task.dosya_esas_no.replaceAll('/', '_') + ' Toplu Genel Talepleri_' + new Date().getTime() + '.zip';
            formData.append('name', name);
            formData.append('file', zipBlob);
            formData.append('task_id', (task.id as number).toString());
            await app.$http.post('/api/v1/task/file', formData);
        } else {
            response.message = "Dosyada borçlu bulunamadı.";
        }
        let errorExists = response.evraklar.filter(evrak => evrak.status == StatusEnum.error);
        task.status = TaskDurum.BITTI_BASARILI;
        if (errorExists.length == response.evraklar.length)
            task.status = TaskDurum.BITTI_HATALI;
        else if (errorExists.length > 0)
            task.status = TaskDurum.BITTI_EKSIK;
        task.response = response;
        task.description = errorExists.map(x => x.message).join(" , ");
        if (response.message != "")
            task.description = response.message;
        return task;
    }
}