import app from "@/main";
import {AvukatDosyaSorgulaGenelCevapItem} from "../../../uyap/AvukatDosyaSorgulaGenel";
import {DosyaBorclu} from "../../../uyap/DosyaBorclu";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {UyapHacizEvrakTuru} from "../../../enum/uyap/evrak/UyapHacizEvrakTuru";

export class TopluHacizHazirlaTaskCevap {
    dosya!: AvukatDosyaSorgulaGenelCevapItem;
    borclular: DosyaBorclu[] = [];
    params!: {
        hacizParams: any[],//HacizParamsInterface
        tebligatParams: any[],
        digerTalepParams: any[],
    }

}

export class TopluHacizHazirlaTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        try {
            let response: TopluHacizHazirlaTaskCevap = new TopluHacizHazirlaTaskCevap();
            let dosya = await app.$uyap.DosyaBul().run(task);
            await dosya.borclularQuery();
            let hacizParams = task.data.hacizParams;
            if (Object.keys(hacizParams).length > 0) {
                for (const borclu of dosya.borclular) {
                    console.log("BORCLU ", borclu);
                    for (const param of hacizParams) {
                        try {
                            if (param.talepKodu == UyapHacizEvrakTuru.egm) {
                                await borclu.queryEgm(dosya.dosyaId);
                                await borclu.queryEgmMahrumiyet(dosya.dosyaId);
                            }
                            if (param.talepKodu == UyapHacizEvrakTuru.takbis)
                                await borclu.queryTakbis(dosya.dosyaId);
                            if (param.talepKodu == UyapHacizEvrakTuru.banka_haczi)
                                await borclu.queryBankaHesap(dosya.dosyaId);
                            if (param.talepKodu == UyapHacizEvrakTuru.posta_ceki_haciz)
                                await borclu.queryPostaCeki(dosya.dosyaId);
                            if (param.talepKodu == UyapHacizEvrakTuru.maas_haczi) {
                                await borclu.queryBorcluSgkIsyeri(dosya.dosyaId);
                                await borclu.querySgkHaciz(dosya.dosyaId);
                            }
                            if (param.talepKodu == UyapHacizEvrakTuru.alacakli_oldugu_dosya)
                                await borclu.queryIcraDosyaKaydi(dosya.dosyaId);
                        } catch (e: any) {
                            console.log("HATA", e);
                            if (e.message == "Bu işlemi bu dosya için gerçekleştiremezsiniz. Dosyada ALACAKLI vekili değilsiniz. ")
                                throw new Error(e.message);
                        }
                    }
                }
            }
            response.params = task.data;
            response.borclular = dosya.borclular;
            response.dosya = dosya;
            task.response = response;
            return task;
        }catch (e: any) {
            console.log('Error',e);
            throw new Error(e.message)
        }
    }
}