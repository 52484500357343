import {UyapRequest} from "./UyapRequest";

export interface BankaHacziParamsTalep {
    // Boş
}

export interface BankaHacziParamsCevap {
    bankalar: Array<BankaHacziParamsBankaRow>,
    hesaplar: Array<BankaHacziParamsHesapRow>,
    evrakAdlari: Array<BankaHacziParamsEvrakAdiRow>,
    haric: Array<BankaHacziParamsHaricRow>
}

export interface BankaHacziParamsBankaRow {
    ilgiliKisiTipi: string,
    ilgili: string,
    asilTaraf: string,
    ilgiliKisiIlkKisiKurumId: number,
    asilTarafIlkKisiKurumId: number,
    ilgiliKisiRolu: string,
    ilgiliKisiRolId: number,
    secili: boolean,
    kisi: boolean,
    tcVatandasi: boolean,
    mersisNo: string,
    kisaAd: any
}

export interface BankaHacziParamsHesapRow {
    tktId: string,
    kod: string,
    aciklama: string
}

export interface BankaHacziParamsEvrakAdiRow {
    name: string,
    value: string,
}

export interface BankaHacziParamsHaricRow {
    name: string,
    value: string,
}

export default class BankaHacziParams extends UyapRequest<BankaHacziParamsTalep, BankaHacziParamsCevap> {
    delayTime = 0;

    getMethod(): "POST" | "GET" {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "getBankaHacziParams_brd"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/getBankaHacziParams_brd.ajx';
    }

    async parse(data: any): Promise<BankaHacziParamsCevap> {
        return {
            bankalar: data[0],
            hesaplar: data[1],
            evrakAdlari: data[2],
            haric: data[3]
        };
    }

    getMockResponse(): any {
        return [[{
            "ilgiliKisiTipi": "H",
            "ilgili": "QNB FİNANSBANK ANONİM ŞİRKETİ",
            "asilTaraf": "QNB FİNANSBANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 159238904,
            "asilTarafIlkKisiKurumId": 159238904,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0388002333400576"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRKİYE FİNANS KATILIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "TÜRKİYE FİNANS KATILIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 166411454,
            "asilTarafIlkKisiKurumId": 166411454,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0068006387095226"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "ŞEKERBANK TÜRK ANONİM ŞİRKETİ",
            "asilTaraf": "ŞEKERBANK TÜRK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 172648472,
            "asilTarafIlkKisiKurumId": 172648472,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0801004857598667"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "VAKIF KATILIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "VAKIF KATILIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 171383708,
            "asilTarafIlkKisiKurumId": 171383708,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0883042770000010"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "ZİRAAT KATILIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "ZİRAAT KATILIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 165057828,
            "asilTarafIlkKisiKurumId": 165057828,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0998079311700015"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "ICBC TURKEY BANK ANONİM ŞİRKETİ",
            "asilTaraf": "ICBC TURKEY BANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163266767,
            "asilTarafIlkKisiKurumId": 163266767,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0836004772800050"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TURKLAND BANK ANONİM ŞİRKETİ",
            "asilTaraf": "TURKLAND BANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 180639821,
            "asilTarafIlkKisiKurumId": 180639821,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0389000710568940"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TURKISH BANK ANONİM ŞİRKETİ",
            "asilTaraf": "TURKISH BANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 167300192,
            "asilTarafIlkKisiKurumId": 167300192,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0871003631728905"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "ARAP TÜRK BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "ARAP TÜRK BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163265081,
            "asilTarafIlkKisiKurumId": 163265081,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0072000479500015"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "CİTİBANK ANONİM ŞİRKETİ",
            "asilTaraf": "CİTİBANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163265142,
            "asilTarafIlkKisiKurumId": 163265142,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0211045571800015"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "BANK MELLAT MERKEZİ TAHRAN-İSTANBUL TÜRKİYE MERKEZ ŞUBESİ",
            "asilTaraf": "BANK MELLAT MERKEZİ TAHRAN-İSTANBUL TÜRKİYE MERKEZ ŞUBESİ",
            "ilgiliKisiIlkKisiKurumId": 163265089,
            "asilTarafIlkKisiKurumId": 163265089,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0140003254400014"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRKİYE SINAİ KALKINMA BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "TÜRKİYE SINAİ KALKINMA BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163268162,
            "asilTarafIlkKisiKurumId": 163268162,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0879003330900013"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRKİYE EMLAK KATILIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "TÜRKİYE EMLAK KATILIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 164015239,
            "asilTarafIlkKisiKurumId": 164015239,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0879001527700040"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "GSD YATIRIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "GSD YATIRIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163266248,
            "asilTarafIlkKisiKurumId": 163266248,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0411008228000017"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "ADABANK ANONİM ŞİRKETİ",
            "asilTaraf": "ADABANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 172696893,
            "asilTarafIlkKisiKurumId": 172696893,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0007001548100028"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "BİRLEŞİK FON BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "BİRLEŞİK FON BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163139475,
            "asilTarafIlkKisiKurumId": 163139475,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0292003349600019"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "BANK OF CHINA TURKEY ANONİM ŞİRKETİ",
            "asilTaraf": "BANK OF CHINA TURKEY ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 199495597,
            "asilTarafIlkKisiKurumId": 199495597,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0140039115300018"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "DEUTSCHE BANK ANONİM ŞİRKETİ",
            "asilTaraf": "DEUTSCHE BANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163265180,
            "asilTarafIlkKisiKurumId": 163265180,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0876004872200015"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "MUFG BANK TURKEY ANONİM ŞİRKETİ",
            "asilTaraf": "MUFG BANK TURKEY ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163265096,
            "asilTarafIlkKisiKurumId": 163265096,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0140036123700018"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "RABOBANK ANONİM ŞİRKETİ",
            "asilTaraf": "RABOBANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163266677,
            "asilTarafIlkKisiKurumId": 163266677,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0734073912400011"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "HABİB BANK LİMİTED MERKEZİ PAKİSTAN-İSTANBUL TÜRKİYE MERKEZ ŞB.",
            "asilTaraf": "HABİB BANK LİMİTED MERKEZİ PAKİSTAN-İSTANBUL TÜRKİYE MERKEZ ŞB.",
            "ilgiliKisiIlkKisiKurumId": 163266249,
            "asilTarafIlkKisiKurumId": 163266249,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0454001183500012"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "INTESA SANPAOLO S.P.A. İTALYA İSTANBUL MERKEZ ŞUBESİ",
            "asilTaraf": "INTESA SANPAOLO S.P.A. İTALYA İSTANBUL MERKEZ ŞUBESİ",
            "ilgiliKisiIlkKisiKurumId": 163266632,
            "asilTarafIlkKisiKurumId": 163266632,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0465040810400013"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "JPMORGAN CHASE BANK NATİONAL ASSOCİATİON MERKEZİ COLUMBUS OHİO İSTANBUL TÜRKİYE ŞUBESİ",
            "asilTaraf": "JPMORGAN CHASE BANK NATİONAL ASSOCİATİON MERKEZİ COLUMBUS OHİO İSTANBUL TÜRKİYE ŞUBESİ",
            "ilgiliKisiIlkKisiKurumId": 163266640,
            "asilTarafIlkKisiKurumId": 163266640,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0843000158100011"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "SOCIETE GENERALE(S.A)(PARİS)MERKEZİ FRANSA İSTANBUL TÜRKİYE MERKEZ ŞUBESİ",
            "asilTaraf": "SOCIETE GENERALE(S.A)(PARİS)MERKEZİ FRANSA İSTANBUL TÜRKİYE MERKEZ ŞUBESİ",
            "ilgiliKisiIlkKisiKurumId": 163266680,
            "asilTarafIlkKisiKurumId": 163266680,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "7136729256854498"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRKİYE KALKINMA VE YATIRIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "TÜRKİYE KALKINMA VE YATIRIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163268160,
            "asilTarafIlkKisiKurumId": 163268160,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0879002993500013"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "İSTANBUL TAKAS VE SAKLAMA BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "İSTANBUL TAKAS VE SAKLAMA BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163266638,
            "asilTarafIlkKisiKurumId": 163266638,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0481002693100015"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "BANKPOZİTİF KREDİ VE KALKINMA BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "BANKPOZİTİF KREDİ VE KALKINMA BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163265103,
            "asilTarafIlkKisiKurumId": 163265103,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0854012611200011"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "BANK OF AMERICA YATIRIM BANK ANONİM ŞİRKETİ",
            "asilTaraf": "BANK OF AMERICA YATIRIM BANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163266642,
            "asilTarafIlkKisiKurumId": 163266642,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0830003922200010"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "STANDARD CHARTERED YATIRIM BANKASI TÜRK ANONİM ŞİRKETİ",
            "asilTaraf": "STANDARD CHARTERED YATIRIM BANKASI TÜRK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 163266681,
            "asilTarafIlkKisiKurumId": 163266681,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0465003220300016"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "MÜFLİS ASYA KATILIM BANKASI A.Ş.",
            "asilTaraf": "MÜFLİS ASYA KATILIM BANKASI A.Ş.",
            "ilgiliKisiIlkKisiKurumId": 196835301,
            "asilTarafIlkKisiKurumId": 196835301,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "HSBC BANK ANONİM ŞİRKETİ",
            "asilTaraf": "HSBC BANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 73380618,
            "asilTarafIlkKisiKurumId": 73380618,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0621002428200197"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "TÜRKİYE GARANTİ BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 19303042,
            "asilTarafIlkKisiKurumId": 19303042,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0879001756600379"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "ANADOLUBANK ANONİM ŞİRKETİ",
            "asilTaraf": "ANADOLUBANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 143847838,
            "asilTarafIlkKisiKurumId": 143847838,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0068011300300024"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRKİYE VAKIFLAR BANKASI TÜRK ANONİM ORTAKLIĞI GENEL MÜDÜRLÜĞÜ",
            "asilTaraf": "TÜRKİYE VAKIFLAR BANKASI TÜRK ANONİM ORTAKLIĞI GENEL MÜDÜRLÜĞÜ",
            "ilgiliKisiIlkKisiKurumId": 234309964,
            "asilTarafIlkKisiKurumId": 234309964,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "ING BANK ANONİM ŞİRKETİ",
            "asilTaraf": "ING BANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 67026870,
            "asilTarafIlkKisiKurumId": 67026870,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0876004224300365"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "BURGAN BANK ANONİM ŞİRKETİ",
            "asilTaraf": "BURGAN BANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 137428880,
            "asilTarafIlkKisiKurumId": 137428880,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0140003231000116"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "DENİZBANK ANONİM ŞİRKETİ",
            "asilTaraf": "DENİZBANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 13102159,
            "asilTarafIlkKisiKurumId": 13102159,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0292008449600341"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRK EKONOMİ BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "TÜRK EKONOMİ BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 58335369,
            "asilTarafIlkKisiKurumId": 58335369,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0876004342000105"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "YAPI VE KREDİ BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "YAPI VE KREDİ BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 180704065,
            "asilTarafIlkKisiKurumId": 180704065,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0937002089200741"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "ALTERNATİFBANK ANONİM ŞİRKETİ",
            "asilTaraf": "ALTERNATİFBANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 19159660,
            "asilTarafIlkKisiKurumId": 19159660,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0060003154500048"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "AKTİF YATIRIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "AKTİF YATIRIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 155589794,
            "asilTarafIlkKisiKurumId": 155589794,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0225013653700015"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "DİLER YATIRIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "DİLER YATIRIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 164947080,
            "asilTarafIlkKisiKurumId": 164947080,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0297012220500011"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "PASHA YATIRIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "PASHA YATIRIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 190303981,
            "asilTarafIlkKisiKurumId": 190303981,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0940001907300014"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRKİYE İHRACAT KREDİ BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "TÜRKİYE İHRACAT KREDİ BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 197656005,
            "asilTarafIlkKisiKurumId": 197656005,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0879002298700034"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "ALBARAKA TÜRK KATILIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "ALBARAKA TÜRK KATILIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 76033411,
            "asilTarafIlkKisiKurumId": 76033411,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0047000870200019"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRKİYE İŞ BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "TÜRKİYE İŞ BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 26554983,
            "asilTarafIlkKisiKurumId": 26554983,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0481005859000909"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "KUVEYT TÜRK KATILIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "KUVEYT TÜRK KATILIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 28878548,
            "asilTarafIlkKisiKurumId": 28878548,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0600002681400074"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRKİYE HALK BANKASI ANONİM ŞİRKETİ GENEL MÜDÜRLÜĞÜ",
            "asilTaraf": "TÜRKİYE HALK BANKASI ANONİM ŞİRKETİ GENEL MÜDÜRLÜĞÜ",
            "ilgiliKisiIlkKisiKurumId": 161240901,
            "asilTarafIlkKisiKurumId": 161240901,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "AKBANK TÜRK ANONİM ŞİRKETİ",
            "asilTaraf": "AKBANK TÜRK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 177453035,
            "asilTarafIlkKisiKurumId": 177453035,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0015001526400497"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "ODEA BANK ANONİM ŞİRKETİ",
            "asilTaraf": "ODEA BANK ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 133084222,
            "asilTarafIlkKisiKurumId": 133084222,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0634042197300010"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "TÜRKİYE CUMHURİYETİ ZİRAAT BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "TÜRKİYE CUMHURİYETİ ZİRAAT BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 183262875,
            "asilTarafIlkKisiKurumId": 183262875,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "GOLDEN GLOBAL YATIRIM BANKASI ANONİM ŞİRKETİ",
            "asilTaraf": "GOLDEN GLOBAL YATIRIM BANKASI ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 210885830,
            "asilTarafIlkKisiKurumId": 210885830,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0396123319100001"
        }, {
            "ilgiliKisiTipi": "H",
            "ilgili": "FİBABANKA ANONİM ŞİRKETİ",
            "asilTaraf": "FİBABANKA ANONİM ŞİRKETİ",
            "ilgiliKisiIlkKisiKurumId": 120150101,
            "asilTarafIlkKisiKurumId": 120150101,
            "ilgiliKisiRolu": "Tebligat Yapılacak 3. Taraf",
            "ilgiliKisiRolId": 122,
            "secili": false,
            "kisi": false,
            "tcVatandasi": false,
            "mersisNo": "0209000780814852"
        }], [{"tktId": "HTBLG0001", "kod": "00001", "aciklama": "Vadesiz Türk Parası Hesabı"}, {
            "tktId": "HTBLG0002",
            "kod": "00002",
            "aciklama": "Yatırım Hesabı Serbest Bakiye"
        }, {"tktId": "HTBLG0003", "kod": "00003", "aciklama": "Vadesiz Yabancı Para Hesabı"}, {
            "tktId": "HTBLG0004",
            "kod": "00004",
            "aciklama": "Kıymetli Maden Hesabı (Vadesiz Altın Hesabı Dahil)"
        }, {
            "tktId": "HTBLG0005",
            "kod": "00005",
            "aciklama": "B Tipi Yatırım Fonu(B Tipi Likit ve Kısa Vadeli Tahvil-Bono Fonu)"
        }, {
            "tktId": "HTBLG0006",
            "kod": "00006",
            "aciklama": "Hazine Bonosu \u0026 Devlet Tahvili(TL)"
        }, {"tktId": "HTBLG0007", "kod": "00007", "aciklama": "Repo"}, {
            "tktId": "HTBLG0008",
            "kod": "00008",
            "aciklama": "Diğer Fonlar ve A Tipi Yatırım Fonu"
        }, {"tktId": "HTBLG0009", "kod": "00009", "aciklama": "Vadeli Türk Parası Hesabı"}, {
            "tktId": "HTBLG0010",
            "kod": "00010",
            "aciklama": "Vadeli Yabancı Para Hesabı"
        }, {
            "tktId": "HTBLG0011",
            "kod": "00011",
            "aciklama": "Kıymetli Maden Hesabı (Vadeli Altın Hesabı dahil)"
        }, {
            "tktId": "HTBLG0012",
            "kod": "00012",
            "aciklama": "Anapara Koruma Amaçlı ve Anapara Garantili Fonlar"
        }, {"tktId": "HTBLG0013", "kod": "00013", "aciklama": "Hisse Senedi"}, {
            "tktId": "HTBLG0014",
            "kod": "00014",
            "aciklama": "Diğer (Hazine Bonosu \u0026 Devlet Tahvili/YP), Eurobond, VOB, Özel Sektör Tahvili v.b. menkul kıymetler)"
        }, {
            "tktId": "HTBLG0015",
            "kod": "00015",
            "aciklama": "Alış Satış Emri Olan Kıymetler (Niteliği Değişebilen Varlıklar)"
        }], [{"name": "Haciz Müzekkeresi", "value": "MZK"}, {
            "name": "89/1 Haciz İhbarnamesi",
            "value": "IHB"
        }, {"name": "89/2 Haciz İhbarnamesi", "value": "IHB_2"}, {
            "name": "89/3 Haciz İhbarnamesi",
            "value": "IHB_3"
        }], [{
            "name": "Maaş Hesabı Hariç",
            "value": "MAAS"
        }, {
            "name": "Borçlu Belediye İse; *5393 Sayılı Belediye Kanununun 15.maddesi kapsamındaki Alacakları Hariç",
            "value": "BELEDIYE"
        }]]
    }
}


