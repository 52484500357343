import {MessageLogger} from "../MessageLogger";
import {FileEntity} from "../../store/entity/FileEntity";

interface TakipEvrak {
    name: string,
    mimeType: string,
    //base64 string
    data: string
}

export default class TakipInjectable extends MessageLogger {
    dayanaklar: TakipEvrak[] = [];
    vekalet: TakipEvrak | null = null;
    vekaletObject: FileEntity | null = null;
    dayanaklarObject: Array<FileEntity> = [];

    setEvraklar(dosyaNode: Element): void {
        if (dosyaNode) {
            let evraklarNodeList = dosyaNode.querySelectorAll("evraklar evrak");
            if (evraklarNodeList) {
                evraklarNodeList.forEach((evrakNode: any) => {
                    let evrakTuru = evrakNode.getAttribute("evrakturu");
                    let evrak = {
                        name: evrakNode.getAttribute("filename"),
                        mimeType: evrakNode.getAttribute("mimetype"),
                        data: evrakNode.innerHTML
                    };
                    if (evrakTuru == "1")//Vekalet
                        this.vekalet = evrak;
                    if (evrakTuru == "2")//dayanak belge
                        this.dayanaklar.push(evrak)
                })
            }
        }

    }

}

