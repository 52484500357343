import {UyapRequest} from "./UyapRequest";

import {AdresRow} from "./CommonInterfaces";

export interface DosyaHarcMasrafHesabiTalep {
    dosyaId: number,
    dosyaTurKod: number//35
}

export interface DosyaHarcMasrafHesabiCevap {
    harcBilgileri: Array<HarcMasrafRow>,
    toplam: number,
    taraflar: Array<TarafRow>
}

export interface HarcMasrafRow {
    harcmasrafKodStr: number;
    harcmasrafBedelmiOranmi: string;
    harcMasrafBedel: number;
    hesapMiktar: number;
    ekranID: number;
    pesinHarcDusulsunmu: string;
    hmDurumu: number;
    hmAktif: boolean;
    isSatirDegisecek: boolean;
    harcMasrafKod: number;
    harcMasrafAdi: string;
    aciklama: string;
    baslangicHarci: string;
    harcMasraf: string;
    harcTip: number;
    gv_tabi: number;
    dv_tabi: number;
    yg_tabi: number;
    makbuzTipi: number;
}

export interface KisiTumDVO {
    olumKaydi: boolean;
    isAvukat: boolean;
    isNoter: boolean;
    isTarafUyari: boolean;
    isHakimSavci: boolean;
    digerIletisimBilgileri: any;
    mernisDegisiklikVarmi: boolean;
    mernisDegisiklikNedeni: string;
    verildigiIlKodu: number;
    verildigiIlceKodu: number;
    verildigiIlAdi: string;
    verildigiIlceAdi: string;
    sorguTuru: number;
    kisiKurumID: number;
    tcKimlikNo: string;
    adi: string;
    soyadi: string;
    babaAdi: string;
    anaAdi: string;
    dogumTarihiStr: string;
    cinsiyeti: string;
    medeniHali: number;
    dogumYeri: string;
    aktifMernisAdresiSorgusu: boolean;
    mernisGuncellemesiYapildi: string;
    hasOlumKaydi: boolean;
}

export interface KurumDVO {
    kisiKurumID: number;
    kurumAdi: string;
    vergiNo: string;
    harcDurumu: number;
    kamuOzel: string;
    veriGelisYeri: number;
    faaliyetDurumu: number;
    tacirMi: string;
    mersisIlKodu: number;
    mersisIlceKodu: number;
    digerIletisimBilgileri: any;
}

export interface KisiKurumBilgileriDVO {
    kisiTumDVO: KisiTumDVO;
    kisiKurumId: number;
    turu: number;
    ortakAd: string;
    adresList: Array<AdresRow>;
    kurumDVO: KurumDVO;
}

export interface KisiBilgDVO {
    tcKimlikNo: string;
    adi: string;
    soyadi: string;
    babaAdi: string;
    anaAdi: string;
    dogumTarihiStr: string;
    dogumYeri: string;
    cinsiyeti: string;
    ozurOrani: number;
    isEngelliKisiKurum: boolean;
    harcDurumu?: number;
}

export interface KurumBilgDVO {
    isEngelliKisiKurum: boolean;
    kurumAdi: string;
    kurumVergiNo: string;
    harcDurumu?: number;
    kamuOzel: string;
}

export interface DosyaBilg {
    dosyaDurumu: number;
    dosyaAcilisTarihi: string;
    birimId: string;
    dosyaTurKod: number;
}

export interface DosyaKisiDVO {
    kisiBilgDVO: KisiBilgDVO;
    kurumBilgDVO: KurumBilgDVO;
    dosyaBilg: DosyaBilg;
    dosyasiIncelenebilir: boolean;
    kisiMiKurumMu: number;
    guncelKisiKurumId: number;
    ilkKisiKurumId: number;
    dosyaId: number;
    tarafRolu: number;
    dosyaKisiId: number;
    dosyaDurumuAktif: string;
    kimlikBilgisiAktif: string;
    kayitId: any;
    sanikStatusunde: string;
    davaliDavaciGrup: string;
    tarafDurumu: number;
    aboneVarmi: boolean;
    tarafAdi: string;
    tarafTC: string;
    cbsGroup: number;
}

export interface AvukatHesapBilg {
    hesapGenel: boolean;
}

export interface AvukatDVO {
    AVUKAT_ID: number;
    GECERLILIK_AKTIF: string;
    KISI_KURUM_ID: number;
    MUVEKKIL_KISI_KURUM_ID: number;
    BAGLI_OLDUGU_BARO_KODU: number;
    BAGLI_OLDUGU_BARO_ADI: string;
    BARO_NO: number;
    TBB_NO: string;
    BAKANLIK_DOSYA_NO: number;
    TC_KIMLIK_NO: string;
    VEKIL_TIPI: string;
    ADI: string;
    SOYADI: string;
    vekilYetkiTuruDVOList: any[];
    dosyaTarafVekilId: number;
    sifati: number;
    vekaletnameliMi: string;
    hazineAvukatiMi: boolean;
    kapanmaDurumuDegisiklikOldu: boolean;
    sertifikasiVar: boolean;
    yabanciAvukat: boolean;
    ortaklikMi: boolean;
    avukatlik_turu: string;
    digerIletisimBilgileri: any;
    tarafRolu: number;
    avukatHesapBilg: AvukatHesapBilg;
}

export interface KisiTumBilgileriDVO {
    olumKaydi: boolean;
    isAvukat: boolean;
    isNoter: boolean;
    isTarafUyari: boolean;
    isHakimSavci: boolean;
    mernisDegisiklikVarmi: boolean;
    mernisDegisiklikNedeni: string;
    sorguTuru: number;
    kisiKurumID: number;
    tcKimlikNo: string;
    adi: string;
    soyadi: string;
    aktifMernisAdresiSorgusu: boolean;
    hasOlumKaydi: boolean;
}

export interface AdresList {
    adres: string;
    eTebligatAdresi: string;
    kisiKurumID: number;
    adresID: number;
    adresTuru: string;
    adresTuruAciklama: string;
    eklenebilirAdresMi: boolean;
    kayitliAdresMi: boolean;
    aktifAdresmi: boolean;
    guncellendiMi: boolean;
    aktif: string;
    gecerliAvukatlik: string;
}

export interface DosyaKisiAdresDVO {
    gizliYazismaAdresi: boolean;
}

export interface VekilArrayList {
    avukatDVO: AvukatDVO;
    kisiTumBilgileriDVO: KisiTumBilgileriDVO;
    adresList: AdresList[];
    dosyaKisiAdresDVO: DosyaKisiAdresDVO;
    avukatinDosyasiVar: boolean;
    silinecekAvukat: boolean;
    atanacakAvukat: boolean;
    secili: boolean;
}

export interface TarafRow {
    kisiKurumBilgileriDVO: KisiKurumBilgileriDVO;
    dosyaKisiDVO: DosyaKisiDVO;
    vekilArrayList: Array<any>;
    secili: boolean;
    dogacakHakDahilmi: boolean;
    borcluBelediye: boolean;
    maasHesabiHaric: boolean;
    kisiMiKurumMu: number;
    guncelKisiKurumId: number;
    ilkKisiKurumId: number;
    tarafRolu: number;
    aboneVarmi: boolean;
}


export class DosyaHarcMasrafHesabi extends UyapRequest<DosyaHarcMasrafHesabiTalep, DosyaHarcMasrafHesabiCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [
            [
                {
                    "harcmasrafKodStr": 71961,
                    "harcmasrafBedelmiOranmi": "B",
                    "harcMasrafBedel": 44.4,
                    "hesapMiktar": 44.4,
                    "ekranID": 8,
                    "pesinHarcDusulsunmu": "H",
                    "hmDurumu": 0,
                    "hmAktif": false,
                    "isSatirDegisecek": false,
                    "harcMasrafKod": 12805,
                    "harcMasrafAdi": "BaÅŸvurma HarcÄ±",
                    "aciklama": "Takibe BaÅŸvurulurken Ã–denen HarÃ§ MiktarÄ±",
                    "baslangicHarci": "E",
                    "harcMasraf": "H",
                    "harcTip": 0,
                    "gv_tabi": 0,
                    "dv_tabi": 0,
                    "yg_tabi": 0,
                    "makbuzTipi": 1
                },
                {
                    "harcmasrafKodStr": 14603,
                    "harcmasrafBedelmiOranmi": "O",
                    "harcMasrafOran": 0.5,
                    "hesapMiktar": 162.61,
                    "ekranID": 8,
                    "pesinHarcDusulsunmu": "H",
                    "harcMasrafAltSiniri": 0,
                    "hmDurumu": 0,
                    "hmAktif": false,
                    "isSatirDegisecek": false,
                    "harcMasrafKod": 12808,
                    "harcMasrafAdi": "PeÅŸin HarÃ§",
                    "aciklama": "Takip Talebi AÃ§Ä±lÄ±rken AlÄ±nÄ±r",
                    "baslangicHarci": "E",
                    "harcMasraf": "H",
                    "harcTip": 2,
                    "gv_tabi": 0,
                    "dv_tabi": 0,
                    "yg_tabi": 0,
                    "makbuzTipi": 1
                },
                {
                    "harcmasrafKodStr": 71965,
                    "harcmasrafBedelmiOranmi": "B",
                    "harcMasrafBedel": 6.4,
                    "hesapMiktar": 6.4,
                    "ekranID": 8,
                    "pesinHarcDusulsunmu": "H",
                    "hmDurumu": 0,
                    "hmAktif": false,
                    "isSatirDegisecek": false,
                    "harcMasrafKod": 12809,
                    "harcMasrafAdi": "Vekalet Suret HarcÄ±",
                    "aciklama": "Vekaletname Suret HarcÄ±dÄ±r",
                    "baslangicHarci": "E",
                    "harcMasraf": "H",
                    "harcTip": 14,
                    "gv_tabi": 0,
                    "dv_tabi": 0,
                    "yg_tabi": 0,
                    "makbuzTipi": 1
                },
                {
                    "hesapMiktar": 10.1,
                    "pesinHarcDusulsunmu": "H",
                    "hmDurumu": 0,
                    "hmAktif": false,
                    "isSatirDegisecek": false,
                    "harcMasrafAdi": "Vekalet Pulu",
                    "harcMasraf": "D",
                    "gv_tabi": 0,
                    "dv_tabi": 0,
                    "yg_tabi": 0
                }], 223.51, [
                {
                    "kisiKurumBilgileriDVO":
                        {
                            "kisiTumDVO":
                                {
                                    "olumKaydi": false,
                                    "isAvukat": false,
                                    "isNoter": false,
                                    "isTarafUyari": false,
                                    "isHakimSavci": false,
                                    "digerIletisimBilgileri":
                                        {},
                                    "mernisDegisiklikVarmi": false,
                                    "mernisDegisiklikNedeni": "",
                                    "verildigiIlKodu": 99,
                                    "verildigiIlceKodu": 0,
                                    "verildigiIlAdi": "SÄ°LÄ°NMÄ°Åž",
                                    "verildigiIlceAdi": "SÄ°LÄ°NMÄ°Åž",
                                    "sorguTuru": 0,
                                    "kisiKurumID": 203434816,
                                    "tcKimlikNo": "99469149146",
                                    "adi": "AYSHE ISMAIL",
                                    "soyadi": "HATIB",
                                    "babaAdi": "ISMAIL",
                                    "anaAdi": "NECMÄ°YE",
                                    "dogumTarihiStr": "07/12/1966",
                                    "cinsiyeti": "K",
                                    "medeniHali": 0,
                                    "dogumYeri": "DYANKOVO",
                                    "aktifMernisAdresiSorgusu": true,
                                    "mernisGuncellemesiYapildi": "E",
                                    "hasOlumKaydi": false
                                },
                            "kisiKurumId": 203434816,
                            "turu": 0,
                            "ortakAd": "AYSHE ISMAIL HATIB",
                            "adresList": []
                        },
                    "dosyaKisiDVO":
                        {
                            "kisiBilgDVO":
                                {
                                    "tcKimlikNo": "99469149146",
                                    "adi": "AYSHE ISMAIL",
                                    "soyadi": "HATIB",
                                    "babaAdi": "ISMAIL",
                                    "anaAdi": "NECMÄ°YE",
                                    "dogumTarihiStr": "07/12/1966",
                                    "dogumYeri": "DYANKOVO",
                                    "cinsiyeti": "K",
                                    "ozurOrani": 0,
                                    "isEngelliKisiKurum": false
                                },
                            "kurumBilgDVO":
                                {
                                    "isEngelliKisiKurum": false
                                },
                            "dosyaBilg":
                                {
                                    "dosyaDurumu": 0,
                                    "dosyaAcilisTarihi": "Aug 2, 2019 6:24:39 PM",
                                    "birimId": "1000987",
                                    "dosyaTurKod": 35
                                },
                            "dosyasiIncelenebilir": true,
                            "kisiMiKurumMu": 0,
                            "guncelKisiKurumId": 203434816,
                            "ilkKisiKurumId": 203434816,
                            "dosyaId": 530388696,
                            "tarafRolu": 22,
                            "dosyaKisiId": 910871460,
                            "dosyaDurumuAktif": "E",
                            "kimlikBilgisiAktif": "E",
                            "kayitId": 6871127725,
                            "sanikStatusunde": "E",
                            "davaliDavaciGrup": "L",
                            "tarafDurumu": 0,
                            "aboneVarmi": true,
                            "tarafAdi": "AYSHE ISMAIL HATIB",
                            "tarafTC": "99469149146",
                            "cbsGroup": 3
                        },
                    "vekilArrayList": [],
                    "secili": false,
                    "dogacakHakDahilmi": false,
                    "borcluBelediye": false,
                    "maasHesabiHaric": false,
                    "kisiMiKurumMu": 0,
                    "guncelKisiKurumId": 203434816,
                    "ilkKisiKurumId": 203434816,
                    "tarafRolu": 22,
                    "aboneVarmi": true
                },
                {
                    "kisiKurumBilgileriDVO":
                        {
                            "kisiKurumId": 180235805,
                            "kurumDVO":
                                {
                                    "kisiKurumID": 180235805,
                                    "kurumAdi": "ASÄ°ST Ã–ÄžRETÄ°M KURUMLARI",
                                    "vergiNo": "3020479979",
                                    "harcDurumu": 1,
                                    "kamuOzel": "O",
                                    "veriGelisYeri": 0,
                                    "faaliyetDurumu": 3,
                                    "tacirMi": "H",
                                    "mersisIlKodu": 0,
                                    "mersisIlceKodu": 0,
                                    "digerIletisimBilgileri":
                                        {}
                                },
                            "turu": 1,
                            "ortakAd": "ASÄ°ST Ã–ÄžRETÄ°M KURUMLARI",
                            "adresList": []
                        },
                    "dosyaKisiDVO":
                        {
                            "kisiBilgDVO":
                                {
                                    "harcDurumu": 1,
                                    "ozurOrani": 0,
                                    "isEngelliKisiKurum": false
                                },
                            "kurumBilgDVO":
                                {
                                    "kurumAdi": "ASÄ°ST Ã–ÄžRETÄ°M KURUMLARI",
                                    "kurumVergiNo": "3020479979",
                                    "harcDurumu": 1,
                                    "kamuOzel": "O",
                                    "isEngelliKisiKurum": false
                                },
                            "dosyaBilg":
                                {
                                    "dosyaDurumu": 0,
                                    "dosyaAcilisTarihi": "Aug 2, 2019 6:24:39 PM",
                                    "birimId": "1000987",
                                    "dosyaTurKod": 35
                                },
                            "dosyasiIncelenebilir": true,
                            "kisiMiKurumMu": 1,
                            "guncelKisiKurumId": 180235805,
                            "ilkKisiKurumId": 180235805,
                            "dosyaId": 530388696,
                            "tarafRolu": 21,
                            "dosyaKisiId": 910871458,
                            "dosyaDurumuAktif": "E",
                            "kimlikBilgisiAktif": "E",
                            "kayitId": 6871127718,
                            "sanikStatusunde": "H",
                            "davaliDavaciGrup": "N",
                            "tarafDurumu": 0,
                            "aboneVarmi": true,
                            "tarafAdi": "ASÄ°ST Ã–ÄžRETÄ°M KURUMLARI",
                            "tarafTC": "3020479979",
                            "cbsGroup": 3
                        },
                    "vekilArrayList": [
                        {
                            "avukatDVO":
                                {
                                    "AVUKAT_ID": 1746688,
                                    "GECERLILIK_AKTIF": "E",
                                    "KISI_KURUM_ID": 94071819,
                                    "MUVEKKIL_KISI_KURUM_ID": 180235805,
                                    "BAGLI_OLDUGU_BARO_KODU": 6,
                                    "BAGLI_OLDUGU_BARO_ADI": "ANKARA",
                                    "BARO_NO": 20112,
                                    "TBB_NO": "78119",
                                    "BAKANLIK_DOSYA_NO": 0,
                                    "TC_KIMLIK_NO": "14537825364",
                                    "VEKIL_TIPI": "B",
                                    "ADI": "NÄ°HAN",
                                    "SOYADI": "ERDOÄžAN",
                                    "vekilYetkiTuruDVOList": [],
                                    "dosyaTarafVekilId": 281276143,
                                    "sifati": 0,
                                    "vekaletnameliMi": "E",
                                    "hazineAvukatiMi": false,
                                    "kapanmaDurumuDegisiklikOldu": false,
                                    "sertifikasiVar": false,
                                    "yabanciAvukat": false,
                                    "ortaklikMi": false,
                                    "avukatlik_turu": "H",
                                    "digerIletisimBilgileri":
                                        {},
                                    "tarafRolu": 21,
                                    "avukatHesapBilg":
                                        {
                                            "hesapGenel": true
                                        }
                                },
                            "kisiTumBilgileriDVO":
                                {
                                    "olumKaydi": false,
                                    "isAvukat": false,
                                    "isNoter": false,
                                    "isTarafUyari": false,
                                    "isHakimSavci": false,
                                    "mernisDegisiklikVarmi": false,
                                    "mernisDegisiklikNedeni": "",
                                    "sorguTuru": 0,
                                    "kisiKurumID": 94071819,
                                    "tcKimlikNo": "14537825364",
                                    "adi": "NÄ°HAN",
                                    "soyadi": "ERDOÄžAN",
                                    "aktifMernisAdresiSorgusu": true,
                                    "hasOlumKaydi": false
                                },
                            "adresList": [
                                {
                                    "adres": " ",
                                    "eTebligatAdresi": "16462-64586-47146",
                                    "kisiKurumID": 94071819,
                                    "adresID": 240570631,
                                    "adresTuru": "ADRTR00015",
                                    "adresTuruAciklama": "AvukatlÄ±k Elektronik Tebligat Adresi",
                                    "eklenebilirAdresMi": false,
                                    "kayitliAdresMi": false,
                                    "aktifAdresmi": false,
                                    "guncellendiMi": false,
                                    "aktif": "E",
                                    "gecerliAvukatlik": "E"
                                }],
                            "dosyaKisiAdresDVO":
                                {
                                    "gizliYazismaAdresi": false
                                },
                            "avukatinDosyasiVar": true,
                            "silinecekAvukat": false,
                            "atanacakAvukat": false,
                            "secili": false
                        }],
                    "secili": false,
                    "dogacakHakDahilmi": false,
                    "borcluBelediye": false,
                    "maasHesabiHaric": false,
                    "kisiMiKurumMu": 1,
                    "guncelKisiKurumId": 180235805,
                    "ilkKisiKurumId": 180235805,
                    "tarafRolu": 21,
                    "aboneVarmi": true
                }]
        ];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/dosya_harc_masraf_hesabi.ajx';
    }

    async parse(data: Array<any>): Promise<DosyaHarcMasrafHesabiCevap> {
        return {harcBilgileri: data[0], toplam: data[1], taraflar: data[2]};
    }

}

export default DosyaHarcMasrafHesabi;
