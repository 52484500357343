import BorcluIski from "./BorcluIski";
import DosyaBorclulari from "./DosyaBorclulari";
import AvukatSorgulama from "./AvukatSorgulama";
import Adliyeler from "./Adliyeler";
import AvukatDosyaSorgulaGenel from "./AvukatDosyaSorgulaGenel";
import AvukatKisiselBilgileriSorgulama from "./AvukatKisiselBilgileriSorgulama";
import AvukatYetkiliKurumlar from "./AvukatYetkiliKurumlar";
import BorcluDib from "./BorcluDib";
import DosyaBorcluMernis from "./DosyaBorcluMernis";
import BorcluGib from "./BorcluGib";
import BorcluGsm from "./BorcluGsm";
import BorcluPostaCeki from "./BorcluPostaCeki";
import BorcluTakbisSorgulama from "./BorcluTakbisSorgulama";
import DosyaBorcluBagkur from "./DosyaBorcluBagkur";
import DosyaBorcluBankaBilgileri from "./DosyaBorcluBankaBilgileri";
import DosyaBorcluEgm from "./DosyaBorcluEgm";
import DosyaBorcluEgmMahrumiyet from "./DosyaBorcluEgmMahrumiyet";
import DosyaBorcluIsyeriSgk from "./DosyaBorcluIsyeriSgk";
import DosyaBorcluSSK from "./DosyaBorcluSSK";
import DosyaDetayBilgileri from "./DosyaDetayBilgileri";
import DosyaEvraklar from "./DosyaEvraklar";
import DosyaHarcMasrafHesabi from "./DosyaHarcMasrafHesabi";
import DosyaHesapBilgileri from "./DosyaHesapBilgileri";
import DosyaOdemeIslemleri from "./DosyaOdemeIslemleri";
import DosyaSafahat from "./DosyaSafahat";
import DosyaSafahatToken from "./DosyaSafahatToken";
import DosyaTarafBilgileri from "./DosyaTarafBilgileri";
import GetDosyaTarafBilgileri from "./GetDosyaTarafBilgileri";
import EvrakIndir from "./EvrakIndir";
import GetIbanDetails from "./GetIbanDetails";
import GetKurumMersisAdresi from "./GetKurumMersisAdresi";
import GonderilebilecekEvrakListesi from "./GonderilebilecekEvrakListesi";
import IcraPesinHarcGetir from "./IcraPesinHarcGetir";
import IcraTakipIlamDosyasiUygunMu from "./IcraTakipIlamDosyasiUygunMu";
import IcraTakipIlamDosyaTuru from "./IcraTakipIlamDosyaTuru";
import IldekiAdliyeler from "./IldekiAdliyeler";
import KisiMernisAdresiVarmi from "./KisiMernisAdresiVarmi";
import KisiSorgula from "./KisiSorgula";
import KurumSorgulama from "./KurumSorgulama";
import MahkemeBirimleri from "./MahkemeBirimleri";
import OdemeYap from "./OdemeYap";
import SgkKamu from "./SgkKamu";
import TahsilatReddiyat from "./TahsilatReddiyat";
import TahsilHarcOranlariniGetir from "./TahsilHarcOranlariniGetir";
import TamamlanmayanDosyalar from "./TamamlanmayanDosyalar";
import MtsTarafTebligatList from "./MtsTarafTebligatList";
import MtsTebligatUcreti from "./MtsTebligatUcreti";
import MtsTebligatGonder from "./MtsTebligatGonder";
import MtsTebligatList from "./MtsTebligatList";
import MtsTebligatSafahatList from "./MtsTebligatSafahatList";
import OdemeIslemiSorgulama from "./OdemeIslemiSorgulama";
import MakbuzIndir from "./MakbuzIndir";
import YargiBirimleri from "./YargiBirimleri";
import AvukatTalepList from "./AvukatTalepList";
import BankaHacziParams from "./BankaHacziParams";
import DosyaKapatmaNedenleri from "./DosyaKapatmaNedenleri";
import PortalAvukatTalepMalTuruList from "./PortalAvukatTalepMalTuruList";
import PortalAvukatTalepTebligatTuruList from "./PortalAvukatTalepTebligatTuruList";
import GetDosyaTarafListesiWithTarafRol from "./GetDosyaTarafListesiWithTarafRol";
import GetMaasHacziParams from "./GetMaasHacziParams";
import GetPortalAvukatTalepAdresTuruList from "./GetPortalAvukatTalepAdresTuruList";
import ParaBirimleri from "./ParaBirimleri";
import AracHacziParams from "./AracHacziParams";
import BorcluSgkHaciz from "./BorcluSgkHaciz";
import BorcluIcraDosyasi from "./BorcluIcraDosyasi";
import TakibiKesinlesenBorcluListesi from "./TakibiKesinlesenBorcluListesi";
import IcraTalepEvrakHazirla from "./IcraTalepEvrakHazirla";
import MtsIcraAcilisAlacakliTaraflar from "./MtsIcraAcilisAlacakliTaraflar";
import MtsIcraTakipBaslat from "./MtsIcraTakipBaslat";
import AvukatIcraTalepEvrakiGonder from "./AvukatIcraTalepEvrakiGonder";
import EvrakGonder from "./EvrakGonder";
import {DosyaBul} from "./DosyaBul";
import AvukatKayitliIbanList from "./AvukatKayitliIbanList";
import GetIbanListesi from "./GetIbanListesi";
import GetAdresList from "./GetAdresList";
import JsonHarcHesapla from "./JsonHarcHesapla";
import TevziAl from "./TevziAl";
import TakipTalebiIndir from "./TakipTalebiIndir";
import MtsTakipTalebiIndir from "./MtsTakipTalebiIndir";
import DosyaAcilisEvrakGonder from "./DosyaAcilisEvrakGonder";
import MtsHarcList from "./MtsHarcList";
import MtsTakipTalebiOlustur from "./MtsTakipTalebiOlustur";
import MtsDavaAcilis from "./MtsDavaAcilis";
import VakifbankSmsOnay from "./VakifbankSmsOnay";
import VakifbankHesapListesi from "./VakifbankHesapListesi";
import SetCookie from "./SetCookie";
import DosyaSorgulaTarafIle from "./DosyaSorgulaTarafIle";
import DosyaBorcluMersis from "./DosyaBorcluMersis";
import IcraTakipMahiyetleri from "./IcraTakipMahiyetleri";
import ChangePlayerStatus from "@/plugins/uyap-plugin/uyap/ChangePlayerStatus";
import IldekiIdariBirimler from "@/plugins/uyap-plugin/uyap/IldekiIdariBirimler";
import AdliyedekiTumMahkemeler from "@/plugins/uyap-plugin/uyap/AdliyedekiTumMahkemeler";

class UyapClient {

    Adliyeler(): Adliyeler {
        return new Adliyeler();
    }

    AracHacziParams(): AracHacziParams {
        return new AracHacziParams();
    }

    DosyaBul(): DosyaBul {
        return new DosyaBul();
    }

    AvukatDosyaSorgulaGenel(): AvukatDosyaSorgulaGenel {
        return new AvukatDosyaSorgulaGenel();
    }

    DosyaSorgulaTarafIle(): DosyaSorgulaTarafIle {
        return new DosyaSorgulaTarafIle();
    }

    SetCookie(): SetCookie {
        return new SetCookie();
    }

    ChangePlayerStatus(): ChangePlayerStatus {
        return new ChangePlayerStatus();
    }

    AvukatKisiselBilgileriSorgulama(): AvukatKisiselBilgileriSorgulama {
        return new AvukatKisiselBilgileriSorgulama();
    }

    AvukatIcraTalepEvrakiGonder(): AvukatIcraTalepEvrakiGonder {
        return new AvukatIcraTalepEvrakiGonder();
    }

    AvukatSorgulama(): AvukatSorgulama {
        return new AvukatSorgulama();
    }

    AvukatTalepList(): AvukatTalepList {
        return new AvukatTalepList();
    }

    AvukatYetkiliKurumlar(): AvukatYetkiliKurumlar {
        return new AvukatYetkiliKurumlar();
    }

    BankaHacziParams(): BankaHacziParams {
        return new BankaHacziParams();
    }

    IcraTakipMahiyetleri(): IcraTakipMahiyetleri {
        return new IcraTakipMahiyetleri();
    }

    BorcluDib(): BorcluDib {
        return new BorcluDib();
    }

    JsonHarcHesapla(): JsonHarcHesapla {
        return new JsonHarcHesapla();
    }

    TevziAl(): TevziAl {
        return new TevziAl();
    }

    TakipTalebiIndir(dosyaId: number, birimId: string): TakipTalebiIndir {
        return new TakipTalebiIndir(dosyaId, birimId);
    }

    MtsTakipTalebiIndir(dosyaId: any, birimId: any): MtsTakipTalebiIndir {
        return new MtsTakipTalebiIndir(dosyaId, birimId);
    }

    BorcluGib(): BorcluGib {
        return new BorcluGib();
    }

    BorcluGsm(): BorcluGsm {
        return new BorcluGsm();
    }

    BorcluIcraDosyasi(): BorcluIcraDosyasi {
        return new BorcluIcraDosyasi();
    }

    BorcluIski(): BorcluIski {
        return new BorcluIski();
    }

    BorcluPostaCeki(): BorcluPostaCeki {
        return new BorcluPostaCeki();
    }

    BorcluSgkHaciz(): BorcluSgkHaciz {
        return new BorcluSgkHaciz();
    }

    DosyaBorclulari(): DosyaBorclulari {
        return new DosyaBorclulari();
    }

    DosyaBorcluBankaBilgileri(): DosyaBorcluBankaBilgileri {
        return new DosyaBorcluBankaBilgileri();
    }

    DosyaBorcluMernis(): DosyaBorcluMernis {
        return new DosyaBorcluMernis();
    }

    DosyaBorcluMersis(): DosyaBorcluMersis {
        return new DosyaBorcluMersis();
    }

    BorcluTakbisSorgulama(): BorcluTakbisSorgulama {
        return new BorcluTakbisSorgulama();
    }

    DosyaBorcluBagkur(): DosyaBorcluBagkur {
        return new DosyaBorcluBagkur();
    }

    DosyaBorcluEgm(): DosyaBorcluEgm {
        return new DosyaBorcluEgm();
    }

    DosyaBorcluEgmMahrumiyet(): DosyaBorcluEgmMahrumiyet {
        return new DosyaBorcluEgmMahrumiyet();
    }

    DosyaBorcluIsyeriSgk(): DosyaBorcluIsyeriSgk {
        return new DosyaBorcluIsyeriSgk();
    }

    DosyaBorcluSSK(): DosyaBorcluSSK {
        return new DosyaBorcluSSK();
    }

    DosyaDetayBilgileri(): DosyaDetayBilgileri {
        return new DosyaDetayBilgileri();
    }

    DosyaEvraklar(): DosyaEvraklar {
        return new DosyaEvraklar();
    }

    DosyaHarcMasrafHesabi(): DosyaHarcMasrafHesabi {
        return new DosyaHarcMasrafHesabi();
    }

    DosyaHesapBilgileri(): DosyaHesapBilgileri {
        return new DosyaHesapBilgileri();
    }

    OdemeIslemiSorgulama(): OdemeIslemiSorgulama {
        return new OdemeIslemiSorgulama();
    }

    DosyaKapatmaNedenleri(): DosyaKapatmaNedenleri {
        return new DosyaKapatmaNedenleri();
    }

    DosyaOdemeIslemleri(): DosyaOdemeIslemleri {
        return new DosyaOdemeIslemleri();
    }

    DosyaSafahatToken(): DosyaSafahatToken {
        return new DosyaSafahatToken();
    }

    DosyaSafahat(): DosyaSafahat {
        return new DosyaSafahat();
    }

    VakifbankHesapListesi(): VakifbankHesapListesi {
        return new VakifbankHesapListesi();
    }

    VakifbankSmsOnay(): VakifbankSmsOnay {
        return new VakifbankSmsOnay();
    }

    AvukatKayitliIbanList(): AvukatKayitliIbanList {
        return new AvukatKayitliIbanList();
    }

    GetIbanListesi(): GetIbanListesi {
        return new GetIbanListesi();
    }

    DosyaTarafBilgileri(): DosyaTarafBilgileri {
        return new DosyaTarafBilgileri();
    }

    GetDosyaTarafBilgileri(): GetDosyaTarafBilgileri {
        return new GetDosyaTarafBilgileri();
    }

    GetDosyaTarafListesiWithTarafRol(): GetDosyaTarafListesiWithTarafRol {
        return new GetDosyaTarafListesiWithTarafRol();
    }

    EvrakGonder(): EvrakGonder {
        return new EvrakGonder();
    }

    DosyaAcilisEvrakGonder(): DosyaAcilisEvrakGonder {
        return new DosyaAcilisEvrakGonder();
    }

    EvrakIndir(): EvrakIndir {
        return new EvrakIndir();
    }

    MakbuzIndir(): MakbuzIndir {
        return new MakbuzIndir();
    }

    GetIbanDetails(): GetIbanDetails {
        return new GetIbanDetails();
    }

    GetAdresList(): GetAdresList {
        return new GetAdresList();
    }

    GetKurumMersisAdresi(): GetKurumMersisAdresi {
        return new GetKurumMersisAdresi();
    }

    GetMaasHacziParams(): GetMaasHacziParams {
        return new GetMaasHacziParams();
    }

    GetPortalAvukatTalepAdresTuruList(): GetPortalAvukatTalepAdresTuruList {
        return new GetPortalAvukatTalepAdresTuruList();
    }

    GonderilebilecekEvrakListesi(): GonderilebilecekEvrakListesi {
        return new GonderilebilecekEvrakListesi();
    }

    IcraPesinHarcGetir(): IcraPesinHarcGetir {
        return new IcraPesinHarcGetir();
    }

    IcraTakipIlamDosyasiUygunMu(): IcraTakipIlamDosyasiUygunMu {
        return new IcraTakipIlamDosyasiUygunMu();
    }

    IcraTakipIlamDosyaTuru(): IcraTakipIlamDosyaTuru {
        return new IcraTakipIlamDosyaTuru();
    }

    IldekiIdariBirimler(): IldekiIdariBirimler {
        return new IldekiIdariBirimler();
    }

    IldekiAdliyeler(): IldekiAdliyeler {
        return new IldekiAdliyeler();
    }

    AdliyedekiTumMahkemeler(): AdliyedekiTumMahkemeler {
        return new AdliyedekiTumMahkemeler();
    }

    IcraTalepEvrakHazirla(): IcraTalepEvrakHazirla {
        return new IcraTalepEvrakHazirla();
    }

    KisiMernisAdresiVarmi(): KisiMernisAdresiVarmi {
        return new KisiMernisAdresiVarmi();
    }

    KisiSorgula(): KisiSorgula {
        return new KisiSorgula();
    }

    KurumSorgulama(): KurumSorgulama {
        return new KurumSorgulama();
    }

    MahkemeBirimleri(): MahkemeBirimleri {
        return new MahkemeBirimleri();
    }

    MtsTarafTebligatList(): MtsTarafTebligatList {
        return new MtsTarafTebligatList();
    }

    MtsTebligatList(): MtsTebligatList {
        return new MtsTebligatList();
    }

    MtsTebligatSafahatList(): MtsTebligatSafahatList {
        return new MtsTebligatSafahatList();
    }

    MtsIcraAcilisAlacakliTaraflar(): MtsIcraAcilisAlacakliTaraflar {
        return new MtsIcraAcilisAlacakliTaraflar();
    }

    MtsIcraTakipBaslat(): MtsIcraTakipBaslat {
        return new MtsIcraTakipBaslat();
    }

    MtsHarcList(): MtsHarcList {
        return new MtsHarcList();
    }

    MtsTakipTalebiOlustur(): MtsTakipTalebiOlustur {
        return new MtsTakipTalebiOlustur();
    }

    MtsDavaAcilis(): MtsDavaAcilis {
        return new MtsDavaAcilis();
    }

    OdemeYap(): OdemeYap {
        return new OdemeYap();
    }

    ParaBirimleri(): ParaBirimleri {
        return new ParaBirimleri();
    }

    PortalAvukatTalepMalTuruList(): PortalAvukatTalepMalTuruList {
        return new PortalAvukatTalepMalTuruList();
    }

    PortalAvukatTalepTebligatTuruList(): PortalAvukatTalepTebligatTuruList {
        return new PortalAvukatTalepTebligatTuruList();
    }

    SgkKamu(): SgkKamu {
        return new SgkKamu();
    }

    TahsilatReddiyat(): TahsilatReddiyat {
        return new TahsilatReddiyat();
    }

    TahsilHarcOranlariniGetir(): TahsilHarcOranlariniGetir {
        return new TahsilHarcOranlariniGetir();
    }

    TakibiKesinlesenBorcluListesi(): TakibiKesinlesenBorcluListesi {
        return new TakibiKesinlesenBorcluListesi();
    }

    TamamlanmayanDosyalar(): TamamlanmayanDosyalar {
        return new TamamlanmayanDosyalar();
    }

    MtsTebligatUcreti(): MtsTebligatUcreti {
        return new MtsTebligatUcreti();
    }

    MtsTebligatGonder(): MtsTebligatGonder {
        return new MtsTebligatGonder();
    }

    YargiBirimleri(): YargiBirimleri {
        return new YargiBirimleri();
    }
}

export default UyapClient;