export enum IndirilecekEvrakTuru {
    dosyaya_eklenen_son_evrak = 'dosyaya_eklenen_son_evrak',
    tahsil_harci_makbuzu_sayman_mutemet_alindisi = 'tahsil_harci_makbuzu_sayman_mutemet_alindisi',
    takip_talebi = 'takip_talebi',
    teblig_mazbatasi = 'teblig_mazbatasi',
    tensip_zapti = 'tensip_zapti'
}

export enum IndirilecekEvrakTuruLabel {
    dosyaya_eklenen_son_evrak = "Dosyaya Eklenen Son ... Evrak",
    tahsil_harci_makbuzu_sayman_mutemet_alindisi = "Tahsil Harcı Makbuzu(Sayman Mutemet Alındısı)",
    takip_talebi = "Takip Talebi",
    teblig_mazbatasi = "Tebliğ Mazbatası",
    tensip_zapti = "Tensip zaptı"
}