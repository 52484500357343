import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface DosyaBorcluMernisTalep {
    dosyaId: string,
    kisiKurumId: string,
    sorguBakiye: number
}

export class DosyaBorcluMernisCevap {
    html: string = "";
    kimlikBilgileri: MernisKimlikBilgileri = new MernisKimlikBilgileri();
    mernisAdresBilgileri: MernisAdresBilgileri = new MernisAdresBilgileri();
    found: boolean = false;
    message: string = "";
}

class MernisKimlikBilgileri {
    tcKimlikNo: string = "";
    cinsiyeti: string = "";
    adi: string = "";
    soyadi: string = "";
    babaAdi: string = "";
    anaAdi: string = "";
    dogumYeri: string = "";
    dogumTarihi: string = "";
    nufuyKayitIl: string = "";
    nufusKayitIlce: string = "";
    mahalleKoy: string = "";
    verilisNedeni: string = "";
    verildigiTarih: string = "";
    verildiliIl: string = "";
    verildiliIlce: string = "";
    cuzdanSeri: string = "";
    cuzdanNo: string = "";
    ciltNo: string = "";
    aileSiraNo: string = "";
    siraNo: string = "";
    dini: string = "";
    oncekiSoyadi: string = "";
    olumTarihi: string = "";
}

class MernisAdresBilgileri {
    adresTipi: string = "";
    beyanTarihi: string = "";
    tasinmaTarihi: string = "";
    tescilTarihi: string = "";
    mahalle: string = "";
    caddeSokak: string = "";
    disKapiNo: string = "";
    icKapiNo: string = "";
    il: string = "";
    ilce: string = "";
}

export default class DosyaBorcluMernis extends UyapRequest<DosyaBorcluMernisTalep, DosyaBorcluMernisCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "DosyaBorcluMernis_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): string {
        return `<div id="servis_sonuc_mernis">
  <div id="content">
    <style>
      @media print {
        .hidden-print {
          display: none !important;
        }

        body {
          font-family: Arial, Helvetica, sans-serif;
          ;
        }
    </style>
    <div id="content_pdf">
      <table class='table table-bordered table-striped'>
        <tr>
          <td style=" border: none; text-align: center; font-weight: bold; width: 100%;">BORÇLUYA AİT MERNİS BİLGİLERİ</td>
        </tr>
      </table>
      <fieldset style="margin-bottom: 30px; padding: 0 1.4em 0 1.4em;">
        <legend>Sorgulamaya Ait Genel Bilgiler</legend>
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>İşlemi Yapan</th>
              <th>Birim Adı / Dosya No</th>
              <th>Sorgulanan Kişi Bilgileri</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Av. NİHAN ERDOĞAN</td>
              <td>Ankara 9. İcra Dairesi 2017/12831</td>
              <td>M_____ S___ Ş____</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <fieldset>
        <legend>Mernis'e Kayıtlı Kimlik Bilgileri</legend>
        <table class='table table-bordered table-striped'>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">T.C. Kimlik No</td>
            <td style="border: none;">11111111110</td>
            <td style="border: none; text-align: right; font-weight: bold;">Cinsiyeti</td>
            <td style="border: none;">E</td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Adı</td>
            <td style="border: none;">M____ S___</td>
            <td style="border: none; text-align: right; font-weight: bold;">Soyadı</td>
            <td style="border: none;">Ş______</td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Baba Adı</td>
            <td style="border: none;">D___</td>
            <td style="border: none; text-align: right; font-weight: bold;">Ana Adı</td>
            <td style="border: none;">Y___</td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Doğum Yeri</td>
            <td style="border: none;">T_____</td>
            <td style="border: none; text-align: right; font-weight: bold;">Doğum Tarihi</td>
            <td style="border: none;">21/05/1964</td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Nüf. Kay. İl</td>
            <td style="border: none;">M____</td>
            <td style="border: none; text-align: right; font-weight: bold;">Nüf. Kay. İlçe</td>
            <td style="border: none;">T____</td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Maha/Köy</td>
            <td style="border: none;">Ç____</td>
            <td style="border: none; text-align: right; font-weight: bold;">Ver. Nedeni</td>
            <td style="border: none;">Yenileme</td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Ver. Tarih</td>
            <td style="border: none;">05/07/2007</td>
            <td style="border: none; text-align: right; font-weight: bold;">Ver. İl</td>
            <td style="border: none;">M_____</td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Ver. İlçe</td>
            <td style="border: none;">Mersin Merkez</td>
            <td style="border: none; text-align: right; font-weight: bold;"></td>
            <td style="border: none;"></td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Cüzdan Seri</td>
            <td style="border: none;">E11</td>
            <td style="border: none; text-align: right; font-weight: bold;">Cüzdan No</td>
            <td style="border: none;">5377777</td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Cilt No</td>
            <td style="border: none;">12</td>
            <td style="border: none; text-align: right; font-weight: bold;">Aile Sıra No</td>
            <td style="border: none;">4</td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Sıra No</td>
            <td style="border: none;">31</td>
            <td style="border: none; text-align: right; font-weight: bold;">Dini</td>
            <td style="border: none;">Bilinmeyen</td>
          </tr>
          <tr>
            <td style="border: none; text-align: right; font-weight: bold;">Önceki Soyadı</td>
            <td style="border: none;"></td>
            <td style="border: none; text-align: right; font-weight: bold;">Ölüm Tarihi</td>
            <td style="border: none;"></td>
          </tr>
        </table>
        <fieldset>
          <legend>Mernis'e Kayıtlı Adres Bilgileri</legend>
          <table class='table table-bordered table-striped'>
            <tr>
              <td style="border: none; text-align: right; font-weight: bold;">Adres Tipi</td>
              <td style="border: none;">IlIlceMerkeziAdresi</td>
              <td style="border: none; text-align: right; font-weight: bold;">Beyan Tarihi</td>
              <td style="border: none;">4/3/2007</td>
            </tr>
            <tr>
              <td style="border: none; text-align: right; font-weight: bold;">Taşınma Tarihi</td>
              <td style="border: none;">0/0/0</td>
              <td style="border: none; text-align: right; font-weight: bold;">Tescil Tarihi</td>
              <td style="border: none;">0/0/0</td>
            </tr>
            <tr>
              <td style="border: none; text-align: right; font-weight: bold;">Mahalle</td>
              <td style="border: none;">AKKENT MAH.</td>
              <td style="border: none; text-align: right; font-weight: bold;">Cadde/Sokak</td>
              <td style="border: none;">GAZİ MUSTAFA KEMAL BULVARI</td>
            </tr>
            <tr>
              <td style="border: none; text-align: right; font-weight: bold;">Dış Kapı No</td>
              <td style="border: none;">51</td>
              <td style="border: none; text-align: right; font-weight: bold;">İç Kapı No</td>
              <td style="border: none;">2</td>
            </tr>
            <tr>
              <td style="border: none; text-align: right; font-weight: bold;">İl</td>
              <td style="border: none;">M_____</td>
              <td style="border: none; text-align: right; font-weight: bold;">İlçe</td>
              <td style="border: none;">Y_______</td>
            </tr>
          </table>
        </fieldset>
        <table style="border: none;" class="table table-bordered table-striped">
          <tr>
            <td style="width: 50%; border: none;"></td>
            <td style="width: 50%; border: none;">
              <table style="border: none;" class="table table-bordered table-striped">
                <tr>
                  <td style=" border: none; text-align: center; width: 100%;">19/06/2023 10:34</td>
                </tr>
                <tr>
                  <td style=" border: none; text-align: center; font-weight: bold; width: 100%;">Düzenleyen</td>
                </tr>
                <tr>
                  <td style=" border: none; text-align: center; font-weight: bold; width: 100%;">AVUKAT PORTAL BİLGİ SİSTEMİ</td>
                </tr>
                <tr>
                  <td style=" border: none; text-align: center; width: 100%;">Avukatların, Avukat Portal'dan almış oldukları hizmete ilişkin belgedir. </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
    </div>
  </div>
</div>
<div style="width: 100%; border: none; text-align: center;padding: 30px;font-size: 18px;">
  <a id="10060460043622443662441643453475325406435430_print" class="print_btn" style="margin-right: 10px; color: blue;" href="javascript: void(0);">Yazdır</a>
  <a id="10060460043622443662441643453475325406435430_pdf" class="pdf_btn" style="margin-right: 10px; color: blue;" href="javascript: void(0);">PDF</a>
</div>
<script type="text/javascript">
  $('#10060460043622443662441643453475325406435430_print').click(function() {
        $(this).hide();
        var w = window.open();
        w.document.write(' < html > < head > < title > UYAP Bilgi Sistemi < /title> < /head> < body > ');\t\t\tw.document.write($('
            div #servis_sonuc_mernis div[id = content]
            ').html());\t\t\tw.document.write(' < /body> < /html>');\t\t\tw.print();\t\t\tw.close();\t\t\t$(this).show();\t\t}\t);
</script>
<script type="text/javascript">
  $('#10060460043622443662441643453475325406435430_pdf').click(function() {
    $(this).hide();
    postToURL_target('convert_to_pdf.uyap', {
      data: $('div#servis_sonuc_mernis div[id=content_pdf]').html(),
      filename: 'Borçlu Bilgileri Mernis'
    }, 'width=400,height=10,resizable=yes');
    $(this).show();
  });
</script>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/dosya_gor_jsp/borclu_bilgileri_goruntule_mernis_brd.ajx';
    }

    async parse(data: string): Promise<DosyaBorcluMernisCevap> {

        if (data == "Mernisten kimlik sorgusu yapabilmek için gerekli değerlerden bir veya birkaçı mevcut değil (T.C. Kimlik No veya Adı, Soyadı, Cinsiyeti, Baba Adı, Ana Adı, Doğum Tarihi)") {
            let response = new DosyaBorcluMernisCevap();
            response.message = data;
            return response;
        }
        let div = document.createElement('div');
        div.innerHTML = data;
        let tables = div.querySelectorAll('table');
        let kimlikBilgileriTable = tables[2];
        let adresBilgileriTable = tables[3];

        return {
            html: (div.querySelector('#servis_sonuc_mernis') as HTMLElement).innerHTML,
            kimlikBilgileri: {
                tcKimlikNo: (kimlikBilgileriTable.querySelector('tr:nth-child(1) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                cinsiyeti: (kimlikBilgileriTable.querySelector('tr:nth-child(1) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                adi: (kimlikBilgileriTable.querySelector('tr:nth-child(2) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                soyadi: (kimlikBilgileriTable.querySelector('tr:nth-child(2) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                babaAdi: (kimlikBilgileriTable.querySelector('tr:nth-child(3) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                anaAdi: (kimlikBilgileriTable.querySelector('tr:nth-child(3) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                dogumYeri: (kimlikBilgileriTable.querySelector('tr:nth-child(4) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                dogumTarihi: (kimlikBilgileriTable.querySelector('tr:nth-child(4) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                nufuyKayitIl: (kimlikBilgileriTable.querySelector('tr:nth-child(5) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                nufusKayitIlce: (kimlikBilgileriTable.querySelector('tr:nth-child(5) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                mahalleKoy: (kimlikBilgileriTable.querySelector('tr:nth-child(6) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                verilisNedeni: (kimlikBilgileriTable.querySelector('tr:nth-child(6) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                verildigiTarih: (kimlikBilgileriTable.querySelector('tr:nth-child(7) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                verildiliIl: (kimlikBilgileriTable.querySelector('tr:nth-child(7) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                verildiliIlce: (kimlikBilgileriTable.querySelector('tr:nth-child(8) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                cuzdanSeri: (kimlikBilgileriTable.querySelector('tr:nth-child(9) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                cuzdanNo: (kimlikBilgileriTable.querySelector('tr:nth-child(9) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                ciltNo: (kimlikBilgileriTable.querySelector('tr:nth-child(10) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                aileSiraNo: (kimlikBilgileriTable.querySelector('tr:nth-child(10) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                siraNo: (kimlikBilgileriTable.querySelector('tr:nth-child(11) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                dini: (kimlikBilgileriTable.querySelector('tr:nth-child(11) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                oncekiSoyadi: (kimlikBilgileriTable.querySelector('tr:nth-child(12) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                olumTarihi: (kimlikBilgileriTable.querySelector('tr:nth-child(12) td:nth-child(4)') as HTMLTableCellElement)?.innerText
            },
            mernisAdresBilgileri: {
                adresTipi: (adresBilgileriTable.querySelector('tr:nth-child(1) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                beyanTarihi: (adresBilgileriTable.querySelector('tr:nth-child(1) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                tasinmaTarihi: (adresBilgileriTable.querySelector('tr:nth-child(2) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                tescilTarihi: (adresBilgileriTable.querySelector('tr:nth-child(2) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                mahalle: (adresBilgileriTable.querySelector('tr:nth-child(3) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                caddeSokak: (adresBilgileriTable.querySelector('tr:nth-child(3) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                disKapiNo: (adresBilgileriTable.querySelector('tr:nth-child(4) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                icKapiNo: (adresBilgileriTable.querySelector('tr:nth-child(4) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
                il: (adresBilgileriTable.querySelector('tr:nth-child(5) td:nth-child(2)') as HTMLTableCellElement)?.innerText,
                ilce: (adresBilgileriTable.querySelector('tr:nth-child(5) td:nth-child(4)') as HTMLTableCellElement)?.innerText,
            },
            message: "",
            found: true
        }

    }

}

