import {DosyaBorcluSSKCevap} from "./DosyaBorcluSSK";
import {DosyaBorcluEgmCevap} from "./DosyaBorcluEgm";
import {DosyaBorcluMernisCevap} from "./DosyaBorcluMernis";
import {SgkKamuCevap} from "./SgkKamu";
import {BorcluGibCevap} from "./BorcluGib";
import {DosyaBorcluBagkurCevap} from "./DosyaBorcluBagkur";
import {DosyaBorcluBankaBilgileriCevap} from "./DosyaBorcluBankaBilgileri";
import {BorcluDibCevap} from "./BorcluDib";
import {DosyaBorcluIsyeriSgkCevap} from "./DosyaBorcluIsyeriSgk";
import {BorcluTakbisSorgulamaCevap} from "./BorcluTakbisSorgulama";
import {BorcluSgkHacizCevap} from "./BorcluSgkHaciz";
import {BorcluIskiCevap} from "./BorcluIski";
import {BorcluIcraDosyasiCevap} from "./BorcluIcraDosyasi";
import {BorcluGsmCevap} from "./BorcluGsm";
import {BorcluPostaCekiCevap} from "./BorcluPostaCeki";
import app from "@/main";
import {DosyaBorcluMersisCevap} from "./DosyaBorcluMersis";
import {DosyaBorcluHacizInterface,} from "../haciz/HacizParamsInterfaces";
import {DosyaBorcluTebligatInterface} from "../haciz/TebligatParamsInterface";
import {DigerTalepParamsInterface} from "../haciz/TopluDigerTalepParamsInterface";

export class DosyaBorcluInterface {
    kurumAdi: string = "";
    vergiNo: string = "";
    kisiKurumId: any = "";
    adi: string = "";
    soyadi: string = "";
    babaAdi: string = "";
    anaAdi: string = "";
    dogumTarihi: string = "";
    tcKimlikNo: string = "";
    haciz: DosyaBorcluHacizInterface = new DosyaBorcluHacizInterface();
    tebligat: DosyaBorcluTebligatInterface = new DosyaBorcluTebligatInterface();
    digerTalep: DigerTalepParamsInterface = new DigerTalepParamsInterface();
}

export class DosyaBorclu extends DosyaBorcluInterface {
    ssk: DosyaBorcluSSKCevap = new DosyaBorcluSSKCevap();
    sskAktif: boolean = false;
    egm: DosyaBorcluEgmCevap = new DosyaBorcluEgmCevap();
    mernis: DosyaBorcluMernisCevap = new DosyaBorcluMernisCevap();
    mersis: DosyaBorcluMersisCevap = new DosyaBorcluMersisCevap();
    sgkkamu: SgkKamuCevap = new SgkKamuCevap();
    gib: BorcluGibCevap = new BorcluGibCevap();
    sgkbagkur: DosyaBorcluBagkurCevap = new DosyaBorcluBagkurCevap();
    bankaBilgileri: DosyaBorcluBankaBilgileriCevap = new DosyaBorcluBankaBilgileriCevap();
    dib: BorcluDibCevap = new BorcluDibCevap();
    sgkIsyeri: DosyaBorcluIsyeriSgkCevap = new DosyaBorcluIsyeriSgkCevap();
    takbis: BorcluTakbisSorgulamaCevap = new BorcluTakbisSorgulamaCevap();
    sgkhaciz: BorcluSgkHacizCevap = new BorcluSgkHacizCevap();
    iski: BorcluIskiCevap = new BorcluIskiCevap();
    icraDosyaKaydi: BorcluIcraDosyasiCevap = new BorcluIcraDosyasiCevap();
    gsm: BorcluGsmCevap = new BorcluGsmCevap();
    postaCeki: BorcluPostaCekiCevap = new BorcluPostaCekiCevap();

    isKisiBool: boolean = false;
    isKurumBool: boolean = false;

    isKurum(): boolean {
        return this.kurumAdi != "";
    }

    isKisi(): boolean {
        return this.adi != "";
    }

    setBorcluErrorAllFields(message:string){
        this.ssk.message = message;
        this.egm.message = message;
        this.mernis.message = message;
        this.mersis.message = message;
        this.sgkkamu.message = message;
        this.gib.message = message;
        this.sgkbagkur.message = message;
        this.bankaBilgileri.message = message;
        this.dib.message = message;
        this.sgkIsyeri.message = message;
        this.takbis.message = message;
        this.sgkhaciz.message = message;
        this.iski.message = message;
        this.icraDosyaKaydi.message = message;
        this.gsm.message = message;
        this.postaCeki.message = message;
    }

    async queryBorcluSsk(dosyaId: any): Promise<void> {
        try {
            this.ssk = await app.$uyap.DosyaBorcluSSK().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: ""
            });
            this.ssk.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.ssk.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryEgmMahrumiyet(dosyaId: any): Promise<void> {
        try {
            await this.queryEgm(dosyaId);
            for (const arac of this.egm.items) {
                try {
                    arac.mahrumiyet = await app.$uyap.DosyaBorcluEgmMahrumiyet().run({
                        dosyaId: dosyaId,
                        kisiKurumId: this.kisiKurumId,
                        plaka: arac.plaka
                    })
                    console.log("ARAC MAHRUMIYET", arac.mahrumiyet);
                }catch (e: any) {
                    arac.mahrumiyet.message = e.message;
                }
            }
            return Promise.resolve();
        } catch (e: any) {
            console.log("MAHRUMIYET ERR", e);
            return Promise.reject(e);
        }
    }

    async queryBorcluMernis(dosyaId: string): Promise<void> {
        try {
            this.mernis = await app.$uyap.DosyaBorcluMernis().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            this.mernis.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.mernis.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluMersis(dosyaId: string): Promise<void> {
        try {
            this.mersis = await app.$uyap.DosyaBorcluMersis().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            this.mersis.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.mersis.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkKamu(dosyaId: string): Promise<void> {
        try {
            this.sgkkamu = await app.$uyap.SgkKamu().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            this.sgkkamu.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.sgkkamu.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluGib(dosyaId: string): Promise<void> {
        try {
            this.gib = await app.$uyap.BorcluGib().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            return Promise.resolve();
        } catch (e: any) {
            this.gib.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkBagkur(dosyaId: string): Promise<void> {
        try {
            this.sgkbagkur = await app.$uyap.DosyaBorcluBagkur().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            this.sgkbagkur.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.sgkbagkur.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluDib(dosyaId: string): Promise<void> {
        try {
            this.dib = await app.$uyap.BorcluDib().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            return Promise.resolve();
        } catch (e: any) {
            this.dib.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkIsyeri(dosyaId: string): Promise<void> {
        try {
            this.sgkIsyeri = await app.$uyap.DosyaBorcluIsyeriSgk().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            this.sgkIsyeri.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.sgkIsyeri.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluTakbis(dosyaId: string): Promise<void> {
        try {
            this.takbis = await app.$uyap.BorcluTakbisSorgulama().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
            });
            console.log(this.takbis)
            this.takbis.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.takbis.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkHaciz(dosyaId: string): Promise<void> {
        try {
            this.sgkhaciz = await app.$uyap.BorcluSgkHaciz().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
            });
            return Promise.resolve();
        } catch (e: any) {
            this.sgkhaciz.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluIski(dosyaId: string): Promise<void> {
        try {
            this.iski = await app.$uyap.BorcluIski().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            return Promise.resolve();
        } catch (e: any) {
            this.iski.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluIcraDosyaKaydi(dosyaId: string): Promise<void> {
        try {
            this.icraDosyaKaydi = await app.$uyap.BorcluIcraDosyasi().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
            });
            return Promise.resolve();
        } catch (e: any) {
            this.icraDosyaKaydi.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluGsm(dosyaId: string): Promise<void> {
        try {
            this.gsm = await app.$uyap.BorcluGsm().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            return Promise.resolve();
        } catch (e: any) {
            this.gsm.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluPostaCeki(dosyaId: string): Promise<void> {
        try {
            this.postaCeki = await app.$uyap.BorcluPostaCeki().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            return Promise.resolve();
        } catch (e: any) {
            this.postaCeki.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluBankaBilgileri(dosyaId: string): Promise<void> {
        try {
            this.bankaBilgileri = await app.$uyap.DosyaBorcluBankaBilgileri().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: 1
            });
            return Promise.resolve();
        } catch (e: any) {
            this.bankaBilgileri.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryPostaCeki(dosyaId: any): Promise<BorcluPostaCekiCevap> {
        try {
            this.postaCeki = await app.$uyap.BorcluPostaCeki().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: ""
            });
            return Promise.resolve(this.postaCeki);
        } catch (e: any) {
            return Promise.reject(e);
        }
    }

    async queryIcraDosyaKaydi(dosyaId: any): Promise<BorcluIcraDosyasiCevap> {
        try {
            this.icraDosyaKaydi = await app.$uyap.BorcluIcraDosyasi().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve(this.icraDosyaKaydi);
        } catch (e: any) {
            this.icraDosyaKaydi.message=e.message;
            return Promise.reject(e);
        }
    }

    async queryBankaHesap(dosyaId: any): Promise<DosyaBorcluBankaBilgileriCevap> {
        try {
            this.bankaBilgileri = await app.$uyap.DosyaBorcluBankaBilgileri().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: ""
            });
            return Promise.resolve(this.bankaBilgileri);
        } catch (e: any) {
            this.bankaBilgileri.message=e.message;
            return Promise.reject(e);
        }
    }

    async queryTakbis(dosyaId: any): Promise<BorcluTakbisSorgulamaCevap> {
        try {
            this.takbis = await app.$uyap.BorcluTakbisSorgulama().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve(this.takbis);
        } catch (e: any) {
            this.takbis.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryEgm(dosyaId: any): Promise<DosyaBorcluEgmCevap> {
        try {
            this.egm = await app.$uyap.DosyaBorcluEgm().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                pageIndex: 0
            });
            return Promise.resolve(this.egm);
        } catch (e: any) {
            this.egm.message=e.message;
            return Promise.reject(e);
        }
    }

    async querySgkHaciz(dosyaId: any): Promise<BorcluSgkHacizCevap> {
        try {
            this.sgkhaciz = await app.$uyap.BorcluSgkHaciz().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve(this.sgkhaciz);
        } catch (e: any) {
            this.sgkhaciz.message=e.message;
            return Promise.reject(e);
        }
    }

    async isSskDurumAktif(dosyaId: any): Promise<boolean> {
        await this.queryBorcluSsk(dosyaId);
        this.sskAktif = false;
        let donemYilAy = this.ssk.kimlikBilgileri.donemYilAy;
        let durum = this.ssk.kimlikBilgileri.durum;
        let aktifSayilabilecekDonem = new Date(app.$uyapHelper.getDateNow("/", "month -2", true)).getTime(); //2019/01
        let mevcutDosyaDonem = new Date(donemYilAy).getTime();
        let sonIstenCikis = this.ssk.kimlikBilgileri.isetenCikisTarihi;
        if (durum == "Aktif") {
            if (mevcutDosyaDonem > aktifSayilabilecekDonem) {
                this.sskAktif = true;
            }
        }
        return this.sskAktif;
    }

    async queryGsm(dosyaId: any): Promise<BorcluGsmCevap> {
        try {
            this.gsm = await app.$uyap.BorcluGsm().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: ""
            });
            return Promise.resolve(this.gsm);
        } catch (e: any) {
            return Promise.reject(e);
        }
    }

}