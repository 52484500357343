import {UyapRequest} from "./UyapRequest";

export interface DosyaSafahatTalep {
}

export interface DosyaSafahatTokenCevap {
    token: string
}


export class DosyaSafahatToken extends UyapRequest<DosyaSafahatTalep, DosyaSafahatTokenCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): string {
        return "";
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/pages/avukat_dosya_sorgula.jsp';
    }

    async parse(data: string): Promise<DosyaSafahatTokenCevap> {
        let nerde = data.indexOf('token') + 7;
        let ex = data.substr(nerde, data.length);
        let token = ex.substr(0, ex.indexOf("'"));
        return {token: token};
    }
}

export default DosyaSafahatToken;
