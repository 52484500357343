import {AvukatDosyaSorgulaGenelCevapItem} from "../AvukatDosyaSorgulaGenel";
import app from "@/main";
import {DosyaBorclu} from "../DosyaBorclu";
import {DosyaTaraflarCevapRow} from "../DosyaTarafBilgileri";
import {GetDosyaTarafBilgileriCevapRow} from "../GetDosyaTarafBilgileri";
import {ITask, Task} from "../../store/modules/GorevYoneticisiTypes";
import Args from "@/plugins/uyap-plugin/takip_ac/parser/Args";

const DOSYA_KAC_GUN_SONRA_DUSECEK: number = 350;
export default class TakipDosya extends AvukatDosyaSorgulaGenelCevapItem {
    sonIslemTarihindenItibarenGecenGunSayisi: number = 0;
    sonIslemTarihindenItibarenKalanGunSayisi: number = 0;
    borclular: DosyaBorclu[] = [];
    tarafBilgileri: DosyaTaraflarCevapRow[] = [];
    detayliTarafBilgileri: GetDosyaTarafBilgileriCevapRow[] = [];
    task!: Task;

    setBorclular(borclular: DosyaBorclu[]) {
        this.borclular = borclular;
    }

    static fromJson(jsonData: AvukatDosyaSorgulaGenelCevapItem | ITask): TakipDosya {
        let json = Args.staticFromJson(jsonData,new TakipDosya());
        if ((jsonData as ITask).dosya_id)
            json.dosyaId = (jsonData as ITask).dosya_id;
        if ((jsonData as ITask).dosya_esas_no)
            json.dosyaNo = (jsonData as ITask).dosya_esas_no;
        if ((jsonData as ITask).birim_adi)
            json.birimAdi = (jsonData as ITask).birim_adi;
        return json;
    }

    dosyaGuncelMi(): boolean {
        return this.sonIslemTarihindenItibarenGecenGunSayisi < DOSYA_KAC_GUN_SONRA_DUSECEK;
    }

    async dosyaDusecekMiSorgula(): Promise<void> {
        let acilisTarihi = new Date(this.dosyaAcilisTarihi).getTime();
        this.sonIslemTarihindenItibarenGecenGunSayisi = ((Date.now() - acilisTarihi) / 86400000);
        this.sonIslemTarihindenItibarenKalanGunSayisi = Math.floor(365 - this.sonIslemTarihindenItibarenGecenGunSayisi);
        if (this.dosyaGuncelMi()) {
            console.log("DOSYA GUNCEL..");
            return;
        }
        let dosyaEvraklarCevap = await app.$uyap.DosyaEvraklar().run({
            dosyaId: this.dosyaId
        })
        let sonIslemTarih = 1;
        dosyaEvraklarCevap.items.forEach((evrak) => {
            let s = String(evrak.evrakinOnaylandigiTarih).split("/");
            let d = s[1] + "/" + s[0] + "/" + s[2];
            let islemTarihi = new Date(d).getTime();
            if (islemTarihi > sonIslemTarih)
                sonIslemTarih = islemTarihi;
        })
        this.sonIslemTarihindenItibarenGecenGunSayisi = Math.floor((Date.now() - sonIslemTarih) / 86400000);
        this.sonIslemTarihindenItibarenKalanGunSayisi = Math.floor(365 - this.sonIslemTarihindenItibarenGecenGunSayisi);
        return;
    }

    async tarafBilgileriQuery() {
        let tarafBilgileri = await app.$uyap.DosyaTarafBilgileri().run({dosyaId: this.dosyaId});
        this.tarafBilgileri = tarafBilgileri.items;
        return this.tarafBilgileri;
    }

    async dosyaDetayliTarafBilgileriQuery() {
        let response = await app.$uyap.GetDosyaTarafBilgileri().run({dosyaId: this.dosyaId});
        this.detayliTarafBilgileri = response.items;
        return this.detayliTarafBilgileri;
    }

    async borclularQuery() {
        let borclular = await app.$uyap.DosyaBorclulari().run({
            dosyaId: this.dosyaId
        })
        this.borclular = borclular.items;
        return this.borclular;
    }


}