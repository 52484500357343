import Vekil from "./Vekil";
import TarafKisi from "./TarafKisi";
import TarafKurum from "./TarafKurum";
import {DosyaFormDataInterface} from "../DosyaFormDataInterface";
import {MtsDosyaFormData} from "../MtsDosyaFormData";
import TemelBilgileri from "@/plugins/uyap-plugin/takip_ac/parser/TemelBilgileri";
import Adres from "@/plugins/uyap-plugin/takip_ac/parser/Adres";
import Args from "@/plugins/uyap-plugin/takip_ac/parser/Args";

export default class Taraf {
    static fromJson(jsonData: any, isMts = false): TarafKisi | TarafKurum {
        let obj: TarafKisi | TarafKurum;
        if (jsonData.kisiMi == true) {
            obj = new TarafKisi(isMts);
        } else {
            obj = new TarafKurum(isMts);
        }
        obj = Args.staticFromJson(jsonData,obj);
        obj.temelBilgileri = TemelBilgileri.fromJson(obj.temelBilgileri);
        obj.adresBilgisi = Adres.fromJson(obj.adresBilgisi);
        return obj;
    }

    static parse(
        tarafDomNode: Element,
        vekilList: Vekil[],
        formData: DosyaFormDataInterface | MtsDosyaFormData,
        isMts: boolean = false
    ): TarafKisi | TarafKurum {
        let kisiKurumBilgileriDomNode = tarafDomNode.querySelector('kisikurumbilgileri');
        //@ts-ignore
        let kisiTumBilgileriDomNode = kisiKurumBilgileriDomNode.querySelector('kisitumbilgileri');
        if (kisiTumBilgileriDomNode)
            return new TarafKisi(isMts).parse(
                tarafDomNode,
                vekilList,
                formData
            );
        else
            return new TarafKurum(isMts).parse(
                tarafDomNode,
                vekilList,
                formData
            );
    }
}

