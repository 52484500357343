import {Vue} from "vue-property-decorator";
import {FileEntity} from "@/plugins/uyap-plugin/store/entity/FileEntity";
import {OdemeTipiEnum} from "@/plugins/uyap-plugin/takip_ac/OdemeTipleri";
import {VakifbankHesapBilgileri} from "@/plugins/uyap-plugin/uyap/VakifbankHesapListesi";
import {TamamlanmayanDosyalarRow} from "@/plugins/uyap-plugin/uyap/TamamlanmayanDosyalar";
import {AvukatKisiselBilgileriSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/AvukatKisiselBilgileriSorgulama";

export type VForm = Vue & {
    validate: () => boolean;
    resetValidation: () => boolean;
    reset: () => void;
}

export enum AppStatus {
    loading,
    error,
    update,
    electron,
    browser,
}


export interface ManifestResponse {
    files: ManifestResponseFileItem[];
}

export interface ManifestResponseFileItem {
    path: string,
    sha256: string
}

export interface AppState {
    uyapConnection: boolean,
    appStatus: AppStatus,
    tenant?: ITenant,
    manifestResponse: ManifestResponse,
    tokenExis: boolean,
    devMode: boolean,
    eicraproDevMode: boolean,
    uyapAvukatBilgileri?: AvukatKisiselBilgileriSorgulamaCevap
}

export interface ITenant {
    id: number,
    tenant_id: string,
    title: string,
    user_id: number,
    role: string
}

export interface TamamlanmayanDosya extends TamamlanmayanDosyalarRow {
    createByEicrapro: boolean,
    ttDownloading: boolean,
    eicraproData: {
        id: number | undefined,
        hash: string | undefined,
        dosya: any | undefined,
        uyap_dosya_id: string | undefined,
        dosya_esas_no: string | undefined,
        icra_dairesi: string | undefined,
        vekalet_id: number | undefined | null,
        vekalet: {
            data: string, //base64 data
            dosya: Blob,
            hash: string,
            mimeType: string,
            name: string
        } | null,
        tebligatGideri: {
            tutar: string,
            aciklama: string,
            enabled: boolean,
        },
        odemeTipi: OdemeTipiEnum,
        vakifbankHesapBilgileri: VakifbankHesapBilgileri,
        file: FileEntity | null,
        takip_talebi_id: number | undefined,
        dayanak: Array<TamamlanmayanDosyaFile>,
        status: 1 | 2 | undefined  // 1: tevzi, 2: açıldı
    }
}

interface TamamlanmayanDosyaFile extends FileEntity {
    data: string,
    dosya: Blob,
    mimeType: string
}