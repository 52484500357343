import {Mixins} from "../Mixins";

export default class HesapBilgisi {
    kayitID: string = ""//kayitID;
    bankaAdi: string = ""//bankaAdi;
    ibanNumarasi: string | null = null;//ibanNumarasi;
    hesapGenel: string = ""//hesapGenel;
    hesapAktifmi: string = ""//hesapAktifmi;
    constructor(tarafDomNode: HTMLUnknownElement | Element | null = null) {
        if (tarafDomNode) {
            let ibanNode = tarafDomNode.querySelector('iban');
            if (ibanNode)
                this.ibanNumarasi = Mixins.onlyDigit(ibanNode.getAttribute("no"));
        }

    }

}

