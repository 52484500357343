import {AbstractTaskHandler} from "./AbstractTaskHandler";
import TakipXmlParser from "../../../takip_ac/TakipXmlParser";
import UyapTakipAc from "../../../takip_ac/UyapTakipAc";
import {TaskDurum} from "../../../enum/TaskDurum";
import {ITask} from "../GorevYoneticisiTypes";

export class UyapTakipOdemeYapTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        try {
            let takip: UyapTakipAc = UyapTakipAc.fromJson(task.data);
            if (takip.isEicraprodanmiAcilmis()) {
                console.log("EICRAPRODAN ACILMIS !!");
                takip.parsedFile = TakipXmlParser.fromJson(task.data.parsedFile);
                await takip.takipAc();
            } else {
                console.log("UYAPTAN ACILMIS !!");
                await takip.tamamlanmamisDosyaTakipAc();
            }
            task.response = takip;
            task.status = TaskDurum.BITTI_BASARILI;
            return task;
        } catch (e: any) {
            throw new Error(e.message)
        }
    }

}