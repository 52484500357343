import {UyapRequest} from "./UyapRequest";


export interface AvukatKisiselBilgileriSorgulamaTalep {

}

export interface AvukatKisiselBilgileriSorgulamaCevap {
    ad: string,
    adresList: string,
    anaAd: string,
    avukatId: string,
    babaAd: string,
    bagliOlduguBaroAdi: string,
    baroNo: string,
    dogumTarihi: string,
    dogumYeri: string,
    isMock: boolean,
    isTCGecerli: boolean,
    kamuAvukatiMi: boolean,
    kisiKurumId: string,
    lisans: string,
    mernisGuncellemesiYapildi: string,
    soyad: string,
    tbbNo: string,
    tcKimlikNo: string,
    vekilTipiKod: string,
    vergiNo: string,
    bakiye: string,
}


export class AvukatKisiselBilgileriSorgulama extends UyapRequest<AvukatKisiselBilgileriSorgulamaTalep, AvukatKisiselBilgileriSorgulamaCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'GET';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "AvukatKisiselBilgileriSorgulama"
        };
    }

    getMockResponse(): string {
        return `<root><object-array>
  <AvukatTumBilgilerDVO>
    <isMock>false</isMock>
    <avukatId>1746688</avukatId>
    <kisiKurumId>94071819</kisiKurumId>
    <tcKimlikNo>14537825364</tcKimlikNo><lisans>TVRJNU16ZzJOVE0zTnpaVFUxTXhOVGs1TVRjeE5UZzRAQEBMVEUxT1RreE5qZzNPREE9</lisans>
    <ad>NİHAN</ad>
    <soyad>ERDOĞAN</soyad>
    <babaAd>MUSTAFA</babaAd>
    <anaAd>AYŞE NEVİN</anaAd>
    <dogumTarihi>11/08/1976</dogumTarihi>
    <dogumYeri>ANKARA</dogumYeri>
    <mernisGuncellemesiYapildi>false</mernisGuncellemesiYapildi>
    <isTCGecerli>true</isTCGecerli>
    <vekilTipiKod>BARO</vekilTipiKod>
    <baroNo>20112</baroNo>
    <tbbNo>78119</tbbNo>
    <bagliOlduguBaroAdi>ANKARA BAROSU</bagliOlduguBaroAdi>
    <vergiNo>14537825364</vergiNo>
    <adresList/>
    <kamuAvukatiMi>false</kamuAvukatiMi>
  </AvukatTumBilgilerDVO>
  <ResultEvent>
    <type>success</type>
    <value>10.39</value>
    <log/>
  </ResultEvent>
  <AvukatBakiyeDVO>
    <hasBarokart>false</hasBarokart>
    <sorguToplamIslemSayisi>0</sorguToplamIslemSayisi>
    <sorguKalanIslemSayisi>0</sorguKalanIslemSayisi>
    <ucretsizSorguLimit>0</ucretsizSorguLimit>
  </AvukatBakiyeDVO>
  <AvukatBakiyeDVO>
    <hasBarokart>false</hasBarokart>
    <sorguToplamIslemSayisi>0</sorguToplamIslemSayisi>
    <sorguKalanIslemSayisi>0</sorguKalanIslemSayisi>
    <ucretsizSorguLimit>0</ucretsizSorguLimit>
  </AvukatBakiyeDVO>
</object-array></root>
`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/avukat_kisisel_bilgileri_sorgula.ajx';
    }

    async parse(data: string): Promise<AvukatKisiselBilgileriSorgulamaCevap> {

        let json: any = await this.xml2json(data);
        let avukatBilgilerDvo = json.root['object-array'].AvukatTumBilgilerDVO;
        let avukatBakiyeDvo = json.root['object-array'].AvukatBakiyeDVO;
        let resultEvent = json.root['object-array'].ResultEvent;

        return {
            ad: avukatBilgilerDvo.ad,
            adresList: avukatBilgilerDvo.adresList,
            anaAd: avukatBilgilerDvo.anaAd,
            avukatId: avukatBilgilerDvo.avukatId,
            babaAd: avukatBilgilerDvo.babaAd,
            bagliOlduguBaroAdi: avukatBilgilerDvo.bagliOlduguBaroAdi,
            baroNo: avukatBilgilerDvo.baroNo,
            dogumTarihi: avukatBilgilerDvo.dogumTarihi,
            dogumYeri: avukatBilgilerDvo.dogumYeri,
            isMock: avukatBilgilerDvo.isMock == 'true',
            isTCGecerli: avukatBilgilerDvo.isTCGecerli == 'true',
            kamuAvukatiMi: avukatBilgilerDvo.kamuAvukatiMi == 'true',
            kisiKurumId: avukatBilgilerDvo.kisiKurumId,
            lisans: avukatBilgilerDvo.lisans,
            mernisGuncellemesiYapildi: avukatBilgilerDvo.mernisGuncellemesiYapildi,
            soyad: avukatBilgilerDvo.soyad,
            tbbNo: avukatBilgilerDvo.tbbNo,
            tcKimlikNo: avukatBilgilerDvo.tcKimlikNo,
            vekilTipiKod: avukatBilgilerDvo.vekilTipiKod,
            vergiNo: avukatBilgilerDvo.vergiNo,
            bakiye: resultEvent.value
        };
    }

}

export default AvukatKisiselBilgileriSorgulama;
