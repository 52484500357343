import Args from "../Args";
import {Mixins} from "../../Mixins";
import AlacakKalem from "../AlacakKalem";
import Ilamsiz from "./Ilamsiz";
import TarafKisi from "../TarafKisi";
import TarafKurum from "../TarafKurum";

export default class Senet extends Ilamsiz {
    ilamsizTipi     : string = 'senet';
    id             !: number;//;
    meblagi         : string = '';
    meblagTuru      : string = '';
    meblagTuruAciklama: string = '';
    tanzimTarihi    : string = '';
    vadeTarihi      : string = '';
    tanzimYeri      : string = '';
    aciklama       !: string;
    alacakKalemleri : AlacakKalem[] = []// alacakKalemleri;
    static fromJson(jsonData: any): Senet {
        let obj = Args.staticFromJson(jsonData,new Senet());
        obj.alacakKalemleri = jsonData.alacakKalemleri.map((x: AlacakKalem) => AlacakKalem.fromJson(x));
        return obj;
    }

    static parse(
        domNode: HTMLUnknownElement,
        args: Args,
        tarafList: Array<TarafKisi | TarafKurum>
    ): Senet {
        let senet = new Senet();
        senet.id = args.counterId();

        senet.meblagi = Mixins.replaceCommaDots(domNode.getAttribute('belgenintutari'));
        senet.meblagTuru = Mixins.nullUndefCleaner(domNode.getAttribute('tutartur'));
        senet.meblagTuruAciklama = Mixins.nullUndefCleaner(domNode.getAttribute('tutaradi'));
        senet.tanzimTarihi = Mixins.nullUndefCleaner(domNode.getAttribute('tanzimtarihi'));
        senet.vadeTarihi = Mixins.nullUndefCleaner(domNode.getAttribute('vadetarihi'));
        senet.tanzimYeri = Mixins.nullUndefCleaner(domNode.getAttribute('tanzimyeri'));

        senet.alacakKalemleri = AlacakKalem.getAlacakKalemListFromDom(domNode, args, tarafList);

        let sekizinciMadde = domNode.getAttribute("sekizinciMadde");
        if (sekizinciMadde) {
            if (sekizinciMadde.trim() != "")
                senet.aciklama = sekizinciMadde;
        }

        return senet;

    }

    toUyapJson() {
        const {
            id,
            ilamsizTipi,
            meblagi,
            meblagTuru,
            meblagTuruAciklama,
            vadeTarihi,
            tanzimTarihi,
            tanzimYeri,
            aciklama,
            alacakKalemleri,
        } = this;
        let obj: any = {
            id,
            ilamsizTipi,
            meblagi,
            meblagTuru,
            meblagTuruAciklama,
            vadeTarihi,
            tanzimTarihi,
            tanzimYeri,
            alacakKalemleri: alacakKalemleri.map(i => i.toUyapJson()),
        }
        if (aciklama != "") {
            obj["aciklama"] = aciklama;
        }
        return obj;
    }

}

