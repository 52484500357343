import {UyapRequest} from "./UyapRequest";


export interface AdliyelerTalep {
    ilKodu: string | number
}

export type AdliyelerCevap = AdliyelerRow[];

export interface AdliyelerRow {
    abonelikIcraDairesiSayisi: number,
    adliyeBirimID: string,
    adliyeIsmi: string,
    tevziBelirlemeKriteri: number,
    kayitVar: boolean
}

export default class Adliyeler extends UyapRequest<AdliyelerTalep, AdliyelerCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "adliyeler_" + this.talepData.ilKodu
        };
    }

    getMockResponse(): any {
        return [{
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005433",
            "adliyeIsmi": "Aliağa Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1006986",
            "adliyeIsmi": "Bayındır Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1001833",
            "adliyeIsmi": "Bergama Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1004998",
            "adliyeIsmi": "Çeşme Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1001841",
            "adliyeIsmi": "Dikili Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005440",
            "adliyeIsmi": "Foça Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1004955",
            "adliyeIsmi": "İzmir Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005007",
            "adliyeIsmi": "Karaburun Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005420",
            "adliyeIsmi": "Karşıyaka Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005017",
            "adliyeIsmi": "Kemalpaşa Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1001848",
            "adliyeIsmi": "Kınık Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1006999",
            "adliyeIsmi": "Kiraz Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005026",
            "adliyeIsmi": "Menderes Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005451",
            "adliyeIsmi": "Menemen Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1006973",
            "adliyeIsmi": "Ödemiş Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005035",
            "adliyeIsmi": "Seferihisar Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005042",
            "adliyeIsmi": "Selçuk Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1007009",
            "adliyeIsmi": "Tire Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005052",
            "adliyeIsmi": "Torbalı Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }, {
            "abonelikIcraDairesiSayisi": 0,
            "adliyeBirimID": "1005062",
            "adliyeIsmi": "Urla Adliye",
            "tevziBelirlemeKriteri": 0,
            "kayitVar": false
        }];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/icra_takip_adliyeler.ajx';
    }

    async parse(data: any): Promise<AdliyelerCevap> {
        //const list: AdliyelerCevap = data as AdliyelerCevap;
        return data;
    }

}
