import {UyapRequest} from "./UyapRequest";

export interface EvrakGonderTalep {
    formData: true,
    data: FormData
}

export interface EvrakGonderCevap {
    type: string,
    message: string
}

export default class DosyaAcilisEvrakGonder extends UyapRequest<EvrakGonderTalep, EvrakGonderCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return `<root><ResultEvent>
  <type>success</type>
  <message>Evraklar, tevzi dosyasına gönderildi. </message>
  <log/>
</ResultEvent></root>`
    }

    getUrl(): string {
        // @ts-ignore
        return this.ImzaClientAddr + "main/jsp/avukat/dava_acilis_evrak_gonderme_brd.ajx" + "?r=" + Math.floor(Math.random(1, 9999) * 1000000);
    }

    async parse(data: any): Promise<EvrakGonderCevap> {
        let jsonObj: any = await this.xml2json(data);
        console.log("JSONOBJ", jsonObj);
        if (jsonObj.root.ResultEvent.type == "success") {
            return Promise.resolve(jsonObj.root.ResultEvent);
        } else {
            return Promise.reject(jsonObj.root.ResultEvent);
        }
    }
}

