import {UyapRequest} from "./UyapRequest";

export interface MtsHarcListTalep {
    ilamsizList: string,//Array<any>,
    tarafList: string /*{
        kisiList: any[],
        kurumList: any[]
    }*/
}

export interface MtsHarcListCevap {
    data: any,
    toplam: number
}

export default class MtsHarcList extends UyapRequest<MtsHarcListTalep, MtsHarcListCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [
            {
                "harcmasrafKodStr": 72480,
                "harcmasrafBedelmiOranmi": "O",
                "harcMasrafOran": 2,
                "hesapMiktar": 44.40,
                "ekranID": 9,
                "pesinHarcDusulsunmu": "H",
                "harcMasrafAltSiniri": 44.4,
                "hmDurumu": 0,
                "hmAktif": false,
                "isSatirDegisecek": false,
                "harcMasrafKod": 63266,
                "harcMasrafAdi": "MTS Harcı",
                "aciklama": "Mts Harcı",
                "baslangicHarci": "E",
                "harcMasraf": "H",
                "harcTip": 34,
                "gv_tabi": 0,
                "dv_tabi": 0,
                "yg_tabi": 0,
                "makbuzTipi": 0
            },
            {
                "harcmasrafKodStr": 71965,
                "harcmasrafBedelmiOranmi": "B",
                "harcMasrafBedel": 6.4,
                "hesapMiktar": 6.4,
                "ekranID": 9,
                "pesinHarcDusulsunmu": "H",
                "hmDurumu": 0,
                "hmAktif": false,
                "isSatirDegisecek": false,
                "harcMasrafKod": 12809,
                "harcMasrafAdi": "Vekalet Suret Harcı",
                "aciklama": "Vekaletname Suret Harcıdır",
                "baslangicHarci": "E",
                "harcMasraf": "H",
                "harcTip": 14,
                "gv_tabi": 0,
                "dv_tabi": 0,
                "yg_tabi": 0,
                "makbuzTipi": 1
            }];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/mts_harc_list_brd.ajx';
    }

    parse(data: any): Promise<MtsHarcListCevap> {
        return new Promise<MtsHarcListCevap>((resolve, reject) => {
            try {
                if (typeof data.error === undefined) {
                    return reject(data.error);
                }
                let toplam = 0;
                data.forEach((val: any) => {
                    toplam += val.hesapMiktar;
                })
                return resolve({data: data, toplam: toplam});
            } catch (e) {
                return reject(e);
            }
        });
    }

}

