import {UyapRequest} from "./UyapRequest";

export interface GetPortalAvukatTalepAdresTuruListTalep {
    // Boş
}

export interface GetPortalAvukatTalepAdresTuruListCevap {
    items: Array<GetPortalAvukatTalepAdresTuruListCevapRow>
}

export interface GetPortalAvukatTalepAdresTuruListCevapRow {
    name: string,
    value: number
}

export default class GetPortalAvukatTalepAdresTuruList extends UyapRequest<GetPortalAvukatTalepAdresTuruListTalep, GetPortalAvukatTalepAdresTuruListCevap> {
    delayTime = 0;

    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "GetPortalAvukatTalepAdresTuruList_"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/getPortalAvukatTalepAdresTuruList_brd.ajx";
    }

    async parse(data: any): Promise<GetPortalAvukatTalepAdresTuruListCevap> {
        return {items: data};
    }

    getMockResponse(): any {
        return [{"name": "E-Tebligat Adresi", "value": 0}, {
            "name": "Mernis Adresi",
            "value": 1
        }, {"name": "Mersis Adresi", "value": 2}, {"name": "Yeni Adres", "value": 3}];
    }
}