import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import app from "@/main";
import JSZip from 'jszip';
import {IndirilecekEvrakTuru, IndirilecekEvrakTuruLabel} from "../../../enum/IndirilecekEvrakTuru";
import {DosyaEvraklarCevap} from "../../../uyap/DosyaEvraklar";
import {TaskDurum} from "../../../enum/TaskDurum";

export class EvrakIndirTaskHandler extends AbstractTaskHandler {
    zip : JSZip = new JSZip();
    async handle(task: ITask): Promise<ITask> {
        try {
            let dosya = await app.$uyap.DosyaBul().run(task);
            task.dosya_id = dosya.dosyaId;
            let evraklar: DosyaEvraklarCevap = await app.$uyap.DosyaEvraklar().run({dosyaId: task.dosya_id.toString()});
            for (let tur of task.data.turler) {
                switch (tur) {
                    case IndirilecekEvrakTuru.dosyaya_eklenen_son_evrak : {
                        let path = 'Dosyaya eklenen son ' + task.data.sonEvrakSayisi + ' evrak/';
                        let indirilecekEvraklar = evraklar.items.sort((a, b) => (new Date(b.sistemeGonderildigiTarih).getTime() - new Date(a.sistemeGonderildigiTarih).getTime())).slice(0, Number(task.data.sonEvrakSayisi));
                        for (const indirilecekEvrak of indirilecekEvraklar) {
                            await this.setEvrakToZip(path,task.dosya_id,indirilecekEvrak.evrakId);
                        }
                        break;
                    }
                    case IndirilecekEvrakTuru.tahsil_harci_makbuzu_sayman_mutemet_alindisi : {
                        let path = IndirilecekEvrakTuruLabel[IndirilecekEvrakTuru.tahsil_harci_makbuzu_sayman_mutemet_alindisi] + '/';
                        let indirilecekEvraklar = evraklar.items.filter(item => (item.turu == IndirilecekEvrakTuruLabel[IndirilecekEvrakTuru.tahsil_harci_makbuzu_sayman_mutemet_alindisi]));
                        for (const indirilecekEvrak of indirilecekEvraklar) {
                            await this.setEvrakToZip(path,task.dosya_id,indirilecekEvrak.evrakId);
                        }
                        break;
                    }
                    case IndirilecekEvrakTuru.takip_talebi : {
                        let path = IndirilecekEvrakTuruLabel[IndirilecekEvrakTuru.takip_talebi] + '/';
                        let indirilecekEvraklar = evraklar.items.filter(item => (item.turu == IndirilecekEvrakTuruLabel[IndirilecekEvrakTuru.takip_talebi]));
                        for (const indirilecekEvrak of indirilecekEvraklar) {
                            await this.setEvrakToZip(path,task.dosya_id,indirilecekEvrak.evrakId);
                        }
                        break;
                    }
                    case IndirilecekEvrakTuru.teblig_mazbatasi : {
                        let path = IndirilecekEvrakTuruLabel[IndirilecekEvrakTuru.teblig_mazbatasi] + '/';
                        let indirilecekEvraklar = evraklar.items.filter(item => (item.turu == IndirilecekEvrakTuruLabel[IndirilecekEvrakTuru.teblig_mazbatasi]));
                        for (const indirilecekEvrak of indirilecekEvraklar) {
                            await this.setEvrakToZip(path,task.dosya_id,indirilecekEvrak.evrakId);
                        }
                        break;
                    }
                    case IndirilecekEvrakTuru.tensip_zapti : {
                        let path = IndirilecekEvrakTuruLabel[IndirilecekEvrakTuru.tensip_zapti] + '/';
                        let indirilecekEvraklar = evraklar.items.filter(item => (item.turu == IndirilecekEvrakTuruLabel[IndirilecekEvrakTuru.tensip_zapti]));
                        for (const indirilecekEvrak of indirilecekEvraklar) {
                            await this.setEvrakToZip(path,task.dosya_id,indirilecekEvrak.evrakId);
                        }
                        break;
                    }
                }
            }
            let zipBlob = await this.zip.generateAsync({type: "blob"});
            let formData = new FormData();
            let name = task.birim_adi + ' ' + task.dosya_esas_no.replaceAll('/', '_') + ' Evrakları_' + new Date().getTime() + '.zip';
            formData.append('name', name);
            formData.append('file', zipBlob);
            formData.append('task_id', (task.id as number).toString());
            await app.$http.post('/api/v1/task/file', formData);
            task.status = TaskDurum.BITTI_BASARILI
            task.response = [task.dosya_esas_no, task.birim_adi, name];
            return task;
        } catch (e: any) {
            throw new Error(e.message);
        }
    }

   async setEvrakToZip(path:string , dosyaId : any ,evrakId:any){
        try{
            let evrakBlob = await app.$uyap.EvrakIndir().run({
                dosyaId: dosyaId,
                evrakId: evrakId,
                yargiTuru: 1
            });
            //@ts-ignore
            this.zip.file(path + evrakBlob.name, evrakBlob);
        }catch (e) {
            console.log("setEvrakToZip Error ? ",e);
        }
    }
}