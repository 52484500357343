import {UyapRequest} from "./UyapRequest";
import {MDB} from "../MDB";
import app from "@/main";

export interface AvukatSorgulamaTalep {
    tcKimlikNo: string,
    baroNo: string,
    tbbNo: string,
    ilKodu: string,
    baroKodu: string,
}

export interface AvukatSorgulamaCevap {
    ad: string,
    soyad: string,
    avukatId: number,
    tcKimlikNo: number,
    isKamuAvukati: boolean,
    durum: number
}

export default class AvukatSorgulama extends UyapRequest<AvukatSorgulamaTalep, AvukatSorgulamaCevap> {
    delayTime = 0;

    mockResponse: string = `<root><AvukatBilgileriDVO>
  <ad>F____</ad>
  <soyad>K____</soyad>
  <avukatId>1111111</avukatId>
  <tcKimlikNo>111111021</tcKimlikNo>
  <isKamuAvukati>false</isKamuAvukati>
  <durum>0</durum>
</AvukatBilgileriDVO></root>`;

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "avukatsorgulama_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    async parse(data: string): Promise<AvukatSorgulamaCevap> {
        let json: any = await this.xml2json(data)
        return json.root.AvukatBilgileriDVO;
    }

    async recursive(data: AvukatSorgulamaTalep): Promise<AvukatSorgulamaCevap> {
        return new Promise(async (resolve, reject) => {
            this.delayTime = 3000;
            data.tcKimlikNo = data.tcKimlikNo.toString().trim();
            console.log("AvukatSorgulamaTalep DATA ==", data)
            let rejectMessage = {"message": "Vekil avukat bulunamadı"};
            if (app.$uyapHelper.tcKimlikNoValidate(data.tcKimlikNo)) {
                console.log("1");
                try {
                    let avukat = await this.run({
                        tcKimlikNo: data.tcKimlikNo,
                        baroNo: "",
                        tbbNo: "",
                        ilKodu: "",
                        baroKodu: ""
                    })
                    return resolve(avukat);
                } catch (e) {
                    //return reject(e);
                }
            }
            if (data.tbbNo != "") {
                console.log("2");
                try {
                    let avukat = await this.run({
                        tcKimlikNo: "",
                        baroNo: "",
                        tbbNo: data.tbbNo,
                        ilKodu: "",
                        baroKodu: ""
                    })
                    return resolve(avukat);
                } catch (e) {
                    //return reject(e);
                }
            }
            if (data.baroNo != "" && data.ilKodu != "") {
                console.log("3");
                let barolar = MDB.baroKodlari.filter((i) => i.ilKodu == data.ilKodu);
                let avukat: AvukatSorgulamaCevap;
                for (const baro of barolar) {
                    try {
                        let requestPayload = {
                            tcKimlikNo: "",
                            baroNo: data.baroNo,
                            tbbNo: "",
                            baroKodu: baro.baroKodu,
                            ilKodu: data.ilKodu
                        }
                        console.log("requestPayload for recursive barono + ilkodu",requestPayload);
                        console.log("requestPayload for recursive data",data);
                        avukat = await this.run(requestPayload)
                        if (avukat.ad && avukat.ad != "")
                            return resolve(avukat);
                    } catch (e) {
                        console.log("CATCH ==== BARKO KODI", e);
                        // eğer patladığında devam etmesini istiyorsan alttakini dene @abdullah
                        // continue;
                    }
                }
                //return reject(rejectMessage);
            }
            return reject(rejectMessage);
            //EUP - 403
        })

    }

    getMockResponse(): any {
        return this.mockResponse;
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/avukat_sorgulama.ajx";
    }

    // parse(data: string): Promise<AvukatSorgulamaCevap> {
    //     throw new DOMException("Not Implemented");
    // }
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

}


