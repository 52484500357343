import Args from "../Args";
import {Mixins} from "../../Mixins";
import AlacakKalem from "../AlacakKalem";
import Taraf from "../Taraf";
import Ilamsiz from "./Ilamsiz";

class KontratTasinmazAdres {
    adresTuru:string = ""
    ilKodu:string = ""
    ilceKodu:string = ""
    adres:string = ""
    postaKodu:string = ""
}

export default class Kontrat extends Ilamsiz{
    ilamsizTipi  :string = 'kontrat';
    id           ?:number;//;
    sozlesmeBilgisi      :string = '';
    meblagi      :string = '';
    meblagTuru   :string = '';
    meblagTuruAciklama:string = '';
    aciklama:string = '';
    baslangicTarihi:string = '';
    bitisTarihi:string = '';
    hazirlanisTarihi:string = '';
    tasinmazAdresBilgileri:KontratTasinmazAdres = new KontratTasinmazAdres();
    tasinmazAdresiDetay !: string;
    alacakKalemleri:AlacakKalem[] = [];// alacakKalemleri;

    static fromJson(jsonData:any):Kontrat{
        let obj = Args.staticFromJson(jsonData,new Kontrat());
        obj.alacakKalemleri = jsonData.alacakKalemleri.map((x:AlacakKalem)=> AlacakKalem.fromJson(x));
        obj.tasinmazAdresBilgileri = Args.staticFromJson(obj.tasinmazAdresBilgileri,new KontratTasinmazAdres());
        return obj;
    }
    static parse(
        kontratKefilNode:HTMLUnknownElement,
        args:Args,
        tarafList:Taraf[]
    ):Kontrat{
        let kontrat = new Kontrat();
        let adresNode = kontratKefilNode.querySelector("adres");
        if (adresNode) {
            kontrat.tasinmazAdresBilgileri.adresTuru    = Mixins.nullUndefCleaner(adresNode.getAttribute("adresturu"));
            kontrat.tasinmazAdresBilgileri.ilKodu       = Mixins.nullUndefCleaner(adresNode.getAttribute("ilkodu"));
            kontrat.tasinmazAdresBilgileri.ilceKodu     = Mixins.nullUndefCleaner(adresNode.getAttribute("ilcekodu"));
            kontrat.tasinmazAdresBilgileri.adres        = Mixins.nullUndefCleaner(adresNode.getAttribute("adres"));
            kontrat.tasinmazAdresBilgileri.postaKodu    = Mixins.nullUndefCleaner(adresNode.getAttribute("postakodu"));
            kontrat.tasinmazAdresiDetay                 = Mixins.nullUndefCleaner(adresNode.getAttribute("adres"));
        }
        if(kontratKefilNode){
            let aciklama = "";
            let kontratNode = kontratKefilNode.querySelector("kontrat");
            //adres yok burda patlayabilir yeni sistem adres zorunlu ...
            if(kontratNode){
                let sekizinciMadde = Mixins.nullUndefCleaner(kontratNode.getAttribute("sekizinciMadde"));
                if (sekizinciMadde != "")
                    aciklama = sekizinciMadde;
                kontrat.id = args.counterId();
                kontrat.sozlesmeBilgisi =  Mixins.nullUndefCleaner(kontratNode.getAttribute("sozlesmesozlubelgeli"));
                kontrat.meblagi = Mixins.replaceCommaDots(kontratNode.getAttribute('belgenintutari'));
                kontrat.meblagTuru = Mixins.nullUndefCleaner(kontratNode.getAttribute('tutartur'));
                kontrat.meblagTuruAciklama = Mixins.nullUndefCleaner(kontratNode.getAttribute('tutarturaciklama'));
                kontrat.aciklama        =  aciklama!="" ? aciklama : Mixins.nullUndefCleaner(kontratNode.getAttribute("aciklama"));
                kontrat.baslangicTarihi =  Mixins.nullUndefCleaner(kontratNode.getAttribute("gecerlilikbaslangictarihi"));
                kontrat.bitisTarihi =  Mixins.nullUndefCleaner(kontratNode.getAttribute("gecerliliksonlanmatarihi"));
                kontrat.hazirlanisTarihi =  Mixins.nullUndefCleaner(kontratNode.getAttribute("hazirlanistarihi"));
                //@ts-ignore
                kontrat.alacakKalemleri = AlacakKalem.getAlacakKalemListFromDom(kontratNode,args,tarafList);
            }
        }
        return kontrat;
    }
    toUyapJson(){
        const {
            id,
            ilamsizTipi,
            sozlesmeBilgisi,
            meblagi,
            meblagTuru,
            meblagTuruAciklama,
            aciklama,
            baslangicTarihi,
            bitisTarihi,
            hazirlanisTarihi,
            tasinmazAdresBilgileri,
            tasinmazAdresiDetay,
            alacakKalemleri,
        } = this;
        return {
            id,
            ilamsizTipi,
            sozlesmeBilgisi,
            meblagi,
            meblagTuru,
            meblagTuruAciklama,
            aciklama,
            baslangicTarihi,
            bitisTarihi,
            hazirlanisTarihi,
            tasinmazAdresBilgileri,
            tasinmazAdresiDetay,
            alacakKalemleri: alacakKalemleri.map(i=>i.toUyapJson()),
        }
    }

}

