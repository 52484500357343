import {UyapRequest} from "./UyapRequest";

export interface TahsilHarcOranlariniGetirTalep {
}

export interface TahsilHarcOranlariniGetirCevap {
    items: Array<TahsilHarcOranlariniGetirRow>
}

export interface TahsilHarcOranlariniGetirRow {
    aciklama: string,
    oran: string,
    kayitId: string
}

export class TahsilHarcOranlariniGetir extends UyapRequest<TahsilHarcOranlariniGetirTalep, TahsilHarcOranlariniGetirCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "TahsilHarcOranlariniGetir"
        };
    }

    getMockResponse(): string {
        return `<root><list>
    <TahsilHarcDVO>
      <aciklama>(%0) Harçtan Muaf</aciklama>
      <oran>0</oran>
      <kayitId>1024</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%0.56) Tahsil Harci 492 S.K.nun 1.S.T.B.I.3/h bendi</aciklama>
      <oran>0.56</oran>
      <kayitId>409606</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%1.13) Konkordatoda Alacaklilara Verilmesi Kararlastirilan Para Üzerinden</aciklama>
      <oran>1.13</oran>
      <kayitId>5650</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%1.13) Tahsil Harcı 492 S.K.nun 1.S.T.B.I.3/h bendi</aciklama>
      <oran>1.1375</oran>
      <kayitId>409604</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%2.27) Hacizden Önce Haricen Tahsilde</aciklama>
      <oran>2.27</oran>
      <kayitId>5646</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%2.27) Takip Talebi Bulunmayan Alacaklılara İİK. 125/3 Maddesi Gereğince Ödenen Paralardan</aciklama>
      <oran>2.27</oran>
      <kayitId>5648</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%2,27) Tahsil Harcı 492 S.K.nun 1.S.T.B.I.3/h bendi</aciklama>
      <oran>2.275</oran>
      <kayitId>409605</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%2.84) Tahsil Harcı 492 S.K.nun 1.S.T.B.I.3/h bendi</aciklama>
      <oran>2.845</oran>
      <kayitId>409607</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%4.55) Hacizden Sonra Satıştan Önce Haricen Tahsilde</aciklama>
      <oran>4.55</oran>
      <kayitId>773</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%4.55) İflasta Paylaştırılan Para Üzerinden</aciklama>
      <oran>4.55</oran>
      <kayitId>5649</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%4.55) Ödeme veya İcra Emrinin Tebliğinden Sonra, Hacizden Önce</aciklama>
      <oran>4.55</oran>
      <kayitId>21</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%4.55) Maaş Ve Ücretlerden</aciklama>
      <oran>4.55</oran>
      <kayitId>5647</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%9.10) Hacizden Sonra, Satıştan Önce</aciklama>
      <oran>9.1</oran>
      <kayitId>23</kayitId>
    </TahsilHarcDVO>
    <TahsilHarcDVO>
      <aciklama>(%11.38) Hacizli veya Rehinli Malın Satışından Sonra</aciklama>
      <oran>11.38</oran>
      <kayitId>22</kayitId>
    </TahsilHarcDVO>
    </list></root>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/tahsil_harc_oranlarini_getir.ajx';
    }

    async parse(data: string): Promise<TahsilHarcOranlariniGetirCevap> {
        let json = await this.xml2json(data);
        let jsonData: Array<TahsilHarcOranlariniGetirRow> = [];
        json.root['list'].TahsilHarcDVO.forEach((tahsilHarcOrani: any) => {
            jsonData.push({
                aciklama: tahsilHarcOrani.aciklama,
                oran: tahsilHarcOrani.oran,
                kayitId: tahsilHarcOrani.kayitId
            })
        })
        return {items: jsonData};
    }

}

export default TahsilHarcOranlariniGetir;
