import MtsDosyaBilgileri from "./parser/Mts/MtsDosyaBilgileri";
import TakipInjectable from "./parser/TakipInjectable";
import {Mixins} from "./Mixins";
import Args from "./parser/Args";
import TarafKisi from "./parser/TarafKisi";
import TarafKurum from "./parser/TarafKurum";
import Taraf from "./parser/Taraf";
import MtsDiger from "./parser/Mts/MtsDiger";
import MtsIlamsizParser from "./parser/Mts/MtsIlamsizParser";
import {MtsDosyaFormData} from "./MtsDosyaFormData";
import app from "@/main";
import Crypto from "crypto";

export interface MtsBelge {
    id: any,
    faturaId: any,
    acikTutar: any,
    odemeTarihi: string,
    faturaTarihi: string,
    tutarAdi: string,
}

export class MtsXmlParser extends TakipInjectable {
    dosyaNode !: HTMLUnknownElement;
    formData!: MtsDosyaFormData;
    args: Args = new Args();
    mtsDosyaBilgileri!: MtsDosyaBilgileri;
    tarafList: Array<TarafKisi | TarafKurum> = [];
    belgeList: MtsBelge[] = [];
    ilamsizList: MtsDiger[] = [];
    selectedTarafHashKeyList: string[] = []
    toplamTakibeEsasMiktar: number = 0;
    hash: string = '';
    hashObj: any;

    static fromJson(jsonData: any): MtsXmlParser {
        let obj = Args.staticFromJson(jsonData,new MtsXmlParser());
        obj.mtsDosyaBilgileri = MtsDosyaBilgileri.fromJson(jsonData.mtsDosyaBilgileri);
        obj.tarafList = jsonData.tarafList.map((taraf: any) => {
            return Taraf.fromJson(taraf, true);
        });
        obj.ilamsizList = jsonData.ilamsizList.map((diger: any) => {
            return MtsDiger.fromJson(diger);
        })
        return obj;
    }

    static async parse(dosyaNode: HTMLUnknownElement, formData: MtsDosyaFormData): Promise<MtsXmlParser> {
        try {
            let parser = new MtsXmlParser();
            parser.dosyaNode = dosyaNode;
            parser.formData = formData;

            //MDB.adliyeler
            //parser.formData.birimAd = formData.adliyeler.find(adliye=>adliye.adliyeBirimID == formData.adliye).adliyeIsmi;

            parser.setEvraklar(parser.dosyaNode);
            parser.parseAndSetBelgeler();
            await parser.setDayanakFromAlacakBelgePath();

            parser.mtsDosyaBilgileri = MtsDosyaBilgileri.parse(parser.dosyaNode, parser.formData);
            parser.args.icraproXmliMi = parser.mtsDosyaBilgileri.isIcraProXml();
            parser.setTaraflar();
            parser.ilamsizList = MtsIlamsizParser.parse(
                parser.dosyaNode.querySelectorAll('digerAlacak'),
                parser.belgeList,
                parser.tarafList,
                parser.args
            );
            parser.takibeEsasMiktarTopla();
            //this.setHashData(true , this.mtsDosyaBilgileri.dosyaBelirleyici.id);
            parser.generateXmlHash();
            return Promise.resolve(parser);
        } catch (e) {
            return Promise.reject(e);
        }

    }

    setTaraflar() {
        this.dosyaNode.querySelectorAll('taraf').forEach((node) => {
            let taraf = Taraf.parse(node, [], this.formData, true);
            this.tarafList.push(taraf)
        });
    }

    parseAndSetBelgeler() {
        // <belge id="fatura_191000206" faturaId="20210401740523" acikTutar="107.9" odemeTarihi="20/05/2021" faturaTarihi="30/04/2021" tutarAdi="TL - Türk Lirası" aciklama=" " />
        let belgeler = this.dosyaNode.querySelectorAll('belgeler belge');
        belgeler.forEach(node => {
            this.belgeList.push({
                id: Mixins.onlyDigit(node.getAttribute("id")),
                faturaId: node.getAttribute("faturaid"),
                acikTutar: Mixins.nullUndefCleaner(node.getAttribute("aciktutar")),
                odemeTarihi: Mixins.tarihConverter(node.getAttribute("odemetarihi")),
                faturaTarihi: Mixins.tarihConverter(node.getAttribute("faturatarihi")),
                tutarAdi: Mixins.nullUndefCleaner(node.getAttribute("tutaradi"))
            });
        })
    }

    async setDayanakFromAlacakBelgePath(): Promise<void> {
        try {
            let alacakBelgePath = Mixins.nullUndefCleaner(this.dosyaNode.getAttribute("alacakbelge"));
            // alacakBelge="C:\Faturalar\MTS_CASE_OPENING_202201181077234757..pdf"
            console.log("alacakBelgePath", alacakBelgePath);
            console.log("dosyaNode", this.dosyaNode);
            if (alacakBelgePath != "") {
                let belge = await app.$imza.getFileFromLocalPath(alacakBelgePath);
                console.log("BELGEEEE ------", belge);
                let splited = alacakBelgePath.replace(/\\/g, "/").split("/");
                let fileName = splited[splited.length - 1];
                let evrak = {
                    data: belge.file,
                    mimeType: Mixins.findFileMimeType(fileName),
                    name: fileName
                };
                console.log("alacakBelge To Dayanak", evrak);
                this.dayanaklar.push(evrak);
            }
        } catch (e) {
            console.log("AlacakBelge Eklenemedi ", e);
        }
    }

    generateXmlHash() {
        let hashObj: any = {
            taraflar: [],
            takibeEsasMiktar: this.toplamTakibeEsasMiktar,
        };
        this.tarafList.forEach(taraf => {
            console.log("TARAF", taraf);
            if (taraf.isKisi()) {
                hashObj.taraflar.push({
                    ad: taraf.temelBilgileri.adi,
                    soyad: taraf.temelBilgileri.soyadi,
                    tcKimlikNo: taraf.temelBilgileri.tcKimlikNo,
                    rolId: taraf.tarafSifati.rolID
                })
            }
            if (taraf.isKurum()) {
                hashObj.taraflar.push({
                    kurumadi: taraf.temelBilgileri.kurumAdi,
                    vergino: taraf.temelBilgileri.vergiNo,
                    mersisno: taraf.temelBilgileri.mersisNo,
                    rolId: taraf.tarafSifati.rolID
                })
            }
        })
        this.hashObj = hashObj;
        this.hash = Crypto.createHash('sha1').update(JSON.stringify(hashObj)).digest().toString('hex');
    }

    takibeEsasMiktarTopla() {
        this.toplamTakibeEsasMiktar = 0;
        this.ilamsizList.forEach(ilamsiz => {
            let deleteKeyList: any = [];
            ilamsiz.alacakKalemleri.forEach((alacakKalemi, key) => {
                if (alacakKalemi === null) {
                    //tutar 0  esit veya kucukse null geliyor
                    deleteKeyList.push(key);
                } else {
                    this.toplamTakibeEsasMiktar += parseFloat(alacakKalemi.temelBilgiler.tutarTL)
                }
            });
            deleteKeyList.forEach((index: any) => {
                ilamsiz.alacakKalemleri.splice(index, 1);
            })
        })
    }

}