import {UyapRequest} from "./UyapRequest";

export interface AvukatTalepListTalep {
    dosyaId: number,
    islemTuru: 'haciz' | 'genel' | 'tebligat'
}

export interface AvukatTalepListCevap {
    items: Array<AvukatTalepListCevapRow>
}

export interface AvukatTalepListCevapRow {
    grupKodu: number,
    talepKodu: number,
    talepAdi: string,
    talepKisaAdi: string,
    talepMasrafi: number,
    className: string,
    postaMasrafId: number,
    dosyaDurum: string
}

export default class AvukatTalepList extends UyapRequest<AvukatTalepListTalep, AvukatTalepListCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/getPortalAvukatTalepList.ajx";
    }

    async parse(data: Array<AvukatTalepListCevapRow>): Promise<AvukatTalepListCevap> {
        return {items: data};
    }

    getMockResponse(): any {
        if (this.talepData.islemTuru == 'haciz')
            return [
                {
                    "grupKodu": 0,
                    "talepKodu": 7,
                    "talepAdi": "Alacaklı Olduğu Dosya",
                    "talepKisaAdi": "Alacaklı Olduğu Dosya",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepAlacakliDosyaDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 0,
                    "talepAdi": "Araç Haczi Talebi",
                    "talepKisaAdi": "Araç Haczi Talebi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepAracHacziDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 4,
                    "talepAdi": "Banka Haczi Talebi",
                    "talepKisaAdi": "Banka Haczi Talebi",
                    "talepMasrafi": 13.75,
                    "className": "AvukatTalepBankaHacziDVO",
                    "postaMasrafId": 3585,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 2,
                    "talepAdi": "Gayrimenkul Haczi Talebi",
                    "talepKisaAdi": "Gayrimenkul Haczi Talebi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGayrimenkulHacziDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 1,
                    "talepAdi": "Maaş Haczi Talebi",
                    "talepKisaAdi": "Maaş Haczi Talebi",
                    "talepMasrafi": 86,
                    "className": "AvukatTalepMaasHacziDVO",
                    "postaMasrafId": 6144,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 3,
                    "talepAdi": "Posta Çeki Haczi Talebi",
                    "talepKisaAdi": "Posta Çeki Haczi Talebi",
                    "talepMasrafi": 1.25,
                    "className": "AvukatTalepPostaCekiDVO",
                    "postaMasrafId": 4864,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 6,
                    "talepAdi": "SGK Mosip Haczi Talebi",
                    "talepKisaAdi": "SGK Mosip Haczi Talebi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepSgkMossipDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 23,
                    "talepAdi": "Borçlunun Taşınır Mallarının Haczi için Adresinde Haciz Yapılması, Kapalı Yerlerin Çilingir ile Açılması, haczedilen Malların Muhafaza Altına Alınması",
                    "talepKisaAdi": "Taşınır Haciz Talebi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelTasinirHacziCilingirDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 24,
                    "talepAdi": "Borçlunun Taşınır Mallarının Haczedilmesi ve Muhafaza Altına Alınması için Talimat Yazılması",
                    "talepKisaAdi": "Taşınır Haciz Talimatı Talebi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelHacizTalimatDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 5,
                    "talepAdi": "89-1 İhbarname Talebi",
                    "talepKisaAdi": "89-1 İhbarname Talebi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalep89_1DVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 43,
                    "talepAdi": "89-2 İhbarname Talebi",
                    "talepKisaAdi": "89-2 İhbarname Talebi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalep89_2DVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                },
                {
                    "grupKodu": 0,
                    "talepKodu": 44,
                    "talepAdi": "89-3 İhbarname Talebi",
                    "talepKisaAdi": "89-3 İhbarname Talebi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalep89_3DVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }
            ];
        else if (this.talepData.islemTuru == 'tebligat')
            return [
                {
                    "grupKodu": 1,
                    "talepKodu": 11,
                    "talepAdi": "Bakiye Borç Muhtırasınin Tebliğe Çıkartılması",
                    "talepKisaAdi": "Bakiye Borç Muhtırasınin Tebliğe Çıkartılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepTebligatDosyaHesabiDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 1,
                    "talepKodu": 8,
                    "talepAdi": "İcra/Ödeme Emrinin Tebliğe Çıkartılması",
                    "talepKisaAdi": "İcra/Ödeme Emrinin Tebliğe Çıkartılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepTebligatOdemeEmriDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 1,
                    "talepKodu": 9,
                    "talepAdi": "Kıymet Takdirinin Tebliğe Çıkartılması",
                    "talepKisaAdi": "Kıymet Takdirinin Tebliğe Çıkartılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepTebligatKiymetTakdiriDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 1,
                    "talepKodu": 40,
                    "talepAdi": "Taahhudu Kabul Muhtırası Tebliği",
                    "talepKisaAdi": "Taahhudu Kabul Muhtırası Tebliği",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepTebligatTaahhuduKabulMuhtirasiDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 1,
                    "talepKodu": 41,
                    "talepAdi": "Temlik Bilgisinin Bildirilmesi",
                    "talepKisaAdi": "Temlik Bilgisinin Bildirilmesi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepTebligatTemlikBilgisiDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 1,
                    "talepKodu": 12,
                    "talepAdi": "Yenileme Emrinin Tebliğe Çıkartılması",
                    "talepKisaAdi": "Yenileme Emrinin Tebliğe Çıkartılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepTebligatDosyaYenilenmesiDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 1,
                    "talepKodu": 10,
                    "talepAdi": "103 Davetiyesinin Tebliğe Çıkartılması",
                    "talepKisaAdi": "103 Davetiyesinin Tebliğe Çıkartılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepTebligat103DavetiyeDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }];
        else if (this.talepData.islemTuru == 'genel')
            return [
                {
                    "grupKodu": 2,
                    "talepKodu": 32,
                    "talepAdi": "Aciz Belgesi Hazırlanması",
                    "talepKisaAdi": "Aciz Belgesi Hazırlanması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelAcizBelgesiDvo",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 17,
                    "talepAdi": "Araç Üzerindeki Haciz Şerhinin Kaldırılması",
                    "talepKisaAdi": "Araç Üzerindeki Haciz Şerhinin Kaldırılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelAracHacziKaldirDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 16,
                    "talepAdi": "Araç Üzerindeki Yakalama Şerhinin Kaldırılması",
                    "talepKisaAdi": "Araç Üzerindeki Yakalama Şerhinin Kaldırılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelAracYakalamaKaldirDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 42,
                    "talepAdi": "Bankadan Hacizli Paranın İstenilmesi",
                    "talepKisaAdi": "Bankadan Hacizli Paranın İstenilmesi",
                    "talepMasrafi": 9.25,
                    "className": "AvukatTalepBankadanHacizliParaIsteDVO",
                    "postaMasrafId": 3585,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 14,
                    "talepAdi": "Borçlu Tarafından Yapılan Ödemelerin Hesaba Aktarılması",
                    "talepKisaAdi": "Borçlu Tarafından Yapılan Ödemelerin Hesaba Aktarılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelOdemeAktarDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 13,
                    "talepAdi": "Dosya Hesabının Yapılması ve Dosyaya Kaydedilmesi",
                    "talepKisaAdi": "Dosya Hesabının Yapılması ve Dosyaya Kaydedilmesi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelDosyaHesabiDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 27,
                    "talepAdi": "Dosyada Hacizli Malın Satışı",
                    "talepKisaAdi": "Dosyada Hacizli Malın Satışı",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelSatisTalebiDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 37,
                    "talepAdi": "Dosyadaki Avansın İadesi",
                    "talepKisaAdi": "Dosyadaki Avansın İadesi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepDosyaAvansIadesiDvo",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 21,
                    "talepAdi": "Dosyadaki Hacizlerin Kaldırılması",
                    "talepKisaAdi": "Dosyadaki Hacizlerin Kaldırılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelTumHacziKaldirDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 15,
                    "talepAdi": "Dosyadaki IBAN Bilgisinin Güncellenmesi",
                    "talepKisaAdi": "Dosyadaki IBAN Bilgisinin Güncellenmesi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelIbanGuncelleDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 26,
                    "talepAdi": "Dosyanın İşlemden Kaldırılması",
                    "talepKisaAdi": "Dosyanın İşlemden Kaldırılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelDosyaKapatDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 25,
                    "talepAdi": "Dosyanın Yetkili İcra Dairesine Gönderilmesi",
                    "talepKisaAdi": "Dosyanın Yetkili İcra Dairesine Gönderilmesi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelYetkiliIcraDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 30,
                    "talepAdi": "Hacizli Malın Kıymet Takdiri için Talimat Yazılması",
                    "talepKisaAdi": "Hacizli Malın Kıymet Takdiri için Talimat Yazılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelKiymetTakdiricinTalimatDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 29,
                    "talepAdi": "Hacizli Malın Satışı için Talimat Yazılması",
                    "talepKisaAdi": "Hacizli Malın Satışı için Talimat Yazılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelSatisicinTalimatDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 20,
                    "talepAdi": "Hacizli Taşınır Malların Muhafaza Altına Alınması",
                    "talepKisaAdi": "Hacizli Taşınır Malların Muhafaza Altına Alınması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelTasinirMuhafazaDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 39,
                    "talepAdi": "Haricen Tahsilat Bildirimi",
                    "talepKisaAdi": "Haricen Tahsilat Bildirimi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepHaricenTahsilatDvo",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 34,
                    "talepAdi": "İİK.121. Maddeye Göre Yetki Verilmesi",
                    "talepKisaAdi": "İİK.121. Maddeye Göre Yetki Verilmesi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelIIk121YetkiVerDvo",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 33,
                    "talepAdi": "İİK.150/C Şerhi Eklenmesi",
                    "talepKisaAdi": "İİK.150/C Şerhi Eklenmesi",
                    "talepMasrafi": 0.75,
                    "className": "AvukatTalepGenelIIk150CSerhiEkleDvo",
                    "postaMasrafId": 4864,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 28,
                    "talepAdi": "Kıymet Takdirinin Yapılması",
                    "talepKisaAdi": "Kıymet Takdirinin Yapılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelKiymetTakdiriDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 18,
                    "talepAdi": "Maaş Üzerindeki Hacizlerin Kaldırılması",
                    "talepKisaAdi": "Maaş Üzerindeki Hacizlerin Kaldırılması",
                    "talepMasrafi": 31,
                    "className": "AvukatTalepGenelMaasHacziKaldirDVO",
                    "postaMasrafId": 1536,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 22,
                    "talepAdi": "Muhafaza Altındaki Malların Haciz Baki Kalarak Yeddiemin Değişikliği",
                    "talepKisaAdi": "Muhafaza Altındaki Malların Haciz Baki Kalarak Yeddiemin Değişikliği",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelYeddiEminDegisikligiDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 35,
                    "talepAdi": "Rehin Açığı Belgesi Hazırlanması",
                    "talepKisaAdi": "Rehin Açığı Belgesi Hazırlanması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelRehinAcigiBelgesiDvo",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 46,
                    "talepAdi": "Rehinin Paraya Çevrilmesi Şerhi Talebi",
                    "talepKisaAdi": "Rehinin Paraya Çevrilmesi Şerhi Talebi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelRehninParayaCevrilmesiSerhiDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 31,
                    "talepAdi": "Takibin Kesinleşmesi",
                    "talepKisaAdi": "Takibin Kesinleşmesi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelTakibiKesinlestirDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 19,
                    "talepAdi": "Taşınmaz Haczinin Kaldırılması",
                    "talepKisaAdi": "Taşınmaz Haczinin Kaldırılması",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenelTasinmazHacziKaldirDVO",
                    "postaMasrafId": 0,
                    "dosyaDurum": "H"
                }, {
                    "grupKodu": 2,
                    "talepKodu": 36,
                    "talepAdi": "100. Maddeye Yarar Bilgi İstenilmesi",
                    "talepKisaAdi": "100. Maddeye Yarar Bilgi İstenilmesi",
                    "talepMasrafi": 0,
                    "className": "AvukatTalepGenel100MaddeYararBilgiDvo",
                    "postaMasrafId": 0,
                    "dosyaDurum": "A"
                }];
        else
            return [];

    }
}