import {UyapRequest} from "./UyapRequest";

export interface AvukatIcraTalepEvrakiGonderTalep {
    formData: boolean,
    data: FormData
}

export interface AvukatIcraTalepEvrakiGonderCevap {
    message: string,
    result: string
}

export class AvukatIcraTalepEvrakiGonder extends UyapRequest<AvukatIcraTalepEvrakiGonderTalep, AvukatIcraTalepEvrakiGonderCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): string {
        if (Math.random() > 0.6)
            return `<root><ResultEvent><type>error</type><message>Borçlu hakkındaki icra takibi kesinleştirilmediği için talep evrağı gönderemezsiniz.</message><log/></ResultEvent></root>`
        else
            return `<root><ResultEvent><type>success</type><message>İnternet yoluyla gönderdiğiniz evrakları Ankara 12. İcra Dairesi - 2013/13969 nolu dosyasında görebilirsiniz. </message><log/></ResultEvent></root>`
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/avukatIcraTalepEvrakiGonder.ajx';
    }

    async parse(data: any): Promise<AvukatIcraTalepEvrakiGonderCevap> {
        let json: any = await this.xml2json(data);
        return {
            message: json.root.ResultEvent.message,
            result: json.root.ResultEvent.type
        }
    }
}

export default AvukatIcraTalepEvrakiGonder;