import {UyapRequest} from "./UyapRequest";

export interface DosyaOdemeIslemleriTalep {
    dosyaId: number,
    harcMasrafTipi: string,
    harcMasrafList: string,
    postaMasraflariList: {
        id: number,
        tur: 'D' | string,
        adi: string,
        adet: number,
        tutar: number
    }[] | string,
    odemeTipi: number,
    uyapId: string,
    vakifbankHesapBilgileri: string
}

export interface DosyaOdemeIslemleriCevap {
    type: string,
    message: string
}


export class DosyaOdemeIslemleri extends UyapRequest<DosyaOdemeIslemleriTalep, DosyaOdemeIslemleriCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): string {
        return `<root><ResultEvent>
          <type>success</type>
          <message>&lt;strong&gt;İstanbul Anadolu 18. İcra Dairesi - 2021/17812 &lt;/strong&gt; nolu dosya i
        çin ödeme işlem(ler)i başarıyla gerçekleşti. 19 TL  ilgili vezne hesap(lar)ına aktarıldı. Yapılan ödemelere
         ait makbuzlar dosyaya eklendi. İlgili yargı birimi iş listesine yapılan ödemelere ilişkin uyarı düş
        ürüldü.</message>
          <log/>
        </ResultEvent></root>`;

        //for error

        //`<root><errorCode>PRTL_AVKT_10038-1</errorCode><error>Bu işlemi gerçekleştirebilmek için barokart bakiyeniz yetersizdir. https://barokart.com.tr adresinden bakiye yükledikten sonra tekrar deneyiniz. </error></root>`,

    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/dosya_odeme_islemleri_brd.ajx';
    }

    async parse(data: string): Promise<DosyaOdemeIslemleriCevap> {
        let obj: any = await this.xml2json(data);
        return obj.root.ResultEvent;
    }
}

export default DosyaOdemeIslemleri;
