import {UyapRequest} from "./UyapRequest";

export interface TamamlanmayanDosyalarTalep {
    // normal 35 mts 294
    dosyaTurKod: number | string
}

export type TamamlanmayanDosyalarCevap = TamamlanmayanDosyalarRow[];

export interface TamamlanmayanDosyalarRow {
    birimID: string,
    birimAdi: string,
    dosyaID: number,
    dosyaNo: string,
    dosyaAcilisTarihi: string,
    taraflar: string,
    sonIslemTarihi: string,
    surecNo: number,                      // 1: vekalet yok, 3: açılmaya/ödemeye hazır
    iliskiliDosyaVarmi: string,
    tebligatGonderimAktif: string,
}


export class TamamlanmayanDosyalar extends UyapRequest<TamamlanmayanDosyalarTalep, TamamlanmayanDosyalarCevap> {
    delayTime = 1000;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [{
            "birimID": "1054286",
            "birimAdi": "Merkezi Takip Sistemi",
            "dosyaID": 533971526,
            "dosyaNo": "2019/383596",
            "dosyaAcilisTarihi": "Sep 4, 2019 5:04:24 PM",
            "taraflar": "ALACAKLI ENERJİSA BAŞKENT ELEKTRİK PERAKENDE SATIŞ ANONİM ŞİRKETİ / BORÇLU VE MÜFLİS ANKA KARDELEN İNŞAAT TEKS. TİC. LTD. ŞTİ.",
            "sonIslemTarihi": "Sep 4, 2019 5:04:24 PM",
            "surecNo": 5,
            "iliskiliDosyaVarmi": "H"
        }, {
            "birimID": "1054286",
            "birimAdi": "Merkezi Takip Sistemi",
            "dosyaID": 553117361,
            "dosyaNo": "2019/1034166",
            "dosyaAcilisTarihi": "Dec 26, 2019 3:33:37 PM",
            "taraflar": "ALACAKLI ENERJİSA BAŞKENT ELEKTRİK PERAKENDE SATIŞ ANONİM ŞİRKETİ / BORÇLU VE MÜFLİS MERTGÜN KAYA",
            "sonIslemTarihi": "Dec 26, 2019 3:33:38 PM",
            "surecNo": 5,
            "iliskiliDosyaVarmi": "H"
        }];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/tamamlanmayan_dosyalar_brd.ajx';
    }

    async parse(data: any): Promise<TamamlanmayanDosyalarCevap> {
        return data.map((row: any) => {
            row.sonIslemTarihi = new Date(row.sonIslemTarihi);
            row.dosyaAcilisTarihi = new Date(row.dosyaAcilisTarihi);
            return row;
        })
    }

}

export default TamamlanmayanDosyalar;
