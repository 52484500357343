import JSZip from "jszip";
import {saveAs} from "file-saver";
import {HtmlToUdf} from "./HtmlToUdf";

export class UdfSablon{
    html:string;
    constructor() {
        this.html="";
    }
    create(sablon:any):Promise<Blob>{
        console.log("sablon",sablon);
        let keys = Object.keys(sablon.data);
        for(let i = 0 ; i < keys.length;i++){
            sablon.html = sablon.html.replace(new RegExp("{"+keys[i]+"}", 'g'),sablon.data[keys[i]]);
        }
        this.html = sablon.html;
        let creater = new HtmlToUdf();
        return creater.getUdfBlob(sablon.html);
    }
    download(sablon:any,fileName:string){
        this.create(sablon).then( (data) =>{
            saveAs(data,fileName+".udf");
        })
    }
    contentXmlToUdf(contentXml:any){
        return new Promise( (resolve, reject)=> {
            let zip = new JSZip();
            zip.file("content.xml", contentXml);
            zip.generateAsync({type:"blob"})
                .then((content) =>{
                    return resolve(content);
                }).catch( (err)=> {
                return reject(err);
            });
        })
    }
}