import Vue from 'vue'
import Vuex, {ActionContext} from 'vuex'
import axios from "axios";
import gorevYoneticisi from "@/plugins/uyap-plugin/store/modules/GorevYoneticisi";
import {AppState, AppStatus, ITenant, ManifestResponse} from '@/types';
import VueSocketIOExt from 'vue-socket.io-extended';
import {io} from 'socket.io-client';
import app from "@/main";
import {UYAP_PLATFORM_SOCKET} from "@/plugins/uyap-plugin/imza";
import {AvukatKisiselBilgileriSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/AvukatKisiselBilgileriSorgulama";

const socket = io('https://imza.eicrapro.com:3000');
Vue.use(VueSocketIOExt, socket);
Vue.use(Vuex);

export const store = new Vuex.Store<AppState>({
    modules: {
        // @ts-ignore
        gorevYoneticisi
    },
    state: {
        uyapConnection: false,
        appStatus: AppStatus.loading,
        tenant: undefined,
        manifestResponse: {files: []},
        tokenExis: false,
        devMode: true,
        eicraproDevMode: false,  // Mock data kullanmak için true yap
        uyapAvukatBilgileri: undefined
    },
    getters: {
        appStatus: (state) => state.appStatus,
        isLoading: (state) => state.appStatus === AppStatus.loading,
        isUpdate: (state) => state.appStatus === AppStatus.update,
        isBrowser: (state) => state.appStatus === AppStatus.browser,
        isElectron: (state) => state.appStatus === AppStatus.electron,
        isUyapConnected: (state) => state.uyapConnection,
        isTokenExis: (state) => state.tokenExis,
        isDevMode: (state) => state.devMode,
        isEicraproDevMode: (state) => state.eicraproDevMode,
        uyapAvukatBilgileri: (state) => state.uyapAvukatBilgileri,
        tenantBilgileri: (state) => state.tenant
    },
    mutations: {
        setAppState(state: AppState, value: AppStatus): void {
            state.appStatus = value;
        },
        setManifestResponse(state: AppState, response: ManifestResponse) {
            state.manifestResponse = response;
        },
        setUyapConnection(state: AppState, payload: boolean) {
            app.$socket.client.emit("uyapconnection", {
                connected: payload,
                JSESSIONID: payload ? sessionStorage.getItem('JSESSIONID') : ''
            });
            state.uyapConnection = payload;
        },
        setUyapAvukatBilgileri(state: AppState, avukat: AvukatKisiselBilgileriSorgulamaCevap) {
            app.$socket.client.emit("uyapAvukatBilgileri", avukat);
            state.uyapAvukatBilgileri = avukat;
        },
        setTenant(state: AppState, tenant: ITenant) {
            state.tenant = tenant;
        },
    },
    actions: {
        connectToUyap(ctx: ActionContext<AppState, AppState>) {
            console.log("connectToUyap triggered");
            console.log("isUyapConnected", ctx.getters.isUyapConnected)
            app.$imza.login(UYAP_PLATFORM_SOCKET.AVUKAT_PORTAL).then((avukat: AvukatKisiselBilgileriSorgulamaCevap) => {
                ctx.commit('setUyapConnection', true);
                ctx.commit('setUyapAvukatBilgileri', avukat);
                app.$toast.success('Uyap bağlantısı tamamlandı.');
            }).catch((error: any) => {
                ctx.commit('setUyapConnection', false);
                app.$toast.error('Uyap bağlantısı yapılamadı. (' + error + ')');
            });
        },
        openUyapWindow(ctx: ActionContext<AppState, AppState>) {
            window.open('http://avukat.uyap.gov.tr/desktop.uyap?r=login&token=' + sessionStorage.getItem("uyapAuthorizationCode"), "_blank");
        },
        async fastLoginUyap(ctx: ActionContext<AppState, AppState>) {
            try {
                let avukat = await app.$uyap.AvukatKisiselBilgileriSorgulama().run({});
                ctx.commit('setUyapConnection', true);
                ctx.commit('setUyapAvukatBilgileri', avukat);
            } catch (e) {
                await ctx.dispatch('connectToUyap');
            }
        },
        async checkUyapSession(ctx: ActionContext<AppState, AppState>) {
            console.log("checkUyapSession triggered");
            try {
                if (ctx.getters.isEicraproDevMode) {
                    ctx.commit('setUyapConnection', true);
                } else {
                    await app.$imza.setCookieToImza();
                    await ctx.dispatch("fastLoginUyap");
                }
            } catch (error) {
                ctx.commit('setUyapConnection', false);
            }
        },
        async setTasksSocket(ctx: ActionContext<AppState, AppState>) {
            // context.di/getTasksFromServer
            await ctx.dispatch("getTasksFromServer")
            app.$socket.client.emit("tasks", ctx.getters.getGorevler);
        },
        async load(ctx: ActionContext<AppState, AppState>): Promise<void> {
            ctx.commit("setAppState", AppStatus.loading);
            if (sessionStorage.getItem('accessToken'))
                ctx.state.tokenExis = true;
            if (!window.zirvelib) {
                return ctx.commit('setAppState', AppStatus.browser);
            } else {
                const updateRequires = !await ctx.dispatch('isLocalUpToDate');
                console.log("updateRequires", updateRequires);
                if (updateRequires) {
                    return ctx.commit('setAppState', AppStatus.update);
                }
                try {
                    if (!await ctx.dispatch('checkSignService')) {
                        await ctx.dispatch('startSignService');
                    }
                    if (!await ctx.dispatch('checkProxyService')) {
                        await ctx.dispatch('startProxyService');
                    }
                    return ctx.commit('setAppState', AppStatus.electron);
                } catch (e) {
                    console.log(e);
                    return ctx.commit('setAppState', AppStatus.error);
                }
            }
        },
        async isLocalUpToDate(): Promise<boolean> {
            return new Promise((resolve) => {
                if (!window.zirvelib) {
                    return resolve(false);
                }
                if (!window.zirvelib?.version) {
                    return resolve(false);
                }
                console.log("window.zirvelib.version", window.zirvelib.version);
                return resolve(
                    window.zirvelib.version === "1.2.0"
                );
            });
        },

        async checkSignService(): Promise<boolean> {
            return new Promise((resolve, reject) => {
                if (!window.zirvelib) {
                    return reject('zirvelib not found');
                }
                const heartbeatPromise = axios.post('http://127.0.0.1:4723/xml/heartbeat', {});
                heartbeatPromise.then(() => {
                    resolve(true);
                }).catch(() => {
                    resolve(false);
                });
            })
        },
        async checkProxyService(): Promise<boolean> {
            return new Promise((resolve) => {
                const heartbeatPromise = axios.post('https://imza.eicrapro.com:6090/xml/heartbeat', {});
                heartbeatPromise.then(() => {
                    resolve(true);
                }).catch(() => {
                    resolve(false);
                });
            });
        },
        async startSignService(): Promise<void> {
            console.log("Starting Sign Service");
            return new Promise((resolve, reject) => {
                if (!window.zirvelib) {
                    return reject('zirvelib not found');
                }
                window.zirvelib.signManager.start();
                window.setTimeout(() => {
                    resolve();
                }, 2000)
            })
        },

        async startProxyService(ctx: ActionContext<AppState, AppState>): Promise<void> {
            return new Promise((resolve, reject) => {
                if (!window.zirvelib) {
                    return reject('zirvelib not found');
                }
                window.zirvelib.proxyManager.start();
                window.zirvelib.socketManager.start();
                window.setTimeout(() => {
                    resolve();
                }, 2000)
            });
        },

        async stopSignService(): Promise<void> {
            return new Promise((resolve, reject) => {
                if (!window.zirvelib) {
                    return reject('zirvelib not found');
                }
                window.zirvelib.signManager.kill();
                window.setTimeout(() => {
                    resolve();
                }, 1500)
            })
        },
        async stopProxyService(): Promise<void> {
            return new Promise((resolve, reject) => {
                if (!window.zirvelib) {
                    return reject('zirvelib not found');
                }
                window.zirvelib.proxyManager.stop();
                window.zirvelib.socketManager.stop();
                resolve();
            })
        },
    }
})
