import {AvukatDosyaSorgulaGenelCevapItem} from "../uyap/AvukatDosyaSorgulaGenel";
import HacizInjectable from "./HacizInjectable";
import {MDB} from "../MDB";
import {
    BakiyeBorcMuhtirasininTebligeCikartilmasiParamsInterface,
    IcraOdemeEmrininTebligeCikartilmasiParamsInterface,
    KiymetTakdirininTebligeCikartilmasiParamsInterface,
    TaahhuduKabulMuhtirasiTebligiParamsInterface, TemlikbilgisininBildirilmesiParamsInterface,
    YenilemeEmrininTebligeCikartilmasiParamsInterface,
    YuzucDavetiyesininTebligeCikartilmasiParamsInterface
} from "./TebligatParamsInterface";
import {TebligatEvrakTypeEnum} from "../uyap/enum/TebligatEvrakTypeEnum";

const mdb = new MDB();
export default class TebligatController extends HacizInjectable {
    constructor(borclu: any, dosya: AvukatDosyaSorgulaGenelCevapItem) {
        super(borclu, dosya);
    }

    yuz3DavetiyesininTebligeCikartilmasiEkle(params: YuzucDavetiyesininTebligeCikartilmasiParamsInterface) {
        let obj = mdb.tebligat.talepListesi.find((i: any) => i.className == TebligatEvrakTypeEnum.YUZUCDAVETIYESININ_TEBLIGE_CIKARTILMASI);
        let talepObj = Object.assign({}, obj, {
            "tebligatTuru": params.tebligatTuru,
            "tebligatTuruAciklama": params.tebligatTuruAciklama,
            "adresTuru": params.adresTuru,
            "adresTuruAciklama": params.adresTuruAciklama,
            "adres": params.adres,
            "hacizTarihi": params.hacizTarihi,
            "malTip": params.malTip,
            "malTipAciklama": params.malTipAciklama,
            "aciklama": params.aciklama
        });

        let tebligatTuru = mdb.tebligat.turler[params.tebligatTuru];
        this.evrakTutar += parseFloat(tebligatTuru.fiyati);
        //TODO talep turlerden masrafi yapicaz...
        /*
        	var tebligatJson = tebligatTurleriJson[val["tebligatTuru"]];
			var tutar = parseFloat(tebligatJson["fiyati"]);
         */
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }

    bakiyeBorcMuhtirasininTebligeCikartilmasiEkle(params: BakiyeBorcMuhtirasininTebligeCikartilmasiParamsInterface) {
        let obj = mdb.tebligat.talepListesi.find((i: any) => i.className == TebligatEvrakTypeEnum.BakiyeBorcMuhtirasininTebligeCikartilmasi);
        let talepObj = Object.assign({}, obj, {
            "tebligatTuru": params.tebligatTuru,
            "tebligatTuruAciklama": params.tebligatTuruAciklama,
            "adresTuru": params.adresTuru,
            "adresTuruAciklama": params.adresTuruAciklama,
            "adres": params.adres
        });
        let tebligatTuru = mdb.tebligat.turler[params.tebligatTuru];
        this.evrakTutar += parseFloat(tebligatTuru.fiyati);
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }

    icraOdemeEmrininTebligeCikartilmasiEkle(params: IcraOdemeEmrininTebligeCikartilmasiParamsInterface) {
        let obj = mdb.tebligat.talepListesi.find((i: any) => i.className == TebligatEvrakTypeEnum.IcraOdemeEmrininTebligeCikartilmasi);
        let talepObj = Object.assign({}, obj, {
            "tebligatTuru": params.tebligatTuru,
            "tebligatTuruAciklama": params.tebligatTuruAciklama,
            "adresTuru": params.adresTuru,
            "adresTuruAciklama": params.adresTuruAciklama,
            "adres": params.adres
        });
        let tebligatTuru = mdb.tebligat.turler[params.tebligatTuru];
        this.evrakTutar += parseFloat(tebligatTuru.fiyati);
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }

    yenilemeEmrininTebligeCikartilmasiEkle(params: YenilemeEmrininTebligeCikartilmasiParamsInterface) {
        let obj = mdb.tebligat.talepListesi.find((i: any) => i.className == TebligatEvrakTypeEnum.YenilemeEmrininTebligeCikartilmasi);
        let talepObj = Object.assign({}, obj, {
            "tebligatTuru": params.tebligatTuru,
            "tebligatTuruAciklama": params.tebligatTuruAciklama,
            "adresTuru": params.adresTuru,
            "adresTuruAciklama": params.adresTuruAciklama,
            "adres": params.adres
        });
        let tebligatTuru = mdb.tebligat.turler[params.tebligatTuru];
        this.evrakTutar += parseFloat(tebligatTuru.fiyati);
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }

    kiymetTakdirininTebligeCikartilmasiEkle(params: KiymetTakdirininTebligeCikartilmasiParamsInterface) {
        let obj = mdb.tebligat.talepListesi.find((i: any) => i.className == TebligatEvrakTypeEnum.KiymetTakdirininTebligeCikartilmasi);
        let talepObj = Object.assign({}, obj, {
            "tebligatTuru": params.tebligatTuru,
            "tebligatTuruAciklama": params.tebligatTuruAciklama,
            "adresTuru": params.adresTuru,
            "adresTuruAciklama": params.adresTuruAciklama,
            "adres": params.adres,
            "kiymetTakdirTarihi": params.kiymetTakdirTarihi,
            "malTip": params.malTip,
            "malTipAciklama": params.malTipAciklama,
            "aciklama": params.aciklama
        });
        let tebligatTuru = mdb.tebligat.turler[params.tebligatTuru];
        this.evrakTutar += parseFloat(tebligatTuru.fiyati);
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }

    taahhuduKabulMuhtirasiTebligiEkle(params: TaahhuduKabulMuhtirasiTebligiParamsInterface) {
        let obj = mdb.tebligat.talepListesi.find((i: any) => i.className == TebligatEvrakTypeEnum.TaahuduKabulMuhtirasiTebligi);
        let talepObj = Object.assign({}, obj, {
            "tebligatTuru": params.tebligatTuru,
            "tebligatTuruAciklama": params.tebligatTuruAciklama,
            "adresTuru": params.adresTuru,
            "adresTuruAciklama": params.adresTuruAciklama,
            "adres": params.adres,
            "taahhutTarihi": params.taahhutTarihi,
            "aciklama": params.aciklama
        });
        let tebligatTuru = mdb.tebligat.turler[params.tebligatTuru];
        this.evrakTutar += parseFloat(tebligatTuru.fiyati);
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }

    temlikbilgisininBildirilmesiEkle(params: TemlikbilgisininBildirilmesiParamsInterface) {
        let obj = mdb.tebligat.talepListesi.find((i: any) => i.className == TebligatEvrakTypeEnum.TemlikBilgisininBildirilmesi);
        let talepObj = Object.assign({}, obj, {
            "tebligatTuru": params.tebligatTuru,
            "tebligatTuruAciklama": params.tebligatTuruAciklama,
            "adresTuru": params.adresTuru,
            "adresTuruAciklama": params.adresTuruAciklama,
            "adres": params.adres,
            "kismiTemlikMi": params.kismiTemlikMi,//boolean
            "kismiTemlikMiStr": params.kismiTemlikMi ? "Evet" : "Hayır",
            "temlikAlacakli": params.temlikAlacakliKisiKurumId,
            "temlikAlacakliStr": params.temlikAlacakliAdi,
            "temlikEdilen": params.temlikEdilen,
            "temlikTipi": params.temlikTipi,
            "temlikTutarTuru": params.temlikTutarTuru,
            "temlikTutarTuruStr": params.temlikTutarTuru,
            "temlikTutari": params.temlikTutari
        });
        let tebligatTuru = mdb.tebligat.turler[params.tebligatTuru];
        this.evrakTutar += parseFloat(tebligatTuru.fiyati);
        this.evrakTutar += obj.talepMasrafi; //0
        this.talepBilgileri.push(talepObj);
    }
}
