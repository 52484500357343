import {UyapRequest} from "./UyapRequest";

export interface KisiMernisAdresiVarmiTalep {
    tcKimlikNo: any
}

export interface KisiMernisAdresiVarmiCevap {
    type: string,
    message: string,
    value: boolean,
    log: string
}

export class KisiMernisAdresiVarmi extends UyapRequest<KisiMernisAdresiVarmiTalep, KisiMernisAdresiVarmiCevap> {
    delayTime = 1000;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "KisiMernisAdresiVarmi_" + this.talepData.tcKimlikNo
        };
    }

    getMockResponse(): any {
        return {"type": "success", "message": "", "value": false, "log": ""};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/kisi_mernis_adresi_var_miJSON_brd.ajx';
    }

    async parse(data: any): Promise<KisiMernisAdresiVarmiCevap> {
        return data;
    }

}

export default KisiMernisAdresiVarmi;
