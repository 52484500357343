import {UyapRequest} from "./UyapRequest";
import {OdemeTipiEnum} from "@/plugins/uyap-plugin/takip_ac/OdemeTipleri";

export interface OdemeYapTalep {
    dosyaId: number,
    kisiKurumId: number,
    odemeTipi: OdemeTipiEnum, // "2"
    uyapId: string,
    vakifbankHesapBilgileri: any,
    vakifbankOdemeIstekBilgileri: any,
    smsSifre: any,
}

//var odemeDataJson = {dosyaId:odeme_bilgileri.dosyaId, kisiKurumId:kisiKurumId, odemeTipi:odeme_bilgileri.odemeTipi, uyapId:odeme_bilgileri.uyapId, vakifbankHesapBilgileri:odeme_bilgileri.vakifbankHesapBilgileri, vakifbankOdemeIstekBilgileri:odeme_bilgileri.vakifbankOdemeIstekBilgileri, smsSifre:odeme_bilgileri.vakifbankSMSSifre};


export interface OdemeYapCevapSuccess {
    message: string,
    icraDairesi: string,
    dosyaEsasNo: string,
    result: "success"
}

export interface OdemeYapCevapFail {
    message: string,
    result: "fail"
}

export type OdemeYapCevap = OdemeYapCevapSuccess | OdemeYapCevapFail;

export class OdemeYap extends UyapRequest<OdemeYapTalep, OdemeYapCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): string {
        if (Math.random() > 0.4) {
            return `<root><ResultEvent>
		  <type>error</type>
		  <message>Bu işlemi gerçekleştirebilmek için barokart servislerine ulaşılamadı.</message>
		  <log/>
		</ResultEvent></root>`;
        }

        return `<root><ResultEvent>
		  <type>success</type>
		  <message>Ödeme işlem(ler)i başarıyla gerçekleşti. 81.35 TL  ilgili vezne hesap(lar)ına aktarıldı. Yapılan ödemelere ait makbuzlar dosyaya eklendi. &lt;br&gt;&lt;br&gt;&lt;strong&gt;Bandırma 3. İcra Dairesi&lt;/strong&gt; - &lt;strong&gt;2019/1600&lt;/strong&gt; esas numaralı dosyanız açılmıştır.</message>
		  <log/>
		</ResultEvent></root>`;

    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/dava_acilis_odeme_islemleri_brd.ajx';
    }

    async parse(data: string): Promise<OdemeYapCevap> {
        let jsonObj: any = await this.xml2json(data);
        let result = jsonObj.root.ResultEvent;
        let div = document.createElement("div");
        div.innerHTML = result.message;
        let strongs = div.querySelectorAll("strong");
        let icraDairesi = strongs[0].innerText;
        let dosyaEsasNo = strongs[1].innerText;
        return {
            message: result.message,
            icraDairesi: icraDairesi,
            dosyaEsasNo: dosyaEsasNo,
            result: "success"
        };
    }

}

export default OdemeYap;
