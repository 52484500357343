import {UyapRequest} from "./UyapRequest";
import UyapHtmlParser from "./parser/UyapHtmlParser";
import app from "@/main";

export interface BorcluIcraDosyasiTalep {
    dosyaId: number | string,
    kisiKurumId: number | string
}

export class BorcluIcraDosyasiCevap {
    message: string = "";
    found: boolean = false;
    data: Array<BorcluIcraDosyasiCevapRow> = [];
    html: string = '';
}

export interface BorcluIcraDosyasiCevapRow {
    dosyaId: string,
    kisiKurumId: string,
    dosyaTurKod: string,
    birimAdi: string,
    dosyaNo: string,
    dosyaTuru: string,
    takipTuru: string,
    takipYolu: string,
    takipSekli: string,
    takipDurumu: string,
    acilis: string,
    kapanis: string,

    selected: boolean
}

export default class BorcluIcraDosyasi extends UyapRequest<BorcluIcraDosyasiTalep, BorcluIcraDosyasiCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "borcluIcraDosyasi_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): string {
        return `<div id="servis_sonuc_21">
  <div id="content">
    <style>
      @media print {
        .hidden-print {
          display: none !important;
        }

        body {
          font-family: Arial, Helvetica, sans-serif;
          ;
        }
    </style>
    <div id="content_pdf">
      <table class='table table-bordered table-striped'>
        <tr>
          <td style=" border: none; text-align: center; font-weight: 600; width: 100%;">BORÇLUYA AİT İCRA DOSYASI BİLGİLERİ</td>
        </tr>
      </table>
      <fieldset style="margin-bottom: 10px;">
        <legend>Sorgulamaya Ait Genel Bilgiler</legend>
        <table class='table table-bordered table-striped'>
          <tr>
            <td style=" border: none; text-align: left; font-weight: 600;width: 250px;">İşlemi Gerçekleştirenin Adı Soyadı: </td>
            <td style="border: none; text-align: left;">Av. NİHAN ERDOĞAN</td>
          </tr>
          <tr>
            <td style=" border: none; text-align: left; font-weight: 600;width: 250px;">Birim Adı/Dosya No: </td>
            <td style="border: none; text-align: left;">Ankara 9. İcra Dairesi 2017/21042</td>
          </tr>
          <tr>
            <td style=" border: none; text-align: left; font-weight: 600;width: 250px;">Borçlunun Adı Soyadı:</td>
            <td style="border: none; text-align: left;"> ME**** FA*** UÇ**</td>
          </tr>
          <tr>
            <td style=" border: none; text-align: left; font-weight: 600;width: 250px;">Borçlunun TC Kimlik No: </td>
            <td style="border: none; text-align: left;">1********72</td>
          </tr>
          <tr>
            <td style=" border: none; text-align: left; font-weight: 600;width: 250px;">Borçlunun Baba/Anne Adı: </td>
            <td style="border: none; text-align: left;"> Ş**** / G*******</td>
          </tr>
          <tr>
            <td style=" border: none; text-align: left; font-weight: 600;width: 250px;">Borçlunun Doğum Yılı: </td>
            <td style="border: none; text-align: left;">1972</td>
          </tr>
        </table>
      </fieldset>
      <div>
        <p style='border:1px solid #ddd;padding:10px;'>
          <strong> Kişiye ait alacaklı olduğu İcra Dosyası kaydı var.</strong>
        </p>
        <div class="table-scrollable">
          <table class="table table-bordered table-striped">
            <tbody>
              <tr style='font-weight:700;'>
                <td>#</td>
                <td style="width:18%;">Birim Adı / Dosya</td>
                <td>Takip Türü</td>
                <td style="width:27%;">Takip Yolu / Şekli</td>
                <td>Durumu</td>
                <td>Açılış</td>
                <td>Kapanış</td>
                <td class="hidden-print haciz-talebi-ekle-btn"></td>
              </tr>
              <tr>
                <td>1</td>
                <td style="width:18%;">Amasya İcra Dairesi <br>2018/26764 <br>İcra Dosyası </td>
                <td>iLAMSIZ</td>
                <td style="width:27%;">Kambiyo Senetlerine Mahsus Haciz Yolu <br>( ÖRNEK: Eski No: 163 - Yeni No: 10 ) Kambiyo Senetleri ( Çek, Poliçe Ve Emre Muharrer Senet ) Üzerine Haciz Yolu İle Yapılacak Takip Taleplerinde Ödeme Emri </td>
                <td>Açık</td>
                <td>2012-02-27 15:04</td>
                <td></td>
                <td class="hidden-print haciz-talebi-ekle-btn" style="width:10%;">
                  <input type="button" value="Haciz Talebine Ekle" onclick="HacizTalebiGonderme.talepEkleIcraDosyasi('197793660', '19508806','Amasya İcra Dairesi', '2018/26764', '35');" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table style="border: none;margin-top:30px;">
          <tr>
            <td style="width: 50%; border: none;"></td>
            <td style="width: 50%; border: none;">
              <table style="border: none;">
                <tr>
                  <td style=" border: none; text-align: center; width: 100%;">25/07/2023 15:50</td>
                </tr>
                <tr>
                  <td style=" border: none; text-align: center; font-weight: 600; width: 100%;">Düzenleyen</td>
                </tr>
                <tr>
                  <td style=" border: none; text-align: center; font-weight: 600; width: 100%;">AVUKAT PORTAL BİLGİ SİSTEMİ</td>
                </tr>
                <tr>
                  <td style=" border: none; text-align: center; width: 100%;">Avukatların, Avukat Portal'dan almış oldukları hizmete ilişkin belgedir. </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div style="width: 100%; border: none; text-align: center;padding: 30px;font-size: 18px;">
    <a id="10636242162733552242524764003100202752236721_print" class="print_btn" style="margin-right: 10px; color: blue;" href="javascript: void(0);">Yazdır</a>
    <a id="10636242162733552242524764003100202752236721_pdf" class="pdf_btn" style="margin-right: 10px; color: blue;" href="javascript: void(0);">PDF</a>
  </div>
  <script type="text/javascript">
    $('#10636242162733552242524764003100202752236721_print').click(function() {
          $(this).hide();
          var w = window.open();
          w.document.write(' < html > < head > < title > UYAP Bilgi Sistemi < /title> < /head> < body > ');\t\t\tw.document.write($('
              div #servis_sonuc_21 div[id = content]
              ').html());\t\t\tw.document.write(' < /body> < /html>');\t\t\tw.print();\t\t\tw.close();\t\t\t$(this).show();\t\t}\t);
  </script>
  <script type="text/javascript">
    $('#10636242162733552242524764003100202752236721_pdf').click(function() {
      $(this).hide();
      postToURL_target('convert_to_pdf.uyap', {
        data: $('div#servis_sonuc_21 div[id=content_pdf]').html(),
        filename: 'Borçlu Bilgileri İCRA DOSYASI'
      }, 'width=400,height=10,resizable=yes');
      $(this).show();
    });
  </script>`
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/borclu_bilgileri_goruntule_icra_dosyasi_brd.ajx';
    }

    async parse(data: any): Promise<BorcluIcraDosyasiCevap> {
        let div: HTMLElement = document.createElement('div');
        div.innerHTML = data;
        let message: string = (div.querySelector('strong') as HTMLElement).innerText;
        let found: boolean = message.indexOf(' var.') > 0;
        let items: BorcluIcraDosyasiCevapRow[] = [];
        if (found) {
            let rows = (div.querySelector('.table-scrollable table') as HTMLElement).querySelectorAll('tbody tr');
            console.log(rows)
            rows[0].remove();
            console.log(rows)
            items = UyapHtmlParser.nodeListMap<BorcluIcraDosyasiCevapRow>(rows, (tr: HTMLElement): BorcluIcraDosyasiCevapRow => {
                let row1 = (tr.querySelector('td:nth-child(2)') as HTMLTableCellElement)?.innerHTML.split('<br>');
                let row3 = (tr.querySelector('td:nth-child(4)') as HTMLTableCellElement)?.innerHTML.split('<br>');
                let input: any = (tr.querySelector('td:nth-child(8) input') as HTMLTableCellElement);
                console.log('input', input)
                if (!input) {
                    // @ts-ignore
                    return null;
                }
                let inputStr = input.getAttribute('onclick') as string;
                console.log('inputstr', inputStr)
                input = inputStr.slice(inputStr.indexOf('(') + 1).slice(0, -2).replaceAll(`'`, '');
                if (input) {
                    input = input.split(',');
                    input = input.map((item: any) => item.trim());
                }
                let item: BorcluIcraDosyasiCevapRow = {
                    dosyaId: input ? input[0] : '',
                    kisiKurumId: input ? input[1] : '',
                    birimAdi: input ? input[2] : row1[0],
                    dosyaNo: input ? input[3] : row1[1],
                    dosyaTurKod: input ? input[4] : '',
                    dosyaTuru: row1[2],
                    takipTuru: (tr.querySelector('td:nth-child(3)') as HTMLTableCellElement)?.innerHTML,
                    takipYolu: row3[0],
                    takipSekli: row3[1],
                    takipDurumu: (tr.querySelector('td:nth-child(5)') as HTMLTableCellElement)?.innerHTML,
                    acilis: (tr.querySelector('td:nth-child(6)') as HTMLTableCellElement)?.innerHTML,
                    kapanis: (tr.querySelector('td:nth-child(7)') as HTMLTableCellElement)?.innerHTML,
                    selected: true
                }
                return item;
            });
        }

        return {
            message: message,
            found: found,
            data: items,
            html: data
        } as BorcluIcraDosyasiCevap
    }

}

