import {UyapRequest} from "./UyapRequest";
import {ResponseType} from "axios";

export interface DosyaHesapBilgileriTalep {
    dosyaId: number | string
}

export class DosyaHesapBilgileriCevap {
    items: HesapBilgileriRow[] = [];
    message: string = "";
    html: string = "";
}

interface HesapBilgileriRow {
    textAlan: string,
    degerAlan: string,
    grupId: string
}

export class DosyaHesapBilgileri extends UyapRequest<DosyaHesapBilgileriTalep, DosyaHesapBilgileriCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [{
            "textAlan": "Takipte Kesinlesen Miktar",
            "degerAlan": 1156.98,
            "grupId": 1
        }, {"textAlan": "Basvurma Harci", "degerAlan": 269.85, "grupId": 1}, {
            "textAlan": "Toplam Faiz Miktari",
            "degerAlan": 1360.42,
            "grupId": 1
        }, {"textAlan": "Vekalet Ücreti", "degerAlan": 1152.85, "grupId": 1}, {
            "textAlan": "Masraf Miktari",
            "degerAlan": 65.8,
            "grupId": 1
        }, {"textAlan": "BSMV Miktari", "degerAlan": 68.02, "grupId": 1}, {
            "textAlan": "KKDF Miktari",
            "grupId": 1
        }, {"textAlan": "KDV Miktari", "grupId": 1}, {
            "textAlan": "Tahsil Harcı",
            "degerAlan": 52.64,
            "grupId": 1
        }, {"textAlan": "Özel Iletisim Vergisi", "grupId": 1}, {
            "textAlan": "Toplam Alacak",
            "degerAlan": 4126.56,
            "grupId": 1
        }, {
            "textAlan": "Yatan Para (09/10/2019-4.55 , 14/11/2019-4.55 , 10/12/2019-4.55 , 08/01/2020-4.55 , 07/02/2020-4.55 , 18/02/2020-4.55 , 13/03/2020-4.55 , 10/04/2020-4.55)",
            "degerAlan": 3987.6806,
            "grupId": 2
        }, {"textAlan": "Bakiye Borç Miktari", "degerAlan": 138.8794, "grupId": 3}]
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/dosya_hesap_bilgileri.ajx';
    }

    getResponseType(): ResponseType {
        return 'json';
    }

    async parse(data: any): Promise<DosyaHesapBilgileriCevap> {
        let response = new DosyaHesapBilgileriCevap();
        response.items = data;
        let html = document.createElement('div');
        for (let item of data) {
            let li = document.createElement('li');
            li.innerHTML = '<strong>' + item.textAlan + '</strong>';
            if (item.degerAlan)
                li.innerHTML += '<strong>: </strong>' + item.degerAlan + ' TL';
            html.appendChild(li);
        }
        response.html = html.innerHTML;
        return Promise.resolve(response);
    }

}

export default DosyaHesapBilgileri;
