import {UyapRequest} from "./UyapRequest";
import JSZip from "jszip";

export class MtsTakipTalebiIndirTalep {
    data: any = {};
    download = true;
    filename: string = "takip_talebi.udf";
}

export interface MtsTakipTalebiIndirCevap {
    file: Blob
}


export default class MtsTakipTalebiIndir extends UyapRequest<MtsTakipTalebiIndirTalep, MtsTakipTalebiIndirCevap> {
    dosyaId: string = "";
    birimId: string = "";

    constructor(dosyaId: string, birimId: string) {
        super();
    }

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): Blob {
        return new Blob();
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/mts_takip_talebi_hazirla_brd.avukat?dosyaId=" + this.dosyaId + "&birimId=" + this.birimId;
    }

    async parse(data: any): Promise<MtsTakipTalebiIndirCevap> {
        return new Promise((resolve, reject) => {
            let blobData = data;
            let reader = new FileReader();
            reader.readAsText(data); //udf base64 ceviriliyor..
            reader.onloadend = () => {
                let textContent = reader.result;
                //@ts-ignore
                if (textContent.substr(0, 2) == "PK") {
                    return resolve(blobData);
                } else {
                    /**
                     * 08/04/2019
                     * uyap güncellemesi ile
                     * GELEN UDF zip olarak gelmiyor artık gelen xml datası mı diye text e çevirilip kontrol ediliyor.
                     * eğer zip olarak gelirse X tarihte ona göre de kontrol koyuldu.
                     * PK gelen data zip mi değilmi kontrolüdür..
                     * Emre
                     * @return zip blob data;
                     */
                    let zip = new JSZip();
                    zip.file("content.xml", blobData);
                    zip.generateAsync({
                        type: "blob",
                        compression: "DEFLATE",
                        compressionOptions: {
                            level: 6
                        }
                    }).then((content) => {
                        let blob = new Blob([content], {type: "application/octet-stream"});//udf type ına çeviriliyor.
                        console.log("UDF CONVERTING BLOB", blob);
                        return resolve({file: blob});
                    }).catch(function (err) {
                        return reject(err);
                    });
                }
            }
        })
    }

}
