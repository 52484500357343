import {UyapRequest} from "./UyapRequest";

export interface ParaBirimleriTalep {
    // Boş
}

export interface ParaBirimleriCevap {
    items: Array<ParaBirimleriCevapRow>
}

export interface ParaBirimleriCevapRow {
    name: string,
    value: string
}

export default class ParaBirimleri extends UyapRequest<ParaBirimleriTalep, ParaBirimleriCevap> {
    delayTime = 0;

    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "ParaBirimleri"
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/get_para_birimleriJSON.ajx";
    }

    async parse(data: any): Promise<ParaBirimleriCevap> {
        return {items: data}
    }

    getMockResponse(): any {
        return [{"name": "ANDORRA PESETA\u0027SI", "value": "PRBRMADP"}, {
            "name": "UAE DİRHAM\u0027I",
            "value": "PRBRMAED"
        }, {"name": "AFGHANİ", "value": "PRBRMAFA"}, {
            "name": "ARNAVUTLUK LEK\u0027İ",
            "value": "PRBRMALL"
        }, {"name": "DRAM", "value": "PRBRMAMD"}, {
            "name": "ARJANTİN PESO\u0027SU",
            "value": "PRBRMARA"
        }, {"name": "AVUSTURYA ŞİLİN\u0027İ", "value": "PRBRMATS"}, {
            "name": "AVUSTRALYA DOLARI",
            "value": "PRBRMAUD"
        }, {"name": "ARUBA FLORİNİ", "value": "PRBRMAWG"}, {
            "name": "AZERBAYCAN MANAT\u0027I",
            "value": "PRBRMAZM"
        }, {"name": "BOSNA HERSEK DİNARI", "value": "PRBRMBAD"}, {
            "name": "BARBADOS DOLARI",
            "value": "PRBRMBBD"
        }, {"name": "TAKA", "value": "PRBRMBDT"}, {"name": "BELÇİKA FRANGI", "value": "PRBRMBEF"}, {
            "name": "LEV",
            "value": "PRBRMBGL"
        }, {"name": "BGN - BULGAR LEVASI", "value": "PRBRMBGN"}, {
            "name": "BAHREYN DİNARI",
            "value": "PRBRMBHD"
        }, {"name": "BRUNDİ FRANGI", "value": "PRBRMBIF"}, {
            "name": "BERMUDA DOLARI",
            "value": "PRBRMBMD"
        }, {"name": "BRUNEİ DOLARI", "value": "PRBRMBND"}, {
            "name": "BOLİVYA PESOSU",
            "value": "PRBRMBOP"
        }, {"name": "BREZİLYA REAL\u0027İ", "value": "PRBRMBRR"}, {
            "name": "BAHAMA DOLARI",
            "value": "PRBRMBSD"
        }, {"name": "PULA", "value": "PRBRMBWP"}, {
            "name": "BEYAZ RUSYA RUBLE\u0027Sİ",
            "value": "PRBRMBYR"
        }, {"name": "BELİZE DOLARI", "value": "PRBRMBZD"}, {
            "name": "KANADA DOLARI",
            "value": "PRBRMCAD"
        }, {"name": "ZAİRE", "value": "PRBRMCDZ"}, {
            "name": "İSVİÇRE FRANK\u0027I",
            "value": "PRBRMCHF"
        }, {"name": "ŞİLİ PESOSU", "value": "PRBRMCLP"}, {
            "name": "YUAN RENMİNBİ",
            "value": "PRBRMCNY"
        }, {"name": "KOLOMBİYA PESO\u0027SU", "value": "PRBRMCOP"}, {
            "name": "KOSTARİKO KOLON\u0027U",
            "value": "PRBRMCRC"
        }, {"name": "KÜBA PESO\u0027SU", "value": "PRBRMCUP"}, {
            "name": "KIBRIS POUND\u0027U",
            "value": "PRBRMCYP"
        }, {"name": "KORUNA", "value": "PRBRMCZK"}, {
            "name": "DEM - Alman Markı",
            "value": "PRBRMDEM"
        }, {"name": "DANİMARKA KRONU", "value": "PRBRMDKK"}, {
            "name": "CEZAİR DİNAR\u0027I",
            "value": "PRBRMDZD"
        }, {"name": "SUCRE", "value": "PRBRMECS"}, {
            "name": "KROON",
            "value": "PRBRMEEK"
        }, {"name": "MISIR POUND\u0027U", "value": "PRBRMEGP"}, {
            "name": "İSPANYA PESETA\u0027SI",
            "value": "PRBRMESP"
        }, {"name": "BİRR", "value": "PRBRMETB"}, {"name": "EUR - Euro", "value": "PRBRMEUR"}, {
            "name": "MARKKA",
            "value": "PRBRMFIM"
        }, {"name": "FİJİ DOLARI", "value": "PRBRMFJD"}, {
            "name": "FRANSIZ FRANK\u0027I",
            "value": "PRBRMFRF"
        }, {"name": "POUND", "value": "PRBRMGBP"}, {"name": "LARİ", "value": "PRBRMGEL"}, {
            "name": "CEDİ",
            "value": "PRBRMGHC"
        }, {"name": "DALASİ", "value": "PRBRMGMD"}, {
            "name": "GİNE FRANK\u0027I",
            "value": "PRBRMGNS"
        }, {"name": "DRACHMA", "value": "PRBRMGRD"}, {"name": "QUETZAL", "value": "PRBRMGTQ"}, {
            "name": "GUYANA DOLARI",
            "value": "PRBRMGYD"
        }, {"name": "HONK KONG DOLARI", "value": "PRBRMHKD"}, {
            "name": "HIRVATİSTAN DİNAR\u0027I",
            "value": "PRBRMHRD"
        }, {"name": "GOURDE", "value": "PRBRMHTG"}, {"name": "FORİNT", "value": "PRBRMHUF"}, {
            "name": "RUPİAH",
            "value": "PRBRMIDR"
        }, {"name": "PUNT", "value": "PRBRMIEP"}, {
            "name": "SHEKEL",
            "value": "PRBRMILS"
        }, {"name": "HİNDİSTAN RUPEE\u0027Sİ", "value": "PRBRMINR"}, {
            "name": "IRAK DİNAR\u0027I",
            "value": "PRBRMIQD"
        }, {"name": "İRAN RİAL\u0027İ", "value": "PRBRMIRR"}, {
            "name": "İSLANDA KRONA\u0027SI",
            "value": "PRBRMISK"
        }, {"name": "İNGİLİZ STERLİNİ", "value": "PRBRMIST"}, {
            "name": "İTALYAN LİRASI",
            "value": "PRBRMITL"
        }, {"name": "JAMAİKA DOLARI", "value": "PRBRMJMD"}, {
            "name": "ÜRDÜN DİNAR\u0027I",
            "value": "PRBRMJOD"
        }, {"name": "YEN", "value": "PRBRMJPY"}, {
            "name": "KENYA ŞİLİN\u0027İ",
            "value": "PRBRMKES"
        }, {"name": "KIRGIZİSTAN SOM\u0027U", "value": "PRBRMKGS"}, {
            "name": "KAMBOÇYA RİEL\u0027İ",
            "value": "PRBRMKHR"
        }, {"name": "KUZEY KORE WON\u0027U", "value": "PRBRMKPW"}, {
            "name": "GÜNEY KORE WON\u0027U",
            "value": "PRBRMKRW"
        }, {"name": "KUVEYT DİNARI", "value": "PRBRMKWD"}, {
            "name": "TENGE",
            "value": "PRBRMKZT"
        }, {"name": "LÜBNAN POUND\u0027U", "value": "PRBRMLBP"}, {
            "name": "SRİLANKA RUPEE\u0027Sİ",
            "value": "PRBRMLKR"
        }, {"name": "LİBERYA DOLARI", "value": "PRBRMLRD"}, {
            "name": "LİTAS",
            "value": "PRBRMLTL"
        }, {"name": "LÜKSEMBURG FRANK\u0027I", "value": "PRBRMLUF"}, {
            "name": "LAT",
            "value": "PRBRMLVL"
        }, {"name": "FAS DİRHAM\u0027I", "value": "PRBRMMAD"}, {
            "name": "LEU",
            "value": "PRBRMMDL"
        }, {"name": "MADAGASKAR FRANK\u0027I", "value": "PRBRMMGF"}, {
            "name": "MAKEDONYA DİNAR\u0027I",
            "value": "PRBRMMKD"
        }, {"name": "MALİ FRANK\u0027I", "value": "PRBRMMLF"}, {
            "name": "TUGRİK",
            "value": "PRBRMMNT"
        }, {"name": "OUGUİYA", "value": "PRBRMMRO"}, {
            "name": "MALTA LİRASI",
            "value": "PRBRMMTL"
        }, {"name": "MUR - MAURİTİUS RUPİSİ", "value": "PRBRMMUR"}, {
            "name": "RUFİYAA",
            "value": "PRBRMMVR"
        }, {"name": "KWACHA", "value": "PRBRMMWK"}, {
            "name": "MEKSİKA PESO\u0027SU",
            "value": "PRBRMMXN"
        }, {"name": "MALEZYA DOLARI", "value": "PRBRMMYR"}, {
            "name": "METİCAL",
            "value": "PRBRMMZM"
        }, {"name": "GÜNEY BATI AFRİKA DOLARI", "value": "PRBRMNAD"}, {
            "name": "NAİRA",
            "value": "PRBRMNGN"
        }, {"name": "CORDOBA", "value": "PRBRMNIC"}, {
            "name": "GULDEN",
            "value": "PRBRMNLG"
        }, {"name": "NORVEÇ KRON\u0027U", "value": "PRBRMNOK"}, {
            "name": "NEPAL RUPEE\u0027Sİ",
            "value": "PRBRMNPR"
        }, {"name": "YENİ ZELANDA DOLARI", "value": "PRBRMNZD"}, {
            "name": "UMMAN RİAL\u0027İ",
            "value": "PRBRMOMR"
        }, {"name": "BALBOA", "value": "PRBRMPAB"}, {
            "name": "PERU INTİ\u0027Sİ",
            "value": "PRBRMPEI"
        }, {"name": "PAPUA YENİ GİNE KİNA\u0027SI", "value": "PRBRMPGK"}, {
            "name": "FİLİPİNLER PESO\u0027SU",
            "value": "PRBRMPHP"
        }, {"name": "PAKİSTAN RUPEE\u0027Sİ", "value": "PRBRMPKR"}, {
            "name": "ZLOTY",
            "value": "PRBRMPLN"
        }, {"name": "ESCUDO", "value": "PRBRMPTE"}, {
            "name": "GUARANİ",
            "value": "PRBRMPYG"
        }, {"name": "KATAR RİYAL\u0027İ", "value": "PRBRMQAR"}, {
            "name": "LEU",
            "value": "PRBRMROL"
        }, {"name": "RUMEN LEYİ", "value": "PRBRMRON"}, {"name": "RUS RUBLESİ", "value": "PRBRMRUB"}, {
            "name": "RUBLE",
            "value": "PRBRMRUR"
        }, {"name": "RUANDA FRANK\u0027I", "value": "PRBRMRWF"}, {
            "name": "SUUDİ RİYAL\u0027İ",
            "value": "PRBRMSAR"
        }, {"name": "SEYŞEL RUPEE\u0027Sİ", "value": "PRBRMSCR"}, {
            "name": "SUDAN POUND\u0027U",
            "value": "PRBRMSDP"
        }, {"name": "İSVEÇ KRONA\u0027SI", "value": "PRBRMSEK"}, {
            "name": "SİNGAPUR DOLARI",
            "value": "PRBRMSGD"
        }, {"name": "TOLAR", "value": "PRBRMSIT"}, {
            "name": "SLOVAKYA KORUNA\u0027SI",
            "value": "PRBRMSKK"
        }, {"name": "SOMALİ ŞİLİN\u0027İ", "value": "PRBRMSOS"}, {
            "name": "EL SALVADOR KOLON\u0027U",
            "value": "PRBRMSVC"
        }, {"name": "SURİYE POUND\u0027U", "value": "PRBRMSYP"}, {
            "name": "TAYLAND BAHT\u0027I",
            "value": "PRBRMTHB"
        }, {"name": "TACİKİSTAN RUBLE\u0027Sİ", "value": "PRBRMTJR"}, {
            "name": "TL-Türk Lirası",
            "value": "PRBRMTL"
        }, {"name": "TÜRKMENİSTAN MANAT\u0027I", "value": "PRBRMTMM"}, {
            "name": "TUNUS DİNAR\u0027I",
            "value": "PRBRMTND"
        }, {"name": "TONGA PA\u0027ANGA\u0027SI", "value": "PRBRMTOP"}, {
            "name": "TAYVAN DOLARI",
            "value": "PRBRMTWD"
        }, {"name": "TANZANYA ŞİLİN\u0027İ", "value": "PRBRMTZS"}, {
            "name": "UKRAYNA HRYVNA\u0027SI",
            "value": "PRBRMUAH"
        }, {"name": "UGANDA ŞİLİN\u0027İ", "value": "PRBRMUGS"}, {
            "name": "USD - Amerikan Doları",
            "value": "PRBRMUSD"
        }, {"name": "URUGUAY PESO\u0027SU", "value": "PRBRMUYU"}, {
            "name": "ÖZBEKİSTAN SOM\u0027U",
            "value": "PRBRMUZS"
        }, {"name": "BOLİVAR", "value": "PRBRMVEV"}, {"name": "DONG", "value": "PRBRMVND"}, {
            "name": "AFRİKA FRANGI",
            "value": "PRBRMXAF"
        }, {"name": "DOGU KARAİB DOLARI", "value": "PRBRMXCD"}, {
            "name": "BATI AFRİKA FRANK\u0027I",
            "value": "PRBRMXOF"
        }, {"name": "YEMEN RİYAL\u0027İ", "value": "PRBRMYER"}, {
            "name": "YUGOSLAVYA DİNAR\u0027I",
            "value": "PRBRMYUD"
        }, {"name": "GÜNEY AFRİKA RAND\u0027I", "value": "PRBRMZAR"}, {
            "name": "ZAMBİYA KWACHA\u0027SI",
            "value": "PRBRMZMK"
        }, {"name": "ZİMBABVE DOLARI", "value": "PRBRMZWD"}, {"name": "TRL - Türk Lirası", "value": "PRBRM01"}]
    }

}