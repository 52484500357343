export default class Args {
    counter: number = 0;
    ilamCounter: number = 0;
    icraproXmliMi: boolean = false;
    ilamliMi: boolean = false;

    constructor(icraproXmliMi = false, ilamliMi = false) {
        this.counter = 0; // belgeIdleri icin counter
        this.icraproXmliMi = icraproXmliMi;
        this.ilamliMi = ilamliMi;
    }

    static staticFromJson(jsonData: any, obj :any): any {
        let keys = Object.keys(jsonData);
        if(keys.length == 0)
            return {};
        keys.forEach(key => {
            //console.log("key",key);
            //console.log("jsonData[key]",jsonData[key]);
            if(jsonData[key] == null)
                jsonData[key] = "";
            // @ts-ignore
            obj[key] = jsonData[key];
        });
        return obj;
    }

    static fromJson(jsonData: any): Args {
        return Args.staticFromJson(jsonData,new Args());
    }

    counterId() {
        let counterId = this.counter;
        this.counter++;
        return counterId;
    }

    ilamCounterId() {
        let counterId = this.ilamCounter;
        this.ilamCounter++;
        return counterId;
    }

    setIlamli() {
        this.ilamliMi = true;
    }

    setIcraproXmliMi(val: boolean) {
        this.icraproXmliMi = val;
    }

    isIcrapro() {
        return this.icraproXmliMi;
    }

    isIlamli() {
        return this.ilamliMi;
    }
}