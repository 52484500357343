import {UyapRequest} from "./UyapRequest";
import UyapHtmlParser from "./parser/UyapHtmlParser";

export interface DosyaTaraflarTalep {
    dosyaId: any
}

export class DosyaTaraflarCevap {
    items: DosyaTaraflarCevapRow[] = [];
    message: string = "";
}

export interface DosyaTaraflarCevapRow {
    rol: string;
    tipi: string;
    kisiKurumID: string;
    adi: string;
    vekil: string;
}

export class DosyaTaraflar extends UyapRequest<DosyaTaraflarTalep, DosyaTaraflarCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "DosyaTaraflar_" + this.talepData.dosyaId
        };
    }

    getMockResponse(): string {
        return `<div id="tarafEkleButtonDiv" style="display:none"></div><table class="table table-striped table-bordered table-advance table-hover" id="taraf_listesi_table"><thead><th>Rol</th><th>Tipi</th><th class="hidden"></th><th>Adı</th><th>Vekil</th><th style="width:12%">İşlem</th></thead><tbody><tr class="even"><td>Borçlu</td><td>Kişi</td><td class="hidden">134062154</td><td>M___ B___</td><td></td><td></td></tr><tr class="odd"><td>Alacaklı</td><td>Kurum</td><td class="hidden">159636789</td><td>V____ ANONİM ŞİRKETİ</td><td>[C___ T___, B___ O___]</td><td></td></tr><tr class="even"><td>Üçüncü Şahıs</td><td>Kurum</td><td class="hidden">2097456789</td><td>Z___ LTD. ŞTİ.</td><td>[N___ E___]</td><td></td></tr></tbody><tfoot><th class="footerSearch">Rol</th><th class="footerSearch">Tipi</th><th class="hidden"></th><th class="footerSearch">Adı</th><th class="footerSearch">Vekil</th><th></th></tfoot></table>`
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/dosya_taraf_bilgileri_brd.ajx';
    }

    async parse(data: string): Promise<DosyaTaraflarCevap> {
        let div: HTMLElement = document.createElement('div');
        div.innerHTML = data;
        let response = new DosyaTaraflarCevap();

        response.items = UyapHtmlParser.nodeListMap<DosyaTaraflarCevapRow>(
            (div.querySelector('tbody') as HTMLElement).querySelectorAll('tr'),
            (tr) => {
                let tds = tr.querySelectorAll('td');
                return {
                    rol: tds[0].innerText,
                    tipi: tds[1].innerText,
                    kisiKurumID: tds[2].innerText,
                    adi: tds[3].innerText,
                    vekil: tds[4].innerText
                }
            });
        return response;
    }

}

export default DosyaTaraflar;
