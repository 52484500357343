export class ITabloParser<T> {
    data: Array<T> = [];
    found: boolean = false;
    message: string = "";
    html: string = "";
}

export interface IIcerikAl {
    baslik: string,
    icerik: string
}

export interface AdresRow {
    ilKodu: number,
    ilAdi: string,
    ilceAdi: string,
    ilceKodu: number,
    adres: string,
    kisiKurumID: number,
    adresID: number,
    adresTuru: string,
    eklenebilirAdresMi: boolean,
    kayitliAdresMi: boolean,
    aktifAdresmi: boolean,
    guncellendiMi: boolean
}

export interface adresDVORow {
    id: number,
    adresId: number,
    dosyaId: number,
    ilkKisiKurumId: number,
    guncelKisiKurumId: number,
    gizliYazismaAdresi: boolean,
    kisikurumtur: string,
    adresDVO: AdresRow
}

export interface MahkemeBirimleriRow {
    birimAdi: string,
    birimId: number,
    evraginBirimDVODaGonderilecegiBirimBilgisi: number,
    yeniBirimEkle: boolean,
    orgKoduDegisti: boolean,
    eskiAd: string,
    taranmamisKaydetme: string,
    testMi: boolean
}
