import {UyapRequest} from "./UyapRequest";

export interface GetDosyaTarafBilgileriTalep {
    dosyaId: number
}

export interface GetDosyaTarafBilgileriCevap {
    items: GetDosyaTarafBilgileriCevapRow[]
}

export interface GetDosyaTarafBilgileriCevapRow {
    aboneVarmi: string;
    dosyaKisiDVO: DosyaKisiDVO;
    secili: string;
    dogacakHakDahilmi: string;
    borcluBelediye: string;
    maasHesabiHaric: string;
}

export interface KisiBilgDVO {
    adi: string;
    soyadi: string;
    isEngelliKisiKurum: string;
}

export interface KurumBilgDVO {
    isEngelliKisiKurum: string;
    kurumAdi: string;
}

export interface DosyaKisiDVO {
    kisiMiKurumMu: string;
    ilkKisiKurumId: string;
    tarafRolu: number;
    dosyaKisiId: string;
    aboneVarmi: string;
    kisiBilgDVO: KisiBilgDVO;
    kurumBilgDVO: KurumBilgDVO;
    dosyasiIncelenebilir: string;
}

export default class GetDosyaTarafBilgileri extends UyapRequest<GetDosyaTarafBilgileriTalep, GetDosyaTarafBilgileriCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "GetDosyaTarafBilgileri_" + this.talepData.dosyaId
        };
    }

    getMockResponse(): string {
        return `<root><DVOList>
    <liste>
      <DosyaSucVekilBilgiliTarafDVO>
        <aboneVarmi>true</aboneVarmi>
        <kisiKurumBilgileriDVO/>
        <dosyaKisiDVO>
          <kisiMiKurumMu>0</kisiMiKurumMu>
          <ilkKisiKurumId>134062990</ilkKisiKurumId>
          <tarafRolu>22</tarafRolu>
          <dosyaKisiId>500464296</dosyaKisiId>
          <aboneVarmi>true</aboneVarmi>
          <kisiBilgDVO>
            <adi>MEHMET</adi>
            <soyadi>BODUR</soyadi>
            <isEngelliKisiKurum>false</isEngelliKisiKurum>
          </kisiBilgDVO>
          <kurumBilgDVO>
            <isEngelliKisiKurum>false</isEngelliKisiKurum>
          </kurumBilgDVO>
          <dosyasiIncelenebilir>true</dosyasiIncelenebilir>
        </dosyaKisiDVO>
        <secili>false</secili>
        <dogacakHakDahilmi>false</dogacakHakDahilmi>
        <borcluBelediye>false</borcluBelediye>
        <maasHesabiHaric>false</maasHesabiHaric>
      </DosyaSucVekilBilgiliTarafDVO>
      <DosyaSucVekilBilgiliTarafDVO>
        <aboneVarmi>true</aboneVarmi>
        <kisiKurumBilgileriDVO/>
        <dosyaKisiDVO>
          <kisiMiKurumMu>1</kisiMiKurumMu>
          <ilkKisiKurumId>155636343</ilkKisiKurumId>
          <tarafRolu>21</tarafRolu>
          <dosyaKisiId>915824628</dosyaKisiId>
          <aboneVarmi>true</aboneVarmi>
          <kisiBilgDVO>
            <isEngelliKisiKurum>false</isEngelliKisiKurum>
          </kisiBilgDVO>
          <kurumBilgDVO>
            <kurumAdi>EFES VARLIK YÖNETİM ANONİM ŞİRKETİ</kurumAdi>
            <isEngelliKisiKurum>false</isEngelliKisiKurum>
          </kurumBilgDVO>
          <dosyasiIncelenebilir>true</dosyasiIncelenebilir>
        </dosyaKisiDVO>
        <secili>false</secili>
        <dogacakHakDahilmi>false</dogacakHakDahilmi>
        <borcluBelediye>false</borcluBelediye>
        <maasHesabiHaric>false</maasHesabiHaric>
      </DosyaSucVekilBilgiliTarafDVO>
      <DosyaSucVekilBilgiliTarafDVO>
        <aboneVarmi>true</aboneVarmi>
        <kisiKurumBilgileriDVO/>
        <dosyaKisiDVO>
          <kisiMiKurumMu>1</kisiMiKurumMu>
          <ilkKisiKurumId>209751419</ilkKisiKurumId>
          <tarafRolu>26</tarafRolu>
          <dosyaKisiId>915824638</dosyaKisiId>
          <aboneVarmi>true</aboneVarmi>
          <kisiBilgDVO>
            <isEngelliKisiKurum>false</isEngelliKisiKurum>
          </kisiBilgDVO>
          <kurumBilgDVO>
            <kurumAdi>ZİRVE BİLGİSAYAR TİC. VE SAN. LTD. ŞTİ.</kurumAdi>
            <isEngelliKisiKurum>false</isEngelliKisiKurum>
          </kurumBilgDVO>
          <dosyasiIncelenebilir>true</dosyasiIncelenebilir>
        </dosyaKisiDVO>
        <secili>false</secili>
        <dogacakHakDahilmi>false</dogacakHakDahilmi>
        <borcluBelediye>false</borcluBelediye>
        <maasHesabiHaric>false</maasHesabiHaric>
      </DosyaSucVekilBilgiliTarafDVO>
    </liste>
    <actualCount>0</actualCount>
    <currentPage>0</currentPage>
    <pageSize>0</pageSize>
    <cacheID>0</cacheID>
    <recordCount>0</recordCount>
    </DVOList></root>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/getDosyaTarafBilgileri_brd.ajx';
    }

    async parse(data: string): Promise<GetDosyaTarafBilgileriCevap> {
        let json: any = await this.xml2json(data);
        let dosyaTarafBilgileri = json.root['DVOList'].liste.DosyaSucVekilBilgiliTarafDVO;
        let items: Array<GetDosyaTarafBilgileriCevapRow> = [];
        dosyaTarafBilgileri.forEach((dosyaTarafBilgisi: any) => {
            items.push({
                aboneVarmi: dosyaTarafBilgisi.aboneVarmi,
                dosyaKisiDVO: {
                    aboneVarmi: dosyaTarafBilgisi.dosyaKisiDVO.aboneVarmi,
                    dosyaKisiId: dosyaTarafBilgisi.dosyaKisiDVO.dosyaKisiId,
                    dosyasiIncelenebilir: dosyaTarafBilgisi.dosyaKisiDVO.dosyasiIncelenebilir,
                    ilkKisiKurumId: dosyaTarafBilgisi.dosyaKisiDVO.ilkKisiKurumId,
                    kisiMiKurumMu: dosyaTarafBilgisi.dosyaKisiDVO.kisiMiKurumMu,
                    kisiBilgDVO: {
                        adi: dosyaTarafBilgisi.dosyaKisiDVO.kisiBilgDVO.adi ? dosyaTarafBilgisi.dosyaKisiDVO.kisiBilgDVO.adi : "",
                        soyadi: dosyaTarafBilgisi.dosyaKisiDVO.kisiBilgDVO.soyadi ? dosyaTarafBilgisi.dosyaKisiDVO.kisiBilgDVO.soyadi : "",
                        isEngelliKisiKurum: dosyaTarafBilgisi.dosyaKisiDVO.kisiBilgDVO.isEngelliKisiKurum ? dosyaTarafBilgisi.dosyaKisiDVO.kisiBilgDVO.isEngelliKisiKurum : "",
                    },
                    kurumBilgDVO: {
                        isEngelliKisiKurum: dosyaTarafBilgisi.dosyaKisiDVO.kurumBilgDVO.isEngelliKisiKurum ? dosyaTarafBilgisi.dosyaKisiDVO.kurumBilgDVO.isEngelliKisiKurum : "",
                        kurumAdi: dosyaTarafBilgisi.dosyaKisiDVO.kurumBilgDVO.kurumAdi ? dosyaTarafBilgisi.dosyaKisiDVO.kurumBilgDVO.kurumAdi : "",
                    },
                    tarafRolu: dosyaTarafBilgisi.dosyaKisiDVO.tarafRolu,
                },
                secili: dosyaTarafBilgisi.secili,
                dogacakHakDahilmi: dosyaTarafBilgisi.dogacakHakDahilmi,
                borcluBelediye: dosyaTarafBilgisi.borcluBelediye,
                maasHesabiHaric: dosyaTarafBilgisi.maasHesabiHaric,
            })
        });
        return {
            items: items
        };
    }
}
