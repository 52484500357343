import _Vue from "vue";
import app from "@/main";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";

export class Helper {
    download(name: string, URL: string) {
        return new Promise((resolve, reject) => {
            app.$http({
                url: URL,
                method: 'GET',
                responseType: 'blob'
            }).then((response: any) => {
                let fileURL = window.URL.createObjectURL(new Blob([!!response.data ? response.data : response]));
                let fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', name);
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(response);
            }).catch((error) => {
                app.$toast.error(error.message)
                reject(error.message);
            });
        });
    }

    tarihStr(val: Date | string): string {
        try {
            if (typeof val === "string") { //
                return val.substr(0, 10).split('-').reverse().join('/');
            } else if (typeof val === "object") {
                return val.toISOString().substr(0, 10).split('-').reverse().join('/');
            }
        } catch (e) {
            console.error(e)
        }
        return "";
    }

    saatStr(val: Date | string): string {
        try {
            if (typeof val === "string") {
                return val.substr(11, 8);
            } else if (typeof val === "object") {
                return val.toISOString().substr(11, 5);
            }
        } catch (e) {
            console.error(e)
        }
        return "";
    }

    tarihSaatStr(val: Date | null, convertToUtc3: boolean = false): string {
        if (val === null) {
            return "";
        }
        try {
            let date: any = val;
            if (convertToUtc3) {
                let convertedDate = new Date(date).setHours(new Date(date).getHours() + 3);
                date = new Date(convertedDate).toISOString();
            }
            return this.tarihStr(date) + " " + this.saatStr(date);
        } catch (e) {
            return "";
        }
    }

    tutarStr(val: number, paraBirimi: ParaBirimiEntity | null = null): string {
        // let temp = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY'}).format(val);
        if (!val) {
            val = 0.00;
        }
        return new Intl.NumberFormat('tr-TR', {minimumFractionDigits: 2}).format(val) + " " + (paraBirimi ? paraBirimi?.kisa_isim : " TL");

    }
}

export default function HelperPlugin(Vue: typeof _Vue): void {
    Vue.prototype.$helper = new Helper();
}