import {UyapRequest} from "./UyapRequest";
import {ResponseType} from "axios";

export class MakbuzIndirTalep {
    uyapId!: number;
    harcMiMasrafMi: string = "";
    uyapIslemTur!: number;
}

export default class MakbuzIndir extends UyapRequest<MakbuzIndirTalep, Blob> {
    delayTime = 1000;

    getMethod(): "POST" | "GET" {
        return 'GET';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): Blob {
        return new Blob([], {});
    }

    getResponseType(): ResponseType {
        return 'blob';
    }

    //OdemeIslemlerim.makbuzIndir(395606894,'',16);
    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/odeme_makbuz_indir.uyap';//?uyapId='+this.uyapId+'&harcMiMasrafMi=&uyapIslemTur='+this.uyapIslemTur;
    }

    async parse(data: any): Promise<Blob> {
        //@ts-ignore
        if (!data.name) {
            //@ts-ignore
            data.name = "hata.html";
        }
        return data;
    }
}
