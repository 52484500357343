import Vue from 'vue';
import {AxiosPlugin} from './plugins/axios';
import App from './App.vue';
import router from './router';
import {store} from './store';
import vuetify from './plugins/vuetify';
import helper from './plugins/helper';
import imza from './plugins/uyap-plugin/imza';
import uyap from './plugins/uyap-plugin/uyap';
import uyapHelper from './plugins/uyap-plugin/helper';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;

const toastOptions = {
  // Default bildirim ayarları
  transition: "Vue-Toastification__fade",
  position: 'top-right',
  timeout: 4000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  // Custom icon kullanımı
  // icon: {
  //     iconClass: 'mdi mdi-eye',
  //     iconTag: 'span'
  // },
  maxToasts: 5
}

Vue.use(helper);
Vue.use(imza);
Vue.use(uyap);
Vue.use(uyapHelper);
Vue.use(Toast, toastOptions);
Vue.use(AxiosPlugin);

const app: Vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
export default app;
