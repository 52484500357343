import {MessageLogger} from "./MessageLogger";
import {TakipAcStatusEnum} from "./parser/TakipAcStatusEnum";
import app from "@/main";
import {Mixins} from "./Mixins";
import {EvrakGonderCevap} from "../uyap/DosyaAcilisEvrakGonder";
import TakipSenkronize from "./TakipSenkronize";
import UyapTakipTalebiContent from "./UyapTakipTalebiContent";

export default class TakipAcInjectable extends MessageLogger {
    status: TakipAcStatusEnum = TakipAcStatusEnum.QUERY_PENDING;
    imzasizUdf !: Blob;
    imzaliUdf !: Blob;
    vekalet !: Blob;
    takipAcilisEvraklariResponse!: EvrakGonderCevap;

    takipBilgileri = {
        dosyaEsasNo: "",
        icraDairesi: ""
    };
    dayanaklar: Blob[] = []

    constructor() {
        super();
    }

    setStatus(status: TakipAcStatusEnum) {
        this.status = status;
    }

    mukerrerDosyaKontrol(hash: string, type: "xml" | "uyap"): Promise<any> {
        return new Promise(async (resolve, reject) => {
            //        //this.hashObj, this.tarafList, this.toplamTakibeEsasMiktar
            try {
                let isMukerrer: any = await app.$http.post('/api/v1/hash-kontrol/' + type, {
                    hash: hash,
                    type: type,
                });
                return reject({message: ('Seçili takip daha önce tevzi almıştır. Hash: #' + hash), dosya: isMukerrer.dosya});
            } catch (e) {
                return resolve({});
            }
        })
    }


    mukerrerDosyaKontrolFromTakipTalebi(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let parsedContent: any = await TakipSenkronize.parseContentXml(this.imzasizUdf);
                let uyapTakipTalebiContent = UyapTakipTalebiContent.parse(parsedContent);
                let isMukerrer: any = await app.$http.post('/api/v1/hash-kontrol/uyap', {
                    hash: uyapTakipTalebiContent.hash,
                    type: 'uyap',
                });
                return reject({message: ('Seçili takip daha önce tevzi almıştır. Hash: #' + uyapTakipTalebiContent.hash), dosya: isMukerrer.dosya});
            } catch (e) {
                return resolve({});
            }
        })
    }

    setTakipBilgileriToIcraPro(): Promise<any> {
        // TODO: icw dosya açma isteği tamamlandığında ekle, hash ekleme (hash, dosya bilgileri)
        //Buraya apiden biseyler gonderilebilir ..
        return Promise.resolve();
    }

    async getImzasizUdfFromUyap(dosyaId: number, birimId: any): Promise<void> {
        let takibTalebi = await app.$uyap.TakipTalebiIndir(dosyaId, birimId)
            .run({
                download: true,
                filename: "takip_talebi.udf"
            });
        this.imzasizUdf = takibTalebi.file;
        console.log("this.imzasizUdf", this.imzasizUdf);
    }

    setTakipBilgileriFromTakipAcilisEvraklariResponse(): Promise<any> {
        try {
            let div = document.createElement("div");
            //console.log("takipAcilisEvraklariResponse",this.takipAcilisEvraklariResponse);
            div.innerHTML = this.takipAcilisEvraklariResponse.message;
            let nodeList = div.querySelectorAll("strong");
            if (nodeList.length == 0)
                return Promise.reject({message: "Takip açılış yapılamadı !"});
            this.takipBilgileri.icraDairesi = nodeList[0].innerHTML;
            this.takipBilgileri.dosyaEsasNo = nodeList[1].innerHTML;
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    async takipAcilisEvraklariniGonder(tevziDosyaId: number): Promise<EvrakGonderCevap> {
        try {
            await this.dokumanImzala();
            let evraklarResult = await app.$uyap.DosyaAcilisEvrakGonder().run(
                {
                    formData: true,
                    data: this.getDosyaAcilisEvraklarFormData(tevziDosyaId)
                })
            this.addMessage('success', "Dosya açılış evrakları gönderildi.");
            this.takipAcilisEvraklariResponse = evraklarResult;
            return Promise.resolve(evraklarResult);
        } catch (e: any) {
            this.setStatus(TakipAcStatusEnum.OPEN_FAIL);
            this.addMessage('error', e.message);
            return Promise.reject(e)
        }
    }

    getDosyaAcilisEvraklarFormData(tevziDosyaId: any): FormData {
        //acilisda mandatory true gidiyor ///

        let gonderilecekEvraklar = {
            "tur": "",
            "turAciklama": "",
            "dosyaId": tevziDosyaId,
            "esasNo": "",
            "data": [
                {
                    "tur": "ICR_TAKIP_TLP",
                    "turAciklama": "Takip Talebi",
                    "aciklama": "Takip Talebi",
                    "evrak": this.imzaliUdf,
                    "dosyaEkEvrak": []
                },
                {
                    "tur": "CZM_VEKALETNAME",
                    "turAciklama": "Vekaletname",
                    "aciklama": "Vekaletname",
                    "evrak": this.getVekaletBlob(),
                    "dosyaEkEvrak": []
                }]
        };
        this.getDayanaklarBlobArray().forEach((dayanakBlob) => {
            gonderilecekEvraklar.data.push(
                {
                    "tur": "MTS_TAKIBIN_DAYANAGI",
                    "turAciklama": "Takibin Dayanağı",
                    "aciklama": "Takibin Dayanağı",
                    "evrak": dayanakBlob,
                    "dosyaEkEvrak": []
                }
            );
        })
        console.log("gonderilecekEvraklar", gonderilecekEvraklar);
        let evraklar: any[] = gonderilecekEvraklar.data;
        let items: any[] = [];
        let anaEvrakCounter = 1;
        let counter = 1;
        let form = new FormData();
        let defaultTurlist = ["udf", "pdf", "jpg", "png", "jpeg", "tif", "tiff", "JPG", "JPEG", "PNG", "PDF", "TIF", "TIFF"];
        let turList = [];
        evraklar.forEach((evrak, i) => {
            let evrakName = "evrak_" + anaEvrakCounter + ".udf";
            if (typeof evrak.evrak.name != "undefined") {
                evrakName = evrak.evrak.name;
            } else {
                let fileExt = Mixins.getFileExtension(evrak.evrak.type);
                evrakName = "dosya_" + i + "." + fileExt;
            }
            if (evrak.tur == "ICR_TAKIP_TLP") {
                turList = ["udf"];
            } else {
                turList = defaultTurlist;
            }
            items.push({
                id: i + 1,//i 0 oldugu icin +1 = id 1 den basliyor
                evrakTuruOptionDVO: {
                    tur: evrak.tur,
                    id: evrak.tur,
                    label: evrak.turAciklama,
                    mandatory: true,
                    max: 1,
                    ekEvrakMax: 5,
                    acceptTypeList: {
                        turList: turList
                    }
                },
                tur: evrak.tur,
                turAciklama: evrak.turAciklama,
                mandatory: true,
                file: {},
                path: "C:\\fakepath\\" + evrakName,
                kullaniciEvrakAciklama: evrak.aciklama,
                parentId: -1,//-1
                fileId: anaEvrakCounter
            });
            console.log("EKLENEN EVRAK ", evrak.evrak);
            form.append("file_" + anaEvrakCounter, evrak.evrak, evrakName);
            console.log("F append => ", "file_" + anaEvrakCounter, evrak.evrak, evrakName);
            counter++;
            evrak.dosyaEkEvrak.forEach((dosyaEkEvrak: any) => {
                items.push({
                    id: i + 1,//i 0 oldugu icin +1 = id 1 den basliyor
                    evrakTuruOptionDVO: {
                        tur: evrak.tur,
                        id: evrak.tur,
                        label: evrak.turAciklama,
                        mandatory: false,
                        max: 1,
                        ekEvrakMax: 5,
                        acceptTypeList: {
                            turList: ["udf"]
                        }
                    },
                    tur: evrak.tur,
                    turAciklama: evrak.turAciklama,
                    mandatory: false,
                    file: {},
                    path: "C:\\fakepath\\" + dosyaEkEvrak.name,
                    kullaniciEvrakAciklama: "Ek " + counter,
                    parentId: anaEvrakCounter,
                    fileId: counter
                });

                form.append("file_" + counter, dosyaEkEvrak, dosyaEkEvrak.name);
                console.log("append => ", "file_" + counter, dosyaEkEvrak, dosyaEkEvrak.name);
                counter++;
            })
            anaEvrakCounter = counter;
        })
        form.append("items", JSON.stringify(items));
        form.append("dosyaId", tevziDosyaId);
        return form;
    }

    getDayanaklarBlobArray(): Blob[] {
        return this.dayanaklar;
    }

    getVekaletBlob() {
        return this.vekalet;
        /*
        if (typeof this.par.vekalet.data == "string") {
            this.vekalet.data = this.vekalet.data.replace('<!--[CDATA[', '').replace(']]-->', '');
        }
        let vekaletB64 = this.vekalet.data;
        //vekalet base64 olarak geldiyse
        if (typeof vekaletB64 == "string" && vekaletB64.indexOf(',') > 0) {
            vekaletB64 = this.vekalet.data.split(',')[1];
        }
        console.log("this.vekalet",this.vekalet);
        console.log("this.imzaliUdf",this.imzaliUdf);
        this.vekaletBlob = Mixins.b64toBlob(vekaletB64, this.vekalet.mimeType);
        //application/octet-stream
        console.log("VEKALET BLOB",this.vekaletBlob);
         */
    }


    async dokumanImzala(): Promise<void> {
        //app.$imza.setSimulator();
        this.addMessage("info", "Takip talebi imzalanıyor.")
        let imzaliUdf = await app.$imza.dokumanImzala(this.imzasizUdf);
        this.addMessage('success', 'Takip talebi imzalandı')
        let imzaliUdfBlob: Blob = app.$uyapHelper.base64toBlob(imzaliUdf, "application/octet-stream");
        console.log("Dokuman Imzalandi", imzaliUdfBlob);
        this.imzaliUdf = imzaliUdfBlob;
    }
}

