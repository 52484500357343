import app from "@/main";
import {saveAs} from 'file-saver';
import JSZip from "jszip";

export default class MakbuzController {
    static async getMakbuzBlob(
        uyapId: number,
        uyapIslemTur: number
    ): Promise<Blob> {
        return await app.$uyap.MakbuzIndir().run({
            uyapId: uyapId,
            harcMiMasrafMi: "",
            uyapIslemTur: uyapIslemTur
        });
    }

    static async download(uyapId: number,
                          uyapIslemTur: number,
                          fileName: string
    ): Promise<void> {
        let blob: Blob = await this.getMakbuzBlob(uyapId, uyapIslemTur);
        //@ts-ignore
        fileName = fileName + blob.name.substr(blob.name.lastIndexOf('.'))
        saveAs(blob, fileName);
    }

    static async saveAsZipFromBlobArray(blobArray: Blob[]) {
        try {
            let zipFile = new JSZip();
            for (const blob of blobArray) {
                //@ts-ignore
                zipFile.file(app.$uyapHelper.seoUrl(blob.name), blob);
            }
            console.log("all success ? ");
            zipFile.generateAsync({type: "blob"}).then((content) => {
                // see FileSaver.js
                saveAs(content, "makbuzlar.zip");
                return Promise.resolve();
            });
        } catch (e) {
            return Promise.reject(e);
        }
    }
}