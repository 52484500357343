import {UyapRequest} from "@/plugins/uyap-plugin/uyap/UyapRequest";

export interface IldekiAdliyelerTalep {
    il: number | string
}

export interface IldekiAdliyelerCevap {
    birimAdi: string,
    birimId: string,
    orgKodu: string,
    evraginBirimDVODaGonderilecegiBirimBilgisi: string,
    yeniBirimEkle: string,
    orgKoduDegisti: string,
    isTumunuKopyala: string,
    eskiAd: string,
    eskiOrgKod: string,
    taranmamisKaydetme: string,
    testMi: string
}

export default class IldekiAdliyeler extends UyapRequest<IldekiAdliyelerTalep, IldekiAdliyelerCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "ildekiAdliyeler_" + this.talepData.il
        };
    }

    getMockResponse() {
        return `<root><object-array>
  <object-array>
    <list>
      <BirimDVO>
        <birimAdi>Gümüşhane Adliye</birimAdi>
        <birimId>1004291</birimId>
        <orgKodu>1.04.060.000.4001</orgKodu>
        <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
        <yeniBirimEkle>true</yeniBirimEkle>
        <orgKoduDegisti>false</orgKoduDegisti>
        <isTumunuKopyala>false</isTumunuKopyala>
        <eskiAd/>
        <eskiOrgKod/>
        <taranmamisKaydetme/>
        <testMi>0</testMi>
      </BirimDVO>
      <BirimDVO>
        <birimAdi>Kelkit Adliye</birimAdi>
        <birimId>1004300</birimId>
        <orgKodu>1.04.060.001.4001</orgKodu>
        <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
        <yeniBirimEkle>true</yeniBirimEkle>
        <orgKoduDegisti>false</orgKoduDegisti>
        <isTumunuKopyala>false</isTumunuKopyala>
        <eskiAd/>
        <eskiOrgKod/>
        <taranmamisKaydetme/>
        <testMi>0</testMi>
      </BirimDVO>
      <BirimDVO>
        <birimAdi>Şiran Adliye</birimAdi>
        <birimId>1004319</birimId>
        <orgKodu>1.04.060.004.4001</orgKodu>
        <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
        <yeniBirimEkle>true</yeniBirimEkle>
        <orgKoduDegisti>false</orgKoduDegisti>
        <isTumunuKopyala>false</isTumunuKopyala>
        <eskiAd/>
        <eskiOrgKod/>
        <taranmamisKaydetme/>
        <testMi>0</testMi>
      </BirimDVO>
      <BirimDVO>
        <birimAdi>Torul Adliye</birimAdi>
        <birimId>1004329</birimId>
        <orgKodu>1.04.060.005.4001</orgKodu>
        <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
        <yeniBirimEkle>true</yeniBirimEkle>
        <orgKoduDegisti>false</orgKoduDegisti>
        <isTumunuKopyala>false</isTumunuKopyala>
        <eskiAd/>
        <eskiOrgKod/>
        <taranmamisKaydetme/>
        <testMi>0</testMi>
      </BirimDVO>
    </list>
  </object-array>
</object-array></root>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/ildeki_adliyeleri_getir.ajx";
    }

    async parse(data: any): Promise<IldekiAdliyelerCevap> {
        let json: any = await this.xml2json(data);
        let liste = json.root['object-array']['object-array'].list.BirimDVO
        if (!Array.isArray(liste)) {
            liste = [liste];
        }
        return liste;
    }
}