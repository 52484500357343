export interface YuzuncuMaddeyeYararBilgiIstenilmesiParamsInterface {
    malTip: number,
    malTipAciklama: string,
    aciklama: string,
}

export interface BankadanHacizliParaninIstenilmesiParamsInterface {
    bankaList: number[],// Bankalarin IDLERININ tutuldugu number array i
    hacizTarihi: string,
    aciklama: string
}

export interface BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiParamsInterface {
    ibanNo: string,
    hesapKimeAit: "Vekil" | "Avukat"
}

export interface DosyadaHacizliMalinSatisiParamsInterface {
    malTip: number,
    malTipAciklama: string,
    aciklama: string,
    hacizTarihi: string
}

export type DosyadakiAvansinIadesiParamsInterface = BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiParamsInterface

export type KiymetTakdirininYapilmasiParamsInterface = DosyadaHacizliMalinSatisiParamsInterface

export interface DosyadakiHacizlerinKaldirilmasiParamsInterface {
    aciklama: string,
    kapanmaAdi: string,
    kapanmaId: number
}

export interface DosyaninYetkiliIcraDairesineGonderilmesiParamsInterface {
    aciklama: string,
    birimAdi: string,
    birimId: number
}

export interface HacizliMalinKiymetTakdiriIcinTalimatYazilmasiParamsInterface {
    aciklama: string,
    birimAdi: string,
    birimId: number,
    hacizTarihi: string,
    malTip: number,
    malTipAciklama: string,
}

export type HacizliMalinSatisiIcinTalimatYazilmasiParamsInterface = HacizliMalinKiymetTakdiriIcinTalimatYazilmasiParamsInterface;

export interface HaricenTahsilatBildirimiParamsInterface {
    tahsilatTarihi: string,
    tahsilatTutari: number,
    aciklama: string
}

export interface MaasUzerindekiHacizlerinKaldirilmasiParamsInterface {
    aciklama: string,
    calismaDurumu: number
}

export interface MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiParamsInterface {
    aciklama: string,
    muhafazaTarihi: string,
    yeddiemin: string
}

export interface RehinAcigiBelgesiHazirlanmasiParamsInterface {
    belgeTip: number,
    belgeTipAciklama: string,
    aciklama: string
}

export interface TasinmazHacizKaldirilmasiParamsInterface {
    hacizTarihi: string,
    aciklama: string
}

export class DigerTalepParamsInterface {
    yuzuncuMaddeyeYararBilgiIstenilmesiParams: YuzuncuMaddeyeYararBilgiIstenilmesiParamsInterface = {
        malTip: 0,
        malTipAciklama: "",
        aciklama: "",
    }

    acizBelgesiHazirlanmasiParams: {
        aciklama: string
    } = {
        aciklama: ""
    }

    aracUzerindekiHacizSerhininKaldirilmasiParams: {
        plaka: string
    } = {
        plaka: ""
    }

    aracUzerindekiYakalamaSerhininKaldirilmasiParams: {
        plaka: string
    } = {
        plaka: ""
    }

    bankadanHacizliParaninIstenilmesiParams: BankadanHacizliParaninIstenilmesiParamsInterface = {
        bankaList: [],// Bankalarin IDLERININ tutuldugu number array i
        hacizTarihi: "",
        aciklama: ""
    }

    borclutarafindanYapilanOdemelerinHesabaAktarilmasiParams: BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiParamsInterface = {
        ibanNo: "",
        hesapKimeAit: "Avukat"
    }

    dosyadaHacizliMalinSatisiParams: DosyadaHacizliMalinSatisiParamsInterface = {
        malTip: 0,
        malTipAciklama: "",
        aciklama: "",
        hacizTarihi: ""
    }

    dosyadakiAvansinIadesiParams: DosyadakiAvansinIadesiParamsInterface = {
        ibanNo: "",
        hesapKimeAit: "Avukat"
    }

    dosyadakiHacizlerinKaldirilmasiParams: {
        aciklama: string
    } = {
        aciklama: ""
    }

    dosyaninIslemdenKaldirilmasiParams: DosyadakiHacizlerinKaldirilmasiParamsInterface = {
        aciklama: "",
        kapanmaAdi: "",
        kapanmaId: 0
    }

    dosyaninYetkiliIcraDairesineGonderilmesiParams: DosyaninYetkiliIcraDairesineGonderilmesiParamsInterface = {
        aciklama: "",
        birimAdi: "",
        birimId: 0
    }

    hacizliMalinKiymetTakdiriIcinTalimatYazilmasiParams: HacizliMalinKiymetTakdiriIcinTalimatYazilmasiParamsInterface = {
        aciklama: "",
        birimAdi: "",
        birimId: 0,
        hacizTarihi: "",
        malTip: 0,
        malTipAciklama: "",
    }

    hacizliMalinSatisiIcinTalimatYazilmasiParams: HacizliMalinSatisiIcinTalimatYazilmasiParamsInterface = {
        aciklama: "",
        birimAdi: "",
        birimId: 0,
        hacizTarihi: "",
        malTip: 0,
        malTipAciklama: "",
    }

    hacizliTasinirMallarinMuhafazaAltinaAlinmasiParams: { hacizTarihi: string } = {
        hacizTarihi: ""
    }

    haricenTahsilatBildirimiParams: HaricenTahsilatBildirimiParamsInterface = {
        tahsilatTarihi: "",
        tahsilatTutari: 0,
        aciklama: ""
    }

    iik121MaddeyeGoreYetkiVerilmesiParams: { aciklama: string } = {
        aciklama: ""
    }

    iik150cSerhiEklenmesiParams: { aciklama: string } = {
        aciklama: ""
    }

    kiymetTakdirininYapilmasiParams: KiymetTakdirininYapilmasiParamsInterface = {
        malTip: 0,
        malTipAciklama: "",
        aciklama: "",
        hacizTarihi: ""
    }

    maasUzerindekiHacizlerinKaldirilmesiParams: MaasUzerindekiHacizlerinKaldirilmasiParamsInterface = {
        aciklama: "",
        calismaDurumu: 0
    }

    muhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiParams: MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiParamsInterface = {
        aciklama: "",
        muhafazaTarihi: "",
        yeddiemin: ""
    }

    rehinAcigiBelgesiHazirlanmasiParams: RehinAcigiBelgesiHazirlanmasiParamsInterface = {
        belgeTip: 0,
        belgeTipAciklama: "",
        aciklama: ""
    }

    rehininParayaCevrilmesiSerhiParams: { aciklama: string } = {
        aciklama: ""
    }

    tasinmazHaczininKaldirilmasiParams: TasinmazHacizKaldirilmasiParamsInterface = {
        hacizTarihi: "",
        aciklama: ""
    }

    ibanGuncellemeParams: {
        ibanSecimi: string,
        ibanNo: string
    } = {
        ibanNo: "",
        ibanSecimi: ""
    }
}