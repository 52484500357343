import app from "@/main";
import {AvukatSorgulamaCevap} from "../../uyap/AvukatSorgulama";
import {Mixins} from "../Mixins";
import Adres from "./Adres";
import Args from "@/plugins/uyap-plugin/takip_ac/parser/Args";

export default class Vekil {
    refId: string = "";
    adSoyad: string = "";
    tcKimlikNo: string = "";
    ilKodu: string = "";
    baroNo: string = "";
    tbbNo: string = "";
    borcluVekiliMi: boolean = false;
    adres: Adres | null = null;
    vekil: AvukatSorgulamaCevap | null = null;

    static fromJson(jsonData: any): Vekil {
        return Args.staticFromJson(jsonData,new Vekil());
    }

    static parse(vekilKisiNode: Element): Vekil | null {
        let vekilNode = vekilKisiNode.querySelector("vekil");
        let kisiTumBilgileriNode = vekilKisiNode.querySelector("kisiTumBilgileri");
        let adresNode = vekilKisiNode.querySelector("adres");
        if (vekilNode == null || kisiTumBilgileriNode == null || adresNode == null) {
            console.error("Vekil bilgisi eksik olmalı");
            return null;
        }
        let vekil = new Vekil();
        vekil.refId = Mixins.nullUndefCleaner(vekilKisiNode.getAttribute("id"))
        vekil.adSoyad = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("adi")) + " " + Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("soyadi"));
        vekil.tcKimlikNo = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("tckimlikno"));
        vekil.ilKodu = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("ilkodu"));
        vekil.baroNo = Mixins.nullUndefCleaner(kisiTumBilgileriNode.getAttribute("barono"));
        vekil.tbbNo = Mixins.nullUndefCleaner(vekilNode.getAttribute("tbbno"));
        vekil.borcluVekiliMi = Mixins.xmlStringsToBoolean(vekilNode.getAttribute("borcluvekilimi"));
        vekil.adres = Adres.parse(adresNode);
        return vekil;
    }

    async runQuery(): Promise<void> {
        console.log("VEKIL RUNQUERY this",this);
        let vekil = await app.$uyap.AvukatSorgulama().recursive({
            tcKimlikNo: this.tcKimlikNo.trim(),
            baroNo: this.baroNo.trim(),
            tbbNo: this.tbbNo.trim(),
            ilKodu: this.ilKodu.trim(),
            baroKodu: ""
        })
        if (typeof vekil.tcKimlikNo != "undefined") {
            this.vekil = vekil;
        }else{
            throw new Error("Vekil avukat : " + this.adSoyad + " UYAP sisteminde sorgu sonuçlarında bulunamadı.")
        }
    }
}

