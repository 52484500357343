import {UyapRequest} from "./UyapRequest";
import {AvukatDosyaSorgulaGenelCevap, AvukatDosyaSorgulaGenelCevapItem} from "./AvukatDosyaSorgulaGenel";
import app from "@/main";
import UyapHtmlParser from "./parser/UyapHtmlParser";

export class DosyaSorgulaTarafIleTalep {
    yargiTuru: number = 2;
    yargiBirimi: number = 1101;
    tcKimlikNo: string = "";
    adi: string = "";
    tarafTuru: number = 0;
    soyadi: string = "";
    kurumAdi: string = "";
    mersisNo: string = "";
    vergiNo: string = "";
    dosyaKapaliMi: boolean = false;
    pageCount: number = 1;
    pageSize: number = 10000;

    constructor(tarafTuru: number, tcKimlikNo_VergiNo: string, adi_kurumAdi: string, soyadi_mersisNo: string, dosyaKapaliMi: boolean) {
        this.tarafTuru = tarafTuru;
        if (tarafTuru == 0) {
            this.tcKimlikNo = tcKimlikNo_VergiNo;
            this.adi = adi_kurumAdi;
            this.soyadi = soyadi_mersisNo;
        } else {
            this.vergiNo = tcKimlikNo_VergiNo;
            this.kurumAdi = adi_kurumAdi;
            this.mersisNo = soyadi_mersisNo;
        }
        this.dosyaKapaliMi = dosyaKapaliMi;
        this.yargiBirimi = 1101;
        this.yargiTuru = 2
    }
}

export default class DosyaSorgulaTarafIle extends UyapRequest<DosyaSorgulaTarafIleTalep, AvukatDosyaSorgulaGenelCevap> {
    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "dosyasorgulataraf_" + app.$uyapHelper.hash(JSON.stringify(this.talepData))
        };
    }

    getMethod(): "POST" | "GET" {
        return 'POST';
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/avukat_dosya_sorgula_tarafile_brd.ajx";
    }

    async parse(data: string): Promise<AvukatDosyaSorgulaGenelCevap> {
        let jsonObj: any = await this.xml2json(data);
        let liste = jsonObj.root['object-array'].DVOList.liste;
        if (typeof liste.AvukatDosyaKisiDVO == "undefined") {
            return {
                items: [],
                currentPage: 0,
                pageSize: 0
            }
        }
        let dosyalar = liste.AvukatDosyaKisiDVO;
        if (!Array.isArray(dosyalar)) {
            dosyalar = [dosyalar];
        }
        const response: AvukatDosyaSorgulaGenelCevap = {
            currentPage: jsonObj.root['object-array'].DVOList.currentPage,
            pageSize: jsonObj.root['object-array'].DVOList.pageSize,
            items: dosyalar.map(
                (dosyaObj: any): AvukatDosyaSorgulaGenelCevapItem => {
                    return UyapHtmlParser.normalizeXmlSourcedJson(dosyaObj);
                }),
        }
        return response;
    }

    getMockResponse(): string {
        return `<root><object-array>
  <DVOList>
    <liste>
      <AvukatDosyaKisiDVO>
        <birimId>1001116</birimId>
        <birimAdi>Polatlı İcra Dairesi</birimAdi>
        <dosyaNo>2020/1134</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2020-03-02 14:38:54.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>564323606</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1054058</birimId>
        <birimAdi>Alanya İcra Dairesi</birimAdi>
        <dosyaNo>2019/35562</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2019-08-08 11:15:37.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>294659683</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000952</birimId>
        <birimAdi>Ankara 14. İcra Dairesi</birimAdi>
        <dosyaNo>2019/7569</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2019-05-31 15:52:27.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>521430323</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000943</birimId>
        <birimAdi>Ankara 5. İcra Dairesi</birimAdi>
        <dosyaNo>2018/9718</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2018-08-09 11:20:42.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>475773780</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000954</birimId>
        <birimAdi>Ankara 16. İcra Dairesi</birimAdi>
        <dosyaNo>2018/1441</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2018-02-06 15:01:45.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>449224900</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000947</birimId>
        <birimAdi>Ankara 9. İcra Dairesi</birimAdi>
        <dosyaNo>2017/21042</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2017-11-10 11:46:15.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>436298544</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000947</birimId>
        <birimAdi>Ankara 9. İcra Dairesi</birimAdi>
        <dosyaNo>2017/20981</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2017-11-08 15:49:17.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>435945166</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000947</birimId>
        <birimAdi>Ankara 9. İcra Dairesi</birimAdi>
        <dosyaNo>2017/12831</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2017-06-28 17:42:51.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>419454405</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000947</birimId>
        <birimAdi>Ankara 9. İcra Dairesi</birimAdi>
        <dosyaNo>2017/2903</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2017-02-07 12:42:22.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>400986886</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000964</birimId>
        <birimAdi>Ankara 26. İcra Dairesi</birimAdi>
        <dosyaNo>2016/24119</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2016-12-15 17:42:01.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>394303483</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık (Durdurulmuş : Takibe İtiraz)</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000964</birimId>
        <birimAdi>Ankara 26. İcra Dairesi</birimAdi>
        <dosyaNo>2016/19966</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2016-10-18 22:44:56.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>387094936</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık (Durdurulmuş : Diğer)</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000964</birimId>
        <birimAdi>Ankara 26. İcra Dairesi</birimAdi>
        <dosyaNo>2016/13447</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2016-06-29 15:27:40.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>375412547</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000939</birimId>
        <birimAdi>Ankara 1. İcra Dairesi</birimAdi>
        <dosyaNo>2016/5570</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2016-03-08 15:58:54.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>360856415</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık (Durdurulmuş : Takibe İtiraz)</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000944</birimId>
        <birimAdi>Ankara 6. İcra Dairesi</birimAdi>
        <dosyaNo>2015/10863</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2015-05-22 10:09:26.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>326440207</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000944</birimId>
        <birimAdi>Ankara 6. İcra Dairesi</birimAdi>
        <dosyaNo>2015/10858</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2015-05-22 10:00:28.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>326435053</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000944</birimId>
        <birimAdi>Ankara 6. İcra Dairesi</birimAdi>
        <dosyaNo>2015/8779</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2015-04-30 09:41:29.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>323790766</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000944</birimId>
        <birimAdi>Ankara 6. İcra Dairesi</birimAdi>
        <dosyaNo>2015/8775</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2015-04-30 09:38:07.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>323790753</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000944</birimId>
        <birimAdi>Ankara 6. İcra Dairesi</birimAdi>
        <dosyaNo>2016/6776 - ( 2015/8345 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2015-04-21 10:53:19.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2015-12-19 23:42:11.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>322794271</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000944</birimId>
        <birimAdi>Ankara 6. İcra Dairesi</birimAdi>
        <dosyaNo>2016/6778 - ( 2015/7442 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2015-04-09 10:02:32.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2015-12-19 23:16:18.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>321290781</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000944</birimId>
        <birimAdi>Ankara 6. İcra Dairesi</birimAdi>
        <dosyaNo>2016/7234 - ( 2015/7072 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2015-04-02 09:32:48.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2015-12-19 23:12:03.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>320360357</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000944</birimId>
        <birimAdi>Ankara 6. İcra Dairesi</birimAdi>
        <dosyaNo>2016/7233 - ( 2015/7071 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2015-04-02 09:31:33.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2015-12-19 23:12:01.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>320360355</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000944</birimId>
        <birimAdi>Ankara 6. İcra Dairesi</birimAdi>
        <dosyaNo>2016/7232 - ( 2015/7070 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2015-04-02 09:29:17.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2015-12-19 23:12:01.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>320360354</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000950</birimId>
        <birimAdi>Ankara 12. İcra Dairesi</birimAdi>
        <dosyaNo>2013/14223</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2013-09-05 13:42:15.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>255628691</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000950</birimId>
        <birimAdi>Ankara 12. İcra Dairesi</birimAdi>
        <dosyaNo>2013/14221</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2013-09-05 13:37:43.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>255628689</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000950</birimId>
        <birimAdi>Ankara 12. İcra Dairesi</birimAdi>
        <dosyaNo>2013/14182</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2013-09-04 12:10:22.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>255487097</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000950</birimId>
        <birimAdi>Ankara 12. İcra Dairesi</birimAdi>
        <dosyaNo>2013/13970</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2013-08-27 10:43:17.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>254888010</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000950</birimId>
        <birimAdi>Ankara 12. İcra Dairesi</birimAdi>
        <dosyaNo>2013/13969</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2013-08-27 10:42:24.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>254888008</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000950</birimId>
        <birimAdi>Ankara 12. İcra Dairesi</birimAdi>
        <dosyaNo>2013/13968</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2013-08-27 10:41:18.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>254888007</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000950</birimId>
        <birimAdi>Ankara 12. İcra Dairesi</birimAdi>
        <dosyaNo>2013/13967</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2013-08-27 10:40:17.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>254888005</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000950</birimId>
        <birimAdi>Ankara 12. İcra Dairesi</birimAdi>
        <dosyaNo>2013/13966</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2013-08-27 10:39:27.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>254888002</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000943</birimId>
        <birimAdi>Ankara 5. İcra Dairesi</birimAdi>
        <dosyaNo>2016/951 - ( 2013/12390 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2013-08-13 11:48:35.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2014-12-12 19:06:45.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>253750886</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/16341</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-12-22 10:20:31.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>191040979</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/16313</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-12-21 10:23:37.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>190909060</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/16312</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-12-21 10:21:46.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>190909059</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/16311</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-12-21 10:20:35.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>190909058</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/16307</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-12-21 10:12:05.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>190909052</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/16108</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-12-16 10:18:17.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>190471024</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/16107</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-12-16 10:17:01.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>190471023</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/15213</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-11-25 10:42:53.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>188276149</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/15212</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-11-25 10:40:52.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>188276148</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/15210</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-11-25 10:32:19.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>188276132</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/14118</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-11-04 11:07:02.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>186634229</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/13816</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-10-25 15:59:03.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>185660460</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/13813</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-10-25 15:54:39.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>185660457</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/1502</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-02-15 11:26:15.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>161030144</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/1497</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-02-15 11:16:23.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>161022971</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000967</birimId>
        <birimAdi>Ankara 29. İcra Dairesi</birimAdi>
        <dosyaNo>2011/1486</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2011-02-15 10:54:57.0</dosyaAcilisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>161022957</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/12302 - ( 2012/12302 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-07-04 15:38:16.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2011-11-26 14:22:31.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>66154985</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/12300 - ( 2012/12300 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-07-04 15:31:21.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2010-12-04 13:55:35.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>66154990</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/12299 - ( 2012/12299 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-07-04 15:23:54.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2011-11-26 14:21:05.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>66154994</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/11731 - ( 2008/9803 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-07-04 10:53:39.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2011-11-26 14:20:48.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>66154983</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/11728 - ( 2008/9800 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-07-04 10:47:00.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2011-11-26 14:20:45.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>66154980</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/11740 - ( 2008/4006 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-04-08 14:47:14.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2009-06-05 15:06:55.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>58536553</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/11739 - ( 2008/3462 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-03-28 10:50:54.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2009-06-05 12:09:42.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>57642355</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/11738 - ( 2008/3461 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-03-28 10:49:39.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2009-06-05 12:09:41.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>57642356</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/11734 - ( 2008/3451 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-03-28 10:35:24.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2011-11-26 11:07:37.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>57642346</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/11733 - ( 2008/3447 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-03-28 10:27:03.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2009-06-05 12:09:20.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>57642342</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
      <AvukatDosyaKisiDVO>
        <birimId>1000940</birimId>
        <birimAdi>Ankara 2. İcra Dairesi</birimAdi>
        <dosyaNo>2012/11732 - ( 2008/3445 )</dosyaNo>
        <dosyaTurKod>35</dosyaTurKod>
        <dosyaAcilisTarihi>2008-03-28 10:22:51.0</dosyaAcilisTarihi>
        <dosyaKapanisTarihi>2009-06-05 12:09:16.0</dosyaKapanisTarihi>
        <dosyaDurumu>0</dosyaDurumu>
        <birimTuru1>11</birimTuru1>
        <birimTuru2>1101</birimTuru2>
        <birimTuru3>1199</birimTuru3>
        <dosyaId>57642340</dosyaId>
        <dosyaKisiSize>58</dosyaKisiSize>
        <dosyaTurAciklama>İcra Dosyası</dosyaTurAciklama>
        <dosyaDurumAciklama>Açık</dosyaDurumAciklama>
      </AvukatDosyaKisiDVO>
    </liste>
    <actualCount>0</actualCount>
    <currentPage>0</currentPage>
    <pageSize>0</pageSize>
    <cacheID>0</cacheID>
    <recordCount>0</recordCount>
  </DVOList>
</object-array></root>
`;
    }
}