import {UyapRequest} from "./UyapRequest";

export interface IcraPesinHarcGetirTalep {
    dosyaId: number
}

export interface IcraPesinHarcGetirCevap {
    pesinHarcDusulsunmu: string,
    nafakaIleIliskilimi: boolean,
    pesinHarcUcreti: number,
    pesinHarcSerhMi: boolean
}

export default class IcraPesinHarcGetir extends UyapRequest<IcraPesinHarcGetirTalep, IcraPesinHarcGetirCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "IcraPesinHarcGetir_" + this.talepData.dosyaId
        };
    }

    getMockResponse(): string {
        return `<root><HarcMasrafTahsilatStrDVO>
          <pesinHarcDusulsunmu>false</pesinHarcDusulsunmu>
          <dusulenPesinHarc>3.45</dusulenPesinHarc>
          <nafakaIleIliskilimi>false</nafakaIleIliskilimi>
          <pesinHarcSerhMi>false</pesinHarcSerhMi>
        </HarcMasrafTahsilatStrDVO></root>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/icraPesinHarcGetir.ajx';
    }

    async parse(data: string): Promise<IcraPesinHarcGetirCevap> {
        if (data == "") {
            throw new Error("Peşin harç bulunamadı");
        }
        let json = await this.xml2json(data);
        let harcMasrafTahsilat = json.root['HarcMasrafTahsilatStrDVO'];
        return {
            pesinHarcDusulsunmu: harcMasrafTahsilat.pesinHarcDusulsunmu,
            nafakaIleIliskilimi: harcMasrafTahsilat.nafakaIleIliskilimi,
            pesinHarcUcreti: harcMasrafTahsilat.dusulenPesinHarc,
            pesinHarcSerhMi: harcMasrafTahsilat.pesinHarcSerhMi
        }
    }

}

