import {UyapRequest} from "@/plugins/uyap-plugin/uyap/UyapRequest";

export interface IldekiIdariBirimlerTalep {
    il: number | string,
    bltr2: string           //yargiBirimi
}

export interface IldekiIdariBirimlerCevap {
    birimAdi: string,
    birimId: string,
    evraginBirimDVODaGonderilecegiBirimBilgisi: string,
    yeniBirimEkle: string,
    orgKoduDegisti: string,
    isTumunuKopyala: string,
    eskiAd: string,
    eskiOrgKod: string,
    taranmamisKaydetme: string,
    testMi: string
}

export default class IldekiIdariBirimler extends UyapRequest<IldekiIdariBirimlerTalep, IldekiIdariBirimlerCevap> {
    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "ildekiIdariBirimler_" + this.talepData.il
        };
    }

    getMockResponse() {
        return `<root><object-array>
        <list>
            <BirimDVO>
                <birimAdi>Ankara 1. İdare Mahkemesi</birimAdi>
                <birimId>1008927</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 2. İdare Mahkemesi</birimAdi>
                <birimId>1008928</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 3. İdare Mahkemesi</birimAdi>
                <birimId>1008929</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 4. İdare Mahkemesi</birimAdi>
                <birimId>1008930</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 5. İdare Mahkemesi</birimAdi>
                <birimId>1008931</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 6. İdare Mahkemesi</birimAdi>
                <birimId>1008932</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 7. İdare Mahkemesi</birimAdi>
                <birimId>1008933</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 8. İdare Mahkemesi</birimAdi>
                <birimId>1008934</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 9. İdare Mahkemesi</birimAdi>
                <birimId>1008935</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 10. İdare Mahkemesi</birimAdi>
                <birimId>1008936</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 11. İdare Mahkemesi</birimAdi>
                <birimId>1010802</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 12. İdare Mahkemesi</birimAdi>
                <birimId>1011126</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 13. İdare Mahkemesi</birimAdi>
                <birimId>1019252</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 14. İdare Mahkemesi</birimAdi>
                <birimId>1019253</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 15. İdare Mahkemesi</birimAdi>
                <birimId>1019254</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 16. İdare Mahkemesi</birimAdi>
                <birimId>1019255</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 17. İdare Mahkemesi</birimAdi>
                <birimId>1019256</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 18. İdare Mahkemesi</birimAdi>
                <birimId>1019257</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 19. İdare Mahkemesi</birimAdi>
                <birimId>1050705</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 20. İdare Mahkemesi</birimAdi>
                <birimId>1050706</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 21. İdare Mahkemesi</birimAdi>
                <birimId>1050707</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 22. İdare Mahkemesi</birimAdi>
                <birimId>1050708</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 23. İdare Mahkemesi</birimAdi>
                <birimId>1050709</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 24. İdare Mahkemesi</birimAdi>
                <birimId>1050710</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 25. İdare Mahkemesi</birimAdi>
                <birimId>1050711</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 26. İdare Mahkemesi</birimAdi>
                <birimId>1050712</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 27. İdare Mahkemesi</birimAdi>
                <birimId>1050713</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 28. İdare Mahkemesi</birimAdi>
                <birimId>1050714</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 29. İdare Mahkemesi</birimAdi>
                <birimId>1057470</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 30. İdare Mahkemesi</birimAdi>
                <birimId>1057471</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
            <BirimDVO>
                <birimAdi>Ankara 31. İdare Mahkemesi</birimAdi>
                <birimId>1057472</birimId>
                <evraginBirimDVODaGonderilecegiBirimBilgisi>0</evraginBirimDVODaGonderilecegiBirimBilgisi>
                <yeniBirimEkle>true</yeniBirimEkle>
                <orgKoduDegisti>false</orgKoduDegisti>
                <isTumunuKopyala>false</isTumunuKopyala>
                <eskiAd/>
                <eskiOrgKod/>
                <taranmamisKaydetme/>
                <testMi>0</testMi>
            </BirimDVO>
        </list>
    </object-array></root>`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/ildeki_idaribirimleri_getir.ajx";
    }

    async parse(data: any): Promise<IldekiIdariBirimlerCevap> {
        let json: any = await this.xml2json(data);
        let liste = json.root['object-array'].list.BirimDVO
        if (!Array.isArray(liste)) {
            liste = [liste];
        }
        return liste;
    }
}