import Args from "../Args";
import {Mixins} from "../../Mixins";
import AlacakKalem from "../AlacakKalem";
import Ilamsiz from "./Ilamsiz";
import TarafKisi from "../TarafKisi";
import TarafKurum from "../TarafKurum";

export default class Police extends Ilamsiz {
    ilamsizTipi     : string = 'police';
    id             !: number;//;
    meblagi         : string = '';
    meblagTuru      : string = '';
    meblagTuruAciklama: string = '';
    kesideTarihi    : string = '';
    kesideYeri      : string = '';
    odemeYeri       : string = '';
    vadeTarihi      : string = '';
    lehtar          : string = '';
    kesideci        : string = '';
    odeyecekKisi    : string = '';
    aciklama        : string = '';
    alacakKalemleri : AlacakKalem[] = []// alacakKalemleri;

    static fromJson(jsonData: any): Police {
        let obj = Args.staticFromJson(jsonData,new Police());
        obj.alacakKalemleri = jsonData.alacakKalemleri.map((x: AlacakKalem) => AlacakKalem.fromJson(x));
        return obj;
    }

    static parse(
        domNode: HTMLUnknownElement,
        args: Args,
        tarafList: Array<TarafKisi | TarafKurum>
    ): Police {
        let police = new Police();

        police.id = args.counterId();
        police.meblagi = Mixins.replaceCommaDots(domNode.getAttribute('belgenintutari'));
        police.meblagTuru = Mixins.nullUndefCleaner(domNode.getAttribute('tutartur'));
        police.meblagTuruAciklama = Mixins.nullUndefCleaner(domNode.getAttribute('tutaradi'));
        police.kesideTarihi = Mixins.nullUndefCleaner(domNode.getAttribute('kesidetarihi'));
        police.kesideYeri = Mixins.nullUndefCleaner(domNode.getAttribute('kesideyeri'));
        police.odemeYeri = Mixins.nullUndefCleaner(domNode.getAttribute('odemeyeri'));
        police.vadeTarihi = Mixins.nullUndefCleaner(domNode.getAttribute('vadetarihi'));
        police.lehtar = Mixins.nullUndefCleaner(domNode.getAttribute('lehtaradsoyad'));
        police.kesideci = Mixins.nullUndefCleaner(domNode.getAttribute('kesideciadsoyad'));
        police.odeyecekKisi = Mixins.nullUndefCleaner(domNode.getAttribute('odeyecekkisiadsoyad'));

        let sekizinciMadde = domNode.getAttribute("sekizinciMadde");
        if (sekizinciMadde) {
            if (sekizinciMadde.trim() != "")
                police.aciklama = sekizinciMadde;
        }
        police.alacakKalemleri = AlacakKalem.getAlacakKalemListFromDom(domNode, args, tarafList);
        return police;
    }

    toUyapJson() {
        const {
            id,
            ilamsizTipi,
            meblagi,
            meblagTuru,
            meblagTuruAciklama,
            kesideTarihi,
            kesideYeri,
            odemeYeri,
            vadeTarihi,
            lehtar,
            kesideci,
            odeyecekKisi,
            aciklama,
            alacakKalemleri
        } = this;

        let obj: any = {
            id,
            ilamsizTipi,
            meblagi,
            meblagTuru,
            meblagTuruAciklama,
            kesideTarihi,
            kesideYeri,
            odemeYeri,
            vadeTarihi,
            lehtar,
            kesideci,
            odeyecekKisi,
            alacakKalemleri: alacakKalemleri.map(i => i.toUyapJson()),
        };

        if (aciklama != "") {
            obj["aciklama"] = aciklama;
        }
        return obj;
    }

}

