import app from "@/main";
import {Mixins} from "../Mixins";
import Vekil from "./Vekil";
import TemelBilgileri from "./TemelBilgileri";
import HesapBilgisi from "./HesapBilgisi";
import Adres from "./Adres";
import TakipRolleri from "./TakipRolleri";
import {HashArguments} from "./HashArguments";
import TarafInterface from "./TarafInterface";
import {DosyaFormDataInterface} from "../DosyaFormDataInterface";
import {MtsDosyaFormData} from "../MtsDosyaFormData";

export default class TarafKurum extends TarafInterface {
    constructor(isMts = false) {
        super();
        this.isMts = isMts;
    }

    parse(
        tarafDomNode: Element,
        vekilList: Vekil[],
        formData: DosyaFormDataInterface | MtsDosyaFormData,
    ) {
        let kisiKurumBilgileriDomNode = tarafDomNode.querySelector('kisiKurumBilgileri');
        //@ts-ignore
        let kisiTumBilgileriDomNode = kisiKurumBilgileriDomNode.querySelector('kisitumbilgileri');
        //@ts-ignore
        let adresDomNode = kisiKurumBilgileriDomNode.querySelector("adres");
        //@ts-ignore
        let kurumDomNode = kisiKurumBilgileriDomNode.querySelector('kurum');
        this.kurumMu = true;
        this.tarafTuru = "KURUM";
        //@ts-ignore
        let rolID = tarafDomNode.querySelector('roltur').getAttribute("rolid");
        this.formData = formData;
        let tarafSifati = new TakipRolleri().find(rolID);
        if (tarafSifati)
            this.tarafSifati = tarafSifati;
        if (this.isMts && tarafSifati) {
            this.rolGirisBilgisi = tarafSifati;
            if (this.isAlacakli()) {
                console.log("TARAF KURUM MTS ALACAKLI ");
                this.alacakliIletisimEPosta = formData.alacakliEposta;
                this.alacakliIletisimTelefon = formData.alacakliTelefon;
            }
        }
        if (kurumDomNode) {
            this.kurumAdi = Mixins.nullUndefCleaner(kurumDomNode.getAttribute("kurumadi"));
            this.mersisNo = Mixins.nullUndefCleaner(kurumDomNode.getAttribute("mersisno"));
            this.vergiNo = Mixins.nullUndefCleaner(kurumDomNode.getAttribute("vergino"));
            this.detsisNo = Mixins.nullUndefCleaner(kurumDomNode.getAttribute("detsisno"));
            this.hesapBilgisi = new HesapBilgisi(tarafDomNode);
            this.refId = Mixins.nullUndefCleaner(tarafDomNode.getAttribute("id"));
            //@ts-ignore
            this.adresBilgisi = Adres.parse(adresDomNode, this.isMts);
            this.adresList.push(this.adresBilgisi);
            this.temelBilgileri = TemelBilgileri.parse(kurumDomNode);
        }

        this.setAlacakliMernisAdresiTercihi(formData.alacakliMernisAdresiKullan);
        this.setMernisAdresiTercihi(formData.mernisAdresiKullan);
        this.setVekil(tarafDomNode, vekilList);
        this.setTarafHashList();

        return this;

    }


    setTarafHashList() {
        let hashArguments = HashArguments.kurum();
        hashArguments.rolId = this.tarafSifati.rolID.toString();
        hashArguments.vergino = this.temelBilgileri.vergiNo;
        hashArguments.mersisno = this.temelBilgileri.mersisNo;
        hashArguments.kurumadi = this.temelBilgileri.kurumAdi;
        if ((this.isMts && this.tarafSifati.rolID == 21) || !this.isMts)
            this.tarafHashList.push(hashArguments);
    }


    async runQuery(mtsAlacakliTaraf: any = null): Promise<void> {
        console.log("mtsAlacakliTaraf", mtsAlacakliTaraf)
        try {
            this.addMessage('info', 'Borçlu kurum sorgulanıyor!');
            await this.borcluKurumSorgula(mtsAlacakliTaraf);
            this.addMessage('success', 'Borçlu kurum sorgulama yapıldı!');
            await this.setVekilListesi();
            this.addMessage('success', 'Borçlu vekil listesi eklendi !');
            return ;
        } catch (e) {
            // @ts-ignore
            throw new Error(e.message);
        }
    }

    async borcluKurumSorgula(mtsAlacakliTaraf: any): Promise<any> {
        try {
            let kurum = await app.$uyap.KurumSorgulama().recursive({
                mersisNo: this.mersisNo,
                kurumAdi: this.kurumAdi,
                vergiNo: this.vergiNo,
                detsisNo: this.detsisNo,
            })
            this.addMessage('success', 'Kurum Bulundu!');
            await this.setTarafKurum(kurum[0], mtsAlacakliTaraf);
            return Promise.resolve();
        } catch (e) {
            this.addMessage('error', 'Kurum Bulunamadi!');
            await this.setTarafKurum(null, mtsAlacakliTaraf)
            return Promise.reject(e);
        }
    }

    async setTarafKurum(kurumBilgileri: any, mtsAlacakliTaraf: any): Promise<any> {
        try {
            console.log("Kurum Taraf Bilgisi Hazırlanıyor", kurumBilgileri);
            if (this.isMts) {
                this.temelBilgileri.sorguTuru = 2;
                if (kurumBilgileri != null) {
                    this.temelBilgileri = kurumBilgileri; //UYAP update 20/09/2019
                    if (mtsAlacakliTaraf != null) {
                        if (mtsAlacakliTaraf.vergiNo == kurumBilgileri.vergiNo) {
                            this.temelBilgileri = mtsAlacakliTaraf;
                        }
                    }
                    this.temelBilgileri.sorguTuru = 0; //0 diye hatirliyorum bak 15.10.2019
                }
                await this.runQueryForMtsAdresList();
            } else {
                this.sorguTuru = 2;
                if (kurumBilgileri != null) {
                    this.temelBilgileri = kurumBilgileri; //UYAP update 20/09/2019
                    this.sorguTuru = 0;
                }
                await this.runQueryForAdresList();
            }

            this.addMessage('success', 'Adres listesi alındı!')
            if (this.isAlacakli()) {
                if (this.isMts) {
                    await this.runQueryForMtsHesapBilgisi();
                } else {
                    await this.runQueryForHesapBilgisi();
                }
                this.addMessage('success', 'IBAN listesi alındı!')
            }
            return Promise.resolve();
        } catch (e) {
            console.log("ERRSET TARAF KUURUM", e);
            return Promise.reject(e);
        }
    }

    setAdresBilgisi(adresBilgisi: Adres) {
        this.adresBilgisi = adresBilgisi;
        if (this.isMts)
            this.adresBilgisi.kisiKurumID = this.temelBilgileri.kisiKurumId;
    }


    runQueryForMtsAdresList(): Promise<void> {
        return new Promise(async (resolve) => {
            if (this.isBorclu() && this.formData.mernisAdresiKullan === true) {
                console.log("MTS MERNIS ADRESI KULLANSIN TRUE");
                this.mernisAdresiKullan = false;
                this.setAdresBilgisi(this.temelBilgileri.merkezAdresi);
                //this.adresBilgisi = this.temelBilgileri.merkezAdresi;
                return resolve();
            } else {
                try {
                    if (typeof this.temelBilgileri.kisiKurumID != "undefined") {
                        this.temelBilgileri.kisiKurumId = this.temelBilgileri.kisiKurumID;
                    }
                    let adresList = await app.$uyap.GetAdresList().run(
                        {
                            kisiKurumId: this.temelBilgileri.kisiKurumID,
                            tarafTur: 2
                        });
                    if (adresList.length > 0)
                        this.adresList = this.adresList.concat(adresList);
                    let mersisAdresi = adresList.find((adres: Adres) => adres.adresTuruAciklama == "Mersis Adresi");
                    let yurticiIkametAdresi = adresList.find((adres: Adres) => adres.adresTuruAciklama == "Yurt İçi İşyeri Adresi");

                    console.log("MERSIS ADRESİ ", mersisAdresi);
                    if (mersisAdresi)
                        mersisAdresi.adresTuru = "ADRTR00011 "; //Boşluktan hata veriyor EUP - 449 hatası
                    // DO eup - 457
                    if (this.formData.alacakliMernisAdresiKullan && mersisAdresi) {
                        this.setAdresBilgisi(mersisAdresi);
                    } else if (!this.formData.alacakliMernisAdresiKullan && yurticiIkametAdresi) {
                        this.setAdresBilgisi(yurticiIkametAdresi);
                    } else {
                        this.setAdresBilgisi(adresList[0]);
                    }
                    return resolve();
                } catch (e: any) {
                    console.log("MTS ADRES HATA", e.message);
                    return resolve();
                }
            }
        })
    }

    runQueryForAdresList(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (
                typeof this.temelBilgileri.kisiKurumID != "undefined" &&
                this.temelBilgileri.kisiKurumID > 0
            ) {
                try {

                    let adresList = await app.$uyap.GetAdresList().run(
                        {
                            kisiKurumId: this.temelBilgileri.kisiKurumID,
                            tarafTur: 2
                        });
                    if (adresList.length > 0)
                        this.adresList = this.adresList.concat(adresList);

                    // Alacaklı da olsa, borçlu da olsa, öncelikle dönen adresi seç.
                    // Dönen adres hiç yoksa xml'deki adresi tercih et. Ama tüm adresler seçenek olarak eklensin.

                    let mersisAdresi = adresList.find(a => a.adresTuruAciklama == "Mersis Adresi");
                    let yurticiIsyeriAdresi = adresList.find(a => a.adresTuruAciklama == "Yurt İçi İşyeri Adresi");
                    console.log("mersisAdresi", mersisAdresi);
                    console.log("yurticiIsyeriAdresi", yurticiIsyeriAdresi);
                    if ((this.isBorclu() && this.getMernisAdresTercihi() == true) || (this.isAlacakli() && this.getAlacakliMernisAdresTercihi() == true)) {
                        console.log("Uyap'tan gelen adreslerin en maktıklısını seç")
                        if (mersisAdresi) {
                            console.log("Mersis Adresini Seçtim", mersisAdresi);
                            this.adresBilgisi = mersisAdresi;
                        } else if (yurticiIsyeriAdresi) {
                            this.adresBilgisi = yurticiIsyeriAdresi;
                            console.log("yurticiIsyeriAdresi Seçtim", yurticiIsyeriAdresi);
                        }
                    }
                    this.mernisAdresiKullan = false;//True olduğunda hata veriyor * emre
                    this.adresListPickedIndex = this.adresList.indexOf(this.adresBilgisi);
                    if (this.adresListPickedIndex < 0)
                        this.adresListPickedIndex = 0;
                    //xmldeki adresi bazen bulamiyor.
                    return resolve();
                } catch (e) {
                    console.log("ADRES YOK XMLDEKINI KULLAN ", e);
                    this.adresListPickedIndex = 0;
                    return resolve();
                }
            } else {
                return reject({message: "Taraf Kurum Bulunamadı !!"});
            }
        })
    }

}

