import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface AracHacziParamsTalep {
    // Boş
}

export interface AracHacziParamsCevap {
    items: Array<AracHacziParamsCevapRow>
}

export interface AracHacziParamsCevapRow {
    name: string,
    value: number
}

export default class AracHacziParams extends UyapRequest<AracHacziParamsTalep, AracHacziParamsCevap> {
    delayTime = 0;

    getMethod(): "POST" | "GET" {
        return "POST";
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "AracHacziParams_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/getAracHacziParams_brd.ajx';
    }

    async parse(data: any): Promise<AracHacziParamsCevap> {
        return {items: data};
    }

    getMockResponse(): any {
        return [{"name": "Haciz", "value": 0}, {"name": "Yakalama", "value": 3}, {
            "name": "Rehinin Paraya Çevrilmesi",
            "value": 9
        }, {"name": "İhtiyati Haciz", "value": 10}];
    }

}