import {DosyaTaraflarCevap} from "./DosyaTarafBilgileri";
import app from "@/main";
import {AvukatDosyaSorgulaGenelCevapItem} from "./AvukatDosyaSorgulaGenel";
import {DosyaSafahatCevap} from "./DosyaSafahat";
import {TahsilatReddiyatCevap} from "./TahsilatReddiyat";
import {DosyaEvraklarCevap} from "./DosyaEvraklar";
import {DosyaDetayBilgileriCevap} from "./DosyaDetayBilgileri";
import {DosyaHesapBilgileriCevap} from "./DosyaHesapBilgileri";
import {MtsTebligatListCevap} from "./MtsTebligatList";

export class AvukatDosya {
    dosya: AvukatDosyaSorgulaGenelCevapItem;
    taraflar: DosyaTaraflarCevap = new DosyaTaraflarCevap();
    safahat: DosyaSafahatCevap = new DosyaSafahatCevap();
    tahsilatReddiyat: TahsilatReddiyatCevap = new TahsilatReddiyatCevap();
    evraklar: DosyaEvraklarCevap = new DosyaEvraklarCevap();
    dosyaDetayBilgileri: DosyaDetayBilgileriCevap = new DosyaDetayBilgileriCevap();
    dosyaHesapBilgileri: DosyaHesapBilgileriCevap = new DosyaHesapBilgileriCevap();
    mtsTebligatList: MtsTebligatListCevap = new MtsTebligatListCevap();
    barkod: DosyaSafahatCevap = new DosyaSafahatCevap();


    constructor(dosya: AvukatDosyaSorgulaGenelCevapItem) {
        this.dosya = dosya;
    }

    async queryTaraflar(): Promise<void> {
        try {
            this.taraflar = await app.$uyap.DosyaTarafBilgileri().run({
                dosyaId: this.dosya.dosyaId
            });
            this.taraflar.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.taraflar.message = e.message;
            return Promise.reject(e);
        }
    }

    async querySafahat(): Promise<void> {
        try {
            let safahatToken = await app.$uyap.DosyaSafahatToken().run({});
            //TODO Token store da biyere set edilebilir .
            this.safahat = await app.$uyap.DosyaSafahat().run({
                dosyaId: this.dosya.dosyaId,
                token: safahatToken.token
            });
            this.safahat.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.safahat.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryTahRed(): Promise<void> {
        try {
            this.tahsilatReddiyat = await app.$uyap.TahsilatReddiyat().run({
                dosyaId: this.dosya.dosyaId,
                dosyaTurKod: 1
            });
            this.tahsilatReddiyat.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.tahsilatReddiyat.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryEvraklar(): Promise<void> {
        try {
            this.evraklar = await app.$uyap.DosyaEvraklar().run({
                dosyaId: this.dosya.dosyaId
            });
            this.evraklar.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.evraklar.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryDosyaBilgileri(): Promise<void> {
        try {
            this.dosyaDetayBilgileri = await app.$uyap.DosyaDetayBilgileri().run({
                dosyaId: this.dosya.dosyaId
            });
            this.dosyaDetayBilgileri.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.dosyaDetayBilgileri.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryDosyaHesapBilgileri(): Promise<void> {
        try {
            this.dosyaHesapBilgileri = await app.$uyap.DosyaHesapBilgileri().run({
                dosyaId: this.dosya.dosyaId
            });
            this.dosyaHesapBilgileri.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.dosyaHesapBilgileri.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBarkod(): Promise<void> {
        try {
            if (!this.safahat)
                await this.querySafahat();
            let filtered = this.safahat?.items.filter(
                item => item.islemTuru == "Tebligat Bilgileri Kaydedildi"
            );
            if (filtered) {
                this.barkod.items = filtered;
            }
            this.barkod.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.barkod.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryMtsTebligat(): Promise<void> {
        try {
            this.mtsTebligatList = await app.$uyap.MtsTebligatList().run({
                dosyaId: this.dosya.dosyaId
            });
            this.mtsTebligatList.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e: any) {
            this.mtsTebligatList.message = e.message;
            return Promise.reject(e);
        }
    }
}