import {UyapRequest} from "./UyapRequest";
import UyapHtmlParser from "./parser/UyapHtmlParser";
import {DosyaBorclu} from "./DosyaBorclu";

export interface DosyaBorclulariTalep {
    dosyaId: number | string
}

export class DosyaBorclulariCevap {
    items: DosyaBorclu[] = [];
    message: string = "";
}

export default class DosyaBorclulari extends UyapRequest<DosyaBorclulariTalep, DosyaBorclulariCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "dosya_borclu_" + this.talepData.dosyaId
        };
    }

    getMockResponse(): string {
        return `<table  class='table table-bordered table-striped'><thead><th>Kurum Adı</th><th>Vergi No</th><th>&nbsp;</th></thead><tbody><tr><td>T____ E____ A.Ş.</td><td>3860020694</td><td><a class="btn default" data-toggle="modal" href="#dosya_goruntule_borclu_modal" onclick="DosyaBilgileriGoruntuleme.borcluBilgileriSet('70390556','GATE ELEKTRONİK A.Ş.','','1')">Görüntüle </a></td></tr>
<tr><td>E____ E____.</td><td>3860020694</td><td><a class="btn default" data-toggle="modal" href="#dosya_goruntule_borclu_modal" onclick="DosyaBilgileriGoruntuleme.borcluBilgileriSet('70390556','GATE ELEKTRONİK A.Ş.','','1')">Görüntüle </a></td></tr></tbody></table>`
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/dosya_borclu_bilgileri_brd.ajx';
    }

    async parse(data: string): Promise<DosyaBorclulariCevap> {
        let div = document.createElement('div');
        div.innerHTML = data;
        let rows = div.querySelectorAll('tbody tr');
        let items: DosyaBorclu[] = [];
        let response = new DosyaBorclulariCevap();

        rows.forEach((tr: any) => {
            let tds = tr.querySelectorAll('td');
            let btn = tr.querySelector('.btn');
            let dosyaBorclu = new DosyaBorclu();
            let onclickArgs = UyapHtmlParser.parseAttributeCallbackArgs(btn);
            if (tds.length == 3) {
                dosyaBorclu.isKurumBool = true;
                //kurum
                dosyaBorclu.kurumAdi = tds[0].innerText;
                dosyaBorclu.vergiNo = tds[1].innerText;
                dosyaBorclu.tcKimlikNo = tds[1].innerText;
            } else {
                dosyaBorclu.isKisiBool = true;
                //kisi
                dosyaBorclu.tcKimlikNo = tds[0].innerText;
                dosyaBorclu.adi = tds[1].innerText;
                dosyaBorclu.soyadi = tds[2].innerText;
                dosyaBorclu.babaAdi = tds[3].innerText;
                dosyaBorclu.anaAdi = tds[4].innerText;
                dosyaBorclu.dogumTarihi = tds[5].innerText;
            }
            dosyaBorclu.kisiKurumId = onclickArgs[0];
            items.push(dosyaBorclu)
        })
        response.items = items;
        return response;
    }
}

