import app from "@/main";
import {evrakGonder} from "../../../evrak_gonder/EvrakGonder"
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {TaskDurum} from "../../../enum/TaskDurum";

export class EvrakGonderTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        try {
            let dosya = await app.$uyap.DosyaBul().run(task);
            task.dosya_id = dosya.dosyaId;
            task.birim_adi = dosya.birimAdi;
            let evrakRes = await evrakGonder({
                dosyaId: task.dosya_id,
                birimAdi: task.birim_adi,
                evraklar: task.data
            });
            task.status = TaskDurum.BITTI_BASARILI;
            task.response = [task.dosya_esas_no, task.birim_adi, dosya.birimId, evrakRes?.message];
            return task;
        } catch (e: any) {
            throw new Error(e.message)
        }
    }
}
