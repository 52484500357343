import app from "@/main";
import {IslemDurum, ITask, PlayerDurum, TaskManagerState, S, R, ITasks} from "./GorevYoneticisiTypes";
import {TaskDurum} from "../../enum/TaskDurum";
import {ActionContext} from "vuex";
import {TaskTarget} from "../../enum/TaskTarget";
import {TaskTuru} from "../../enum/TaskTuru";
import {AxiosResponse} from "axios";
import {TaskHandler} from "./TaskHandler/TaskHandler";
import {TaskResponser} from "./TaskResponser/TaskResponser";

const taskHandler = new TaskHandler();
const taskResponser = new TaskResponser();

const state: TaskManagerState = {
    tasks: {
        completed_tasks: [],
        incomplete_tasks: [],
        processed_task_childs: []
    },
    islemDurum: IslemDurum.BOSTA,
    playerDurum: PlayerDurum.dur,
    enSonIslenenGorev: null
}

const getters = {
    // e.g. this.$store.getters.XXX
    getGorevler: (state: TaskManagerState) => state.tasks,
    getTamamlananGorevler: (state: TaskManagerState) => {
        return state.tasks.completed_tasks;
    },
    getSiradakiGorevler: (state: TaskManagerState) => {
        return state.tasks.incomplete_tasks;
    },
    getChildTasksStatus: (state: TaskManagerState) => {
        return state.tasks.processed_task_childs;
    },
    getIslemDurum: (state: TaskManagerState) => state.islemDurum,
    getPlayerDurum: (state: TaskManagerState) => state.playerDurum
}

const mutations = {
    // senkron işlemlerde kullanılır anlık olarak state'i günceller
    // e.g. this.$store.commit('fonksiyon adı', deger)
    updateGorevler(state: TaskManagerState, payload: ITasks) {
        state.tasks = payload;
    },
    playerAuto(context: ActionContext<S, R>) {
        context.commit("setPlayerDurum", PlayerDurum.oto);
    },
    setPlayerDurum(state: TaskManagerState, payload: PlayerDurum) {
        state.playerDurum = payload;
    }
}

const actions = {
    // asenkron state güncelleme işlemlerinde kullanılır
    // e.g. this.$store.dispatch('fonksiyon adı', deger)
    async getTasksFromServer(context: ActionContext<S, R>) {
        return new Promise((resolve, reject) => {
            app.$http?.get('/api/v1/task').then((response: AxiosResponse<ITasks>) => {
                context.state.tasks = response.data;
                app.$socket.client.emit("updateTasks");
                resolve(response);
            }).catch((error: Error) => {
                reject(error);
            })
        })
    },
    async updateTasks(context: ActionContext<S, R>) {
        return new Promise((resolve, reject) => {
            app.$http?.get('/api/v1/task').then((response: AxiosResponse<ITasks>) => {
                context.state.tasks = response.data;
                resolve(response);
            }).catch((error: Error) => {
                reject(error);
            })
        })
    },
    async playerStop(context: ActionContext<S, R>) {
        context.commit("setPlayerDurum", PlayerDurum.durduruluyor);
        let islenenAnaGorev: ITask | undefined = context.state.tasks.incomplete_tasks.find((task: ITask) => task.status == TaskDurum.ISLENIYOR);
        if (islenenAnaGorev) {
            await context.dispatch('putTask', {id: islenenAnaGorev.id, status: TaskDurum.DURAKLATILDI});
        }
    },
    taskInterval(context: ActionContext<S, R>) {
        let interval = setInterval(async () => {
            if (context.getters.isUyapConnected &&
                context.getters.getSiradakiGorevler.length > 0 &&
                context.getters.getPlayerDurum == PlayerDurum.oto &&
                context.getters.getIslemDurum == IslemDurum.BOSTA) {
                context.state.islemDurum = IslemDurum.ISLENIYOR;
                clearInterval(interval);
                try {
                    let nextTaskResponse: any = await app.$http.get('/api/v1/next-task');
                    const nextTask = nextTaskResponse.task;
                    if (nextTaskResponse.status == 'Success') {
                        context.state.enSonIslenenGorev = nextTask;
                        if (nextTask.parent_id)
                            await taskHandler.handle(nextTask);
                        else
                            await taskResponser.response(nextTask);
                    } else if (nextTaskResponse.status == 'Waiting') {
                        // TODO: kalan süre delaya eklenecek
                        app.$toast.info(nextTaskResponse.message);
                    }
                } catch (e: any) {
                    app.$toast.error(e);
                } finally {
                    context.state.islemDurum = IslemDurum.BOSTA;
                    console.log('Bitti Boşa Çıktı', context.state.islemDurum);
                    await context.dispatch('taskInterval');
                }
            } else if (context.getters.getPlayerDurum == PlayerDurum.durduruluyor) {
                if (context.state.enSonIslenenGorev != null && context.state.enSonIslenenGorev.parent_id != null)
                    await context.dispatch('putTask', {
                        id: context.state.enSonIslenenGorev.parent_id,
                        status: TaskDurum.DURAKLATILDI
                    });
                app.$socket.client.emit("player", PlayerDurum.dur);
                context.state.enSonIslenenGorev = null;
            } else if (context.getters.getSiradakiGorevler.length == 0 && context.getters.getPlayerDurum == PlayerDurum.oto) {
                app.$socket.client.emit("player", PlayerDurum.dur);
            }
        }, 5000)
    },
    async tebligatGideriGuncelle(context: ActionContext<S, R>, payload: {
        ids: Array<number>,
        tebligat_gideri: {
            tutar: string,
            aciklama: string,
            enabled: boolean
        }
    }) {
        return new Promise(async (resolve, reject)=>{
            try {
                await app.$http.post('/api/v1/task/tebligat-gideri-guncelle', payload);
                await context.dispatch('getTasksFromServer');
                // @ts-ignore
                resolve();
            } catch (e: any){
                reject(e);
            }
        })
    },
    async postTasks(context: ActionContext<S, R>, payload: Array<ITask>) {
        let _payload: any = {parent_id: null};
        if (payload.length > 0)
            _payload = payload[0];
        if (payload.length == 0 || _payload.parent_id == null) {
            // Parrent id yok, parrentı oluştur sonra görev paketini ekle
            let tasks = await context.dispatch('getTasksFromServer');
            let oncelikSirasi;
            if (tasks.data.length > 0) {
                let arr = tasks.data.filter((task: ITask) => (!(task.status == TaskDurum.BITTI_BASARILI || task.status == TaskDurum.BITTI_HATALI) && task.parent_id == null));
                if (arr.length > 0) {
                    arr.reverse();
                    oncelikSirasi = arr[0].oncelik_sirasi + 1;
                } else
                    oncelikSirasi = 1;
            } else {
                oncelikSirasi = 1;
            }
            // @ts-ignore
            let parentTask: ITask = {
                avukat_id: null,
                task_type_id: payload[0].task_type_id,
                oncelik_sirasi: oncelikSirasi,
                data: payload[0].data,
                task_target: (payload[0].task_target == TaskTarget.dosya ? TaskTarget.toplu : TaskTarget.dosya),
                status: TaskDurum.SIRADA
            };
            if (payload[0].aciklama) {
                parentTask.aciklama = payload[0].aciklama;
                delete payload[0].aciklama;
            } else {
                let aciklama = await context.dispatch("gorevAciklamasiOlustur", {
                    anaTask: parentTask,
                    childTasklar: payload
                });
                parentTask.aciklama = aciklama;
            }
            let parentTaskRes = await app.$http?.post('/api/v1/task', parentTask);
            let parentId = parentTaskRes.data.id;
            payload.forEach((task) => (task.parent_id = parentId));
            await app.$http?.post('/api/v1/toplutask', payload);
            await context.dispatch('getTasksFromServer');
            app.$toast?.info('İşlemler sıraya alındı');
            return parentTaskRes;
        } else {
            // parrent_id var direkt post et
            app.$http?.post('/api/v1/toplutask', payload).then(() => {
                context.dispatch('getTasksFromServer');
                app.$toast?.info('İşlemler sıraya alındı');
            })
        }
    },
    async putTask(context: ActionContext<S, R>, payload: ITask) {
        return await app.$http?.put('/api/v1/task/' + payload.id, payload).then(() => {
            context.dispatch('getTasksFromServer');
        });
    },
    deleteTask(context: ActionContext<S, R>, payload: ITask) {
        app.$http?.delete('/api/v1/task/' + payload.id).then(() => {
            context.dispatch('getTasksFromServer');
        })
    },
    async deleteAllTasks(context: ActionContext<S, R>, parentTask: ITask) {
        app.$http?.delete('/api/v1/task/' + parentTask.id).then(() => {
            context.dispatch('getTasksFromServer');
        })
    },
    waitThisLong(saniye: number) {
        return new Promise(resolve => setTimeout(resolve, saniye * 1000))
    },
    gorevAciklamasiOlustur(context: ActionContext<S, R>, payload: { anaTask: ITask, childTasklar: Array<ITask> }) {
        return new Promise((resolve) => {
            let aciklama = "";
            let takipler = payload.childTasklar.map(takip => (takip.birim_adi + " - " + takip.dosya_esas_no)).join(", ");
            let takiplerEsasNoYok = payload.childTasklar.map(takip => (takip.birim_adi)).join(", ");
            switch (payload.anaTask.task_type_id) {
                case TaskTuru.takip_sorgula:
                    aciklama = payload.anaTask.data.sorguAlanlari.length + " tane alan sorgulaması. (" + takipler + ")";
                    break;
                case TaskTuru.evrak_gonder:
                    payload.childTasklar.forEach(task => {
                        aciklama += task.dosya_esas_no + " - " + task.birim_adi + " dosyasına ";
                        task.data.forEach((evrak: any, index: number) => {
                            aciklama += evrak.dosyaData.name;
                            if (evrak.ekDosyalarData.length > 0) {
                                aciklama += ' ve ' + evrak.ekDosyalarData.map((ekEvrak: any) => ekEvrak.name).join(', ') + ' ek evraklı';
                            }
                            aciklama += " " + evrak.seciliTur.aciklama;
                            if (index + 1 !== task.data.length)
                                aciklama += ', ';
                        });
                        aciklama += " gönderilmesi. "
                    });
                    break;
                case TaskTuru.hazir_evrak_gonder:
                    aciklama = 'Evrak Türü: ' + payload.anaTask.data.evrakTur.aciklama + '. ' + payload.anaTask.data.dilekce.title +
                        ' gönderilmesi. Üçüncü şahıs bulunan dosyalara ' + (payload.anaTask.data.ucuncuSahsaGonder ? 'göderilsin.' : 'gönderilmesin.') +
                        ' (' + takipler + ')';
                    break;
                case TaskTuru.harc_masraf_ode:
                    aciklama += payload.anaTask.data.map((harcMasraf: any) => harcMasraf.adi).join(', ') + ' ödemelerinin yapılması. (' + takipler + ")";
                    break;
                case TaskTuru.mts_odeme:
                    aciklama += 'İcra takibi başlatma sebebi: ' + payload.anaTask.data.icraTakipSebep.isim + ' (' + takipler + ')';
                    break;
                case TaskTuru.toplu_genel_talep_hazirla:
                    aciklama = payload.anaTask.data.params.digerTalepParams.map((alan: any) => alan.talepKisaAdi).join(', ') + ' talebinin gönderilmesi. (' + takipler + ')';
                    break;
                case TaskTuru.toplu_haciz_gonder:
                    console.log('DATAAA:', payload.anaTask.data);
                    aciklama = payload.anaTask.data.params.hacizParams.map((alan: any) => alan.talepKisaAdi).join(', ') + ' gönderilmesi. (' + takipler + ')';
                    break;
                case TaskTuru.toplu_tebligat_hazirla:
                    aciklama = payload.anaTask.data.params.tebligatParams.map((alan: any) => alan.talepKisaAdi).join(', ') + ' talebinin gönderilmesi. (' + takipler + ')';
                    break;
                case TaskTuru.mts_tebligat_gonder:
                    aciklama = 'MTS tebligatının gönderilmesi. (' + takipler + ')'
                    break;
                case TaskTuru.makbuz_indir:
                    aciklama = 'Makbuzların indirilmesi. (' + takipler + ')'
                    break;
                case TaskTuru.takip_hazirla:
                    aciklama = payload.anaTask.data.xmlName + " XML'indeki takiplerin tevzi alınması.";
                    break;
                case TaskTuru.mts_takip_hazirla:
                    aciklama = payload.anaTask.data.xmlName + " XML'indeki MTS takiplerinin tevzi alınması.";
                    break;
                case TaskTuru.takip_ac:
                    aciklama = takiplerEsasNoYok + ' takiplerinin açılması.'
                    break;
                case TaskTuru.mts_takip_ac:
                    aciklama = takiplerEsasNoYok + ' MTS takiplerinin açılması.'
                    break;
                default:
                    break;
            }
            resolve(aciklama);
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}