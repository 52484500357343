import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface GetMaasHacziParamsTalep {
    // Boş
}

export interface GetMaasHacziParamsCevap {
    items: Array<GetMaasHacziParamsCevapRow>
}

export interface GetMaasHacziParamsCevapRow {
    name: string,
    value: number
}

export default class GetMaasHacziParams extends UyapRequest<GetMaasHacziParamsTalep, GetMaasHacziParamsCevap> {
    delayTime = 0;

    getMethod(): "POST" | "GET" {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "GetMaasHacziParams_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "main/jsp/avukat/getMaasHacziParams_brd.ajx";
    }

    async parse(data: any): Promise<GetMaasHacziParamsCevap> {
        return {items: data}
    }

    getMockResponse(): any {
        return [
            {"name": "Çalışan Kamu", "value": 0},
            {"name": "Çalışan Özel", "value": 1},
            {"name": "Emekli Kamu", "value": 2},
            {"name": "Emekli Özel", "value": 3}];
    }
}